import { FC, ReactNode } from "react";
import styled from "styled-components";
import { Tooltip as MaterialTooltip } from "@material-ui/core";
import { PunctThemeProps } from "../styles/theme";

type ContainerProps = PunctThemeProps<{ textOverflow?: boolean }>;

const Container = styled.div`
  white-space: ${(props: ContainerProps) =>
    props.textOverflow ? "nowrap" : "normal"};
  overflow: ${(props: ContainerProps) =>
    props.textOverflow ? "hidden" : "visible"};
  text-overflow: ellipsis;
`;

interface Props {
  title?: Exclude<ReactNode, null>;
  className?: string;
  textOverflow?: boolean;
}

const Tooltip: FC<Props> = ({
  title = "",
  className,
  textOverflow,
  children,
}) => {
  return (
    <MaterialTooltip title={!!title ? title : ""}>
      <Container className={className} textOverflow={textOverflow}>
        {children}
      </Container>
    </MaterialTooltip>
  );
};

export { Tooltip };
