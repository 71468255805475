import React, { FC } from "react";
import styled from "styled-components";

const StyledModalActions = styled.div`
  padding: 8px;

  display: flex;
  justify-content: flex-end;

  & > * + * {
    margin-inline-start: 8px;
  }
`;

interface Props {}

const ModalActions: FC<Props> = ({ children }) => {
  return <StyledModalActions>{children}</StyledModalActions>;
};

export { ModalActions };
