import React, { FC } from "react";
import { Icon, IconProps } from "./components/Icon";
import styled from "styled-components";

export const ChevronRight: FC<IconProps> = (props) => {
  return (
    <Icon {...props} d="M10 6L8.59 7.41 13.17 12l-4.58 4.59L10 18l6-6-6-6z" />
  );
};

export const ChevronDown = styled(ChevronRight)`
  transform: ${(props: { open?: boolean }) =>
    props.open ? "rotate(-90deg)" : "rotate(90deg)"};
`;
