import { useEffect } from "react";

const usePressKeys = (onEscape: () => void): void => {
  useEffect(() => {
    const handleKeyPress = (e: KeyboardEvent) => {
      if (e.keyCode === 27) {
        onEscape();
      } else if ((e.charCode || e.keyCode) === 13) {
        e.preventDefault();
      }
    };

    window.addEventListener("keyup", handleKeyPress, false);
    return () => window.removeEventListener("keyup", handleKeyPress, false);
  }, [onEscape]);
};

export { usePressKeys };
