import { ListItemIcon, Menu, MenuItem } from "@material-ui/core";
import React, { FC, useCallback } from "react";
import styled from "styled-components";
import { Logout } from "../../../icons/Logout";
import { HeaderAvatar } from "./HeaderAvatar";

const StyledHeaderUserMenu = styled.div`
  line-height: 0;
`;

interface Props {
  className?: string;
  userName?: string;
  userImage?: string;
  onRequestLogout: () => void;
}

const HeaderUserMenu: FC<Props> = ({
  className,
  userName,
  userImage,
  onRequestLogout,
}) => {
  const [anchorEl, setAnchorEl] = React.useState<HTMLElement | null>(null);

  const handleOpenMenu = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleCloseMenu = () => {
    setAnchorEl(null);
  };

  const handleRequestLogout = useCallback(() => {
    handleCloseMenu();
    onRequestLogout();
  }, [onRequestLogout]);

  return (
    <StyledHeaderUserMenu className={className}>
      <HeaderAvatar
        name={userName}
        image={userImage}
        onClick={handleOpenMenu}
        data-cy="header-avatar"
      />

      <Menu
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleCloseMenu}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
      >
        <MenuItem onClick={handleRequestLogout} data-cy="header-logout">
          <ListItemIcon>
            <Logout />
          </ListItemIcon>

          {/* Logout */}
          <span>התנתקות</span>
        </MenuItem>
      </Menu>
    </StyledHeaderUserMenu>
  );
};

export { HeaderUserMenu };
