import jwt_decode from "jwt-decode";
interface decodedIdToken {
  "https://punct.co.il/company": {
    name: string;
    id: number;
    active: boolean;
    taxId: string;
  };
  name: string;
  picture: string;
}

const decodeIdToken = (idToken: string) => {
  try {
    const decoded = jwt_decode<decodedIdToken>(idToken);

    const { "https://punct.co.il/company": company, name, picture } = decoded;
    const companyName = company?.name ?? "";
    const companyId = company?.id;

    return {
      companyId,
      companyName,
      name,
      userImage: picture,
      idTokenError: "",
    };
  } catch (error) {
    return { idTokenError: error as string };
  }
};

export { decodeIdToken };
