import { Alert } from "@material-ui/lab";
import React, { FC } from "react";
import { useDispatch, useSelector } from "react-redux";
import Snackbar from "@material-ui/core/Snackbar";
import { closeSnackbar, getSnackbars } from "./snackbarsSlice";

interface Props {}

const SnackbarsContainer: FC<Props> = () => {
  const snackbars = useSelector(getSnackbars);
  const dispatch = useDispatch();

  return (
    <>
      {snackbars.map(({ id, type, content }) => (
        <Snackbar
          key={id}
          open
          autoHideDuration={4000}
          onClose={() => dispatch(closeSnackbar({ snackbarId: id }))}
        >
          <Alert color={type} security={type}>
            {content}
          </Alert>
        </Snackbar>
      ))}
    </>
  );
};

export { SnackbarsContainer };
