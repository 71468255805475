import { webAuthClient } from "../clients";
import { SuccessfulAuth } from "../../auth/authSlice";
import { getUserInfo } from "./getUserInfo";

interface Props {
  hash: string;
}

const parseHash = ({ hash }: Props) =>
  new Promise<SuccessfulAuth>((resolve, reject) =>
    webAuthClient.parseHash({ hash }, (error, result) => {
      if (error || !result?.accessToken || !result?.idToken) {
        reject(error);
      } else {
        const { accessToken, idToken } = result;

        getUserInfo({ accessToken, idToken }).then(resolve).catch(reject);
      }
    }),
  );

export { parseHash };
