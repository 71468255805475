import { FormControl, FormHelperText } from "@material-ui/core";
import { FC, useCallback } from "react";
import MaterialChipInput, { BaseTextFieldProps } from "material-ui-chip-input";
import styled from "styled-components";

const StyledChipInput = styled(MaterialChipInput)``;

interface Props extends Omit<BaseTextFieldProps, "error"> {
  value: string[];
  error?: string;
  onBlur: () => void;
  onChange: (newValue: string[]) => void;
}

const ChipInput: FC<Props> = ({ value: chips, error, onBlur, onChange }) => {
  const handleSubmit = useCallback(
    (chip: string) => {
      onChange([...chips, chip]);
    },
    [chips],
  );

  const handleDelete = useCallback(
    (chip: string) => {
      onChange(chips.filter((value) => value !== chip));
    },
    [chips],
  );

  return (
    <FormControl error={!!error}>
      <StyledChipInput
        placeholder='הוספה (לדוגמא: "רצפה צפה")'
        error={!!error}
        value={chips}
        onAdd={handleSubmit}
        onDelete={handleDelete}
        onBlur={onBlur}
      />
      {error && <FormHelperText>{error}</FormHelperText>}
    </FormControl>
  );
};

export { ChipInput };
