import auth0 from "auth0-js";
import authParams from "../params";

const webAuthClient = new auth0.WebAuth({
  domain: authParams.domain,
  clientID: authParams.client_id,
  audience: authParams.audience,
  scope: authParams.scope,
  response_type: authParams.response_type,
});

export { webAuthClient };
