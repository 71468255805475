import { ChangeEventHandler, FC } from "react";
import styled from "styled-components";

const StyledCheckbox = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

type CheckboxProps = {
  name: string;
  isChecked: boolean;
  handleChange: ChangeEventHandler<HTMLInputElement>;
};

const Checkbox: FC<CheckboxProps> = ({
  name,
  isChecked,
  handleChange,
  children,
}) => {
  return (
    <StyledCheckbox>
      <input
        name={name}
        type="checkbox"
        checked={isChecked}
        onChange={handleChange}
      />

      {children}
    </StyledCheckbox>
  );
};

export { Checkbox };
