import { useCallback } from "react";
import { useFlag } from "../../../hooks/useFlag";
import { socialLogin } from "../../auth0/functions";
import { SocialLoginConnection } from "../../auth0/functions/socialLogin";

const useSocialLogin = () => {
  const [isLoading, setLoadingTrue, setLoadingFalse] = useFlag();

  const handleSocialLogin = useCallback((connection: SocialLoginConnection) => {
    setLoadingTrue();

    setTimeout(() => {
      socialLogin({ connection }).catch(setLoadingFalse);
    }, 0);
  }, []);

  return { isLoading, handleSocialLogin };
};

export { useSocialLogin };
