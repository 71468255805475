import { useCallback, useState } from "react";

type UseModal = () => [boolean, () => void, () => void];

const useModal: UseModal = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);

  const handleOpenModal = useCallback(() => {
    setIsModalOpen(true);
  }, []);

  const handleCloseModal = useCallback(() => {
    setIsModalOpen(false);
  }, []);

  return [isModalOpen, handleOpenModal, handleCloseModal];
};

export { useModal };
