import { useCallback } from "react";
import { useFlag } from "../../../hooks/useFlag";
import { useDispatch } from "react-redux";
import { logout } from "../../auth0";
import { resetAuth } from "../authSlice";
import { useApolloClient } from "@apollo/client";

const useLogout = () => {
  const dispatch = useDispatch();
  const client = useApolloClient();

  const [isLoading, onLoadingStart, onLoadingEnd] = useFlag();

  const handleLogout = useCallback(async () => {
    onLoadingStart();

    dispatch(resetAuth());
    await client.resetStore();

    logout().then(() => {
      onLoadingEnd();
    });
  }, [client, dispatch, onLoadingEnd, onLoadingStart]);

  return { isLoading, handleLogout };
};

export { useLogout };
