import { useCallback } from "react";
import { useDispatch } from "react-redux";
import { openSnackbar, SnackbarState } from "./snackbarsSlice";

const useSnackbar = () => {
  const dispatch = useDispatch();

  const handleOpenSnackbar = useCallback(
    (snackbarState: SnackbarState) => {
      dispatch(openSnackbar(snackbarState));
    },
    [dispatch],
  );

  const createHandleOpenInfoSnackbar = useCallback(
    (content: string) => () =>
      handleOpenSnackbar({
        type: "info",
        content,
      }),
    [handleOpenSnackbar],
  );

  const createHandleOpenSuccessSnackbar = useCallback(
    (content: string) => () =>
      handleOpenSnackbar({
        type: "success",
        content,
      }),
    [handleOpenSnackbar],
  );

  const createHandleOpenErrorSnackbar = useCallback(
    (content: string) => () => {
      handleOpenSnackbar({
        type: "error",
        content,
      });
    },
    [handleOpenSnackbar],
  );

  return {
    onOpenSnackbar: handleOpenSnackbar,
    createInfoSnackbar: createHandleOpenInfoSnackbar,
    createSuccessSnackbar: createHandleOpenSuccessSnackbar,
    createErrorSnackbar: createHandleOpenErrorSnackbar,
  };
};

export { useSnackbar };
