import React, { FC } from "react";
import styled from "styled-components";
import { Card as MaterialCard, CardContent } from "@material-ui/core";

const StyledCard = styled.div``;

interface Props {
  className?: string;
}

const Card: FC<Props> = ({ className, children }) => {
  return (
    <StyledCard>
      <MaterialCard raised>
        <CardContent>
          <div className={className}>{children}</div>
        </CardContent>
      </MaterialCard>
    </StyledCard>
  );
};

export { Card };
