import { combineReducers } from "@reduxjs/toolkit";
import { snackbarsReducer } from "../modules/snackbars";
import { authReducer } from "../modules/auth";
import { houterHistoryReducer } from "./routerHistoryReducer";

const rootReducer = combineReducers({
  auth: authReducer,
  snackbars: snackbarsReducer,
  routerHistory: houterHistoryReducer,
});

export { rootReducer };
