import { useRouter } from "next/router";
import React, { FC, useEffect } from "react";
import { useSelector } from "react-redux";
import { isClient } from "../../utils/isClient";
import { getUserRoles } from "./authSlice";
import { UserRole } from "../../generated/graphql";

interface Props {
  roles: UserRole[];
}

const AuthGuard: FC<Props> = ({ roles, children }) => {
  const router = useRouter();
  const userRoles = useSelector(getUserRoles);

  const overlappedRoles = userRoles
    ? roles.filter((role) => userRoles.includes(role))
    : [];

  const isAllowed = overlappedRoles.length > 0;

  useEffect(() => {
    if (!isAllowed) {
      if (isClient) {
        router.replace("/auth/login/");
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isAllowed]);

  if (!isAllowed) {
    return null;
  }

  return <>{children}</>;
};

export { AuthGuard };
