import Axios from "axios";

const externalApi = {
  uploadFile: async (
    file: File,
    uploadUrl: string,
  ): Promise<string | undefined> => {
    const {
      config: { url },
    } = await Axios.put(uploadUrl, file, {
      headers: { "Content-Type": file.type },
    });

    if (!url) {
      return undefined;
    }

    const imageUrl = new URL(url);

    return imageUrl.origin + imageUrl.pathname;
  },
};

export { externalApi };
