import React, { useState } from "react";
import styled from "styled-components";
import Divider from "@material-ui/core/Divider";
import { useSocialLogin } from "../login";
import { LoadingSpinner } from "../../../components/LoadingSpinner";
import { ForgetPasswordForm } from "./ForgetPasswordForm";
import { useForgetPassword } from ".";
import { AuthFormsContainer, SocialLoginForm } from "../components";

const StyledForgetPasswordView = styled.div`
  .ForgetPasswordView__headerText {
    font-size: 18px;
    font-weight: bold;
    color: #292929;
    margin-top: 60px;
    margin-bottom: 111px;
  }

  .ForgetPasswordView__divider {
    height: 0.2px;
    width: 100%;
    color: #707070;
    margin-bottom: 26.5px;
  }
`;

const ForgetPasswordView = () => {
  const [userEmail, setUserEmail] = useState("");

  const { handleSocialLogin, isLoading: isSocialLoginLoading } =
    useSocialLogin();

  const { isLoading, handleSendResetPasswordEmail } = useForgetPassword();

  const handleReset = () => {
    handleSendResetPasswordEmail(userEmail);
  };
  return (
    <StyledForgetPasswordView>
      <AuthFormsContainer>
        <LoadingSpinner isVisible={isLoading || isSocialLoginLoading} />
        <div className="ForgetPasswordView__headerText">איפוס סיסמה</div>

        <ForgetPasswordForm
          handleReset={handleReset}
          setUserEmail={setUserEmail}
        />
        <Divider className="ForgetPasswordView__divider" />
        <SocialLoginForm handleSocialLogin={handleSocialLogin} />
      </AuthFormsContainer>
    </StyledForgetPasswordView>
  );
};

export { ForgetPasswordView };
