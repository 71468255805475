import { addAuthorizationBearerToken } from "../../../api";
import { SuccessfulAuth } from "../authSlice";
import { configureScope } from "@sentry/nextjs";

const AUTH_KEY = "auth";

const setSentryUser = (auth?: SuccessfulAuth) => {
  const newUser = auth
    ? {
        id: auth.userId,
        username: auth.name,
        email: auth.email,
      }
    : null;
  configureScope((scope) => scope.setUser(newUser));
};

const authSerializer = {
  save: (auth: SuccessfulAuth) => {
    setSentryUser(auth);
    localStorage.setItem(AUTH_KEY, JSON.stringify(auth));
  },
  load: (): SuccessfulAuth | undefined => {
    try {
      const authRecord = localStorage.getItem(AUTH_KEY);

      if (authRecord) {
        const auth = JSON.parse(authRecord);

        if (auth.accessToken) {
          addAuthorizationBearerToken(auth.accessToken);
        }

        setSentryUser(auth);
        return auth;
      }
    } catch (err) {
      setSentryUser();
      return undefined;
    }
  },
  reset: () => {
    setSentryUser();
    localStorage.removeItem(AUTH_KEY);
  },
};

export { authSerializer };
