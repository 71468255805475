import type { AppProps } from "next/app";
import { FC } from "react";
import { ThemeProvider } from "styled-components";
import { Provider as ReduxProvider } from "react-redux";
import { Page } from "../../types/Page";
import { ApolloProvider } from "../apollo";
import { MaterialProvider } from "../material";
import { Base } from "../modules/base";
import { Reset } from "../styles/Reset";
import { Global } from "../styles/Global";
import { theme } from "../styles/theme";
import { store } from "../redux";
import { Tokens } from "../styles/Tokens";
import "../../styles/globals.css";

const PunctClient: FC<
  AppProps & {
    Component: Page;
  }
> = ({ Component, pageProps }) => {
  return (
    <>
      <Reset />
      <Global />
      <Tokens />

      <MaterialProvider>
        <ThemeProvider theme={theme}>
          <ReduxProvider store={store}>
            <ApolloProvider>
              <Base fullScreen={Component.fullScreen}>
                <Component {...pageProps} />
              </Base>
            </ApolloProvider>
          </ReduxProvider>
        </ThemeProvider>
      </MaterialProvider>
    </>
  );
};

export default PunctClient;
