import React, { FC, useContext } from "react";
import { Icon, IconProps } from "./components/Icon";
import { ThemeContext } from "styled-components";
import { PunctTheme } from "../styles/theme";

export const File: FC<IconProps> = ({ ...props }) => {
  const themeContext = useContext<PunctTheme>(ThemeContext);
  return (
    <Icon
      {...props}
      fill="none"
      d="M7.04113 1.39111V4.79877H10.2422M7.04113 1.39111H0.742188V14.6085H10.2422V4.79877M7.04113 1.39111L10.2422 4.79877"
      stroke={props.stroke ?? themeContext.colors.grey}
      viewBox="0 0 11 16"
    />
  );
};
