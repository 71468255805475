import { useCallback } from "react";
import { useFlag } from "../../../hooks/useFlag";
import { resetAuth } from "../authSlice";
import { useDispatch } from "react-redux";
import { useRouter } from "next/router";
import { sendResetPasswordEmail } from "../../auth0";
const useForgetPassword = () => {
  const { push } = useRouter();
  const dispatch = useDispatch();
  const [isLoading, onLoadingStart, onLoadingEnd] = useFlag();
  const handleSendResetPasswordEmail = useCallback(
    (email: string) => {
      onLoadingStart();
      sendResetPasswordEmail({ email })
        .then(() => {
          dispatch(resetAuth());
        })
        .finally(() => {
          onLoadingEnd();
        });
    },
    [push, dispatch],
  );
  return { isLoading, handleSendResetPasswordEmail };
};
export { useForgetPassword };
