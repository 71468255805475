import { webAuthClient } from "../clients";
import authParams from "../params";

const { client_id } = authParams;

const logout = () =>
  new Promise<void>(() =>
    webAuthClient.logout({
      returnTo: `${process.env.NEXT_PUBLIC_HOST}auth/login`,
      clientID: client_id,
    }),
  );

export { logout };
