import { BrowserTracing } from "@sentry/tracing";
import { NextjsOptions } from "@sentry/nextjs/dist/utils/nextjsOptions";
import { excludeGraphQLFetch } from "apollo-link-sentry";

const SENTRY_DSN = process.env.SENTRY_DSN || process.env.NEXT_PUBLIC_SENTRY_DSN;
const ENVIRONMENT =
  process.env.SENTRY_ENVIRONMENT || process.env.NEXT_PUBLIC_SENTRY_ENVIRONMENT;
const SENTRY_RELEASE =
  process.env.SENTRY_RELEASE || process.env.NEXT_PUBLIC_SENTRY_RELEASE;

export const baseSentryConfig: NextjsOptions = {
  dsn: SENTRY_DSN,
  tracesSampleRate: 0.01,
  sampleRate: 1,
  autoSessionTracking: true,
  release: SENTRY_RELEASE,
  integrations: [
    new BrowserTracing({ tracingOrigins: [/bff\.\w+\.punct\.co\.il/] }),
  ],
  beforeBreadcrumb: excludeGraphQLFetch,
  environment: ENVIRONMENT,
};
