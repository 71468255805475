import { FC } from "react";
import styled from "styled-components";
import { useSuccessfulSocialLogin } from ".";
import { Spinner } from "../../../components/Spinner";
import { Check, Close } from "../../../icons";

const StyledSuccessfulLogin = styled.div`
  display: flex;
  position: absolute;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  background-image: url("/userFormsBackground.png");
  background-repeat: round;

  .SuccessfulLogin__container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .SuccessfulLogin__icon {
    margin-block: 8px;
  }

  .SuccessfulLogin__message {
    font-family: sans-serif;
    font-size: 22px;
    font-weight: bold;
  }

  .SuccessfulLogin__link {
    cursor: pointer;
    font-family: sans-serif;
    font-size: 16px;
  }
`;

const ICON_SIZE = "32px";

const iconProps = {
  height: ICON_SIZE,
  width: ICON_SIZE,
};

const SuccessfulLoginView: FC = () => {
  const { isLoading, isSuccessful, message, handleRedirectToApp } =
    useSuccessfulSocialLogin();

  return (
    <StyledSuccessfulLogin>
      <div className="SuccessfulLogin__container">
        <div className="SuccessfulLogin__icon">
          {isLoading ? (
            <Spinner />
          ) : isSuccessful ? (
            <Check {...iconProps} />
          ) : (
            <Close {...iconProps} />
          )}
        </div>

        <div className="SuccessfulLogin__message">{message}</div>

        {!isLoading && isSuccessful && (
          <div className="SuccessfulLogin__link" onClick={handleRedirectToApp}>
            לחץ כאן אם אינך מועבר
          </div>
        )}
      </div>
    </StyledSuccessfulLogin>
  );
};

export { SuccessfulLoginView };
