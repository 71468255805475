import { createGlobalStyle } from "styled-components";

const Tokens = createGlobalStyle`
  .PageScroll__container {
    flex: 1;
    display: flex;
    flex-direction: column;
    min-height: 0;
  }
  
  .PageScroll__component {
    flex: 1;
    overflow: scroll;
  }
`;

export { Tokens };
