import { gql } from "@apollo/client";
import * as Apollo from "@apollo/client";
export type Maybe<T> = T | null;
export type InputMaybe<T> = T | null | undefined;
export type Exact<T extends { [key: string]: unknown }> = {
  [K in keyof T]: T[K];
};
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & {
  [SubKey in K]?: Maybe<T[SubKey]>;
};
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & {
  [SubKey in K]: Maybe<T[SubKey]>;
};
const defaultOptions = {} as const;
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  DateTime: any;
};

export type Attachment = {
  __typename?: "Attachment";
  entityId: Scalars["Int"];
  entityType: AttachmentEntityType;
  id: Scalars["Int"];
  mimeType: Scalars["String"];
  name: Scalars["String"];
  url: Scalars["String"];
};

export type AttachmentCreateInput = {
  entityId: Scalars["Int"];
  entityType: AttachmentEntityType;
  mimeType: Scalars["String"];
  name: Scalars["String"];
  url: Scalars["String"];
};

export enum AttachmentEntityType {
  Order = "Order",
  Requisition = "Requisition",
}

export type AttachmentsCreateOutput = {
  __typename?: "AttachmentsCreateOutput";
  attachments: Array<Attachment>;
  status: MutationStatus;
};

export type AttachmentsMutations = {
  __typename?: "AttachmentsMutations";
  createBulk: AttachmentsCreateOutput;
  delete: StatusOutput;
};

export type AttachmentsMutationsCreateBulkArgs = {
  data: Array<AttachmentCreateInput>;
};

export type AttachmentsMutationsDeleteArgs = {
  id: Scalars["Int"];
};

export type CatalogProduct = {
  __typename?: "CatalogProduct";
  companyId: Scalars["Int"];
  id: Scalars["Int"];
  product: Product;
  variantIds: Array<Scalars["Int"]>;
  variants: Array<ProductVariant>;
};

export type CatalogProductCreateInput = {
  companyId: Scalars["Int"];
  productId: Scalars["Int"];
  variantIds: Array<Scalars["Int"]>;
};

export type CatalogProductDeleteInput = {
  id: Scalars["Int"];
  variantId?: InputMaybe<Scalars["Int"]>;
};

export type CatalogProductsCreateBulkOutput = {
  __typename?: "CatalogProductsCreateBulkOutput";
  /** Created products */
  items: Array<CatalogProduct>;
  status: MutationStatus;
};

export type CatalogProductsFindManyFiltersInput = {
  /** Searches products within selected categories */
  categoryIds?: InputMaybe<Array<Scalars["Int"]>>;
  /** Searches products from the specified company catalog */
  companyIds?: InputMaybe<Array<Scalars["Int"]>>;
  productIds?: InputMaybe<Array<Scalars["Int"]>>;
  /** Searches by product or variant name */
  searchQuery?: InputMaybe<Scalars["String"]>;
};

export type CatalogProductsFindManyOutput = {
  __typename?: "CatalogProductsFindManyOutput";
  /** Product results */
  items: Array<CatalogProduct>;
  /** Products pagination info */
  pageInfo: PaginationInfo;
};

export type CatalogProductsMutations = {
  __typename?: "CatalogProductsMutations";
  addProducts: CatalogProductsCreateBulkOutput;
  addProductsByFilters: CatalogProductsCreateBulkOutput;
  deleteProducts: StatusOutput;
  deleteProductsByFilters: StatusOutput;
};

export type CatalogProductsMutationsAddProductsArgs = {
  data: Array<CatalogProductCreateInput>;
};

export type CatalogProductsMutationsAddProductsByFiltersArgs = {
  companyId: Scalars["Int"];
  filters: ProductsFindManyFiltersInput;
  totalItems: Scalars["Int"];
};

export type CatalogProductsMutationsDeleteProductsArgs = {
  data: Array<CatalogProductDeleteInput>;
};

export type CatalogProductsMutationsDeleteProductsByFiltersArgs = {
  companyId: Scalars["Int"];
  filters: CatalogProductsFindManyFiltersInput;
  totalItems: Scalars["Int"];
};

export type CategoriesFindManyOutput = {
  __typename?: "CategoriesFindManyOutput";
  /** Array of categories */
  items: Array<Category>;
};

export type CategoriesMutations = {
  __typename?: "CategoriesMutations";
  create: CategoryUpdateOutput;
  delete: StatusOutput;
  update: CategoryUpdateOutput;
};

export type CategoriesMutationsCreateArgs = {
  category: CategoryCreateInput;
};

export type CategoriesMutationsDeleteArgs = {
  categoryId: Scalars["Int"];
};

export type CategoriesMutationsUpdateArgs = {
  category: CategoryCreateInput;
  categoryId: Scalars["Int"];
};

export type Category = {
  __typename?: "Category";
  /** Contains array of child categories. If an array is empty, then this is a leaf category. */
  children: Array<Category>;
  /** If this category is assigned to any products */
  hasProducts: Scalars["Boolean"];
  /** Category id */
  id: Scalars["Int"];
  /** Category name */
  name: Scalars["String"];
  /** Parent category id */
  parentId: Maybe<Scalars["Int"]>;
};

export type CategoryCreateInput = {
  /** Category name */
  name: Scalars["String"];
  /** Parent category id */
  parentId?: InputMaybe<Scalars["Int"]>;
};

export type CategoryUpdateOutput = {
  __typename?: "CategoryUpdateOutput";
  category: Category;
  status: MutationStatus;
};

export type CompaniesFindManyOutput = {
  __typename?: "CompaniesFindManyOutput";
  /** Companies */
  items: Array<Company>;
};

export type CompaniesMutations = {
  __typename?: "CompaniesMutations";
  create: CompaniesUpdateOutput;
  update: CompaniesUpdateOutput;
};

export type CompaniesMutationsCreateArgs = {
  company: CompanyCreateInput;
};

export type CompaniesMutationsUpdateArgs = {
  company: CompanyUpdateInput;
  companyId: Scalars["Int"];
};

export type CompaniesUpdateOutput = {
  __typename?: "CompaniesUpdateOutput";
  company: Company;
  status: MutationStatus;
};

export type Company = {
  __typename?: "Company";
  active: Scalars["Boolean"];
  id: Scalars["Int"];
  name: Scalars["String"];
  projects: Array<Project>;
  supplierIds: Array<Scalars["Int"]>;
  suppliers: Array<Supplier>;
  taxId: Maybe<Scalars["String"]>;
  useCompanyCatalog: Scalars["Boolean"];
};

export type CompanyCreateInput = {
  active: Scalars["Boolean"];
  name: Scalars["String"];
  taxId?: InputMaybe<Scalars["String"]>;
};

export type CompanyUpdateInput = {
  active?: InputMaybe<Scalars["Boolean"]>;
  name?: InputMaybe<Scalars["String"]>;
  supplierIds?: InputMaybe<Array<Scalars["Int"]>>;
  taxId?: InputMaybe<Scalars["String"]>;
  useCompanyCatalog?: InputMaybe<Scalars["Boolean"]>;
};

export type GraphQlMetadataField = {
  __typename?: "GraphQLMetadataField";
  canEdit: Scalars["Boolean"];
  /** Reason for why the field is not editable */
  reason: Scalars["String"];
};

export type LineItem = {
  __typename?: "LineItem";
  /** Used for product variant information in custom line items */
  description: Maybe<Scalars["String"]>;
  discount: Scalars["Int"];
  discountAmount: Maybe<Scalars["Float"]>;
  discountAmountFormatted: Maybe<Scalars["String"]>;
  discountTotalAmount: Maybe<Scalars["Float"]>;
  discountTotalAmountFormatted: Maybe<Scalars["String"]>;
  /** Line Item display image */
  displayImage: Maybe<Scalars["String"]>;
  /** Line Item display name */
  displayName: Scalars["String"];
  /** Line Item display size */
  displaySize: Scalars["String"];
  /** Line Item display variant name */
  displayVariantName: Maybe<Scalars["String"]>;
  /** Line Item id */
  id: Scalars["Int"];
  /** True if the line item is custom and has no corresponding product */
  isCustom: Scalars["Boolean"];
  /** True if the line item corresponds to existing product */
  isProduct: Scalars["Boolean"];
  /** Line Item note */
  note: Maybe<Scalars["String"]>;
  order: Maybe<Order>;
  orderId: Scalars["Int"];
  price: Maybe<Scalars["Float"]>;
  /** Line Item display price */
  priceFormatted: Maybe<Scalars["String"]>;
  priceTotal: Maybe<Scalars["Float"]>;
  priceTotalFormatted: Maybe<Scalars["String"]>;
  priceTotalWithDiscount: Maybe<Scalars["Float"]>;
  priceTotalWithDiscountFormatted: Maybe<Scalars["String"]>;
  priceTotalWithVat: Maybe<Scalars["Float"]>;
  priceTotalWithVatFormatted: Maybe<Scalars["String"]>;
  priceWithDiscount: Maybe<Scalars["Float"]>;
  /** Line Item display price price with discount */
  priceWithDiscountFormatted: Maybe<Scalars["String"]>;
  priceWithVat: Maybe<Scalars["Float"]>;
  /** Line Item display price price with vat applied after discount */
  priceWithVatFormatted: Maybe<Scalars["String"]>;
  product: Maybe<Product>;
  /** Line Item product id */
  productId: Maybe<Scalars["Int"]>;
  /** Line Item quantity */
  quantity: Scalars["Float"];
  /** Id of the linked requisition line items */
  requisitionItemId: Maybe<Scalars["Int"]>;
  requisitionLineItem: Maybe<RequisitionLineItem>;
  /** Line Item variant id */
  variantId: Maybe<Scalars["Int"]>;
  vat: Scalars["Float"];
  vatAmount: Maybe<Scalars["Float"]>;
  vatAmountFormatted: Maybe<Scalars["String"]>;
};

export type LineItemAddCustomOutput = {
  __typename?: "LineItemAddCustomOutput";
  lineItem: LineItem;
  lineItemId: Scalars["Int"];
};

export type LineItemAddProductsOutput = {
  __typename?: "LineItemAddProductsOutput";
  lineItems: Array<LineItem>;
  status: MutationStatus;
};

export type LineItemCustomCreateInput = {
  /** Line item description */
  description?: InputMaybe<Scalars["String"]>;
  discount?: InputMaybe<Scalars["Int"]>;
  /** Line item name */
  name?: InputMaybe<Scalars["String"]>;
  /** Line Item comment */
  note?: InputMaybe<Scalars["String"]>;
  price?: InputMaybe<Scalars["Float"]>;
  /** Product Quantity */
  quantity: Scalars["Float"];
  /** Line Item comment */
  size?: InputMaybe<Scalars["String"]>;
};

export type LineItemRemoveOutput = {
  __typename?: "LineItemRemoveOutput";
  status: MutationStatus;
};

export type LineItemUpdateOutput = {
  __typename?: "LineItemUpdateOutput";
  lineItem: LineItem;
  status: MutationStatus;
};

export type LineItemsMutations = {
  __typename?: "LineItemsMutations";
  /** Creates a new custom line item on the order. */
  addCustomLineItemToOrder: LineItemAddCustomOutput;
  addCustomProductsToRequisition: RequisitionLineItemAddProductsOutput;
  /**
   *
   *     Adds a line item to the order, if the line item already exists, then it adds
   *     new line item quantity to the existing one.
   *
   */
  addProductsToOrder: LineItemAddProductsOutput;
  /**
   *
   *     Adds a line item to the requisition, if the line item already exists, then it adds
   *     new line item quantity to the existing one.
   *
   */
  addProductsToRequisition: RequisitionLineItemAddProductsOutput;
  removeOrderLineItem: LineItemRemoveOutput;
  removeRequisitionLineItem: LineItemRemoveOutput;
  /** Updates Custom Line Item */
  updateCustomOrderLineItem: LineItemUpdateOutput;
  /** Updates Custom Requisition Line Item */
  updateCustomRequisitionLineItem: RequisitionLineItemUpdateOutput;
  /** Updates Product Line Item */
  updateOrderLineItemWithProduct: LineItemUpdateOutput;
  updateRequisitionLineItemWithProduct: RequisitionLineItemUpdateOutput;
  updateRequisitionLineItemsSupplierId: RequisitionLineItemsUpdateOutput;
};

export type LineItemsMutationsAddCustomLineItemToOrderArgs = {
  lineItemCustom: LineItemCustomCreateInput;
  orderId: Scalars["Int"];
};

export type LineItemsMutationsAddCustomProductsToRequisitionArgs = {
  lineItemProducts: Array<RequisitionLineItemCustomCreateInput>;
  requisitionId: Scalars["Int"];
};

export type LineItemsMutationsAddProductsToOrderArgs = {
  companyId: Scalars["Int"];
  lineItemProducts: Array<OrderLineItemProductCreateInput>;
  orderId: Scalars["Int"];
  supplierId: Scalars["Int"];
};

export type LineItemsMutationsAddProductsToRequisitionArgs = {
  companyId: Scalars["Int"];
  lineItemProducts: Array<RequisitionLineItemProductCreateInput>;
  requisitionId: Scalars["Int"];
};

export type LineItemsMutationsRemoveOrderLineItemArgs = {
  lineItemIds: Array<Scalars["Int"]>;
};

export type LineItemsMutationsRemoveRequisitionLineItemArgs = {
  lineItemIds: Array<Scalars["Int"]>;
};

export type LineItemsMutationsUpdateCustomOrderLineItemArgs = {
  lineItemCustom: OrderLineItemCustomUpdateInput;
  lineItemId: Scalars["Int"];
};

export type LineItemsMutationsUpdateCustomRequisitionLineItemArgs = {
  lineItemCustom: RequisitionLineItemCustomUpdateInput;
  lineItemId: Scalars["Int"];
};

export type LineItemsMutationsUpdateOrderLineItemWithProductArgs = {
  companyId: Scalars["Int"];
  lineItemId: Scalars["Int"];
  lineItemProduct: OrderLineItemProductUpdateInput;
  supplierId: Scalars["Int"];
};

export type LineItemsMutationsUpdateRequisitionLineItemWithProductArgs = {
  companyId: Scalars["Int"];
  lineItemId: Scalars["Int"];
  lineItemProduct: RequisitionLineItemProductUpdateInput;
  supplierId: InputMaybe<Scalars["Int"]>;
};

export type LineItemsMutationsUpdateRequisitionLineItemsSupplierIdArgs = {
  lineItemIds: Array<Scalars["Int"]>;
  supplierId: InputMaybe<Scalars["Int"]>;
};

export type Mutation = {
  __typename?: "Mutation";
  attachments: AttachmentsMutations;
  catalogProducts: CatalogProductsMutations;
  categories: CategoriesMutations;
  companies: CompaniesMutations;
  lineItems: LineItemsMutations;
  orders: OrderMutations;
  persons: PersonsMutations;
  prices: PricesMutations;
  products: ProductsMutations;
  projects: ProjectsMutations;
  requisitions: RequisitionMutations;
  shipments: ShipmentsMutations;
  subCompanies: SubCompaniesMutations;
};

export enum MutationStatus {
  Failure = "FAILURE",
  Success = "SUCCESS",
}

export type Order = {
  __typename?: "Order";
  attachments: Array<Attachment>;
  /** Order Created Date */
  createdDate: Scalars["String"];
  creator: Person;
  /** Order Delivery Date */
  deliveryDate: Scalars["String"];
  discount: Scalars["Int"];
  dropOffContact: Person;
  /** Drop off contact for the order */
  dropOffContactId: Scalars["Int"];
  /** Order ID */
  id: Scalars["Int"];
  lineItems: Array<LineItem>;
  metadata: OrderMetadata;
  /** Order's note */
  note: Maybe<Scalars["String"]>;
  /** Person that created the order */
  personId: Scalars["Int"];
  project: Project;
  /** Project ID */
  projectId: Scalars["Int"];
  shipments: Array<Shipment>;
  /** Order status */
  status: OrderStatus;
  subCompany: Maybe<SubCompany>;
  subCompanyId: Maybe<Scalars["Int"]>;
  supplier: Maybe<Supplier>;
  /** Order's supplier id */
  supplierId: Maybe<Scalars["Int"]>;
};

export type OrderCreateInput = {
  /** Order Delivery Date */
  deliveryDate: Scalars["DateTime"];
  /** Drop off contact ID */
  dropOffContactId: Scalars["Int"];
  /** Order's note */
  note?: InputMaybe<Scalars["String"]>;
  /** Order Project ID */
  projectId: Scalars["Int"];
  subCompanyId?: InputMaybe<Scalars["Int"]>;
  /** Order Supplier ID */
  supplierId: Scalars["Int"];
};

export type OrderCreateOutput = {
  __typename?: "OrderCreateOutput";
  order: Order;
  orderId: Scalars["Int"];
};

export type OrderLineItemCustomUpdateInput = {
  /** Line item description */
  description?: InputMaybe<Scalars["String"]>;
  discount?: InputMaybe<Scalars["Int"]>;
  /** Line item name */
  name?: InputMaybe<Scalars["String"]>;
  /** Line Item comment */
  note?: InputMaybe<Scalars["String"]>;
  price?: InputMaybe<Scalars["Float"]>;
  /** Product Quantity */
  quantity?: InputMaybe<Scalars["Float"]>;
  /** Line Item comment */
  size?: InputMaybe<Scalars["String"]>;
};

export type OrderLineItemProductCreateInput = {
  discount?: InputMaybe<Scalars["Int"]>;
  /** Line Item comment */
  note?: InputMaybe<Scalars["String"]>;
  price?: InputMaybe<Scalars["Float"]>;
  /** Product ID */
  productId: Scalars["Int"];
  /** Product Quantity */
  quantity: Scalars["Float"];
  /** Product Variant ID */
  variantId?: InputMaybe<Scalars["Int"]>;
};

export type OrderLineItemProductUpdateInput = {
  discount?: InputMaybe<Scalars["Int"]>;
  /** Line Item comment */
  note?: InputMaybe<Scalars["String"]>;
  price?: InputMaybe<Scalars["Float"]>;
  /** Product Quantity */
  quantity?: InputMaybe<Scalars["Float"]>;
  /** Product Variant ID */
  variantId?: InputMaybe<Scalars["Int"]>;
};

export type OrderMetadata = {
  __typename?: "OrderMetadata";
  canAttachFiles: Scalars["Boolean"];
  canDelete: Scalars["Boolean"];
  fields: OrderMetadataFields;
  /** Order ID */
  id: Scalars["Int"];
};

export type OrderMetadataFields = {
  __typename?: "OrderMetadataFields";
  deliveryDate: GraphQlMetadataField;
  discount: GraphQlMetadataField;
  items: GraphQlMetadataField;
  note: GraphQlMetadataField;
  shipments: GraphQlMetadataField;
  status: OrderMetadataStatusField;
  subCompanyId: GraphQlMetadataField;
  supplierId: GraphQlMetadataField;
};

export type OrderMetadataStatusField = {
  __typename?: "OrderMetadataStatusField";
  canEdit: Scalars["Boolean"];
  possibleValues: Array<OrderStatus>;
  /** Reason for why the field is not editable */
  reason: Scalars["String"];
};

export type OrderMutations = {
  __typename?: "OrderMutations";
  create: OrderCreateOutput;
  remove: StatusOutput;
  update: OrderUpdateOutput;
  updateStatus: OrderUpdateOutput;
  updateSupplier: OrderUpdateOutput;
};

export type OrderMutationsCreateArgs = {
  order: OrderCreateInput;
};

export type OrderMutationsRemoveArgs = {
  orderId: Scalars["Int"];
};

export type OrderMutationsUpdateArgs = {
  order: OrderUpdateInput;
  orderId: Scalars["Int"];
};

export type OrderMutationsUpdateStatusArgs = {
  orderId: Scalars["Int"];
  status: OrderStatus;
};

export type OrderMutationsUpdateSupplierArgs = {
  orderId: Scalars["Int"];
  supplierId: Scalars["Int"];
};

export enum OrderStatus {
  Approved = "APPROVED",
  AwaitingDelivery = "AWAITING_DELIVERY",
  Cancelled = "CANCELLED",
  Delivered = "DELIVERED",
  Draft = "DRAFT",
  InRevision = "IN_REVISION",
  PartiallyDelivered = "PARTIALLY_DELIVERED",
  PendingApproval = "PENDING_APPROVAL",
}

export type OrderUpdateInput = {
  /** Order delivery date */
  deliveryDate?: InputMaybe<Scalars["String"]>;
  discount?: InputMaybe<Scalars["Int"]>;
  /** Order's Comment */
  note?: InputMaybe<Scalars["String"]>;
  /** Order Status */
  status?: InputMaybe<OrderStatus>;
  subCompanyId?: InputMaybe<Scalars["Int"]>;
  /** Order Supplier id */
  supplierId?: InputMaybe<Scalars["Int"]>;
};

export type OrderUpdateOutput = {
  __typename?: "OrderUpdateOutput";
  order: Order;
  orderId: Scalars["Int"];
};

export type OrdersFindManyFiltersInput = {
  /** Returns orders that have been created on or before specified day */
  createdEndDate?: InputMaybe<Scalars["String"]>;
  /** Returns orders that have been created on or after specified day */
  createdStartDate?: InputMaybe<Scalars["String"]>;
  /** Returns orders that has a delivery date set on or before specified day */
  deliveryEndDate?: InputMaybe<Scalars["String"]>;
  /** Returns orders that has a delivery date set on or after specified day */
  deliveryStartDate?: InputMaybe<Scalars["String"]>;
  /** Returns orders that belong to on of specified projects */
  projectsIds?: InputMaybe<Array<Scalars["Int"]>>;
  /** Returns orders that has an id partially equal to the query */
  searchQuery?: InputMaybe<Scalars["String"]>;
  /** Returns orders that has one of specified statuses */
  statuses?: InputMaybe<Array<OrderStatus>>;
  subCompanyIds?: InputMaybe<Array<Scalars["Int"]>>;
  supplierIds?: InputMaybe<Array<Scalars["Int"]>>;
};

export type OrdersFindManyOutput = {
  __typename?: "OrdersFindManyOutput";
  items: Array<Order>;
  pageInfo: PaginationInfo;
};

export type OrdersSortInput = {
  sortByProperty: OrdersSortProp;
  sortDirection: SortDirection;
};

export enum OrdersSortProp {
  CreatedDate = "createdDate",
  DeliveryDate = "deliveryDate",
  Id = "id",
  SubCompanyId = "subCompanyId",
}

export type PaginationArgs = {
  /** The number of requested page */
  page: Scalars["Int"];
  /** Amount of pages per request */
  perPage: Scalars["Int"];
};

export type PaginationInfo = {
  __typename?: "PaginationInfo";
  /** When paginating forwards, are there more items? */
  hasNextPage: Scalars["Boolean"];
  /** When paginating backwards, are there more items? */
  hasPreviousPage: Scalars["Boolean"];
  /** Current page number */
  page: Scalars["Int"];
  /** Number of items per page */
  perPage: Scalars["Int"];
  /** Total number of items */
  totalItems: Scalars["Int"];
  /** Total number of pages */
  totalPages: Scalars["Int"];
};

export type Person = {
  __typename?: "Person";
  company: Company;
  /** Person company id */
  companyId: Scalars["Int"];
  /** Person email address */
  email: Scalars["String"];
  /** Person id */
  id: Scalars["Int"];
  /** Person name */
  name: Maybe<Scalars["String"]>;
  /** Person phone number */
  phoneNumber: Maybe<Scalars["String"]>;
  projectIds: Array<Scalars["Int"]>;
  projects: Array<Project>;
  role: Maybe<UserRole>;
};

export type PersonUpdateInput = {
  email?: InputMaybe<Scalars["String"]>;
  name?: InputMaybe<Scalars["String"]>;
  phoneNumber?: InputMaybe<Scalars["String"]>;
};

export type PersonUpdateManyInput = {
  email?: InputMaybe<Scalars["String"]>;
  id: Scalars["Int"];
  name?: InputMaybe<Scalars["String"]>;
  phoneNumber?: InputMaybe<Scalars["String"]>;
};

export type PersonUpdateOutput = {
  __typename?: "PersonUpdateOutput";
  person: Person;
  status: MutationStatus;
};

export type PersonsFindManyFiltersInput = {
  /** Returns persons of the specified companies */
  companyIds?: InputMaybe<Array<Scalars["Int"]>>;
  /** Returns persons assigned to the projects + all order moderators of those projects' companies */
  projectsIds?: InputMaybe<Array<Scalars["Int"]>>;
  /** search by name & email */
  searchQuery?: InputMaybe<Scalars["String"]>;
};

export type PersonsFindManyOutput = {
  __typename?: "PersonsFindManyOutput";
  /** Persons */
  items: Array<Person>;
  pageInfo: PaginationInfo;
};

export type PersonsMutations = {
  __typename?: "PersonsMutations";
  update: PersonUpdateOutput;
  updateMany: PersonUpdateOutput;
  updateProjects: PersonUpdateOutput;
};

export type PersonsMutationsUpdateArgs = {
  data: PersonUpdateInput;
  id: Scalars["Int"];
};

export type PersonsMutationsUpdateManyArgs = {
  data: Array<PersonUpdateManyInput>;
};

export type PersonsMutationsUpdateProjectsArgs = {
  id: Scalars["Int"];
  projectIds: Array<Scalars["Int"]>;
};

export type PriceCreateInput = {
  companyId: Scalars["Int"];
  price: Scalars["Int"];
  productId: Scalars["Int"];
  supplierId: Scalars["Int"];
  variantId?: InputMaybe<Scalars["Int"]>;
};

export type PriceCreateOutput = {
  __typename?: "PriceCreateOutput";
  status: MutationStatus;
};

export type PricesMutations = {
  __typename?: "PricesMutations";
  create: PriceCreateOutput;
};

export type PricesMutationsCreateArgs = {
  price: PriceCreateInput;
};

export type Product = {
  __typename?: "Product";
  /** Other names for the product */
  aka: Array<Scalars["String"]>;
  /** Product attributes */
  attributes: Array<ProductAttribute>;
  /** Product category ids */
  categoryIds: Array<Scalars["Int"]>;
  /** Ids of companies that have this product in their catalog */
  companyCatalogProducts: Array<CatalogProduct>;
  displaySize: Scalars["String"];
  /** Product id */
  id: Scalars["Int"];
  images: Array<Scalars["String"]>;
  /** Product active status */
  isActive: Scalars["Boolean"];
  /** Product name */
  name: Scalars["String"];
  /** Product primary image */
  primaryImage: Maybe<Scalars["String"]>;
  /** Product size */
  size: ProductSize;
  supplierItems: Array<ProductSupplier>;
  /** Product variants */
  variants: Array<ProductVariant>;
};

export type ProductAttribute = {
  __typename?: "ProductAttribute";
  /** Attribute label */
  attribute: Scalars["String"];
  /** HTML string with an attribute value for this product */
  description: Scalars["String"];
};

export type ProductCreateAttributeInput = {
  /** Attribute label */
  attribute: Scalars["String"];
  /** HTML string with an attribute value for this product */
  description: Scalars["String"];
};

export type ProductCreateInput = {
  aka: Array<Scalars["String"]>;
  attributes: Array<ProductCreateAttributeInput>;
  categories: Array<Scalars["Int"]>;
  images: Array<Scalars["String"]>;
  isActive: Scalars["Boolean"];
  name: Scalars["String"];
  primaryImage?: InputMaybe<Scalars["String"]>;
  size: ProductCreateSizeInput;
  supplierItems: Array<ProductCreateSupplierInput>;
  variants: Array<ProductVariantCreateInput>;
};

export type ProductCreateOutput = {
  __typename?: "ProductCreateOutput";
  product: Product;
};

export type ProductCreateSizeInput = {
  amount?: InputMaybe<Scalars["Float"]>;
  length?: InputMaybe<Scalars["Float"]>;
  type: ProductSizeType;
  unit: ProductSizeUnit;
  width?: InputMaybe<Scalars["Float"]>;
};

export type ProductCreateSupplierInput = {
  manufacturer?: InputMaybe<Scalars["String"]>;
  name?: InputMaybe<Scalars["String"]>;
  price?: InputMaybe<Scalars["Int"]>;
  productId?: InputMaybe<Scalars["String"]>;
  status: ProductVariantStatus;
  supplier: Scalars["Int"];
};

export type ProductSize = {
  __typename?: "ProductSize";
  amount: Maybe<Scalars["Float"]>;
  length: Maybe<Scalars["Float"]>;
  type: ProductSizeType;
  unit: ProductSizeUnit;
  width: Maybe<Scalars["Float"]>;
};

export enum ProductSizeType {
  Length = "LENGTH",
  Package = "PACKAGE",
  Squares = "SQUARES",
  SquareMeter = "SQUARE_METER",
  Surface = "SURFACE",
  Unit = "UNIT",
  Volume = "VOLUME",
  Weight = "WEIGHT",
}

export enum ProductSizeUnit {
  Acres = "ACRES",
  Cm = "CM",
  CubicMeter = "CUBIC_METER",
  Gram = "GRAM",
  Items = "ITEMS",
  Kg = "KG",
  Liter = "LITER",
  Meter = "METER",
  Milliliter = "MILLILITER",
  Mm = "MM",
  SquareMeter = "SQUARE_METER",
  Surfaces = "SURFACES",
  TubeInches = "TUBE_INCHES",
  Unit = "UNIT",
}

export type ProductSupplier = {
  __typename?: "ProductSupplier";
  id: Scalars["Int"];
  manufacturer: Maybe<Scalars["String"]>;
  name: Maybe<Scalars["String"]>;
  price: Maybe<Scalars["Float"]>;
  productId: Maybe<Scalars["String"]>;
  status: ProductVariantStatus;
  supplier: Scalars["Int"];
};

export type ProductSupplierPrice = {
  __typename?: "ProductSupplierPrice";
  id: Scalars["String"];
  price: Maybe<Scalars["Float"]>;
  priceFormatted: Maybe<Scalars["String"]>;
  productId: Maybe<Scalars["Int"]>;
  supplierId: Scalars["Int"];
  supplierName: Scalars["String"];
  variantId: Maybe<Scalars["Int"]>;
};

export type ProductSupplierPricesFindManyOutput = {
  __typename?: "ProductSupplierPricesFindManyOutput";
  items: Array<ProductSupplierPrice>;
};

export type ProductUpdateInput = {
  aka: Array<Scalars["String"]>;
  attributes: Array<ProductCreateAttributeInput>;
  categories: Array<Scalars["Int"]>;
  images: Array<Scalars["String"]>;
  isActive: Scalars["Boolean"];
  name: Scalars["String"];
  primaryImage?: InputMaybe<Scalars["String"]>;
  size: ProductCreateSizeInput;
  supplierItems: Array<ProductUpdateSupplierInput>;
  variants: Array<ProductUpdateVariantInput>;
};

export type ProductUpdateSupplierInput = {
  id?: InputMaybe<Scalars["Int"]>;
  manufacturer?: InputMaybe<Scalars["String"]>;
  name?: InputMaybe<Scalars["String"]>;
  price?: InputMaybe<Scalars["Int"]>;
  productId?: InputMaybe<Scalars["String"]>;
  status: ProductVariantStatus;
  supplier: Scalars["Int"];
};

export type ProductUpdateVariantInput = {
  color?: InputMaybe<Scalars["String"]>;
  id?: InputMaybe<Scalars["Int"]>;
  image?: InputMaybe<Scalars["String"]>;
  name: Scalars["String"];
  size: ProductCreateSizeInput;
  status: ProductVariantStatus;
  supplierItems: Array<ProductUpdateSupplierInput>;
};

export type ProductVariant = {
  __typename?: "ProductVariant";
  /** Variant color */
  color: Maybe<Scalars["String"]>;
  /** Variant ID */
  id: Scalars["Int"];
  /** Variant image url */
  image: Maybe<Scalars["String"]>;
  /** Variant name */
  name: Scalars["String"];
  /** Variant size */
  size: ProductSize;
  status: ProductVariantStatus;
  supplierItems: Array<ProductSupplier>;
};

export type ProductVariantCreateInput = {
  color?: InputMaybe<Scalars["String"]>;
  image?: InputMaybe<Scalars["String"]>;
  name: Scalars["String"];
  size: ProductCreateSizeInput;
  status: ProductVariantStatus;
  supplierItems: Array<ProductCreateSupplierInput>;
};

export enum ProductVariantStatus {
  Active = "Active",
  Inactive = "Inactive",
}

export type ProductsFindManyFiltersInput = {
  /** Searches products within selected categories */
  categoryIds?: InputMaybe<Array<Scalars["Int"]>>;
  /** Searches products within the specified companies' catalogs */
  companyIds?: InputMaybe<Array<Scalars["Int"]>>;
  /** Searches by product or variant name */
  searchQuery?: InputMaybe<Scalars["String"]>;
};

export type ProductsFindManyOutput = {
  __typename?: "ProductsFindManyOutput";
  /** Product results */
  items: Array<Product>;
  /** Products pagination info */
  pageInfo: PaginationInfo;
};

export type ProductsMutations = {
  __typename?: "ProductsMutations";
  create: ProductCreateOutput;
  update: ProductCreateOutput;
};

export type ProductsMutationsCreateArgs = {
  product: ProductCreateInput;
};

export type ProductsMutationsUpdateArgs = {
  product: ProductUpdateInput;
  productId: Scalars["Int"];
};

export type Project = {
  __typename?: "Project";
  active: Scalars["Boolean"];
  company: Company;
  companyId: Scalars["Int"];
  geoLocation: Scalars["String"];
  id: Scalars["Int"];
  name: Scalars["String"];
  personIds: Array<Scalars["Int"]>;
  persons: Array<Person>;
  subCompanyId: Maybe<Scalars["Int"]>;
};

export type ProjectCreateInput = {
  active: Scalars["Boolean"];
  geoLocation: Scalars["String"];
  name: Scalars["String"];
  personIds?: InputMaybe<Array<Scalars["Int"]>>;
  subCompanyId?: InputMaybe<Scalars["Int"]>;
};

export type ProjectCreateOutput = {
  __typename?: "ProjectCreateOutput";
  project: Project;
  status: MutationStatus;
};

export type ProjectUpdateInput = {
  active?: InputMaybe<Scalars["Boolean"]>;
  geoLocation?: InputMaybe<Scalars["String"]>;
  name?: InputMaybe<Scalars["String"]>;
  personIds?: InputMaybe<Array<Scalars["Int"]>>;
  subCompanyId?: InputMaybe<Scalars["Int"]>;
};

export type ProjectUpdateOutput = {
  __typename?: "ProjectUpdateOutput";
  project: Project;
  status: MutationStatus;
};

export type ProjectsFindManyOutput = {
  __typename?: "ProjectsFindManyOutput";
  items: Array<Project>;
};

export type ProjectsGetByCompanyIdOutput = {
  __typename?: "ProjectsGetByCompanyIdOutput";
  items: Array<Project>;
};

export type ProjectsMutations = {
  __typename?: "ProjectsMutations";
  create: ProjectCreateOutput;
  update: ProjectUpdateOutput;
};

export type ProjectsMutationsCreateArgs = {
  companyId: Scalars["Int"];
  project: ProjectCreateInput;
};

export type ProjectsMutationsUpdateArgs = {
  project: ProjectUpdateInput;
  projectId: Scalars["Int"];
};

export type Query = {
  __typename?: "Query";
  attributesGet: Array<ProductAttribute>;
  catalogProductsFindMany: CatalogProductsFindManyOutput;
  categoriesFindMany: CategoriesFindManyOutput;
  companiesFindMany: CompaniesFindManyOutput;
  companyById: Company;
  lineItemsGetByOrderId: Array<LineItem>;
  orderById: Order;
  orderLineItemGetById: LineItem;
  ordersFindMany: OrdersFindManyOutput;
  personsFindMany: PersonsFindManyOutput;
  productById: Product;
  productSupplierPricesFindMany: ProductSupplierPricesFindManyOutput;
  productsFindLastAdded: ProductsFindManyOutput;
  productsFindMany: ProductsFindManyOutput;
  projectGetById: Project;
  projectsFindMany: ProjectsFindManyOutput;
  projectsGetByCompanyId: ProjectsGetByCompanyIdOutput;
  requisitionById: Requisition;
  requisitionLineItemGetById: RequisitionLineItem;
  requisitionLineItemsGetByRequisitionIds: Array<RequisitionLineItem>;
  requisitionMetadataGetByRequisitionIds: Array<RequisitionMetadata>;
  requisitionsFindMany: RequisitionsFindManyOutput;
  shipmentsFindMany: ShipmentsFindManyOutput;
  shipmentsGetByOrderId: Array<Shipment>;
  subCompaniesFindMany: SubCompanyFindManyOutput;
  supplierIdsGetProductLastOrderedFrom: Array<Scalars["Int"]>;
  suppliersFindMany: SuppliersFindManyOutput;
};

export type QueryCatalogProductsFindManyArgs = {
  filters: CatalogProductsFindManyFiltersInput;
  pagination: InputMaybe<PaginationArgs>;
};

export type QueryCompanyByIdArgs = {
  companyId: Scalars["Int"];
};

export type QueryLineItemsGetByOrderIdArgs = {
  orderId: Scalars["Int"];
};

export type QueryOrderByIdArgs = {
  id: Scalars["Int"];
};

export type QueryOrderLineItemGetByIdArgs = {
  id: Scalars["Int"];
};

export type QueryOrdersFindManyArgs = {
  filters: InputMaybe<OrdersFindManyFiltersInput>;
  pagination: InputMaybe<PaginationArgs>;
  sortBy: InputMaybe<OrdersSortInput>;
};

export type QueryPersonsFindManyArgs = {
  filters: InputMaybe<PersonsFindManyFiltersInput>;
  pagination: InputMaybe<PaginationArgs>;
};

export type QueryProductByIdArgs = {
  productId: Scalars["Int"];
};

export type QueryProductSupplierPricesFindManyArgs = {
  params: SupplierPriceFindManyInput;
};

export type QueryProductsFindLastAddedArgs = {
  catalogCompanyId: InputMaybe<Scalars["Int"]>;
  pagination: InputMaybe<PaginationArgs>;
  projectId: Scalars["Int"];
};

export type QueryProductsFindManyArgs = {
  filters: InputMaybe<ProductsFindManyFiltersInput>;
  pagination: InputMaybe<PaginationArgs>;
};

export type QueryProjectGetByIdArgs = {
  projectId: Scalars["Int"];
};

export type QueryProjectsGetByCompanyIdArgs = {
  companyId: Scalars["Int"];
};

export type QueryRequisitionByIdArgs = {
  id: Scalars["Int"];
};

export type QueryRequisitionLineItemGetByIdArgs = {
  id: Scalars["Int"];
};

export type QueryRequisitionLineItemsGetByRequisitionIdsArgs = {
  requisitionIds: Array<Scalars["Int"]>;
};

export type QueryRequisitionMetadataGetByRequisitionIdsArgs = {
  ids: Array<Scalars["Int"]>;
};

export type QueryRequisitionsFindManyArgs = {
  filters: InputMaybe<RequisitionsFindManyFiltersInput>;
  pagination: InputMaybe<PaginationArgs>;
};

export type QueryShipmentsFindManyArgs = {
  filters: InputMaybe<ShipmentsFindManyFiltersInput>;
  pagination: InputMaybe<PaginationArgs>;
};

export type QueryShipmentsGetByOrderIdArgs = {
  orderId: Scalars["Int"];
};

export type QuerySubCompaniesFindManyArgs = {
  filters: InputMaybe<SubCompanyFindManyFiltersInput>;
  pagination: InputMaybe<PaginationArgs>;
};

export type QuerySupplierIdsGetProductLastOrderedFromArgs = {
  companyId: Scalars["Int"];
  productId: Scalars["Int"];
};

export type Requisition = {
  __typename?: "Requisition";
  attachments: Array<Attachment>;
  /** Requisition Created Date */
  createdDate: Scalars["String"];
  creator: Person;
  /** Requisition Delivery Date */
  deliveryDate: Scalars["String"];
  discount: Scalars["Int"];
  /** Requisition ID */
  id: Scalars["Int"];
  lineItems: Array<RequisitionLineItem>;
  metadata: RequisitionMetadata;
  /** Requisition's note */
  note: Maybe<Scalars["String"]>;
  /** Person that created the requisition */
  personId: Scalars["Int"];
  project: Project;
  /** Project ID */
  projectId: Scalars["Int"];
  /** Requisition status */
  status: RequisitionStatus;
};

export type RequisitionCreateInput = {
  /** Requisition Delivery Date */
  deliveryDate: Scalars["DateTime"];
  /** Requisition's note */
  note?: InputMaybe<Scalars["String"]>;
  /** Requisition Project ID */
  projectId: Scalars["Int"];
};

export type RequisitionCreateOrdersOutput = {
  __typename?: "RequisitionCreateOrdersOutput";
  orderIds: Array<Scalars["Int"]>;
  status: MutationStatus;
};

export type RequisitionCreateOutput = {
  __typename?: "RequisitionCreateOutput";
  requisition: Requisition;
};

export type RequisitionLineItem = {
  __typename?: "RequisitionLineItem";
  /** Used for product variant information in custom line items */
  description: Maybe<Scalars["String"]>;
  discount: Scalars["Int"];
  discountAmount: Maybe<Scalars["Float"]>;
  discountAmountFormatted: Maybe<Scalars["String"]>;
  discountTotalAmount: Maybe<Scalars["Float"]>;
  discountTotalAmountFormatted: Maybe<Scalars["String"]>;
  /** Line Item display image */
  displayImage: Maybe<Scalars["String"]>;
  /** Line Item display name */
  displayName: Scalars["String"];
  /** Line Item display size */
  displaySize: Scalars["String"];
  /** Line Item display variant name */
  displayVariantName: Maybe<Scalars["String"]>;
  /** Line Item id */
  id: Scalars["Int"];
  /** True if the line item is custom and has no corresponding product */
  isCustom: Scalars["Boolean"];
  /** True if the line item corresponds to existing product */
  isProduct: Scalars["Boolean"];
  /** Line Item note */
  note: Maybe<Scalars["String"]>;
  orderItemChanged: Scalars["Boolean"];
  orderLineItem: Maybe<LineItem>;
  price: Maybe<Scalars["Float"]>;
  /** Line Item display price */
  priceFormatted: Maybe<Scalars["String"]>;
  priceTotal: Maybe<Scalars["Float"]>;
  priceTotalFormatted: Maybe<Scalars["String"]>;
  priceTotalWithDiscount: Maybe<Scalars["Float"]>;
  priceTotalWithDiscountFormatted: Maybe<Scalars["String"]>;
  priceTotalWithVat: Maybe<Scalars["Float"]>;
  priceTotalWithVatFormatted: Maybe<Scalars["String"]>;
  priceWithDiscount: Maybe<Scalars["Float"]>;
  /** Line Item display price price with discount */
  priceWithDiscountFormatted: Maybe<Scalars["String"]>;
  priceWithVat: Maybe<Scalars["Float"]>;
  /** Line Item display price price with vat applied after discount */
  priceWithVatFormatted: Maybe<Scalars["String"]>;
  product: Maybe<Product>;
  /** Line Item product id */
  productId: Maybe<Scalars["Int"]>;
  /** Line Item quantity */
  quantity: Scalars["Float"];
  requisitionId: Scalars["Int"];
  supplier: Maybe<Supplier>;
  /** Line Item supplier id */
  supplierId: Maybe<Scalars["Int"]>;
  /** Line Item variant id */
  variantId: Maybe<Scalars["Int"]>;
  vat: Scalars["Float"];
  vatAmount: Maybe<Scalars["Float"]>;
  vatAmountFormatted: Maybe<Scalars["String"]>;
};

export type RequisitionLineItemAddProductsOutput = {
  __typename?: "RequisitionLineItemAddProductsOutput";
  lineItems: Array<RequisitionLineItem>;
  status: MutationStatus;
};

export type RequisitionLineItemCustomCreateInput = {
  /** Line item description */
  description?: InputMaybe<Scalars["String"]>;
  discount?: InputMaybe<Scalars["Int"]>;
  /** Line item name */
  name?: InputMaybe<Scalars["String"]>;
  /** Line Item comment */
  note?: InputMaybe<Scalars["String"]>;
  price?: InputMaybe<Scalars["Float"]>;
  /** Product Quantity */
  quantity: Scalars["Float"];
  /** Line Item comment */
  size?: InputMaybe<Scalars["String"]>;
  /** Line Item supplier id */
  supplierId?: InputMaybe<Scalars["Int"]>;
};

export type RequisitionLineItemCustomUpdateInput = {
  /** Line item description */
  description?: InputMaybe<Scalars["String"]>;
  discount?: InputMaybe<Scalars["Int"]>;
  /** Line item name */
  name?: InputMaybe<Scalars["String"]>;
  /** Line Item comment */
  note?: InputMaybe<Scalars["String"]>;
  price?: InputMaybe<Scalars["Float"]>;
  /** Product Quantity */
  quantity?: InputMaybe<Scalars["Float"]>;
  /** Line Item comment */
  size?: InputMaybe<Scalars["String"]>;
  /** Line Item supplier id */
  supplierId?: InputMaybe<Scalars["Int"]>;
};

export type RequisitionLineItemProductCreateInput = {
  discount?: InputMaybe<Scalars["Int"]>;
  /** Line Item comment */
  note?: InputMaybe<Scalars["String"]>;
  price?: InputMaybe<Scalars["Float"]>;
  /** Product ID */
  productId: Scalars["Int"];
  /** Product Quantity */
  quantity: Scalars["Float"];
  /** Supplier Id */
  supplierId?: InputMaybe<Scalars["Int"]>;
  /** Product Variant ID */
  variantId?: InputMaybe<Scalars["Int"]>;
};

export type RequisitionLineItemProductUpdateInput = {
  discount?: InputMaybe<Scalars["Int"]>;
  /** Line Item comment */
  note?: InputMaybe<Scalars["String"]>;
  price?: InputMaybe<Scalars["Float"]>;
  /** Product Quantity */
  quantity?: InputMaybe<Scalars["Float"]>;
  /** Line Item supplier id */
  supplierId?: InputMaybe<Scalars["Int"]>;
  /** Product Variant ID */
  variantId?: InputMaybe<Scalars["Int"]>;
};

export type RequisitionLineItemUpdateOutput = {
  __typename?: "RequisitionLineItemUpdateOutput";
  lineItem: RequisitionLineItem;
  status: MutationStatus;
};

export type RequisitionLineItemsUpdateOutput = {
  __typename?: "RequisitionLineItemsUpdateOutput";
  lineItems: Array<RequisitionLineItem>;
  status: MutationStatus;
};

export type RequisitionMetadata = {
  __typename?: "RequisitionMetadata";
  canAttachFiles: Scalars["Boolean"];
  canDelete: Scalars["Boolean"];
  canEditItems: Scalars["Boolean"];
  editableItemIds: Array<Scalars["Int"]>;
  editableProperties: Array<Scalars["String"]>;
  /** Requisition ID */
  id: Scalars["Int"];
  nextPossibleStatuses: Array<RequisitionStatus>;
};

export type RequisitionMutations = {
  __typename?: "RequisitionMutations";
  create: RequisitionCreateOutput;
  createOrders: RequisitionCreateOrdersOutput;
  delete: StatusOutput;
  update: RequisitionUpdateOutput;
};

export type RequisitionMutationsCreateArgs = {
  requisition: RequisitionCreateInput;
};

export type RequisitionMutationsCreateOrdersArgs = {
  requisitionId: Scalars["Int"];
};

export type RequisitionMutationsDeleteArgs = {
  id: Scalars["Int"];
};

export type RequisitionMutationsUpdateArgs = {
  id: Scalars["Int"];
  requisition: RequisitionUpdateInput;
};

export enum RequisitionStatus {
  Approved = "APPROVED",
  Cancelled = "CANCELLED",
  Closed = "CLOSED",
  Draft = "DRAFT",
  PendingApproval = "PENDING_APPROVAL",
  Rejected = "REJECTED",
}

export type RequisitionUpdateInput = {
  deliveryDate?: InputMaybe<Scalars["String"]>;
  discount?: InputMaybe<Scalars["Int"]>;
  note?: InputMaybe<Scalars["String"]>;
  status?: InputMaybe<RequisitionStatus>;
};

export type RequisitionUpdateOutput = {
  __typename?: "RequisitionUpdateOutput";
  requisition: Requisition;
};

export type RequisitionsFindManyFiltersInput = {
  /** Returns requisitions that have been created on or before specified day */
  createdEndDate?: InputMaybe<Scalars["String"]>;
  /** Returns requisitions that have been created on or after specified day */
  createdStartDate?: InputMaybe<Scalars["String"]>;
  /** Returns requisitions that has a delivery date set on or before specified day */
  deliveryEndDate?: InputMaybe<Scalars["String"]>;
  /** Returns requisitions that has a delivery date set on or after specified day */
  deliveryStartDate?: InputMaybe<Scalars["String"]>;
  /** Returns requisitions that belong to on of specified projects */
  projectsIds?: InputMaybe<Array<Scalars["Int"]>>;
  /** Returns requisitions that has an id partially equal to the query */
  searchQuery?: InputMaybe<Scalars["String"]>;
  /** Returns requisitions that has one of specified statuses */
  statuses?: InputMaybe<Array<RequisitionStatus>>;
};

export type RequisitionsFindManyOutput = {
  __typename?: "RequisitionsFindManyOutput";
  items: Array<Requisition>;
  pageInfo: PaginationInfo;
};

export type Shipment = {
  __typename?: "Shipment";
  approvedBy: Person;
  approvedById: Scalars["Int"];
  certificates: Array<ShipmentCertificate>;
  deliveryDate: Scalars["String"];
  id: Scalars["Int"];
  items: Array<ShipmentItem>;
  lineItems: Array<ShipmentLineItem>;
  order: Order;
  orderId: Scalars["Int"];
};

export type ShipmentCertificate = {
  __typename?: "ShipmentCertificate";
  id: Scalars["Int"];
  shipmentId: Scalars["Int"];
  url: Scalars["String"];
};

export type ShipmentCreateInput = {
  items: Array<ShipmentCreateItemInput>;
};

export type ShipmentCreateItemInput = {
  itemId: Scalars["Int"];
  receivedCount: Scalars["Float"];
};

export type ShipmentItem = {
  __typename?: "ShipmentItem";
  itemId: Scalars["Int"];
  receivedCount: Scalars["Float"];
};

export type ShipmentLineItem = {
  __typename?: "ShipmentLineItem";
  /** Unique id based on shipment id and line item id */
  id: Scalars["String"];
  lineItem: LineItem;
  shippedQuantity: Scalars["Float"];
};

export type ShipmentsFindManyFiltersInput = {
  /** Returns shipments that belong to specific companies */
  companyIds?: InputMaybe<Array<Scalars["Int"]>>;
  /** Returns shipments that have a delivery date set on or before specified day */
  deliveryDateEnd?: InputMaybe<Scalars["String"]>;
  /** Returns shipments that have a delivery date set on or after specified day */
  deliveryDateStart?: InputMaybe<Scalars["String"]>;
  productIds?: InputMaybe<Array<Scalars["Int"]>>;
  /** Returns shipments that belong to specific projects */
  projectIds?: InputMaybe<Array<Scalars["Int"]>>;
  subCompanyIds?: InputMaybe<Array<Scalars["Int"]>>;
  /** Returns shipments that are supplied by specific suppliers */
  supplierIds?: InputMaybe<Array<Scalars["Int"]>>;
};

export type ShipmentsFindManyOutput = {
  __typename?: "ShipmentsFindManyOutput";
  isAnyLineItemHasNoPrice: Scalars["Boolean"];
  items: Array<Shipment>;
  pageInfo: PaginationInfo;
  totalPrice: Scalars["Float"];
};

export type ShipmentsMutations = {
  __typename?: "ShipmentsMutations";
  create: Shipment;
  deleteCertificate: StatusOutput;
  update: Shipment;
};

export type ShipmentsMutationsCreateArgs = {
  data: ShipmentCreateInput;
  orderId: Scalars["Int"];
};

export type ShipmentsMutationsDeleteCertificateArgs = {
  certificateId: Scalars["Int"];
  orderId: Scalars["Int"];
  shipmentId: Scalars["Int"];
};

export type ShipmentsMutationsUpdateArgs = {
  data: ShipmentCreateInput;
  orderId: Scalars["Int"];
  shipmentId: Scalars["Int"];
};

export enum SortDirection {
  Asc = "ASC",
  Desc = "DESC",
}

export type StatusOutput = {
  __typename?: "StatusOutput";
  status: MutationStatus;
};

export type SubCompaniesMutations = {
  __typename?: "SubCompaniesMutations";
  create: SubCompanyUpdateOutput;
  delete: StatusOutput;
  update: SubCompanyUpdateOutput;
};

export type SubCompaniesMutationsCreateArgs = {
  data: SubCompanyCreateInput;
};

export type SubCompaniesMutationsDeleteArgs = {
  id: Scalars["Int"];
};

export type SubCompaniesMutationsUpdateArgs = {
  data: SubCompanyUpdateInput;
  id: Scalars["Int"];
};

export type SubCompany = {
  __typename?: "SubCompany";
  company: Company;
  companyId: Scalars["Int"];
  id: Scalars["Int"];
  localCompanyNumber: Maybe<Scalars["Int"]>;
  name: Scalars["String"];
  orders: Array<Order>;
  projects: Array<Project>;
};

export type SubCompanyCreateInput = {
  companyId: Scalars["Int"];
  localCompanyNumber?: InputMaybe<Scalars["Int"]>;
  name: Scalars["String"];
};

export type SubCompanyFindManyFiltersInput = {
  companyIds?: InputMaybe<Array<Scalars["Int"]>>;
  query?: InputMaybe<Scalars["String"]>;
};

export type SubCompanyFindManyOutput = {
  __typename?: "SubCompanyFindManyOutput";
  items: Array<SubCompany>;
  pageInfo: PaginationInfo;
};

export type SubCompanyUpdateInput = {
  localCompanyNumber?: InputMaybe<Scalars["Int"]>;
  name?: InputMaybe<Scalars["String"]>;
};

export type SubCompanyUpdateOutput = {
  __typename?: "SubCompanyUpdateOutput";
  status: MutationStatus;
  subCompany: SubCompany;
};

export type Supplier = {
  __typename?: "Supplier";
  /** Supplier is enabled */
  enable: Scalars["Boolean"];
  /** Supplier id */
  id: Scalars["Int"];
  /** Supplier name */
  name: Scalars["String"];
};

export type SupplierPriceFindManyInput = {
  companyId: Scalars["Int"];
  productIds: Array<SupplierPriceFindManyProductIds>;
};

export type SupplierPriceFindManyProductIds = {
  productId?: InputMaybe<Scalars["Int"]>;
  variantId?: InputMaybe<Scalars["Int"]>;
};

export type SuppliersFindManyOutput = {
  __typename?: "SuppliersFindManyOutput";
  /** Suppliers */
  items: Array<Supplier>;
};

export enum UserRole {
  CatalogManager = "CatalogManager",
  ClientUser = "ClientUser",
  OrderCreator = "OrderCreator",
  OrderModerator = "OrderModerator",
  OrderModeratorLimited = "OrderModeratorLimited",
  SystemAdmin = "SystemAdmin",
}

export type CommentsSidebar__AttachmentFragment = {
  __typename?: "Attachment";
  id: number;
  url: string;
  name: string;
  mimeType: string;
};

export type PaginationFragment = {
  __typename?: "PaginationInfo";
  perPage: number;
  totalItems: number;
};

export type ProductsAddPopupCategoryFragment = {
  __typename?: "Category";
  id: number;
  name: string;
  parentId: number | null;
  children: Array<{
    __typename?: "Category";
    id: number;
    name: string;
    parentId: number | null;
    children: Array<{
      __typename?: "Category";
      id: number;
      name: string;
      parentId: number | null;
      children: Array<{
        __typename?: "Category";
        id: number;
        name: string;
        parentId: number | null;
        children: Array<{
          __typename?: "Category";
          id: number;
          name: string;
          parentId: number | null;
          children: Array<{
            __typename?: "Category";
            id: number;
            name: string;
            parentId: number | null;
            children: Array<{
              __typename?: "Category";
              id: number;
              name: string;
              parentId: number | null;
              children: Array<{
                __typename?: "Category";
                id: number;
                name: string;
                parentId: number | null;
                children: Array<{
                  __typename?: "Category";
                  id: number;
                  name: string;
                  parentId: number | null;
                }>;
              }>;
            }>;
          }>;
        }>;
      }>;
    }>;
  }>;
};

export type ProductsAddPopupProductFragment = {
  __typename?: "Product";
  id: number;
  primaryImage: string | null;
  name: string;
  variants: Array<{ __typename?: "ProductVariant"; id: number; name: string }>;
  companyCatalogProducts: Array<{
    __typename?: "CatalogProduct";
    id: number;
    variantIds: Array<number>;
    companyId: number;
  }>;
  attributes: Array<{
    __typename?: "ProductAttribute";
    attribute: string;
    description: string;
  }>;
};

export type ProductsAddPopupPageInfoFragment = {
  __typename?: "PaginationInfo";
  perPage: number;
  totalItems: number;
};

export type ProductsAddPopup__SupplierFragment = {
  __typename?: "Supplier";
  id: number;
  name: string;
};

export type ProductsAddPopupProductsTable__ProductFragment = {
  __typename?: "Product";
  id: number;
  primaryImage: string | null;
  name: string;
  variants: Array<{ __typename?: "ProductVariant"; id: number; name: string }>;
  companyCatalogProducts: Array<{
    __typename?: "CatalogProduct";
    id: number;
    variantIds: Array<number>;
    companyId: number;
  }>;
  attributes: Array<{
    __typename?: "ProductAttribute";
    attribute: string;
    description: string;
  }>;
};

export type ProductsAddPopupProductsTableProductFormAttributes__ProductAttributeFragment =
  { __typename?: "ProductAttribute"; attribute: string; description: string };

export type ProductsAddPopupProductsTableProductForm__ProductFragment = {
  __typename?: "Product";
  id: number;
  variants: Array<{ __typename?: "ProductVariant"; id: number; name: string }>;
  companyCatalogProducts: Array<{
    __typename?: "CatalogProduct";
    id: number;
    variantIds: Array<number>;
    companyId: number;
  }>;
  attributes: Array<{
    __typename?: "ProductAttribute";
    attribute: string;
    description: string;
  }>;
};

export type ProductsAddPopupProductsTableProductForm__ProductVariantFragment = {
  __typename?: "ProductVariant";
  id: number;
  name: string;
};

export type ProductsAddPopupProductsTableProductForm__CatalogProductFragment = {
  __typename?: "CatalogProduct";
  id: number;
  variantIds: Array<number>;
  companyId: number;
};

export type ProductsAddPopupProductsTableRow__ProductFragment = {
  __typename?: "Product";
  id: number;
  primaryImage: string | null;
  name: string;
  variants: Array<{ __typename?: "ProductVariant"; id: number; name: string }>;
  companyCatalogProducts: Array<{
    __typename?: "CatalogProduct";
    id: number;
    variantIds: Array<number>;
    companyId: number;
  }>;
  attributes: Array<{
    __typename?: "ProductAttribute";
    attribute: string;
    description: string;
  }>;
};

export type ProductsAddPopupProductsTableRow__ProductVariantFragment = {
  __typename?: "ProductVariant";
  id: number;
  name: string;
};

export type ProductsAddPopupProductsTableRow__CatalogProductFragment = {
  __typename?: "CatalogProduct";
  id: number;
  variantIds: Array<number>;
  companyId: number;
};

export type ProductsAddPopupProductsTableProductFormSupplierField__ProductSupplierPriceFragment =
  {
    __typename?: "ProductSupplierPrice";
    supplierId: number;
    price: number | null;
  };

export type ProductsAddPopup__GetCategoriesQueryVariables = Exact<{
  [key: string]: never;
}>;

export type ProductsAddPopup__GetCategoriesQuery = {
  __typename?: "Query";
  categoriesFindMany: {
    __typename?: "CategoriesFindManyOutput";
    items: Array<{
      __typename?: "Category";
      id: number;
      name: string;
      parentId: number | null;
      children: Array<{
        __typename?: "Category";
        id: number;
        name: string;
        parentId: number | null;
        children: Array<{
          __typename?: "Category";
          id: number;
          name: string;
          parentId: number | null;
          children: Array<{
            __typename?: "Category";
            id: number;
            name: string;
            parentId: number | null;
            children: Array<{
              __typename?: "Category";
              id: number;
              name: string;
              parentId: number | null;
              children: Array<{
                __typename?: "Category";
                id: number;
                name: string;
                parentId: number | null;
                children: Array<{
                  __typename?: "Category";
                  id: number;
                  name: string;
                  parentId: number | null;
                  children: Array<{
                    __typename?: "Category";
                    id: number;
                    name: string;
                    parentId: number | null;
                    children: Array<{
                      __typename?: "Category";
                      id: number;
                      name: string;
                      parentId: number | null;
                    }>;
                  }>;
                }>;
              }>;
            }>;
          }>;
        }>;
      }>;
    }>;
  };
};

export type ProductsAddPopup__GetProductsQueryVariables = Exact<{
  page: Scalars["Int"];
  perPage: Scalars["Int"];
  filters: ProductsFindManyFiltersInput;
}>;

export type ProductsAddPopup__GetProductsQuery = {
  __typename?: "Query";
  productsFindMany: {
    __typename?: "ProductsFindManyOutput";
    items: Array<{
      __typename?: "Product";
      id: number;
      primaryImage: string | null;
      name: string;
      variants: Array<{
        __typename?: "ProductVariant";
        id: number;
        name: string;
      }>;
      companyCatalogProducts: Array<{
        __typename?: "CatalogProduct";
        id: number;
        variantIds: Array<number>;
        companyId: number;
      }>;
      attributes: Array<{
        __typename?: "ProductAttribute";
        attribute: string;
        description: string;
      }>;
    }>;
    pageInfo: {
      __typename?: "PaginationInfo";
      perPage: number;
      totalItems: number;
    };
  };
};

export type SupplierInputProductSupplierPriceFragment = {
  __typename?: "ProductSupplierPrice";
  id: string;
  supplierId: number;
  supplierName: string;
  price: number | null;
  priceFormatted: string | null;
};

export type SupplierSingleInputSupplierFragment = {
  __typename?: "Supplier";
  id: number;
  name: string;
};

export type SupplierFieldSupplierIdsProductLastOrderedFromQueryVariables =
  Exact<{
    companyId: Scalars["Int"];
    productId: Scalars["Int"];
  }>;

export type SupplierFieldSupplierIdsProductLastOrderedFromQuery = {
  __typename?: "Query";
  supplierIdsGetProductLastOrderedFrom: Array<number>;
};

export type SupplierSelectPopup__SupplierFragment = {
  __typename?: "Supplier";
  id: number;
  name: string;
};

export type GetCompanyQueryVariables = Exact<{
  companyId: Scalars["Int"];
}>;

export type GetCompanyQuery = {
  __typename?: "Query";
  companyById: {
    __typename?: "Company";
    id: number;
    name: string;
    taxId: string | null;
    active: boolean;
    supplierIds: Array<number>;
    useCompanyCatalog: boolean;
  };
};

export type GetCompany__CompanyFragment = {
  __typename?: "Company";
  id: number;
  name: string;
  taxId: string | null;
  active: boolean;
  supplierIds: Array<number>;
  useCompanyCatalog: boolean;
};

export type CompanySuppliersQueryVariables = Exact<{
  companyId: Scalars["Int"];
}>;

export type CompanySuppliersQuery = {
  __typename?: "Query";
  companyById: {
    __typename?: "Company";
    id: number;
    suppliers: Array<{
      __typename?: "Supplier";
      id: number;
      name: string;
      enable: boolean;
    }>;
  };
};

export type CompanySuppliers__SupplierFragment = {
  __typename?: "Supplier";
  id: number;
  name: string;
  enable: boolean;
};

export type CompanyUpdateMutationVariables = Exact<{
  companyId: Scalars["Int"];
  data: CompanyUpdateInput;
}>;

export type CompanyUpdateMutation = {
  __typename?: "Mutation";
  companies: {
    __typename?: "CompaniesMutations";
    update: {
      __typename?: "CompaniesUpdateOutput";
      company: {
        __typename?: "Company";
        id: number;
        name: string;
        taxId: string | null;
        active: boolean;
        supplierIds: Array<number>;
        useCompanyCatalog: boolean;
      };
    };
  };
};

export type GetPersonsByProjectQueryVariables = Exact<{
  projectId: Scalars["Int"];
}>;

export type GetPersonsByProjectQuery = {
  __typename?: "Query";
  personsFindMany: {
    __typename?: "PersonsFindManyOutput";
    items: Array<{
      __typename?: "Person";
      id: number;
      name: string | null;
      email: string;
      phoneNumber: string | null;
      companyId: number;
    }>;
  };
};

export type ProductWithCompanyCatalogFragment = {
  __typename?: "Product";
  id: number;
  variants: Array<{ __typename?: "ProductVariant"; id: number }>;
  companyCatalogProducts: Array<{
    __typename?: "CatalogProduct";
    id: number;
    companyId: number;
    variantIds: Array<number>;
  }>;
};

export type GetPersonsQueryVariables = Exact<{ [key: string]: never }>;

export type GetPersonsQuery = {
  __typename?: "Query";
  personsFindMany: {
    __typename?: "PersonsFindManyOutput";
    items: Array<{
      __typename?: "Person";
      id: number;
      name: string | null;
      email: string;
      phoneNumber: string | null;
      companyId: number;
    }>;
  };
};

export type GetPersons__PersonFragment = {
  __typename?: "Person";
  id: number;
  name: string | null;
  email: string;
  phoneNumber: string | null;
  companyId: number;
};

export type SupplierProductPrices__GetPricesQueryVariables = Exact<{
  companyId: Scalars["Int"];
  productIds:
    | Array<SupplierPriceFindManyProductIds>
    | SupplierPriceFindManyProductIds;
}>;

export type SupplierProductPrices__GetPricesQuery = {
  __typename?: "Query";
  productSupplierPricesFindMany: {
    __typename?: "ProductSupplierPricesFindManyOutput";
    items: Array<{
      __typename?: "ProductSupplierPrice";
      id: string;
      supplierId: number;
      variantId: number | null;
      productId: number | null;
      supplierName: string;
      price: number | null;
      priceFormatted: string | null;
    }>;
  };
};

export type SupplierProductPrices__ProductSupplierPriceFragment = {
  __typename?: "ProductSupplierPrice";
  id: string;
  supplierId: number;
  variantId: number | null;
  productId: number | null;
  supplierName: string;
  price: number | null;
  priceFormatted: string | null;
};

export type SupplierProductPrices__ProductFragment = {
  __typename?: "Product";
  id: number;
  variants: Array<{ __typename?: "ProductVariant"; id: number }>;
};

export type GetAttributesQueryVariables = Exact<{ [key: string]: never }>;

export type GetAttributesQuery = {
  __typename?: "Query";
  attributesGet: Array<{ __typename?: "ProductAttribute"; attribute: string }>;
};

export type ProductsView__ProductFragment = {
  __typename?: "Product";
  id: number;
  primaryImage: string | null;
  name: string;
  isActive: boolean;
};

export type ProductsView__GetProductsQueryVariables = Exact<{
  filters: ProductsFindManyFiltersInput;
  pagination: PaginationArgs;
}>;

export type ProductsView__GetProductsQuery = {
  __typename?: "Query";
  productsFindMany: {
    __typename?: "ProductsFindManyOutput";
    items: Array<{
      __typename?: "Product";
      id: number;
      primaryImage: string | null;
      name: string;
      isActive: boolean;
    }>;
    pageInfo: { __typename?: "PaginationInfo"; totalItems: number };
  };
};

export type ProductsViewTable__ProductFragment = {
  __typename?: "Product";
  id: number;
  primaryImage: string | null;
  name: string;
  isActive: boolean;
};

export type CategoriesSelectItemCategoryFragment = {
  __typename?: "Category";
  id: number;
  name: string;
  parentId: number | null;
  children: Array<{
    __typename?: "Category";
    id: number;
    name: string;
    parentId: number | null;
    children: Array<{
      __typename?: "Category";
      id: number;
      name: string;
      parentId: number | null;
      children: Array<{
        __typename?: "Category";
        id: number;
        name: string;
        parentId: number | null;
        children: Array<{
          __typename?: "Category";
          id: number;
          name: string;
          parentId: number | null;
          children: Array<{
            __typename?: "Category";
            id: number;
            name: string;
            parentId: number | null;
            children: Array<{
              __typename?: "Category";
              id: number;
              name: string;
              parentId: number | null;
              children: Array<{
                __typename?: "Category";
                id: number;
                name: string;
                parentId: number | null;
                children: Array<{
                  __typename?: "Category";
                  id: number;
                  name: string;
                  parentId: number | null;
                }>;
              }>;
            }>;
          }>;
        }>;
      }>;
    }>;
  }>;
};

export type CategoriesSelectListCategoryFragment = {
  __typename?: "Category";
  id: number;
  name: string;
  parentId: number | null;
  children: Array<{
    __typename?: "Category";
    id: number;
    name: string;
    parentId: number | null;
    children: Array<{
      __typename?: "Category";
      id: number;
      name: string;
      parentId: number | null;
      children: Array<{
        __typename?: "Category";
        id: number;
        name: string;
        parentId: number | null;
        children: Array<{
          __typename?: "Category";
          id: number;
          name: string;
          parentId: number | null;
          children: Array<{
            __typename?: "Category";
            id: number;
            name: string;
            parentId: number | null;
            children: Array<{
              __typename?: "Category";
              id: number;
              name: string;
              parentId: number | null;
              children: Array<{
                __typename?: "Category";
                id: number;
                name: string;
                parentId: number | null;
                children: Array<{
                  __typename?: "Category";
                  id: number;
                  name: string;
                  parentId: number | null;
                }>;
              }>;
            }>;
          }>;
        }>;
      }>;
    }>;
  }>;
};

export type CategoryInputCategoryFragment = {
  __typename?: "Category";
  id: number;
  name: string;
  parentId: number | null;
  children: Array<{
    __typename?: "Category";
    id: number;
    name: string;
    parentId: number | null;
    children: Array<{
      __typename?: "Category";
      id: number;
      name: string;
      parentId: number | null;
      children: Array<{
        __typename?: "Category";
        id: number;
        name: string;
        parentId: number | null;
        children: Array<{
          __typename?: "Category";
          id: number;
          name: string;
          parentId: number | null;
          children: Array<{
            __typename?: "Category";
            id: number;
            name: string;
            parentId: number | null;
            children: Array<{
              __typename?: "Category";
              id: number;
              name: string;
              parentId: number | null;
              children: Array<{
                __typename?: "Category";
                id: number;
                name: string;
                parentId: number | null;
                children: Array<{
                  __typename?: "Category";
                  id: number;
                  name: string;
                  parentId: number | null;
                }>;
              }>;
            }>;
          }>;
        }>;
      }>;
    }>;
  }>;
};

export type GetCategoriesQueryVariables = Exact<{ [key: string]: never }>;

export type GetCategoriesQuery = {
  __typename?: "Query";
  categoriesFindMany: {
    __typename?: "CategoriesFindManyOutput";
    items: Array<{
      __typename?: "Category";
      id: number;
      name: string;
      parentId: number | null;
      hasProducts: boolean;
      children: Array<{
        __typename?: "Category";
        id: number;
        name: string;
        parentId: number | null;
        hasProducts: boolean;
        children: Array<{
          __typename?: "Category";
          id: number;
          name: string;
          parentId: number | null;
          hasProducts: boolean;
          children: Array<{
            __typename?: "Category";
            id: number;
            name: string;
            parentId: number | null;
            hasProducts: boolean;
            children: Array<{
              __typename?: "Category";
              id: number;
              name: string;
              parentId: number | null;
              hasProducts: boolean;
              children: Array<{
                __typename?: "Category";
                id: number;
                name: string;
                parentId: number | null;
                hasProducts: boolean;
                children: Array<{
                  __typename?: "Category";
                  id: number;
                  name: string;
                  parentId: number | null;
                  hasProducts: boolean;
                  children: Array<{
                    __typename?: "Category";
                    id: number;
                    name: string;
                    parentId: number | null;
                    hasProducts: boolean;
                    children: Array<{
                      __typename?: "Category";
                      id: number;
                      name: string;
                      parentId: number | null;
                      hasProducts: boolean;
                    }>;
                  }>;
                }>;
              }>;
            }>;
          }>;
        }>;
      }>;
    }>;
  };
};

export type GetCategories__CategoryFragment = {
  __typename?: "Category";
  id: number;
  name: string;
  parentId: number | null;
  hasProducts: boolean;
  children: Array<{
    __typename?: "Category";
    id: number;
    name: string;
    parentId: number | null;
    hasProducts: boolean;
    children: Array<{
      __typename?: "Category";
      id: number;
      name: string;
      parentId: number | null;
      hasProducts: boolean;
      children: Array<{
        __typename?: "Category";
        id: number;
        name: string;
        parentId: number | null;
        hasProducts: boolean;
        children: Array<{
          __typename?: "Category";
          id: number;
          name: string;
          parentId: number | null;
          hasProducts: boolean;
          children: Array<{
            __typename?: "Category";
            id: number;
            name: string;
            parentId: number | null;
            hasProducts: boolean;
            children: Array<{
              __typename?: "Category";
              id: number;
              name: string;
              parentId: number | null;
              hasProducts: boolean;
              children: Array<{
                __typename?: "Category";
                id: number;
                name: string;
                parentId: number | null;
                hasProducts: boolean;
                children: Array<{
                  __typename?: "Category";
                  id: number;
                  name: string;
                  parentId: number | null;
                  hasProducts: boolean;
                }>;
              }>;
            }>;
          }>;
        }>;
      }>;
    }>;
  }>;
};

export type CategoryCreateMutationVariables = Exact<{
  data: CategoryCreateInput;
}>;

export type CategoryCreateMutation = {
  __typename?: "Mutation";
  categories: {
    __typename?: "CategoriesMutations";
    create: {
      __typename?: "CategoryUpdateOutput";
      category: {
        __typename?: "Category";
        id: number;
        name: string;
        parentId: number | null;
        hasProducts: boolean;
        children: Array<{
          __typename?: "Category";
          id: number;
          name: string;
          parentId: number | null;
          hasProducts: boolean;
          children: Array<{
            __typename?: "Category";
            id: number;
            name: string;
            parentId: number | null;
            hasProducts: boolean;
            children: Array<{
              __typename?: "Category";
              id: number;
              name: string;
              parentId: number | null;
              hasProducts: boolean;
              children: Array<{
                __typename?: "Category";
                id: number;
                name: string;
                parentId: number | null;
                hasProducts: boolean;
                children: Array<{
                  __typename?: "Category";
                  id: number;
                  name: string;
                  parentId: number | null;
                  hasProducts: boolean;
                  children: Array<{
                    __typename?: "Category";
                    id: number;
                    name: string;
                    parentId: number | null;
                    hasProducts: boolean;
                    children: Array<{
                      __typename?: "Category";
                      id: number;
                      name: string;
                      parentId: number | null;
                      hasProducts: boolean;
                      children: Array<{
                        __typename?: "Category";
                        id: number;
                        name: string;
                        parentId: number | null;
                        hasProducts: boolean;
                      }>;
                    }>;
                  }>;
                }>;
              }>;
            }>;
          }>;
        }>;
      };
    };
  };
};

export type CategoryDeleteMutationVariables = Exact<{
  id: Scalars["Int"];
}>;

export type CategoryDeleteMutation = {
  __typename?: "Mutation";
  categories: {
    __typename?: "CategoriesMutations";
    delete: { __typename?: "StatusOutput"; status: MutationStatus };
  };
};

export type CategoryUpdateMutationVariables = Exact<{
  data: CategoryCreateInput;
  id: Scalars["Int"];
}>;

export type CategoryUpdateMutation = {
  __typename?: "Mutation";
  categories: {
    __typename?: "CategoriesMutations";
    update: {
      __typename?: "CategoryUpdateOutput";
      category: {
        __typename?: "Category";
        id: number;
        name: string;
        parentId: number | null;
        hasProducts: boolean;
        children: Array<{
          __typename?: "Category";
          id: number;
          name: string;
          parentId: number | null;
          hasProducts: boolean;
          children: Array<{
            __typename?: "Category";
            id: number;
            name: string;
            parentId: number | null;
            hasProducts: boolean;
            children: Array<{
              __typename?: "Category";
              id: number;
              name: string;
              parentId: number | null;
              hasProducts: boolean;
              children: Array<{
                __typename?: "Category";
                id: number;
                name: string;
                parentId: number | null;
                hasProducts: boolean;
                children: Array<{
                  __typename?: "Category";
                  id: number;
                  name: string;
                  parentId: number | null;
                  hasProducts: boolean;
                  children: Array<{
                    __typename?: "Category";
                    id: number;
                    name: string;
                    parentId: number | null;
                    hasProducts: boolean;
                    children: Array<{
                      __typename?: "Category";
                      id: number;
                      name: string;
                      parentId: number | null;
                      hasProducts: boolean;
                      children: Array<{
                        __typename?: "Category";
                        id: number;
                        name: string;
                        parentId: number | null;
                        hasProducts: boolean;
                      }>;
                    }>;
                  }>;
                }>;
              }>;
            }>;
          }>;
        }>;
      };
    };
  };
};

export type CompaniesView__CompanyFragment = {
  __typename?: "Company";
  id: number;
  name: string;
  taxId: string | null;
  active: boolean;
};

export type CompaniesViewTable__CompanyFragment = {
  __typename?: "Company";
  id: number;
  name: string;
  taxId: string | null;
  active: boolean;
};

export type CompanyViewCompanyFragment = {
  __typename?: "Company";
  supplierIds: Array<number>;
  name: string;
  taxId: string | null;
  id: number;
  active: boolean;
};

export type CompanyViewProjects__ProjectFragment = {
  __typename?: "Project";
  id: number;
  name: string;
  geoLocation: string;
  active: boolean;
  personIds: Array<number>;
  subCompanyId: number | null;
};

export type CompanyViewProjectsProjectFormProjectFragment = {
  __typename?: "Project";
  name: string;
  geoLocation: string;
  active: boolean;
  personIds: Array<number>;
  subCompanyId: number | null;
};

export type CompanyViewProjectsTableProjectFragment = {
  __typename?: "Project";
  id: number;
  name: string;
  geoLocation: string;
  active: boolean;
};

export type CompanyViewProjects__CreateProjectMutationVariables = Exact<{
  companyId: Scalars["Int"];
  project: ProjectCreateInput;
}>;

export type CompanyViewProjects__CreateProjectMutation = {
  __typename?: "Mutation";
  projects: {
    __typename?: "ProjectsMutations";
    create: {
      __typename?: "ProjectCreateOutput";
      project: {
        __typename?: "Project";
        id: number;
        name: string;
        geoLocation: string;
        active: boolean;
        personIds: Array<number>;
        subCompanyId: number | null;
      };
    };
  };
};

export type CompanyViewProjectsProjectUpdateMutationVariables = Exact<{
  projectId: Scalars["Int"];
  project: ProjectUpdateInput;
}>;

export type CompanyViewProjectsProjectUpdateMutation = {
  __typename?: "Mutation";
  projects: {
    __typename?: "ProjectsMutations";
    update: {
      __typename?: "ProjectUpdateOutput";
      status: MutationStatus;
      project: {
        __typename?: "Project";
        id: number;
        name: string;
        geoLocation: string;
        active: boolean;
        personIds: Array<number>;
        subCompanyId: number | null;
      };
    };
  };
};

export type CompanyViewProjects__GetProjectsQueryVariables = Exact<{
  companyId: Scalars["Int"];
}>;

export type CompanyViewProjects__GetProjectsQuery = {
  __typename?: "Query";
  projectsGetByCompanyId: {
    __typename?: "ProjectsGetByCompanyIdOutput";
    items: Array<{
      __typename?: "Project";
      name: string;
      id: number;
      geoLocation: string;
      active: boolean;
      personIds: Array<number>;
      subCompanyId: number | null;
    }>;
  };
};

export type CompanyViewSubCompanies__SubCompanyFragment = {
  __typename?: "SubCompany";
  id: number;
  name: string;
  localCompanyNumber: number | null;
  projects: Array<{ __typename?: "Project"; id: number }>;
  orders: Array<{ __typename?: "Order"; id: number }>;
};

export type CompanyViewUsersFilters__ProjectFragment = {
  __typename?: "Project";
  id: number;
  name: string;
};

export type CompanyViewUsersFilters__FindProjectsQueryVariables = Exact<{
  [key: string]: never;
}>;

export type CompanyViewUsersFilters__FindProjectsQuery = {
  __typename?: "Query";
  projectsFindMany: {
    __typename?: "ProjectsFindManyOutput";
    items: Array<{ __typename?: "Project"; id: number; name: string }>;
  };
};

export type CompanyViewSubCompaniesTable__SubCompanyFragment = {
  __typename?: "SubCompany";
  id: number;
  name: string;
  localCompanyNumber: number | null;
  projects: Array<{ __typename?: "Project"; id: number }>;
  orders: Array<{ __typename?: "Order"; id: number }>;
};

export type CompanyViewSubCompaniesTableRow__SubCompanyFragment = {
  __typename?: "SubCompany";
  id: number;
  name: string;
  localCompanyNumber: number | null;
  projects: Array<{ __typename?: "Project"; id: number }>;
  orders: Array<{ __typename?: "Order"; id: number }>;
};

export type CompanyViewSubCompanyForm__SubCompanyFragment = {
  __typename?: "SubCompany";
  name: string;
  localCompanyNumber: number | null;
};

export type CompanyViewSubCompanies__GetSubCompaniesQueryVariables = Exact<{
  filters: InputMaybe<SubCompanyFindManyFiltersInput>;
  pagination: InputMaybe<PaginationArgs>;
}>;

export type CompanyViewSubCompanies__GetSubCompaniesQuery = {
  __typename?: "Query";
  subCompaniesFindMany: {
    __typename?: "SubCompanyFindManyOutput";
    items: Array<{
      __typename?: "SubCompany";
      id: number;
      name: string;
      localCompanyNumber: number | null;
      projects: Array<{ __typename?: "Project"; id: number }>;
      orders: Array<{ __typename?: "Order"; id: number }>;
    }>;
    pageInfo: {
      __typename?: "PaginationInfo";
      perPage: number;
      totalItems: number;
    };
  };
};

export type CompanyView__SubCompanyCreateMutationVariables = Exact<{
  data: SubCompanyCreateInput;
}>;

export type CompanyView__SubCompanyCreateMutation = {
  __typename?: "Mutation";
  subCompanies: {
    __typename?: "SubCompaniesMutations";
    create: {
      __typename?: "SubCompanyUpdateOutput";
      subCompany: {
        __typename?: "SubCompany";
        id: number;
        name: string;
        localCompanyNumber: number | null;
        projects: Array<{ __typename?: "Project"; id: number }>;
        orders: Array<{ __typename?: "Order"; id: number }>;
      };
    };
  };
};

export type CompanyView__SubCompanyDeleteMutationVariables = Exact<{
  id: Scalars["Int"];
}>;

export type CompanyView__SubCompanyDeleteMutation = {
  __typename?: "Mutation";
  subCompanies: {
    __typename?: "SubCompaniesMutations";
    delete: { __typename?: "StatusOutput"; status: MutationStatus };
  };
};

export type CompanyView__SubCompanyUpdateMutationVariables = Exact<{
  id: Scalars["Int"];
  data: SubCompanyUpdateInput;
}>;

export type CompanyView__SubCompanyUpdateMutation = {
  __typename?: "Mutation";
  subCompanies: {
    __typename?: "SubCompaniesMutations";
    update: {
      __typename?: "SubCompanyUpdateOutput";
      subCompany: {
        __typename?: "SubCompany";
        id: number;
        name: string;
        localCompanyNumber: number | null;
        projects: Array<{ __typename?: "Project"; id: number }>;
        orders: Array<{ __typename?: "Order"; id: number }>;
      };
    };
  };
};

export type CompanyView__SubCompanyUpdateReadFragment = {
  __typename?: "SubCompany";
  id: number;
  name: string;
  localCompanyNumber: number | null;
};

export type CompanyViewSuppliersTableSupplierFragment = {
  __typename?: "Supplier";
  id: number;
  name: string;
};

export type CompanyViewSuppliersSuppliersQueryVariables = Exact<{
  [key: string]: never;
}>;

export type CompanyViewSuppliersSuppliersQuery = {
  __typename?: "Query";
  suppliersFindMany: {
    __typename?: "SuppliersFindManyOutput";
    items: Array<{ __typename?: "Supplier"; id: number; name: string }>;
  };
};

export type CompanyViewUsers__PersonFragment = {
  __typename?: "Person";
  id: number;
  name: string | null;
  email: string;
  projectIds: Array<number>;
  phoneNumber: string | null;
  role: UserRole | null;
  projects: Array<{ __typename?: "Project"; id: number; name: string }>;
};

export type CompanyViewUsers__ProjectFragment = {
  __typename?: "Project";
  id: number;
  name: string;
};

export type CompanyViewUsersTable__PersonFragment = {
  __typename?: "Person";
  id: number;
  name: string | null;
  email: string;
  projectIds: Array<number>;
  phoneNumber: string | null;
  role: UserRole | null;
  projects: Array<{ __typename?: "Project"; id: number; name: string }>;
};

export type CompanyViewUsersTable__ProjectFragment = {
  __typename?: "Project";
  id: number;
  name: string;
};

export type CompanyViewUsersTableRow__PersonFragment = {
  __typename?: "Person";
  id: number;
  name: string | null;
  email: string;
  projectIds: Array<number>;
  phoneNumber: string | null;
  role: UserRole | null;
  projects: Array<{ __typename?: "Project"; id: number; name: string }>;
};

export type CompanyViewUsersTableRow__ProjectFragment = {
  __typename?: "Project";
  id: number;
  name: string;
};

export type CompanyViewUsers__GetPersonsQueryVariables = Exact<{
  filters: InputMaybe<PersonsFindManyFiltersInput>;
  page: Scalars["Int"];
  perPage: Scalars["Int"];
}>;

export type CompanyViewUsers__GetPersonsQuery = {
  __typename?: "Query";
  personsFindMany: {
    __typename?: "PersonsFindManyOutput";
    items: Array<{
      __typename?: "Person";
      id: number;
      name: string | null;
      email: string;
      projectIds: Array<number>;
      phoneNumber: string | null;
      role: UserRole | null;
      projects: Array<{ __typename?: "Project"; id: number; name: string }>;
    }>;
    pageInfo: {
      __typename?: "PaginationInfo";
      perPage: number;
      totalItems: number;
    };
  };
};

export type CompanyViewUsers__FindProjectsQueryVariables = Exact<{
  [key: string]: never;
}>;

export type CompanyViewUsers__FindProjectsQuery = {
  __typename?: "Query";
  projectsFindMany: {
    __typename?: "ProjectsFindManyOutput";
    items: Array<{
      __typename?: "Project";
      companyId: number;
      id: number;
      name: string;
    }>;
  };
};

export type CompanyViewUsers__UpdatePersonProjectsMutationVariables = Exact<{
  id: Scalars["Int"];
  projectIds: Array<Scalars["Int"]> | Scalars["Int"];
}>;

export type CompanyViewUsers__UpdatePersonProjectsMutation = {
  __typename?: "Mutation";
  persons: {
    __typename?: "PersonsMutations";
    updateProjects: {
      __typename?: "PersonUpdateOutput";
      person: { __typename?: "Person"; id: number; projectIds: Array<number> };
    };
  };
};

export type CompanyViewUsers__ReadPersonFragment = {
  __typename?: "Person";
  id: number;
  projectIds: Array<number>;
};

export type CompanyViewHeaderCompanyFragment = {
  __typename?: "Company";
  name: string;
  taxId: string | null;
};

export type CompanyViewCompanyQueryVariables = Exact<{
  companyId: Scalars["Int"];
}>;

export type CompanyViewCompanyQuery = {
  __typename?: "Query";
  companyById: {
    __typename?: "Company";
    supplierIds: Array<number>;
    name: string;
    taxId: string | null;
    id: number;
    active: boolean;
  };
};

export type CompanyView__UpdateSuppliersMutationVariables = Exact<{
  companyId: Scalars["Int"];
  supplierIds: Array<Scalars["Int"]> | Scalars["Int"];
}>;

export type CompanyView__UpdateSuppliersMutation = {
  __typename?: "Mutation";
  companies: {
    __typename?: "CompaniesMutations";
    update: {
      __typename?: "CompaniesUpdateOutput";
      status: MutationStatus;
      company: {
        __typename?: "Company";
        id: number;
        supplierIds: Array<number>;
      };
    };
  };
};

export type ManageCompanyModalCompanyFragment = {
  __typename?: "Company";
  id: number;
  name: string;
  taxId: string | null;
  active: boolean;
};

export type GetCompaniesQueryVariables = Exact<{ [key: string]: never }>;

export type GetCompaniesQuery = {
  __typename?: "Query";
  companiesFindMany: {
    __typename?: "CompaniesFindManyOutput";
    items: Array<{
      __typename?: "Company";
      id: number;
      name: string;
      taxId: string | null;
      active: boolean;
    }>;
  };
};

export type CreateCompanyMutationVariables = Exact<{
  company: CompanyCreateInput;
}>;

export type CreateCompanyMutation = {
  __typename?: "Mutation";
  companies: {
    __typename?: "CompaniesMutations";
    create: {
      __typename?: "CompaniesUpdateOutput";
      status: MutationStatus;
      company: {
        __typename?: "Company";
        id: number;
        name: string;
        taxId: string | null;
        active: boolean;
        supplierIds: Array<number>;
        useCompanyCatalog: boolean;
      };
    };
  };
};

export type CompanyCatalog__CatalogProductFragment = {
  __typename?: "CatalogProduct";
  id: number;
  product: {
    __typename?: "Product";
    id: number;
    primaryImage: string | null;
    name: string;
    displaySize: string;
  };
  variants: Array<{
    __typename?: "ProductVariant";
    id: number;
    image: string | null;
    name: string;
  }>;
};

export type CompanyCatalog__ProductFragment = {
  __typename?: "Product";
  id: number;
  primaryImage: string | null;
  name: string;
  displaySize: string;
};

export type CompanyCatalog__ProductVariantFragment = {
  __typename?: "ProductVariant";
  id: number;
  image: string | null;
  name: string;
};

export type CompanyCatalogAddProducts__ProductFragment = {
  __typename?: "Product";
  id: number;
  primaryImage: string | null;
  name: string;
  variants: Array<{
    __typename?: "ProductVariant";
    id: number;
    image: string | null;
    name: string;
  }>;
  companyCatalogProducts: Array<{
    __typename?: "CatalogProduct";
    id: number;
    variantIds: Array<number>;
    companyId: number;
  }>;
};

export type CompanyCatalogAddProducts__ProductVariantFragment = {
  __typename?: "ProductVariant";
  id: number;
  image: string | null;
  name: string;
};

export type CompanyCatalogAddProductsPopupTable__ProductFragment = {
  __typename?: "Product";
  id: number;
  primaryImage: string | null;
  name: string;
  variants: Array<{
    __typename?: "ProductVariant";
    id: number;
    image: string | null;
    name: string;
  }>;
  companyCatalogProducts: Array<{
    __typename?: "CatalogProduct";
    id: number;
    variantIds: Array<number>;
    companyId: number;
  }>;
};

export type CompanyCatalogAddProductsPopupTable__ProductVariantFragment = {
  __typename?: "ProductVariant";
  id: number;
  image: string | null;
  name: string;
};

export type CompanyCatalogAddProductsPopupTableItem__ProductFragment = {
  __typename?: "Product";
  id: number;
  primaryImage: string | null;
  name: string;
  companyCatalogProducts: Array<{
    __typename?: "CatalogProduct";
    id: number;
    variantIds: Array<number>;
    companyId: number;
  }>;
  variants: Array<{
    __typename?: "ProductVariant";
    id: number;
    image: string | null;
    name: string;
  }>;
};

export type CompanyCatalogAddProductsPopupTableItem__ProductVariantFragment = {
  __typename?: "ProductVariant";
  id: number;
  image: string | null;
  name: string;
};

export type CompanyCatalogAddProductsPopupTableItem__CatalogProductFragment = {
  __typename?: "CatalogProduct";
  id: number;
  variantIds: Array<number>;
  companyId: number;
};

export type CompanyCatalogAddProductsPopupTableItemVariants__ProductVariantFragment =
  {
    __typename?: "ProductVariant";
    id: number;
    image: string | null;
    name: string;
  };

export type CompanyCatalog__AddProductMutationVariables = Exact<{
  data: CatalogProductCreateInput;
}>;

export type CompanyCatalog__AddProductMutation = {
  __typename?: "Mutation";
  catalogProducts: {
    __typename?: "CatalogProductsMutations";
    addProducts: {
      __typename?: "CatalogProductsCreateBulkOutput";
      status: MutationStatus;
      items: Array<{
        __typename?: "CatalogProduct";
        id: number;
        companyId: number;
        variantIds: Array<number>;
        product: { __typename?: "Product"; id: number };
      }>;
    };
  };
};

export type CompanyCatalog__AddProduct__ProductFragment = {
  __typename?: "Product";
  id: number;
  variants: Array<{ __typename?: "ProductVariant"; id: number }>;
};

export type CompanyCatalog__AddProduct__ProductUpdateFragment = {
  __typename?: "Product";
  id: number;
  companyCatalogProducts: Array<{
    __typename?: "CatalogProduct";
    id: number;
    variantIds: Array<number>;
    companyId: number;
  }>;
};

export type CompanyCatalog__AddProductsByFiltersMutationVariables = Exact<{
  companyId: Scalars["Int"];
  totalItems: Scalars["Int"];
  filters: ProductsFindManyFiltersInput;
}>;

export type CompanyCatalog__AddProductsByFiltersMutation = {
  __typename?: "Mutation";
  catalogProducts: {
    __typename?: "CatalogProductsMutations";
    addProductsByFilters: {
      __typename?: "CatalogProductsCreateBulkOutput";
      status: MutationStatus;
    };
  };
};

export type CompanyCatalogAddProducts__GetProductsQueryVariables = Exact<{
  page: Scalars["Int"];
  perPage: Scalars["Int"];
  filters: ProductsFindManyFiltersInput;
}>;

export type CompanyCatalogAddProducts__GetProductsQuery = {
  __typename?: "Query";
  productsFindMany: {
    __typename?: "ProductsFindManyOutput";
    items: Array<{
      __typename?: "Product";
      id: number;
      primaryImage: string | null;
      name: string;
      variants: Array<{
        __typename?: "ProductVariant";
        id: number;
        image: string | null;
        name: string;
      }>;
      companyCatalogProducts: Array<{
        __typename?: "CatalogProduct";
        id: number;
        variantIds: Array<number>;
        companyId: number;
      }>;
    }>;
    pageInfo: {
      __typename?: "PaginationInfo";
      perPage: number;
      totalItems: number;
    };
  };
};

export type CompanyCatalogTable__CatalogProductFragment = {
  __typename?: "CatalogProduct";
  id: number;
  product: {
    __typename?: "Product";
    id: number;
    primaryImage: string | null;
    name: string;
    displaySize: string;
  };
  variants: Array<{
    __typename?: "ProductVariant";
    id: number;
    image: string | null;
    name: string;
  }>;
};

export type CompanyCatalogTable__ProductFragment = {
  __typename?: "Product";
  id: number;
  primaryImage: string | null;
  name: string;
  displaySize: string;
};

export type CompanyCatalogTable__ProductVariantFragment = {
  __typename?: "ProductVariant";
  id: number;
  image: string | null;
  name: string;
};

export type CompanyCatalogTableActions__CatalogProductFragment = {
  __typename?: "CatalogProduct";
  id: number;
};

export type CompanyCatalogTableItem__ProductFragment = {
  __typename?: "Product";
  id: number;
  primaryImage: string | null;
  name: string;
  displaySize: string;
};

export type CompanyCatalogTableItem__ProductVariantFragment = {
  __typename?: "ProductVariant";
  id: number;
  image: string | null;
  name: string;
};

export type CompanyCatalogTableItemVariants__ProductVariantFragment = {
  __typename?: "ProductVariant";
  id: number;
  image: string | null;
  name: string;
};

export type CompanyCatalog__DeleteProductsByFiltersMutationVariables = Exact<{
  companyId: Scalars["Int"];
  totalItems: Scalars["Int"];
  filters: CatalogProductsFindManyFiltersInput;
}>;

export type CompanyCatalog__DeleteProductsByFiltersMutation = {
  __typename?: "Mutation";
  catalogProducts: {
    __typename?: "CatalogProductsMutations";
    deleteProductsByFilters: {
      __typename?: "StatusOutput";
      status: MutationStatus;
    };
  };
};

export type CompanyCatalog__GetProductsQueryVariables = Exact<{
  pagination: PaginationArgs;
  filters: CatalogProductsFindManyFiltersInput;
}>;

export type CompanyCatalog__GetProductsQuery = {
  __typename?: "Query";
  catalogProductsFindMany: {
    __typename?: "CatalogProductsFindManyOutput";
    items: Array<{
      __typename?: "CatalogProduct";
      id: number;
      product: {
        __typename?: "Product";
        id: number;
        primaryImage: string | null;
        name: string;
        displaySize: string;
      };
      variants: Array<{
        __typename?: "ProductVariant";
        id: number;
        image: string | null;
        name: string;
      }>;
    }>;
    pageInfo: {
      __typename?: "PaginationInfo";
      perPage: number;
      totalItems: number;
    };
  };
};

export type CompanyCatalog__DeleteProductsMutationVariables = Exact<{
  data: Array<CatalogProductDeleteInput> | CatalogProductDeleteInput;
}>;

export type CompanyCatalog__DeleteProductsMutation = {
  __typename?: "Mutation";
  catalogProducts: {
    __typename?: "CatalogProductsMutations";
    deleteProducts: { __typename?: "StatusOutput"; status: MutationStatus };
  };
};

export type LineItemsTotalPriceCell__LineItemFragment = {
  __typename?: "LineItem";
  id: number;
  priceTotalWithDiscount: number | null;
};

export type UpdateCustomLineItemOptimisticResponse__LineItemReadFragment = {
  __typename: "LineItem";
  id: number;
  note: string | null;
  quantity: number;
  vat: number;
  variantId: number | null;
  displayVariantName: string | null;
  productId: number | null;
  price: number | null;
  discount: number;
  orderId: number;
  displayName: string;
  displaySize: string;
};

export type UpdateCustomLineItemOptimisticResponse__LineItemFragment = {
  __typename: "LineItem";
  id: number;
  note: string | null;
  quantity: number;
  vat: number;
  variantId: number | null;
  displayVariantName: string | null;
  productId: number | null;
  price: number | null;
  discount: number;
  orderId: number;
  displayName: string;
  displaySize: string;
  priceFormatted: string | null;
  priceTotal: number | null;
  priceTotalFormatted: string | null;
  priceWithDiscount: number | null;
  priceWithDiscountFormatted: string | null;
  priceTotalWithDiscount: number | null;
  priceTotalWithDiscountFormatted: string | null;
  priceWithVat: number | null;
  priceWithVatFormatted: string | null;
  priceTotalWithVat: number | null;
  priceTotalWithVatFormatted: string | null;
  vatAmount: number | null;
  vatAmountFormatted: string | null;
  discountAmount: number | null;
  discountAmountFormatted: string | null;
  discountTotalAmount: number | null;
  discountTotalAmountFormatted: string | null;
};

export type UpdateCustomLineItemOptimisticResponse__RequisitionLineItemReadFragment =
  {
    __typename: "RequisitionLineItem";
    id: number;
    note: string | null;
    quantity: number;
    vat: number;
    supplierId: number | null;
    variantId: number | null;
    displayVariantName: string | null;
    productId: number | null;
    price: number | null;
    discount: number;
    requisitionId: number;
    displayName: string;
    displaySize: string;
    supplier: { __typename?: "Supplier"; id: number; name: string } | null;
  };

export type UpdateCustomLineItemOptimisticResponse__RequisitionLineItemFragment =
  {
    __typename: "RequisitionLineItem";
    id: number;
    note: string | null;
    quantity: number;
    vat: number;
    supplierId: number | null;
    variantId: number | null;
    displayVariantName: string | null;
    productId: number | null;
    price: number | null;
    discount: number;
    requisitionId: number;
    displayName: string;
    displaySize: string;
    priceFormatted: string | null;
    priceTotal: number | null;
    priceTotalFormatted: string | null;
    priceWithDiscount: number | null;
    priceWithDiscountFormatted: string | null;
    priceTotalWithDiscount: number | null;
    priceTotalWithDiscountFormatted: string | null;
    priceWithVat: number | null;
    priceWithVatFormatted: string | null;
    priceTotalWithVat: number | null;
    priceTotalWithVatFormatted: string | null;
    vatAmount: number | null;
    vatAmountFormatted: string | null;
    discountAmount: number | null;
    discountAmountFormatted: string | null;
    discountTotalAmount: number | null;
    discountTotalAmountFormatted: string | null;
    supplier: { __typename?: "Supplier"; id: number; name: string } | null;
  };

export type UpdateCustomLineItemOptimisticResponse__SupplierFragment = {
  __typename?: "Supplier";
  id: number;
  name: string;
};

export type UpdateProductLineItemOptimisticResponse__ProductSupplierPriceFragment =
  { __typename?: "ProductSupplierPrice"; id: string; price: number | null };

export type UpdateProductLineItemOptimisticResponse__LineItemReadFragment = {
  __typename: "LineItem";
  id: number;
  note: string | null;
  quantity: number;
  vat: number;
  variantId: number | null;
  displayVariantName: string | null;
  productId: number | null;
  price: number | null;
  discount: number;
  orderId: number;
};

export type UpdateProductLineItemOptimisticResponse__LineItemFragment = {
  __typename: "LineItem";
  id: number;
  note: string | null;
  quantity: number;
  vat: number;
  variantId: number | null;
  displayVariantName: string | null;
  productId: number | null;
  price: number | null;
  discount: number;
  orderId: number;
  priceFormatted: string | null;
  priceTotal: number | null;
  priceTotalFormatted: string | null;
  priceWithDiscount: number | null;
  priceWithDiscountFormatted: string | null;
  priceTotalWithDiscount: number | null;
  priceTotalWithDiscountFormatted: string | null;
  priceWithVat: number | null;
  priceWithVatFormatted: string | null;
  priceTotalWithVat: number | null;
  priceTotalWithVatFormatted: string | null;
  vatAmount: number | null;
  vatAmountFormatted: string | null;
  discountAmount: number | null;
  discountAmountFormatted: string | null;
  discountTotalAmount: number | null;
  discountTotalAmountFormatted: string | null;
};

export type UpdateProductLineItemOptimisticResponse__RequisitionLineItemReadFragment =
  {
    __typename: "RequisitionLineItem";
    id: number;
    note: string | null;
    quantity: number;
    vat: number;
    supplierId: number | null;
    variantId: number | null;
    displayVariantName: string | null;
    productId: number | null;
    price: number | null;
    discount: number;
    requisitionId: number;
    supplier: { __typename?: "Supplier"; id: number; name: string } | null;
  };

export type UpdateProductLineItemOptimisticResponse__RequisitionLineItemFragment =
  {
    __typename: "RequisitionLineItem";
    id: number;
    note: string | null;
    quantity: number;
    vat: number;
    supplierId: number | null;
    variantId: number | null;
    displayVariantName: string | null;
    productId: number | null;
    price: number | null;
    discount: number;
    requisitionId: number;
    priceFormatted: string | null;
    priceTotal: number | null;
    priceTotalFormatted: string | null;
    priceWithDiscount: number | null;
    priceWithDiscountFormatted: string | null;
    priceTotalWithDiscount: number | null;
    priceTotalWithDiscountFormatted: string | null;
    priceWithVat: number | null;
    priceWithVatFormatted: string | null;
    priceTotalWithVat: number | null;
    priceTotalWithVatFormatted: string | null;
    vatAmount: number | null;
    vatAmountFormatted: string | null;
    discountAmount: number | null;
    discountAmountFormatted: string | null;
    discountTotalAmount: number | null;
    discountTotalAmountFormatted: string | null;
    supplier: { __typename?: "Supplier"; id: number; name: string } | null;
  };

export type UpdateProductLineItemOptimisticResponse__SupplierFragment = {
  __typename?: "Supplier";
  id: number;
  name: string;
};

export type UpdateProductLineItemOptimisticResponse__ProductVariantReadFragment =
  { __typename?: "ProductVariant"; id: number; name: string };

export type LineItemPricesFragment = {
  __typename?: "LineItem";
  price: number | null;
  priceFormatted: string | null;
  priceTotal: number | null;
  priceTotalFormatted: string | null;
  priceWithDiscount: number | null;
  priceWithDiscountFormatted: string | null;
  priceTotalWithDiscount: number | null;
  priceTotalWithDiscountFormatted: string | null;
  priceWithVat: number | null;
  priceWithVatFormatted: string | null;
  priceTotalWithVat: number | null;
  priceTotalWithVatFormatted: string | null;
  vatAmount: number | null;
  vatAmountFormatted: string | null;
  discountAmount: number | null;
  discountAmountFormatted: string | null;
  discountTotalAmount: number | null;
  discountTotalAmountFormatted: string | null;
};

export type RequisitionLineItemPricesFragment = {
  __typename?: "RequisitionLineItem";
  price: number | null;
  priceFormatted: string | null;
  priceTotal: number | null;
  priceTotalFormatted: string | null;
  priceWithDiscount: number | null;
  priceWithDiscountFormatted: string | null;
  priceTotalWithDiscount: number | null;
  priceTotalWithDiscountFormatted: string | null;
  priceWithVat: number | null;
  priceWithVatFormatted: string | null;
  priceTotalWithVat: number | null;
  priceTotalWithVatFormatted: string | null;
  vatAmount: number | null;
  vatAmountFormatted: string | null;
  discountAmount: number | null;
  discountAmountFormatted: string | null;
  discountTotalAmount: number | null;
  discountTotalAmountFormatted: string | null;
};

export type OrderView__OrderFragment = {
  __typename?: "Order";
  id: number;
  status: OrderStatus;
  deliveryDate: string;
  supplierId: number | null;
  subCompanyId: number | null;
  note: string | null;
  discount: number;
  createdDate: string;
  project: {
    __typename?: "Project";
    name: string;
    geoLocation: string;
    companyId: number;
    company: { __typename?: "Company"; id: number; name: string };
  };
  lineItems: Array<{
    __typename: "LineItem";
    productId: number | null;
    variantId: number | null;
    id: number;
    note: string | null;
    quantity: number;
    vat: number;
    displayVariantName: string | null;
    price: number | null;
    discount: number;
    orderId: number;
    displayName: string;
    displaySize: string;
    displayImage: string | null;
    description: string | null;
    priceTotalWithVatFormatted: string | null;
    priceFormatted: string | null;
    priceTotal: number | null;
    priceTotalFormatted: string | null;
    priceTotalWithDiscountFormatted: string | null;
    discountTotalAmountFormatted: string | null;
    priceTotalWithDiscount: number | null;
    product: {
      __typename?: "Product";
      id: number;
      categoryIds: Array<number>;
      primaryImage: string | null;
      name: string;
      variants: Array<{
        __typename?: "ProductVariant";
        id: number;
        name: string;
        color: string | null;
      }>;
      companyCatalogProducts: Array<{
        __typename?: "CatalogProduct";
        id: number;
        companyId: number;
        variantIds: Array<number>;
      }>;
      attributes: Array<{
        __typename?: "ProductAttribute";
        attribute: string;
        description: string;
      }>;
    } | null;
    requisitionLineItem: {
      __typename?: "RequisitionLineItem";
      requisitionId: number;
    } | null;
  }>;
  supplier: { __typename?: "Supplier"; id: number; name: string } | null;
  metadata: {
    __typename?: "OrderMetadata";
    id: number;
    canDelete: boolean;
    canAttachFiles: boolean;
    fields: {
      __typename?: "OrderMetadataFields";
      note: {
        __typename?: "GraphQLMetadataField";
        canEdit: boolean;
        reason: string;
      };
      supplierId: {
        __typename?: "GraphQLMetadataField";
        canEdit: boolean;
        reason: string;
      };
      deliveryDate: {
        __typename?: "GraphQLMetadataField";
        canEdit: boolean;
        reason: string;
      };
      subCompanyId: {
        __typename?: "GraphQLMetadataField";
        canEdit: boolean;
        reason: string;
      };
      items: {
        __typename?: "GraphQLMetadataField";
        canEdit: boolean;
        reason: string;
      };
      status: {
        __typename?: "OrderMetadataStatusField";
        canEdit: boolean;
        reason: string;
        possibleValues: Array<OrderStatus>;
      };
      shipments: { __typename?: "GraphQLMetadataField"; canEdit: boolean };
      discount: { __typename?: "GraphQLMetadataField"; canEdit: boolean };
    };
  };
  attachments: Array<{
    __typename?: "Attachment";
    id: number;
    url: string;
    name: string;
    mimeType: string;
  }>;
  shipments: Array<{
    __typename?: "Shipment";
    id: number;
    orderId: number;
    deliveryDate: string;
    lineItems: Array<{
      __typename?: "ShipmentLineItem";
      id: string;
      shippedQuantity: number;
      lineItem: {
        __typename?: "LineItem";
        id: number;
        vat: number;
        priceWithDiscount: number | null;
        displayImage: string | null;
        displayName: string;
        displayVariantName: string | null;
        displaySize: string;
        price: number | null;
        discount: number;
      };
    }>;
    certificates: Array<{
      __typename?: "ShipmentCertificate";
      id: number;
      url: string;
    }>;
    approvedBy: { __typename?: "Person"; name: string | null };
  }>;
  creator: {
    __typename?: "Person";
    name: string | null;
    phoneNumber: string | null;
  };
  dropOffContact: {
    __typename?: "Person";
    id: number;
    email: string;
    name: string | null;
    phoneNumber: string | null;
  };
};

export type OrderViewShipmentFragment = {
  __typename?: "Shipment";
  id: number;
  orderId: number;
  deliveryDate: string;
  lineItems: Array<{
    __typename?: "ShipmentLineItem";
    id: string;
    shippedQuantity: number;
    lineItem: {
      __typename?: "LineItem";
      id: number;
      vat: number;
      priceWithDiscount: number | null;
      displayImage: string | null;
      displayName: string;
      displayVariantName: string | null;
      displaySize: string;
      price: number | null;
      discount: number;
    };
  }>;
  certificates: Array<{
    __typename?: "ShipmentCertificate";
    id: number;
    url: string;
  }>;
  approvedBy: { __typename?: "Person"; name: string | null };
};

export type OrderView__ShipmentLineItemFragment = {
  __typename?: "ShipmentLineItem";
  id: string;
  shippedQuantity: number;
  lineItem: {
    __typename?: "LineItem";
    id: number;
    vat: number;
    priceWithDiscount: number | null;
    displayImage: string | null;
    displayName: string;
    displayVariantName: string | null;
    displaySize: string;
    price: number | null;
    discount: number;
  };
};

export type OrderView__ShipmentCertificateFragment = {
  __typename?: "ShipmentCertificate";
  id: number;
  url: string;
};

export type OrderView__ShipmentLineItemLineItemFragment = {
  __typename?: "LineItem";
  id: number;
  vat: number;
  priceWithDiscount: number | null;
  displayImage: string | null;
  displayName: string;
  displayVariantName: string | null;
  displaySize: string;
  price: number | null;
  discount: number;
};

export type OrderView__LineItemFragment = {
  __typename: "LineItem";
  id: number;
  note: string | null;
  quantity: number;
  vat: number;
  variantId: number | null;
  displayVariantName: string | null;
  productId: number | null;
  price: number | null;
  discount: number;
  orderId: number;
  displayName: string;
  displaySize: string;
  displayImage: string | null;
  description: string | null;
  priceTotalWithVatFormatted: string | null;
  priceFormatted: string | null;
  priceTotal: number | null;
  priceTotalFormatted: string | null;
  priceTotalWithDiscountFormatted: string | null;
  discountTotalAmountFormatted: string | null;
  priceTotalWithDiscount: number | null;
  product: {
    __typename?: "Product";
    id: number;
    categoryIds: Array<number>;
    primaryImage: string | null;
    name: string;
    variants: Array<{
      __typename?: "ProductVariant";
      id: number;
      name: string;
      color: string | null;
    }>;
    companyCatalogProducts: Array<{
      __typename?: "CatalogProduct";
      id: number;
      companyId: number;
      variantIds: Array<number>;
    }>;
    attributes: Array<{
      __typename?: "ProductAttribute";
      attribute: string;
      description: string;
    }>;
  } | null;
  requisitionLineItem: {
    __typename?: "RequisitionLineItem";
    requisitionId: number;
  } | null;
};

export type OrderView__ProductFragment = {
  __typename?: "Product";
  id: number;
  categoryIds: Array<number>;
  primaryImage: string | null;
  name: string;
  variants: Array<{
    __typename?: "ProductVariant";
    id: number;
    name: string;
    color: string | null;
  }>;
  companyCatalogProducts: Array<{
    __typename?: "CatalogProduct";
    id: number;
    companyId: number;
    variantIds: Array<number>;
  }>;
  attributes: Array<{
    __typename?: "ProductAttribute";
    attribute: string;
    description: string;
  }>;
};

export type OrderView__ProductVariantFragment = {
  __typename?: "ProductVariant";
  id: number;
  color: string | null;
};

export type OrderView__CatalogProductFragment = {
  __typename?: "CatalogProduct";
  id: number;
  variantIds: Array<number>;
  companyId: number;
};

export type OrderView__SupplierFragment = {
  __typename?: "Supplier";
  id: number;
  name: string;
};

export type OrderView__OrderMetadataFragment = {
  __typename?: "OrderMetadata";
  id: number;
  canDelete: boolean;
  canAttachFiles: boolean;
  fields: {
    __typename?: "OrderMetadataFields";
    items: {
      __typename?: "GraphQLMetadataField";
      canEdit: boolean;
      reason: string;
    };
    status: {
      __typename?: "OrderMetadataStatusField";
      canEdit: boolean;
      reason: string;
      possibleValues: Array<OrderStatus>;
    };
    shipments: { __typename?: "GraphQLMetadataField"; canEdit: boolean };
    note: {
      __typename?: "GraphQLMetadataField";
      canEdit: boolean;
      reason: string;
    };
    supplierId: {
      __typename?: "GraphQLMetadataField";
      canEdit: boolean;
      reason: string;
    };
    deliveryDate: {
      __typename?: "GraphQLMetadataField";
      canEdit: boolean;
      reason: string;
    };
    subCompanyId: {
      __typename?: "GraphQLMetadataField";
      canEdit: boolean;
      reason: string;
    };
    discount: { __typename?: "GraphQLMetadataField"; canEdit: boolean };
  };
};

export type OrderView__AttachmentFragment = {
  __typename?: "Attachment";
  id: number;
  url: string;
  name: string;
  mimeType: string;
};

export type OrderView__ProjectFragment = {
  __typename?: "Project";
  companyId: number;
  name: string;
  company: { __typename?: "Company"; id: number; name: string };
};

export type OrderView__CompanyFragment = {
  __typename?: "Company";
  id: number;
  name: string;
};

export type OrderViewLineItemUpdatePopup__LineItemFragment = {
  __typename?: "LineItem";
  productId: number | null;
  variantId: number | null;
  quantity: number;
  note: string | null;
  price: number | null;
  displayName: string;
  description: string | null;
  displaySize: string;
  discount: number;
  requisitionLineItem: {
    __typename?: "RequisitionLineItem";
    requisitionId: number;
  } | null;
};

export type OrderViewLineItemUpdatePopup__RequisitionLineItemFragment = {
  __typename?: "RequisitionLineItem";
  productId: number | null;
  variantId: number | null;
  quantity: number;
  note: string | null;
  price: number | null;
  supplierId: number | null;
  displayName: string;
  description: string | null;
  displaySize: string;
  discount: number;
  orderItemChanged: boolean;
  orderLineItem: { __typename?: "LineItem"; orderId: number } | null;
};

export type OrderViewLineItemUpdateProductFragment = {
  __typename?: "Product";
  categoryIds: Array<number>;
  primaryImage: string | null;
  name: string;
  id: number;
  companyCatalogProducts: Array<{
    __typename?: "CatalogProduct";
    id: number;
    companyId: number;
    variantIds: Array<number>;
  }>;
  variants: Array<{ __typename?: "ProductVariant"; id: number; name: string }>;
  attributes: Array<{
    __typename?: "ProductAttribute";
    attribute: string;
    description: string;
  }>;
};

export type OrderViewLineItemUpdateCategoryFragment = {
  __typename?: "Category";
  id: number;
  name: string;
  children: Array<{
    __typename?: "Category";
    id: number;
    name: string;
    children: Array<{
      __typename?: "Category";
      id: number;
      name: string;
      children: Array<{
        __typename?: "Category";
        id: number;
        name: string;
        children: Array<{
          __typename?: "Category";
          id: number;
          name: string;
          children: Array<{
            __typename?: "Category";
            id: number;
            name: string;
            children: Array<{
              __typename?: "Category";
              id: number;
              name: string;
              children: Array<{
                __typename?: "Category";
                id: number;
                name: string;
              }>;
            }>;
          }>;
        }>;
      }>;
    }>;
  }>;
};

export type OrderViewLineItemUpdatePopupProductSupplierPriceFragment = {
  __typename?: "ProductSupplierPrice";
  price: number | null;
  id: string;
  supplierId: number;
  supplierName: string;
  priceFormatted: string | null;
};

export type OrderProductCategoriesProductFragment = {
  __typename?: "Product";
  categoryIds: Array<number>;
};

export type OrderProductCategoriesCategoryFragment = {
  __typename?: "Category";
  id: number;
  name: string;
  children: Array<{
    __typename?: "Category";
    id: number;
    name: string;
    children: Array<{
      __typename?: "Category";
      id: number;
      name: string;
      children: Array<{
        __typename?: "Category";
        id: number;
        name: string;
        children: Array<{
          __typename?: "Category";
          id: number;
          name: string;
          children: Array<{
            __typename?: "Category";
            id: number;
            name: string;
            children: Array<{
              __typename?: "Category";
              id: number;
              name: string;
              children: Array<{
                __typename?: "Category";
                id: number;
                name: string;
              }>;
            }>;
          }>;
        }>;
      }>;
    }>;
  }>;
};

export type OrderProductPopupForm__LineItemFragment = {
  __typename?: "LineItem";
  displayName: string;
  description: string | null;
  variantId: number | null;
};

export type OrderProductPopupForm__ProductFragment = {
  __typename?: "Product";
  primaryImage: string | null;
  name: string;
  id: number;
  variants: Array<{ __typename?: "ProductVariant"; id: number; name: string }>;
  attributes: Array<{
    __typename?: "ProductAttribute";
    attribute: string;
    description: string;
  }>;
  companyCatalogProducts: Array<{
    __typename?: "CatalogProduct";
    id: number;
    companyId: number;
    variantIds: Array<number>;
  }>;
};

export type OrderProductPopupForm__ProductSupplierPriceFragment = {
  __typename?: "ProductSupplierPrice";
  price: number | null;
  id: string;
  supplierId: number;
  supplierName: string;
  priceFormatted: string | null;
};

export type OrderProductPopupForm__RequisitionLineItemFragment = {
  __typename?: "RequisitionLineItem";
  displayName: string;
  description: string | null;
  variantId: number | null;
};

export type OrderProductRegularLineItemForm__RequisitionLineItemFragment = {
  __typename?: "RequisitionLineItem";
  description: string | null;
  variantId: number | null;
};

export type OrderProductRegularLineItemFormLineItemFragment = {
  __typename?: "LineItem";
  description: string | null;
  variantId: number | null;
};

export type OrderProductRegularLineItemFormProductFragment = {
  __typename?: "Product";
  id: number;
  attributes: Array<{
    __typename?: "ProductAttribute";
    attribute: string;
    description: string;
  }>;
  variants: Array<{ __typename?: "ProductVariant"; id: number; name: string }>;
  companyCatalogProducts: Array<{
    __typename?: "CatalogProduct";
    id: number;
    companyId: number;
    variantIds: Array<number>;
  }>;
};

export type OrderProductRegularLineItemFormProductSupplierPriceFragment = {
  __typename?: "ProductSupplierPrice";
  price: number | null;
  id: string;
  supplierId: number;
  supplierName: string;
  priceFormatted: string | null;
};

export type OrderProductRegularLineItemFormSupplierFieldProductSupplierPriceFragment =
  {
    __typename?: "ProductSupplierPrice";
    price: number | null;
    id: string;
    supplierId: number;
    supplierName: string;
    priceFormatted: string | null;
  };

export type OrderProductRegularLineItemFormSupplierInputProductSupplierPriceFragment =
  {
    __typename?: "ProductSupplierPrice";
    price: number | null;
    id: string;
    supplierId: number;
    supplierName: string;
    priceFormatted: string | null;
  };

export type OrderProductRegularLineItemRow__LineItemFragment = {
  __typename?: "LineItem";
  displayName: string;
  description: string | null;
  variantId: number | null;
};

export type OrderProductRegularLineItemRow__RequisitionLineItemFragment = {
  __typename?: "RequisitionLineItem";
  displayName: string;
  description: string | null;
  variantId: number | null;
};

export type OrderProductRegularLineItemRowProductFragment = {
  __typename?: "Product";
  primaryImage: string | null;
  name: string;
  id: number;
  variants: Array<{ __typename?: "ProductVariant"; id: number; name: string }>;
  attributes: Array<{
    __typename?: "ProductAttribute";
    attribute: string;
    description: string;
  }>;
  companyCatalogProducts: Array<{
    __typename?: "CatalogProduct";
    id: number;
    companyId: number;
    variantIds: Array<number>;
  }>;
};

export type OrderProductRegularLineItemRowProductSupplierPriceFragment = {
  __typename?: "ProductSupplierPrice";
  price: number | null;
  id: string;
  supplierId: number;
  supplierName: string;
  priceFormatted: string | null;
};

export type OrderProductRegularLineItemTable__LineItemFragment = {
  __typename?: "LineItem";
  displayName: string;
  description: string | null;
  variantId: number | null;
};

export type OrderProductRegularLineItemTable__RequisitionLineItemFragment = {
  __typename?: "RequisitionLineItem";
  displayName: string;
  description: string | null;
  variantId: number | null;
};

export type OrderProductRegularLineItemTableProductFragment = {
  __typename?: "Product";
  primaryImage: string | null;
  name: string;
  id: number;
  variants: Array<{ __typename?: "ProductVariant"; id: number; name: string }>;
  attributes: Array<{
    __typename?: "ProductAttribute";
    attribute: string;
    description: string;
  }>;
  companyCatalogProducts: Array<{
    __typename?: "CatalogProduct";
    id: number;
    companyId: number;
    variantIds: Array<number>;
  }>;
};

export type OrderProductRegularLineItemTableProductSupplierPriceFragment = {
  __typename?: "ProductSupplierPrice";
  price: number | null;
  id: string;
  supplierId: number;
  supplierName: string;
  priceFormatted: string | null;
};

export type OrderViewLineItemUpdatePopupCategoriesQueryVariables = Exact<{
  [key: string]: never;
}>;

export type OrderViewLineItemUpdatePopupCategoriesQuery = {
  __typename?: "Query";
  categoriesFindMany: {
    __typename?: "CategoriesFindManyOutput";
    items: Array<{
      __typename?: "Category";
      id: number;
      name: string;
      children: Array<{
        __typename?: "Category";
        id: number;
        name: string;
        children: Array<{
          __typename?: "Category";
          id: number;
          name: string;
          children: Array<{
            __typename?: "Category";
            id: number;
            name: string;
            children: Array<{
              __typename?: "Category";
              id: number;
              name: string;
              children: Array<{
                __typename?: "Category";
                id: number;
                name: string;
                children: Array<{
                  __typename?: "Category";
                  id: number;
                  name: string;
                  children: Array<{
                    __typename?: "Category";
                    id: number;
                    name: string;
                  }>;
                }>;
              }>;
            }>;
          }>;
        }>;
      }>;
    }>;
  };
};

export type OrderViewLineItemUpdatePopupProductQueryVariables = Exact<{
  productId: Scalars["Int"];
}>;

export type OrderViewLineItemUpdatePopupProductQuery = {
  __typename?: "Query";
  productById: {
    __typename?: "Product";
    categoryIds: Array<number>;
    primaryImage: string | null;
    name: string;
    id: number;
    companyCatalogProducts: Array<{
      __typename?: "CatalogProduct";
      id: number;
      companyId: number;
      variantIds: Array<number>;
    }>;
    variants: Array<{
      __typename?: "ProductVariant";
      id: number;
      name: string;
    }>;
    attributes: Array<{
      __typename?: "ProductAttribute";
      attribute: string;
      description: string;
    }>;
  };
};

export type OrderViewShipments__OrderFragment = {
  __typename?: "Order";
  supplierId: number | null;
  discount: number;
  id: number;
  metadata: {
    __typename?: "OrderMetadata";
    fields: {
      __typename?: "OrderMetadataFields";
      discount: { __typename?: "GraphQLMetadataField"; canEdit: boolean };
    };
  };
};

export type OrderViewShipments__OrderMetadataFragment = {
  __typename?: "OrderMetadata";
  fields: {
    __typename?: "OrderMetadataFields";
    discount: { __typename?: "GraphQLMetadataField"; canEdit: boolean };
  };
};

export type OrderViewShipmentsShipmentFragment = {
  __typename?: "Shipment";
  id: number;
  orderId: number;
  deliveryDate: string;
  lineItems: Array<{
    __typename?: "ShipmentLineItem";
    shippedQuantity: number;
    id: string;
    lineItem: {
      __typename?: "LineItem";
      vat: number;
      priceWithDiscount: number | null;
      id: number;
      displayImage: string | null;
      displayName: string;
      displayVariantName: string | null;
      displaySize: string;
      price: number | null;
      discount: number;
    };
  }>;
  certificates: Array<{
    __typename?: "ShipmentCertificate";
    id: number;
    url: string;
  }>;
  approvedBy: { __typename?: "Person"; name: string | null };
};

export type OrderViewShipments__ShipmentLineItemFragment = {
  __typename?: "ShipmentLineItem";
  shippedQuantity: number;
  id: string;
  lineItem: {
    __typename?: "LineItem";
    vat: number;
    priceWithDiscount: number | null;
    id: number;
    displayImage: string | null;
    displayName: string;
    displayVariantName: string | null;
    displaySize: string;
    price: number | null;
    discount: number;
  };
};

export type OrderViewShipments__ShipmentLineItemLineItemFragment = {
  __typename?: "LineItem";
  vat: number;
  priceWithDiscount: number | null;
  id: number;
  displayImage: string | null;
  displayName: string;
  displayVariantName: string | null;
  displaySize: string;
  price: number | null;
  discount: number;
};

export type OrderViewShipments__ShipmentCertificateFragment = {
  __typename?: "ShipmentCertificate";
  id: number;
  url: string;
};

export type OrderViewShipmentsTable__OrderFragment = {
  __typename?: "Order";
  id: number;
  discount: number;
};

export type OrderViewShipmentsTableShipmentFragment = {
  __typename?: "Shipment";
  id: number;
  orderId: number;
  deliveryDate: string;
  lineItems: Array<{
    __typename?: "ShipmentLineItem";
    shippedQuantity: number;
    id: string;
    lineItem: {
      __typename?: "LineItem";
      priceWithDiscount: number | null;
      id: number;
      displayImage: string | null;
      displayName: string;
      displayVariantName: string | null;
      displaySize: string;
      price: number | null;
      discount: number;
    };
  }>;
  certificates: Array<{
    __typename?: "ShipmentCertificate";
    id: number;
    url: string;
  }>;
  approvedBy: { __typename?: "Person"; name: string | null };
};

export type OrderViewShipmentsTable__ShipmentLineItemFragment = {
  __typename?: "ShipmentLineItem";
  shippedQuantity: number;
  id: string;
  lineItem: {
    __typename?: "LineItem";
    priceWithDiscount: number | null;
    id: number;
    displayImage: string | null;
    displayName: string;
    displayVariantName: string | null;
    displaySize: string;
    price: number | null;
    discount: number;
  };
};

export type OrderViewShipmentsTable__ShipmentLineItemLineItemFragment = {
  __typename?: "LineItem";
  priceWithDiscount: number | null;
  id: number;
  displayImage: string | null;
  displayName: string;
  displayVariantName: string | null;
  displaySize: string;
  price: number | null;
  discount: number;
};

export type OrderViewShipmentsTable__ShipmentCertificateFragment = {
  __typename?: "ShipmentCertificate";
  id: number;
  url: string;
};

export type OrderViewShipmentsTableRowShipmentFragment = {
  __typename?: "Shipment";
  id: number;
  orderId: number;
  deliveryDate: string;
  approvedBy: { __typename?: "Person"; name: string | null };
  lineItems: Array<{
    __typename?: "ShipmentLineItem";
    shippedQuantity: number;
    id: string;
    lineItem: {
      __typename?: "LineItem";
      priceWithDiscount: number | null;
      id: number;
      displayImage: string | null;
      displayName: string;
      displayVariantName: string | null;
      displaySize: string;
      price: number | null;
      discount: number;
    };
  }>;
  certificates: Array<{
    __typename?: "ShipmentCertificate";
    id: number;
    url: string;
  }>;
};

export type OrderViewShipmentsTableRow__ShipmentLineItemFragment = {
  __typename?: "ShipmentLineItem";
  shippedQuantity: number;
  id: string;
  lineItem: {
    __typename?: "LineItem";
    priceWithDiscount: number | null;
    id: number;
    displayImage: string | null;
    displayName: string;
    displayVariantName: string | null;
    displaySize: string;
    price: number | null;
    discount: number;
  };
};

export type OrderViewShipmentsTableRow__ShipmentLineItemLineItemFragment = {
  __typename?: "LineItem";
  priceWithDiscount: number | null;
  id: number;
  displayImage: string | null;
  displayName: string;
  displayVariantName: string | null;
  displaySize: string;
  price: number | null;
  discount: number;
};

export type OrderViewShipmentsTableRow__ShipmentCertificateFragment = {
  __typename?: "ShipmentCertificate";
  id: number;
  url: string;
};

export type OrderViewShipmentsTableRow__OrderFragment = {
  __typename?: "Order";
  id: number;
  discount: number;
};

export type OrderViewShipmentsTableRowItemsTable__ShipmentLineItemFragment = {
  __typename?: "ShipmentLineItem";
  id: string;
  shippedQuantity: number;
  lineItem: {
    __typename?: "LineItem";
    id: number;
    displayImage: string | null;
    displayName: string;
    displayVariantName: string | null;
    displaySize: string;
    price: number | null;
    discount: number;
  };
};

export type OrderViewShipmentsTableRowItemsTable__LineItemFragment = {
  __typename?: "LineItem";
  id: number;
  displayImage: string | null;
  displayName: string;
  displayVariantName: string | null;
  displaySize: string;
  price: number | null;
  discount: number;
};

export type OrderViewAcceptShipment__LineItemFragment = {
  __typename?: "LineItem";
  id: number;
  displayImage: string | null;
  quantity: number;
  variantId: number | null;
  note: string | null;
  displayName: string;
  displayVariantName: string | null;
  displaySize: string;
  description: string | null;
  productId: number | null;
  priceTotalWithVatFormatted: string | null;
  product: {
    __typename?: "Product";
    id: number;
    variants: Array<{
      __typename?: "ProductVariant";
      id: number;
      color: string | null;
    }>;
  } | null;
};

export type OrderViewAcceptShipment__ProductFragment = {
  __typename?: "Product";
  id: number;
  variants: Array<{
    __typename?: "ProductVariant";
    id: number;
    color: string | null;
  }>;
};

export type OrderViewAcceptShipment__ProductVariantFragment = {
  __typename?: "ProductVariant";
  id: number;
  color: string | null;
};

export type OrderViewAcceptShipment__ShipmentFragment = {
  __typename?: "Shipment";
  id: number;
  lineItems: Array<{
    __typename?: "ShipmentLineItem";
    id: string;
    shippedQuantity: number;
    lineItem: { __typename?: "LineItem"; id: number };
  }>;
  certificates: Array<{
    __typename?: "ShipmentCertificate";
    id: number;
    url: string;
  }>;
};

export type OrderViewAcceptShipment__ShipmentLineItemFragment = {
  __typename?: "ShipmentLineItem";
  id: string;
  shippedQuantity: number;
  lineItem: { __typename?: "LineItem"; id: number };
};

export type OrderViewAcceptShipment__ShipmentLineItemLineItemFragment = {
  __typename?: "LineItem";
  id: number;
};

export type OrderViewAcceptShipment__ShipmentCertificateFragment = {
  __typename?: "ShipmentCertificate";
  id: number;
  url: string;
};

export type OrderViewControls__OrderFragment = {
  __typename?: "Order";
  status: OrderStatus;
  metadata: {
    __typename?: "OrderMetadata";
    id: number;
    canDelete: boolean;
    fields: {
      __typename?: "OrderMetadataFields";
      items: {
        __typename?: "GraphQLMetadataField";
        canEdit: boolean;
        reason: string;
      };
      status: {
        __typename?: "OrderMetadataStatusField";
        canEdit: boolean;
        reason: string;
        possibleValues: Array<OrderStatus>;
      };
      shipments: { __typename?: "GraphQLMetadataField"; canEdit: boolean };
    };
  };
};

export type OrderViewControls__OrderMetadataFragment = {
  __typename?: "OrderMetadata";
  id: number;
  canDelete: boolean;
  fields: {
    __typename?: "OrderMetadataFields";
    items: {
      __typename?: "GraphQLMetadataField";
      canEdit: boolean;
      reason: string;
    };
    status: {
      __typename?: "OrderMetadataStatusField";
      canEdit: boolean;
      reason: string;
      possibleValues: Array<OrderStatus>;
    };
    shipments: { __typename?: "GraphQLMetadataField"; canEdit: boolean };
  };
};

export type OrderViewHeader__OrderFragment = {
  __typename?: "Order";
  id: number;
  status: OrderStatus;
  project: { __typename?: "Project"; name: string; geoLocation: string };
};

export type OrderViewInfo__OrderFragment = {
  __typename?: "Order";
  id: number;
  status: OrderStatus;
  deliveryDate: string;
  supplierId: number | null;
  subCompanyId: number | null;
  note: string | null;
  createdDate: string;
  project: {
    __typename?: "Project";
    companyId: number;
    name: string;
    company: { __typename?: "Company"; id: number; name: string };
  };
  lineItems: Array<{
    __typename?: "LineItem";
    productId: number | null;
    variantId: number | null;
  }>;
  supplier: { __typename?: "Supplier"; id: number; name: string } | null;
  metadata: {
    __typename?: "OrderMetadata";
    id: number;
    canDelete: boolean;
    canAttachFiles: boolean;
    fields: {
      __typename?: "OrderMetadataFields";
      note: {
        __typename?: "GraphQLMetadataField";
        canEdit: boolean;
        reason: string;
      };
      supplierId: {
        __typename?: "GraphQLMetadataField";
        canEdit: boolean;
        reason: string;
      };
      deliveryDate: {
        __typename?: "GraphQLMetadataField";
        canEdit: boolean;
        reason: string;
      };
      subCompanyId: {
        __typename?: "GraphQLMetadataField";
        canEdit: boolean;
        reason: string;
      };
    };
  };
  attachments: Array<{
    __typename?: "Attachment";
    id: number;
    url: string;
    name: string;
    mimeType: string;
  }>;
  creator: {
    __typename?: "Person";
    name: string | null;
    phoneNumber: string | null;
  };
  dropOffContact: {
    __typename?: "Person";
    id: number;
    email: string;
    name: string | null;
    phoneNumber: string | null;
  };
};

export type OrderViewInfo__LineItemFragment = {
  __typename?: "LineItem";
  productId: number | null;
  variantId: number | null;
};

export type OrderViewInfo__SupplierFragment = {
  __typename?: "Supplier";
  id: number;
  name: string;
};

export type OrderViewInfo__OrderMetadataFragment = {
  __typename?: "OrderMetadata";
  id: number;
  canDelete: boolean;
  canAttachFiles: boolean;
  fields: {
    __typename?: "OrderMetadataFields";
    note: {
      __typename?: "GraphQLMetadataField";
      canEdit: boolean;
      reason: string;
    };
    supplierId: {
      __typename?: "GraphQLMetadataField";
      canEdit: boolean;
      reason: string;
    };
    deliveryDate: {
      __typename?: "GraphQLMetadataField";
      canEdit: boolean;
      reason: string;
    };
    subCompanyId: {
      __typename?: "GraphQLMetadataField";
      canEdit: boolean;
      reason: string;
    };
  };
};

export type OrderViewInfo__MetadataFieldFragment = {
  __typename?: "GraphQLMetadataField";
  canEdit: boolean;
  reason: string;
};

export type OrderViewInfo__AttachmentFragment = {
  __typename?: "Attachment";
  id: number;
  url: string;
  name: string;
  mimeType: string;
};

export type OrderViewInfo__ProjectFragment = {
  __typename?: "Project";
  companyId: number;
  name: string;
  company: { __typename?: "Company"; id: number; name: string };
};

export type OrderViewInfo__CompanyFragment = {
  __typename?: "Company";
  id: number;
  name: string;
};

export type OrderViewSupplierInputSupplierFragment = {
  __typename?: "Supplier";
  id: number;
  name: string;
};

export type OrderViewSummary__OrderFragment = {
  __typename?: "Order";
  createdDate: string;
  creator: {
    __typename?: "Person";
    name: string | null;
    phoneNumber: string | null;
  };
  dropOffContact: {
    __typename?: "Person";
    id: number;
    email: string;
    name: string | null;
    phoneNumber: string | null;
  };
};

export type OrderViewLineItemsLineItemFragment = {
  __typename?: "LineItem";
  id: number;
  displayImage: string | null;
  displayName: string;
  displayVariantName: string | null;
  quantity: number;
  displaySize: string;
  price: number | null;
  discount: number;
  priceFormatted: string | null;
  priceTotal: number | null;
  priceTotalFormatted: string | null;
  priceTotalWithDiscountFormatted: string | null;
  discountTotalAmountFormatted: string | null;
  note: string | null;
  variantId: number | null;
  priceTotalWithDiscount: number | null;
  vat: number;
  product: {
    __typename?: "Product";
    id: number;
    variants: Array<{ __typename?: "ProductVariant"; id: number }>;
    companyCatalogProducts: Array<{
      __typename?: "CatalogProduct";
      id: number;
      variantIds: Array<number>;
      companyId: number;
    }>;
  } | null;
  requisitionLineItem: {
    __typename?: "RequisitionLineItem";
    requisitionId: number;
  } | null;
};

export type OrderViewLineItems__ShipmentLineItemFragment = {
  __typename?: "ShipmentLineItem";
  id: string;
  shippedQuantity: number;
  lineItem: { __typename?: "LineItem"; id: number };
};

export type OrderViewLineItems__OrderFragment = {
  __typename?: "Order";
  id: number;
  supplierId: number | null;
  discount: number;
  metadata: {
    __typename?: "OrderMetadata";
    id: number;
    fields: {
      __typename?: "OrderMetadataFields";
      items: { __typename?: "GraphQLMetadataField"; canEdit: boolean };
      discount: { __typename?: "GraphQLMetadataField"; canEdit: boolean };
    };
  };
};

export type OrderViewLineItems__OrderMetadataFragment = {
  __typename?: "OrderMetadata";
  id: number;
  fields: {
    __typename?: "OrderMetadataFields";
    items: { __typename?: "GraphQLMetadataField"; canEdit: boolean };
    discount: { __typename?: "GraphQLMetadataField"; canEdit: boolean };
  };
};

export type OrderViewLineItems__ProductFragment = {
  __typename?: "Product";
  id: number;
  variants: Array<{ __typename?: "ProductVariant"; id: number }>;
  companyCatalogProducts: Array<{
    __typename?: "CatalogProduct";
    id: number;
    variantIds: Array<number>;
    companyId: number;
  }>;
};

export type OrderViewLineItems__ProductVariantFragment = {
  __typename?: "ProductVariant";
  id: number;
};

export type OrderViewLineItems__CatalogProductFragment = {
  __typename?: "CatalogProduct";
  id: number;
  variantIds: Array<number>;
  companyId: number;
};

export type OrderViewLineItemTableRowLineItemFragment = {
  __typename?: "LineItem";
  id: number;
  displayImage: string | null;
  displayName: string;
  displayVariantName: string | null;
  quantity: number;
  displaySize: string;
  price: number | null;
  discount: number;
  priceFormatted: string | null;
  priceTotal: number | null;
  priceTotalFormatted: string | null;
  priceTotalWithDiscountFormatted: string | null;
  discountTotalAmountFormatted: string | null;
  note: string | null;
  variantId: number | null;
  requisitionLineItem: {
    __typename?: "RequisitionLineItem";
    requisitionId: number;
  } | null;
  product: {
    __typename?: "Product";
    id: number;
    variants: Array<{ __typename?: "ProductVariant"; id: number }>;
    companyCatalogProducts: Array<{
      __typename?: "CatalogProduct";
      id: number;
      variantIds: Array<number>;
      companyId: number;
    }>;
  } | null;
};

export type OrderViewLineItemTableRow__ProductFragment = {
  __typename?: "Product";
  id: number;
  variants: Array<{ __typename?: "ProductVariant"; id: number }>;
  companyCatalogProducts: Array<{
    __typename?: "CatalogProduct";
    id: number;
    variantIds: Array<number>;
    companyId: number;
  }>;
};

export type OrderViewLineItemTableRow__ProductVariantFragment = {
  __typename?: "ProductVariant";
  id: number;
};

export type OrderViewLineItemTableRow__CatalogProductFragment = {
  __typename?: "CatalogProduct";
  id: number;
  variantIds: Array<number>;
  companyId: number;
};

export type OrderViewLineItemsTableLineItemFragment = {
  __typename?: "LineItem";
  id: number;
  displayImage: string | null;
  displayName: string;
  displayVariantName: string | null;
  quantity: number;
  displaySize: string;
  price: number | null;
  discount: number;
  priceFormatted: string | null;
  priceTotal: number | null;
  priceTotalFormatted: string | null;
  priceTotalWithDiscountFormatted: string | null;
  discountTotalAmountFormatted: string | null;
  note: string | null;
  variantId: number | null;
  priceTotalWithDiscount: number | null;
  vat: number;
  product: {
    __typename?: "Product";
    id: number;
    variants: Array<{ __typename?: "ProductVariant"; id: number }>;
    companyCatalogProducts: Array<{
      __typename?: "CatalogProduct";
      id: number;
      variantIds: Array<number>;
      companyId: number;
    }>;
  } | null;
  requisitionLineItem: {
    __typename?: "RequisitionLineItem";
    requisitionId: number;
  } | null;
};

export type OrderViewLineItemsTable__ShipmentLineItemFragment = {
  __typename?: "ShipmentLineItem";
  id: string;
  shippedQuantity: number;
  lineItem: { __typename?: "LineItem"; id: number };
};

export type OrderViewLineItemTable__ProductFragment = {
  __typename?: "Product";
  id: number;
  variants: Array<{ __typename?: "ProductVariant"; id: number }>;
  companyCatalogProducts: Array<{
    __typename?: "CatalogProduct";
    id: number;
    variantIds: Array<number>;
    companyId: number;
  }>;
};

export type OrderViewLineItemTable__ProductVariantFragment = {
  __typename?: "ProductVariant";
  id: number;
};

export type OrderViewLineItemTable__CatalogProductFragment = {
  __typename?: "CatalogProduct";
  id: number;
  variantIds: Array<number>;
  companyId: number;
};

export type OrderViewTabsShipmentFragment = {
  __typename?: "Shipment";
  id: number;
};

export type OrderViewTotalPrice__LineItemFragment = {
  __typename?: "LineItem";
  quantity: number;
  priceTotalWithDiscount: number | null;
  vat: number;
};

export type OrderViewTotalPrice__OrderFragment = {
  __typename?: "Order";
  discount: number;
};

export type UseLineItemManagementMenuLineItemFragment = {
  __typename?: "LineItem";
  id: number;
};

export type OrderAddProductsMutationVariables = Exact<{
  orderId: Scalars["Int"];
  companyId: Scalars["Int"];
  supplierId: Scalars["Int"];
  lineItemProducts:
    | Array<OrderLineItemProductCreateInput>
    | OrderLineItemProductCreateInput;
}>;

export type OrderAddProductsMutation = {
  __typename?: "Mutation";
  lineItems: {
    __typename?: "LineItemsMutations";
    addProductsToOrder: {
      __typename?: "LineItemAddProductsOutput";
      status: MutationStatus;
      lineItems: Array<{
        __typename: "LineItem";
        id: number;
        note: string | null;
        quantity: number;
        vat: number;
        variantId: number | null;
        displayVariantName: string | null;
        productId: number | null;
        price: number | null;
        discount: number;
        orderId: number;
        displayName: string;
        displaySize: string;
        displayImage: string | null;
        description: string | null;
        priceTotalWithVatFormatted: string | null;
        priceFormatted: string | null;
        priceTotal: number | null;
        priceTotalFormatted: string | null;
        priceTotalWithDiscountFormatted: string | null;
        discountTotalAmountFormatted: string | null;
        priceTotalWithDiscount: number | null;
        product: {
          __typename?: "Product";
          id: number;
          categoryIds: Array<number>;
          primaryImage: string | null;
          name: string;
          variants: Array<{
            __typename?: "ProductVariant";
            id: number;
            name: string;
            color: string | null;
          }>;
          companyCatalogProducts: Array<{
            __typename?: "CatalogProduct";
            id: number;
            companyId: number;
            variantIds: Array<number>;
          }>;
          attributes: Array<{
            __typename?: "ProductAttribute";
            attribute: string;
            description: string;
          }>;
        } | null;
        requisitionLineItem: {
          __typename?: "RequisitionLineItem";
          requisitionId: number;
        } | null;
      }>;
    };
  };
};

export type Order__AttachFilesMutationVariables = Exact<{
  data: Array<AttachmentCreateInput> | AttachmentCreateInput;
}>;

export type Order__AttachFilesMutation = {
  __typename?: "Mutation";
  attachments: {
    __typename?: "AttachmentsMutations";
    createBulk: {
      __typename?: "AttachmentsCreateOutput";
      attachments: Array<{
        __typename?: "Attachment";
        id: number;
        url: string;
        name: string;
        mimeType: string;
      }>;
    };
  };
};

export type OrderDeleteAttachmentMutationVariables = Exact<{
  id: Scalars["Int"];
}>;

export type OrderDeleteAttachmentMutation = {
  __typename?: "Mutation";
  attachments: {
    __typename?: "AttachmentsMutations";
    delete: { __typename?: "StatusOutput"; status: MutationStatus };
  };
};

export type UseOrderLineItemEditLineItemFragment = {
  __typename?: "LineItem";
  id: number;
  productId: number | null;
  variantId: number | null;
  quantity: number;
  note: string | null;
  price: number | null;
  displayName: string;
  description: string | null;
  displaySize: string;
  discount: number;
  requisitionLineItem: {
    __typename?: "RequisitionLineItem";
    requisitionId: number;
  } | null;
};

export type OrderLineItemProductUpdateMutationVariables = Exact<{
  companyId: Scalars["Int"];
  supplierId: Scalars["Int"];
  lineItemId: Scalars["Int"];
  lineItemProduct: OrderLineItemProductUpdateInput;
}>;

export type OrderLineItemProductUpdateMutation = {
  __typename?: "Mutation";
  lineItems: {
    __typename?: "LineItemsMutations";
    updateOrderLineItemWithProduct: {
      __typename?: "LineItemUpdateOutput";
      lineItem: {
        __typename?: "LineItem";
        id: number;
        note: string | null;
        quantity: number;
        variantId: number | null;
        displayVariantName: string | null;
        orderId: number;
        price: number | null;
        priceFormatted: string | null;
        priceTotal: number | null;
        priceTotalFormatted: string | null;
        priceWithDiscount: number | null;
        priceWithDiscountFormatted: string | null;
        priceTotalWithDiscount: number | null;
        priceTotalWithDiscountFormatted: string | null;
        priceWithVat: number | null;
        priceWithVatFormatted: string | null;
        priceTotalWithVat: number | null;
        priceTotalWithVatFormatted: string | null;
        vatAmount: number | null;
        vatAmountFormatted: string | null;
        discountAmount: number | null;
        discountAmountFormatted: string | null;
        discountTotalAmount: number | null;
        discountTotalAmountFormatted: string | null;
      };
    };
  };
};

export type OrderLineItemProductUpdate__OrderLineItemFragment = {
  __typename?: "LineItem";
  id: number;
  note: string | null;
  quantity: number;
  variantId: number | null;
  displayVariantName: string | null;
  orderId: number;
  price: number | null;
  priceFormatted: string | null;
  priceTotal: number | null;
  priceTotalFormatted: string | null;
  priceWithDiscount: number | null;
  priceWithDiscountFormatted: string | null;
  priceTotalWithDiscount: number | null;
  priceTotalWithDiscountFormatted: string | null;
  priceWithVat: number | null;
  priceWithVatFormatted: string | null;
  priceTotalWithVat: number | null;
  priceTotalWithVatFormatted: string | null;
  vatAmount: number | null;
  vatAmountFormatted: string | null;
  discountAmount: number | null;
  discountAmountFormatted: string | null;
  discountTotalAmount: number | null;
  discountTotalAmountFormatted: string | null;
};

export type OrderLineItemQuantityUpdateMutationVariables = Exact<{
  companyId: Scalars["Int"];
  supplierId: Scalars["Int"];
  lineItemId: Scalars["Int"];
  quantity: Scalars["Float"];
}>;

export type OrderLineItemQuantityUpdateMutation = {
  __typename?: "Mutation";
  lineItems: {
    __typename?: "LineItemsMutations";
    updateOrderLineItemWithProduct: {
      __typename?: "LineItemUpdateOutput";
      lineItem: {
        __typename?: "LineItem";
        id: number;
        orderId: number;
        quantity: number;
        price: number | null;
        priceFormatted: string | null;
        priceTotal: number | null;
        priceTotalFormatted: string | null;
        priceWithDiscount: number | null;
        priceWithDiscountFormatted: string | null;
        priceTotalWithDiscount: number | null;
        priceTotalWithDiscountFormatted: string | null;
        priceWithVat: number | null;
        priceWithVatFormatted: string | null;
        priceTotalWithVat: number | null;
        priceTotalWithVatFormatted: string | null;
        vatAmount: number | null;
        vatAmountFormatted: string | null;
        discountAmount: number | null;
        discountAmountFormatted: string | null;
        discountTotalAmount: number | null;
        discountTotalAmountFormatted: string | null;
      };
    };
  };
};

export type OrderLineItemQuantityUpdateOptimisticResponseRead__LineItemFragment =
  {
    __typename: "LineItem";
    id: number;
    orderId: number;
    vat: number;
    price: number | null;
    discount: number;
  };

export type OrderLineItemUpdateDiscountMutationVariables = Exact<{
  companyId: Scalars["Int"];
  supplierId: Scalars["Int"];
  lineItemId: Scalars["Int"];
  discount: Scalars["Int"];
}>;

export type OrderLineItemUpdateDiscountMutation = {
  __typename?: "Mutation";
  lineItems: {
    __typename?: "LineItemsMutations";
    updateOrderLineItemWithProduct: {
      __typename?: "LineItemUpdateOutput";
      status: MutationStatus;
      lineItem: {
        __typename?: "LineItem";
        id: number;
        orderId: number;
        discount: number;
        price: number | null;
        priceFormatted: string | null;
        priceTotal: number | null;
        priceTotalFormatted: string | null;
        priceWithDiscount: number | null;
        priceWithDiscountFormatted: string | null;
        priceTotalWithDiscount: number | null;
        priceTotalWithDiscountFormatted: string | null;
        priceWithVat: number | null;
        priceWithVatFormatted: string | null;
        priceTotalWithVat: number | null;
        priceTotalWithVatFormatted: string | null;
        vatAmount: number | null;
        vatAmountFormatted: string | null;
        discountAmount: number | null;
        discountAmountFormatted: string | null;
        discountTotalAmount: number | null;
        discountTotalAmountFormatted: string | null;
      };
    };
  };
};

export type OrderLineItemUpdateDiscountOptimisticResponseRead__LineItemFragment =
  {
    __typename: "LineItem";
    id: number;
    vat: number;
    quantity: number;
    price: number | null;
  };

export type OrderLineItemUpdatePriceMutationVariables = Exact<{
  companyId: Scalars["Int"];
  supplierId: Scalars["Int"];
  lineItemId: Scalars["Int"];
  price: InputMaybe<Scalars["Float"]>;
}>;

export type OrderLineItemUpdatePriceMutation = {
  __typename?: "Mutation";
  lineItems: {
    __typename?: "LineItemsMutations";
    updateOrderLineItemWithProduct: {
      __typename?: "LineItemUpdateOutput";
      status: MutationStatus;
      lineItem: {
        __typename?: "LineItem";
        id: number;
        price: number | null;
        priceFormatted: string | null;
        priceTotal: number | null;
        priceTotalFormatted: string | null;
        priceWithDiscount: number | null;
        priceWithDiscountFormatted: string | null;
        priceTotalWithDiscount: number | null;
        priceTotalWithDiscountFormatted: string | null;
        priceWithVat: number | null;
        priceWithVatFormatted: string | null;
        priceTotalWithVat: number | null;
        priceTotalWithVatFormatted: string | null;
        vatAmount: number | null;
        vatAmountFormatted: string | null;
        discountAmount: number | null;
        discountAmountFormatted: string | null;
        discountTotalAmount: number | null;
        discountTotalAmountFormatted: string | null;
      };
    };
  };
};

export type OrderLineItemUpdatePriceOptimisticResponseRead__LineItemFragment = {
  __typename: "LineItem";
  id: number;
  vat: number;
  quantity: number;
  discount: number;
};

export type UseOrderLineItemShowDeliveryStatusFragment = {
  __typename?: "Order";
  status: OrderStatus;
};

export type OrderRemoveMutationVariables = Exact<{
  orderId: Scalars["Int"];
}>;

export type OrderRemoveMutation = {
  __typename?: "Mutation";
  orders: {
    __typename?: "OrderMutations";
    remove: { __typename?: "StatusOutput"; status: MutationStatus };
  };
};

export type OrderLineItemsRemoveMutationVariables = Exact<{
  lineItemIds: Array<Scalars["Int"]> | Scalars["Int"];
}>;

export type OrderLineItemsRemoveMutation = {
  __typename?: "Mutation";
  lineItems: {
    __typename?: "LineItemsMutations";
    removeOrderLineItem: {
      __typename?: "LineItemRemoveOutput";
      status: MutationStatus;
    };
  };
};

export type CreateShipmentMutationVariables = Exact<{
  orderId: Scalars["Int"];
  data: ShipmentCreateInput;
}>;

export type CreateShipmentMutation = {
  __typename?: "Mutation";
  shipments: {
    __typename?: "ShipmentsMutations";
    create: {
      __typename?: "Shipment";
      id: number;
      orderId: number;
      deliveryDate: string;
      lineItems: Array<{
        __typename?: "ShipmentLineItem";
        id: string;
        shippedQuantity: number;
        lineItem: {
          __typename?: "LineItem";
          id: number;
          vat: number;
          priceWithDiscount: number | null;
          displayImage: string | null;
          displayName: string;
          displayVariantName: string | null;
          displaySize: string;
          price: number | null;
          discount: number;
        };
      }>;
      certificates: Array<{
        __typename?: "ShipmentCertificate";
        id: number;
        url: string;
      }>;
      approvedBy: { __typename?: "Person"; name: string | null };
    };
  };
};

export type OrderView__DeleteShipmentCertificateMutationVariables = Exact<{
  orderId: Scalars["Int"];
  shipmentId: Scalars["Int"];
  certificateId: Scalars["Int"];
}>;

export type OrderView__DeleteShipmentCertificateMutation = {
  __typename?: "Mutation";
  shipments: {
    __typename?: "ShipmentsMutations";
    deleteCertificate: { __typename?: "StatusOutput"; status: MutationStatus };
  };
};

export type OrderView__UpdateShipmentMutationVariables = Exact<{
  orderId: Scalars["Int"];
  shipmentId: Scalars["Int"];
  data: ShipmentCreateInput;
}>;

export type OrderView__UpdateShipmentMutation = {
  __typename?: "Mutation";
  shipments: {
    __typename?: "ShipmentsMutations";
    update: {
      __typename?: "Shipment";
      id: number;
      lineItems: Array<{
        __typename?: "ShipmentLineItem";
        id: string;
        shippedQuantity: number;
      }>;
    };
  };
};

export type OrderView__UpdateShipmentReadFragment = {
  __typename?: "Shipment";
  id: number;
  lineItems: Array<{ __typename?: "ShipmentLineItem"; id: string }>;
};

export type OrderView__UpdateOrderMutationVariables = Exact<{
  orderId: Scalars["Int"];
  order: OrderUpdateInput;
}>;

export type OrderView__UpdateOrderMutation = {
  __typename?: "Mutation";
  orders: {
    __typename?: "OrderMutations";
    update: {
      __typename?: "OrderUpdateOutput";
      order: {
        __typename?: "Order";
        id: number;
        supplierId: number | null;
        subCompanyId: number | null;
        note: string | null;
        status: OrderStatus;
        deliveryDate: string;
        discount: number;
        supplier: { __typename?: "Supplier"; id: number; name: string } | null;
        metadata: {
          __typename?: "OrderMetadata";
          id: number;
          canDelete: boolean;
          canAttachFiles: boolean;
          fields: {
            __typename?: "OrderMetadataFields";
            items: {
              __typename?: "GraphQLMetadataField";
              canEdit: boolean;
              reason: string;
            };
            status: {
              __typename?: "OrderMetadataStatusField";
              canEdit: boolean;
              reason: string;
              possibleValues: Array<OrderStatus>;
            };
            shipments: {
              __typename?: "GraphQLMetadataField";
              canEdit: boolean;
            };
            note: {
              __typename?: "GraphQLMetadataField";
              canEdit: boolean;
              reason: string;
            };
            supplierId: {
              __typename?: "GraphQLMetadataField";
              canEdit: boolean;
              reason: string;
            };
            deliveryDate: {
              __typename?: "GraphQLMetadataField";
              canEdit: boolean;
              reason: string;
            };
            subCompanyId: {
              __typename?: "GraphQLMetadataField";
              canEdit: boolean;
              reason: string;
            };
            discount: { __typename?: "GraphQLMetadataField"; canEdit: boolean };
          };
        };
      };
    };
  };
};

export type OrderUpdate__OrderFragment = {
  __typename?: "Order";
  id: number;
  supplierId: number | null;
  subCompanyId: number | null;
  note: string | null;
  status: OrderStatus;
  deliveryDate: string;
  discount: number;
  supplier: { __typename?: "Supplier"; id: number; name: string } | null;
  metadata: {
    __typename?: "OrderMetadata";
    id: number;
    canDelete: boolean;
    canAttachFiles: boolean;
    fields: {
      __typename?: "OrderMetadataFields";
      items: {
        __typename?: "GraphQLMetadataField";
        canEdit: boolean;
        reason: string;
      };
      status: {
        __typename?: "OrderMetadataStatusField";
        canEdit: boolean;
        reason: string;
        possibleValues: Array<OrderStatus>;
      };
      shipments: { __typename?: "GraphQLMetadataField"; canEdit: boolean };
      note: {
        __typename?: "GraphQLMetadataField";
        canEdit: boolean;
        reason: string;
      };
      supplierId: {
        __typename?: "GraphQLMetadataField";
        canEdit: boolean;
        reason: string;
      };
      deliveryDate: {
        __typename?: "GraphQLMetadataField";
        canEdit: boolean;
        reason: string;
      };
      subCompanyId: {
        __typename?: "GraphQLMetadataField";
        canEdit: boolean;
        reason: string;
      };
      discount: { __typename?: "GraphQLMetadataField"; canEdit: boolean };
    };
  };
};

export type OrderView__UpdateCustomLineItemMutationVariables = Exact<{
  lineItemId: Scalars["Int"];
  lineItemCustom: OrderLineItemCustomUpdateInput;
}>;

export type OrderView__UpdateCustomLineItemMutation = {
  __typename?: "Mutation";
  lineItems: {
    __typename?: "LineItemsMutations";
    updateCustomOrderLineItem: {
      __typename?: "LineItemUpdateOutput";
      lineItem: {
        __typename: "LineItem";
        id: number;
        note: string | null;
        quantity: number;
        vat: number;
        variantId: number | null;
        displayVariantName: string | null;
        productId: number | null;
        price: number | null;
        discount: number;
        orderId: number;
        displayName: string;
        displaySize: string;
        priceFormatted: string | null;
        priceTotal: number | null;
        priceTotalFormatted: string | null;
        priceWithDiscount: number | null;
        priceWithDiscountFormatted: string | null;
        priceTotalWithDiscount: number | null;
        priceTotalWithDiscountFormatted: string | null;
        priceWithVat: number | null;
        priceWithVatFormatted: string | null;
        priceTotalWithVat: number | null;
        priceTotalWithVatFormatted: string | null;
        vatAmount: number | null;
        vatAmountFormatted: string | null;
        discountAmount: number | null;
        discountAmountFormatted: string | null;
        discountTotalAmount: number | null;
        discountTotalAmountFormatted: string | null;
      };
    };
  };
};

export type OrderView__CreateCustomProductMutationVariables = Exact<{
  orderId: Scalars["Int"];
  lineItemCustom: LineItemCustomCreateInput;
}>;

export type OrderView__CreateCustomProductMutation = {
  __typename?: "Mutation";
  lineItems: {
    __typename?: "LineItemsMutations";
    addCustomLineItemToOrder: {
      __typename?: "LineItemAddCustomOutput";
      lineItem: {
        __typename: "LineItem";
        id: number;
        note: string | null;
        quantity: number;
        vat: number;
        variantId: number | null;
        displayVariantName: string | null;
        productId: number | null;
        price: number | null;
        discount: number;
        orderId: number;
        displayName: string;
        displaySize: string;
        displayImage: string | null;
        description: string | null;
        priceTotalWithVatFormatted: string | null;
        priceFormatted: string | null;
        priceTotal: number | null;
        priceTotalFormatted: string | null;
        priceTotalWithDiscountFormatted: string | null;
        discountTotalAmountFormatted: string | null;
        priceTotalWithDiscount: number | null;
        product: {
          __typename?: "Product";
          id: number;
          categoryIds: Array<number>;
          primaryImage: string | null;
          name: string;
          variants: Array<{
            __typename?: "ProductVariant";
            id: number;
            name: string;
            color: string | null;
          }>;
          companyCatalogProducts: Array<{
            __typename?: "CatalogProduct";
            id: number;
            companyId: number;
            variantIds: Array<number>;
          }>;
          attributes: Array<{
            __typename?: "ProductAttribute";
            attribute: string;
            description: string;
          }>;
        } | null;
        requisitionLineItem: {
          __typename?: "RequisitionLineItem";
          requisitionId: number;
        } | null;
      };
    };
  };
};

export type OrderView__GetOrderQueryVariables = Exact<{
  id: Scalars["Int"];
}>;

export type OrderView__GetOrderQuery = {
  __typename?: "Query";
  orderById: {
    __typename?: "Order";
    id: number;
    status: OrderStatus;
    deliveryDate: string;
    supplierId: number | null;
    subCompanyId: number | null;
    note: string | null;
    discount: number;
    createdDate: string;
    project: {
      __typename?: "Project";
      name: string;
      geoLocation: string;
      companyId: number;
      company: { __typename?: "Company"; id: number; name: string };
    };
    lineItems: Array<{
      __typename: "LineItem";
      productId: number | null;
      variantId: number | null;
      id: number;
      note: string | null;
      quantity: number;
      vat: number;
      displayVariantName: string | null;
      price: number | null;
      discount: number;
      orderId: number;
      displayName: string;
      displaySize: string;
      displayImage: string | null;
      description: string | null;
      priceTotalWithVatFormatted: string | null;
      priceFormatted: string | null;
      priceTotal: number | null;
      priceTotalFormatted: string | null;
      priceTotalWithDiscountFormatted: string | null;
      discountTotalAmountFormatted: string | null;
      priceTotalWithDiscount: number | null;
      product: {
        __typename?: "Product";
        id: number;
        categoryIds: Array<number>;
        primaryImage: string | null;
        name: string;
        variants: Array<{
          __typename?: "ProductVariant";
          id: number;
          name: string;
          color: string | null;
        }>;
        companyCatalogProducts: Array<{
          __typename?: "CatalogProduct";
          id: number;
          companyId: number;
          variantIds: Array<number>;
        }>;
        attributes: Array<{
          __typename?: "ProductAttribute";
          attribute: string;
          description: string;
        }>;
      } | null;
      requisitionLineItem: {
        __typename?: "RequisitionLineItem";
        requisitionId: number;
      } | null;
    }>;
    supplier: { __typename?: "Supplier"; id: number; name: string } | null;
    metadata: {
      __typename?: "OrderMetadata";
      id: number;
      canDelete: boolean;
      canAttachFiles: boolean;
      fields: {
        __typename?: "OrderMetadataFields";
        note: {
          __typename?: "GraphQLMetadataField";
          canEdit: boolean;
          reason: string;
        };
        supplierId: {
          __typename?: "GraphQLMetadataField";
          canEdit: boolean;
          reason: string;
        };
        deliveryDate: {
          __typename?: "GraphQLMetadataField";
          canEdit: boolean;
          reason: string;
        };
        subCompanyId: {
          __typename?: "GraphQLMetadataField";
          canEdit: boolean;
          reason: string;
        };
        items: {
          __typename?: "GraphQLMetadataField";
          canEdit: boolean;
          reason: string;
        };
        status: {
          __typename?: "OrderMetadataStatusField";
          canEdit: boolean;
          reason: string;
          possibleValues: Array<OrderStatus>;
        };
        shipments: { __typename?: "GraphQLMetadataField"; canEdit: boolean };
        discount: { __typename?: "GraphQLMetadataField"; canEdit: boolean };
      };
    };
    attachments: Array<{
      __typename?: "Attachment";
      id: number;
      url: string;
      name: string;
      mimeType: string;
    }>;
    shipments: Array<{
      __typename?: "Shipment";
      id: number;
      orderId: number;
      deliveryDate: string;
      lineItems: Array<{
        __typename?: "ShipmentLineItem";
        id: string;
        shippedQuantity: number;
        lineItem: {
          __typename?: "LineItem";
          id: number;
          vat: number;
          priceWithDiscount: number | null;
          displayImage: string | null;
          displayName: string;
          displayVariantName: string | null;
          displaySize: string;
          price: number | null;
          discount: number;
        };
      }>;
      certificates: Array<{
        __typename?: "ShipmentCertificate";
        id: number;
        url: string;
      }>;
      approvedBy: { __typename?: "Person"; name: string | null };
    }>;
    creator: {
      __typename?: "Person";
      name: string | null;
      phoneNumber: string | null;
    };
    dropOffContact: {
      __typename?: "Person";
      id: number;
      email: string;
      name: string | null;
      phoneNumber: string | null;
    };
  };
};

export type OrderViewOrdersFragment = {
  __typename?: "OrdersFindManyOutput";
  items: Array<{
    __typename?: "Order";
    id: number;
    createdDate: string;
    deliveryDate: string;
    status: OrderStatus;
    supplierId: number | null;
    discount: number;
    subCompany: { __typename?: "SubCompany"; id: number; name: string } | null;
    lineItems: Array<{
      __typename?: "LineItem";
      id: number;
      priceTotalWithDiscount: number | null;
    }>;
    project: {
      __typename?: "Project";
      name: string;
      company: { __typename?: "Company"; name: string };
    };
    supplier: { __typename?: "Supplier"; id: number; name: string } | null;
  }>;
  pageInfo: {
    __typename?: "PaginationInfo";
    totalItems: number;
    perPage: number;
  };
};

export type OrdersViewProjectFragment = {
  __typename?: "Project";
  id: number;
  name: string;
  active: boolean;
  companyId: number;
  subCompanyId: number | null;
  persons: Array<{
    __typename?: "Person";
    id: number;
    name: string | null;
    email: string;
    phoneNumber: string | null;
    companyId: number;
  }>;
};

export type OrdersView__LineItemFragment = {
  __typename?: "LineItem";
  id: number;
  priceTotalWithDiscount: number | null;
};

export type OrdersCreatePopupProjectFragment = {
  __typename?: "Project";
  id: number;
  name: string;
  active: boolean;
  companyId: number;
  subCompanyId: number | null;
  persons: Array<{
    __typename?: "Person";
    id: number;
    name: string | null;
    email: string;
    phoneNumber: string | null;
    companyId: number;
  }>;
};

export type OrdersCreateFormProjectFragment = {
  __typename?: "Project";
  id: number;
  name: string;
  active: boolean;
  companyId: number;
  subCompanyId: number | null;
  persons: Array<{
    __typename?: "Person";
    id: number;
    name: string | null;
    email: string;
    phoneNumber: string | null;
    companyId: number;
  }>;
};

export type OrdersViewOrdersOrderFragment = {
  __typename?: "Order";
  id: number;
  createdDate: string;
  deliveryDate: string;
  status: OrderStatus;
  supplierId: number | null;
  discount: number;
  subCompany: { __typename?: "SubCompany"; id: number; name: string } | null;
  lineItems: Array<{
    __typename?: "LineItem";
    id: number;
    priceTotalWithDiscount: number | null;
  }>;
  project: {
    __typename?: "Project";
    name: string;
    company: { __typename?: "Company"; name: string };
  };
  supplier: { __typename?: "Supplier"; id: number; name: string } | null;
};

export type OrdersViewOrdersPageInfoFragment = {
  __typename?: "PaginationInfo";
  perPage: number;
  totalItems: number;
};

export type OrdersViewOrders__LineItemFragment = {
  __typename?: "LineItem";
  id: number;
  priceTotalWithDiscount: number | null;
};

export type OrdersViewOrdersTableOrderFragment = {
  __typename?: "Order";
  id: number;
  createdDate: string;
  deliveryDate: string;
  status: OrderStatus;
  supplierId: number | null;
  discount: number;
  subCompany: { __typename?: "SubCompany"; id: number; name: string } | null;
  lineItems: Array<{
    __typename?: "LineItem";
    id: number;
    priceTotalWithDiscount: number | null;
  }>;
  project: {
    __typename?: "Project";
    name: string;
    company: { __typename?: "Company"; name: string };
  };
  supplier: { __typename?: "Supplier"; id: number; name: string } | null;
};

export type OrdersViewOrdersTable__LineItemFragment = {
  __typename?: "LineItem";
  id: number;
  priceTotalWithDiscount: number | null;
};

export type OrdersFiltersProjectFragment = {
  __typename?: "Project";
  id: number;
  name: string;
};

export type OrdersViewFilters__GetSuppliersQueryVariables = Exact<{
  [key: string]: never;
}>;

export type OrdersViewFilters__GetSuppliersQuery = {
  __typename?: "Query";
  suppliersFindMany: {
    __typename?: "SuppliersFindManyOutput";
    items: Array<{ __typename?: "Supplier"; id: number; name: string }>;
  };
};

export type OrderCreateMutationVariables = Exact<{
  order: OrderCreateInput;
}>;

export type OrderCreateMutation = {
  __typename?: "Mutation";
  orders: {
    __typename?: "OrderMutations";
    create: { __typename?: "OrderCreateOutput"; orderId: number };
  };
};

export type OrdersView__GetOrdersQueryVariables = Exact<{
  page: Scalars["Int"];
  filters: OrdersFindManyFiltersInput;
  sortBy: OrdersSortInput;
}>;

export type OrdersView__GetOrdersQuery = {
  __typename?: "Query";
  ordersFindMany: {
    __typename?: "OrdersFindManyOutput";
    items: Array<{
      __typename?: "Order";
      id: number;
      createdDate: string;
      deliveryDate: string;
      status: OrderStatus;
      supplierId: number | null;
      discount: number;
      subCompany: {
        __typename?: "SubCompany";
        id: number;
        name: string;
      } | null;
      lineItems: Array<{
        __typename?: "LineItem";
        id: number;
        priceTotalWithDiscount: number | null;
      }>;
      project: {
        __typename?: "Project";
        name: string;
        company: { __typename?: "Company"; name: string };
      };
      supplier: { __typename?: "Supplier"; id: number; name: string } | null;
    }>;
    pageInfo: {
      __typename?: "PaginationInfo";
      totalItems: number;
      perPage: number;
    };
  };
};

export type OrdersView__GetProjectsQueryVariables = Exact<{
  [key: string]: never;
}>;

export type OrdersView__GetProjectsQuery = {
  __typename?: "Query";
  projectsFindMany: {
    __typename?: "ProjectsFindManyOutput";
    items: Array<{
      __typename?: "Project";
      id: number;
      name: string;
      active: boolean;
      companyId: number;
      subCompanyId: number | null;
      persons: Array<{
        __typename?: "Person";
        id: number;
        name: string | null;
        email: string;
        phoneNumber: string | null;
        companyId: number;
      }>;
    }>;
  };
};

export type GetOrderSupplierLabelOrderFragment = {
  __typename?: "Order";
  supplier: { __typename?: "Supplier"; id: number; name: string } | null;
};

export type ProductForm__ProductFragment = {
  __typename?: "Product";
  name: string;
  isActive: boolean;
  primaryImage: string | null;
  categoryIds: Array<number>;
  images: Array<string>;
  aka: Array<string>;
  attributes: Array<{
    __typename?: "ProductAttribute";
    attribute: string;
    description: string;
  }>;
  size: {
    __typename?: "ProductSize";
    type: ProductSizeType;
    unit: ProductSizeUnit;
    amount: number | null;
    width: number | null;
    length: number | null;
  };
  supplierItems: Array<{
    __typename?: "ProductSupplier";
    id: number;
    name: string | null;
    manufacturer: string | null;
    productId: string | null;
    price: number | null;
    status: ProductVariantStatus;
    supplier: number;
  }>;
  variants: Array<{
    __typename?: "ProductVariant";
    id: number;
    name: string;
    image: string | null;
    color: string | null;
    status: ProductVariantStatus;
    size: {
      __typename?: "ProductSize";
      type: ProductSizeType;
      unit: ProductSizeUnit;
      amount: number | null;
      width: number | null;
      length: number | null;
    };
    supplierItems: Array<{
      __typename?: "ProductSupplier";
      id: number;
      name: string | null;
      manufacturer: string | null;
      productId: string | null;
      price: number | null;
      status: ProductVariantStatus;
      supplier: number;
    }>;
  }>;
};

export type ProductForm__ProductVariantFragment = {
  __typename?: "ProductVariant";
  id: number;
  name: string;
  image: string | null;
  color: string | null;
  status: ProductVariantStatus;
  size: {
    __typename?: "ProductSize";
    type: ProductSizeType;
    unit: ProductSizeUnit;
    amount: number | null;
    width: number | null;
    length: number | null;
  };
  supplierItems: Array<{
    __typename?: "ProductSupplier";
    id: number;
    name: string | null;
    manufacturer: string | null;
    productId: string | null;
    price: number | null;
    status: ProductVariantStatus;
    supplier: number;
  }>;
};

export type ProductForm__ProductSizeFragment = {
  __typename?: "ProductSize";
  type: ProductSizeType;
  unit: ProductSizeUnit;
  amount: number | null;
  width: number | null;
  length: number | null;
};

export type ProductForm__ProductSupplierFragment = {
  __typename?: "ProductSupplier";
  id: number;
  name: string | null;
  manufacturer: string | null;
  productId: string | null;
  price: number | null;
  status: ProductVariantStatus;
  supplier: number;
};

export type ProductForm__GetSuppliersQueryVariables = Exact<{
  [key: string]: never;
}>;

export type ProductForm__GetSuppliersQuery = {
  __typename?: "Query";
  suppliersFindMany: {
    __typename?: "SuppliersFindManyOutput";
    items: Array<{
      __typename?: "Supplier";
      id: number;
      name: string;
      enable: boolean;
    }>;
  };
};

export type ProductView__ProductFragment = {
  __typename?: "Product";
  id: number;
  name: string;
  isActive: boolean;
  primaryImage: string | null;
  categoryIds: Array<number>;
  images: Array<string>;
  aka: Array<string>;
  size: {
    __typename?: "ProductSize";
    type: ProductSizeType;
    unit: ProductSizeUnit;
    amount: number | null;
    width: number | null;
    length: number | null;
  };
  supplierItems: Array<{
    __typename?: "ProductSupplier";
    id: number;
    name: string | null;
    manufacturer: string | null;
    productId: string | null;
    price: number | null;
    status: ProductVariantStatus;
    supplier: number;
  }>;
  variants: Array<{
    __typename?: "ProductVariant";
    id: number;
    name: string;
    image: string | null;
    color: string | null;
    status: ProductVariantStatus;
    size: {
      __typename?: "ProductSize";
      type: ProductSizeType;
      unit: ProductSizeUnit;
      amount: number | null;
      width: number | null;
      length: number | null;
    };
    supplierItems: Array<{
      __typename?: "ProductSupplier";
      id: number;
      name: string | null;
      manufacturer: string | null;
      productId: string | null;
      price: number | null;
      status: ProductVariantStatus;
      supplier: number;
    }>;
  }>;
  attributes: Array<{
    __typename?: "ProductAttribute";
    attribute: string;
    description: string;
  }>;
};

export type ProductView__ProductVariantFragment = {
  __typename?: "ProductVariant";
  id: number;
  name: string;
  image: string | null;
  color: string | null;
  status: ProductVariantStatus;
  size: {
    __typename?: "ProductSize";
    type: ProductSizeType;
    unit: ProductSizeUnit;
    amount: number | null;
    width: number | null;
    length: number | null;
  };
  supplierItems: Array<{
    __typename?: "ProductSupplier";
    id: number;
    name: string | null;
    manufacturer: string | null;
    productId: string | null;
    price: number | null;
    status: ProductVariantStatus;
    supplier: number;
  }>;
};

export type ProductView__ProductSizeFragment = {
  __typename?: "ProductSize";
  type: ProductSizeType;
  unit: ProductSizeUnit;
  amount: number | null;
  width: number | null;
  length: number | null;
};

export type ProductView__ProductSupplierFragment = {
  __typename?: "ProductSupplier";
  id: number;
  name: string | null;
  manufacturer: string | null;
  productId: string | null;
  price: number | null;
  status: ProductVariantStatus;
  supplier: number;
};

export type ProductView__GetProductQueryVariables = Exact<{
  productId: Scalars["Int"];
}>;

export type ProductView__GetProductQuery = {
  __typename?: "Query";
  productById: {
    __typename?: "Product";
    id: number;
    name: string;
    isActive: boolean;
    primaryImage: string | null;
    categoryIds: Array<number>;
    images: Array<string>;
    aka: Array<string>;
    size: {
      __typename?: "ProductSize";
      type: ProductSizeType;
      unit: ProductSizeUnit;
      amount: number | null;
      width: number | null;
      length: number | null;
    };
    supplierItems: Array<{
      __typename?: "ProductSupplier";
      id: number;
      name: string | null;
      manufacturer: string | null;
      productId: string | null;
      price: number | null;
      status: ProductVariantStatus;
      supplier: number;
    }>;
    variants: Array<{
      __typename?: "ProductVariant";
      id: number;
      name: string;
      image: string | null;
      color: string | null;
      status: ProductVariantStatus;
      size: {
        __typename?: "ProductSize";
        type: ProductSizeType;
        unit: ProductSizeUnit;
        amount: number | null;
        width: number | null;
        length: number | null;
      };
      supplierItems: Array<{
        __typename?: "ProductSupplier";
        id: number;
        name: string | null;
        manufacturer: string | null;
        productId: string | null;
        price: number | null;
        status: ProductVariantStatus;
        supplier: number;
      }>;
    }>;
    attributes: Array<{
      __typename?: "ProductAttribute";
      attribute: string;
      description: string;
    }>;
  };
};

export type ProductView__CreateProductMutationVariables = Exact<{
  product: ProductCreateInput;
}>;

export type ProductView__CreateProductMutation = {
  __typename?: "Mutation";
  products: {
    __typename?: "ProductsMutations";
    create: {
      __typename?: "ProductCreateOutput";
      product: {
        __typename?: "Product";
        id: number;
        name: string;
        isActive: boolean;
        primaryImage: string | null;
        categoryIds: Array<number>;
        images: Array<string>;
        aka: Array<string>;
        size: {
          __typename?: "ProductSize";
          type: ProductSizeType;
          unit: ProductSizeUnit;
          amount: number | null;
          width: number | null;
          length: number | null;
        };
        supplierItems: Array<{
          __typename?: "ProductSupplier";
          id: number;
          name: string | null;
          manufacturer: string | null;
          productId: string | null;
          price: number | null;
          status: ProductVariantStatus;
          supplier: number;
        }>;
        variants: Array<{
          __typename?: "ProductVariant";
          id: number;
          name: string;
          image: string | null;
          color: string | null;
          status: ProductVariantStatus;
          size: {
            __typename?: "ProductSize";
            type: ProductSizeType;
            unit: ProductSizeUnit;
            amount: number | null;
            width: number | null;
            length: number | null;
          };
          supplierItems: Array<{
            __typename?: "ProductSupplier";
            id: number;
            name: string | null;
            manufacturer: string | null;
            productId: string | null;
            price: number | null;
            status: ProductVariantStatus;
            supplier: number;
          }>;
        }>;
        attributes: Array<{
          __typename?: "ProductAttribute";
          attribute: string;
          description: string;
        }>;
      };
    };
  };
};

export type ProductView__UpdateProductMutationVariables = Exact<{
  product: ProductUpdateInput;
  productId: Scalars["Int"];
}>;

export type ProductView__UpdateProductMutation = {
  __typename?: "Mutation";
  products: {
    __typename?: "ProductsMutations";
    update: {
      __typename?: "ProductCreateOutput";
      product: {
        __typename?: "Product";
        id: number;
        name: string;
        isActive: boolean;
        primaryImage: string | null;
        categoryIds: Array<number>;
        images: Array<string>;
        aka: Array<string>;
        size: {
          __typename?: "ProductSize";
          type: ProductSizeType;
          unit: ProductSizeUnit;
          amount: number | null;
          width: number | null;
          length: number | null;
        };
        supplierItems: Array<{
          __typename?: "ProductSupplier";
          id: number;
          name: string | null;
          manufacturer: string | null;
          productId: string | null;
          price: number | null;
          status: ProductVariantStatus;
          supplier: number;
        }>;
        variants: Array<{
          __typename?: "ProductVariant";
          id: number;
          name: string;
          image: string | null;
          color: string | null;
          status: ProductVariantStatus;
          size: {
            __typename?: "ProductSize";
            type: ProductSizeType;
            unit: ProductSizeUnit;
            amount: number | null;
            width: number | null;
            length: number | null;
          };
          supplierItems: Array<{
            __typename?: "ProductSupplier";
            id: number;
            name: string | null;
            manufacturer: string | null;
            productId: string | null;
            price: number | null;
            status: ProductVariantStatus;
            supplier: number;
          }>;
        }>;
        attributes: Array<{
          __typename?: "ProductAttribute";
          attribute: string;
          description: string;
        }>;
      };
    };
  };
};

export type ReportsViewShipments__FindManyOutputFragment = {
  __typename?: "ShipmentsFindManyOutput";
  totalPrice: number;
  isAnyLineItemHasNoPrice: boolean;
  items: Array<{
    __typename?: "Shipment";
    id: number;
    deliveryDate: string;
    lineItems: Array<{
      __typename?: "ShipmentLineItem";
      shippedQuantity: number;
      id: string;
      lineItem: {
        __typename?: "LineItem";
        vat: number;
        priceWithDiscount: number | null;
        displayImage: string | null;
        displayName: string;
        displayVariantName: string | null;
        displaySize: string;
        price: number | null;
        discount: number;
        order: {
          __typename?: "Order";
          id: number;
          supplier: { __typename?: "Supplier"; name: string } | null;
        } | null;
      };
    }>;
    order: {
      __typename?: "Order";
      id: number;
      discount: number;
      project: {
        __typename?: "Project";
        name: string;
        company: { __typename?: "Company"; id: number; name: string };
      };
      subCompany: {
        __typename?: "SubCompany";
        id: number;
        name: string;
      } | null;
    };
    approvedBy: { __typename?: "Person"; name: string | null };
    certificates: Array<{ __typename?: "ShipmentCertificate"; url: string }>;
  }>;
  pageInfo: {
    __typename?: "PaginationInfo";
    perPage: number;
    totalItems: number;
  };
};

export type ReportsViewShipments__ShipmentFragment = {
  __typename?: "Shipment";
  id: number;
  deliveryDate: string;
  lineItems: Array<{
    __typename?: "ShipmentLineItem";
    shippedQuantity: number;
    id: string;
    lineItem: {
      __typename?: "LineItem";
      vat: number;
      priceWithDiscount: number | null;
      displayImage: string | null;
      displayName: string;
      displayVariantName: string | null;
      displaySize: string;
      price: number | null;
      discount: number;
      order: {
        __typename?: "Order";
        id: number;
        supplier: { __typename?: "Supplier"; name: string } | null;
      } | null;
    };
  }>;
  order: {
    __typename?: "Order";
    id: number;
    discount: number;
    project: {
      __typename?: "Project";
      name: string;
      company: { __typename?: "Company"; id: number; name: string };
    };
    subCompany: { __typename?: "SubCompany"; id: number; name: string } | null;
  };
  approvedBy: { __typename?: "Person"; name: string | null };
  certificates: Array<{ __typename?: "ShipmentCertificate"; url: string }>;
};

export type ReportsViewShipments__ShipmentLineItemFragment = {
  __typename?: "ShipmentLineItem";
  shippedQuantity: number;
  id: string;
  lineItem: {
    __typename?: "LineItem";
    vat: number;
    priceWithDiscount: number | null;
    displayImage: string | null;
    displayName: string;
    displayVariantName: string | null;
    displaySize: string;
    price: number | null;
    discount: number;
    order: {
      __typename?: "Order";
      id: number;
      supplier: { __typename?: "Supplier"; name: string } | null;
    } | null;
  };
};

export type ReportsViewShipments__LineItemFragment = {
  __typename?: "LineItem";
  vat: number;
  priceWithDiscount: number | null;
  displayImage: string | null;
  displayName: string;
  displayVariantName: string | null;
  displaySize: string;
  price: number | null;
  discount: number;
  order: {
    __typename?: "Order";
    id: number;
    supplier: { __typename?: "Supplier"; name: string } | null;
  } | null;
};

export type ReportsViewShipments__OrderFragment = {
  __typename?: "Order";
  id: number;
  supplier: { __typename?: "Supplier"; name: string } | null;
};

export type ReportsViewShipments__SupplierFragment = {
  __typename?: "Supplier";
  name: string;
};

export type ReportsViewShipmentsTableFilters__ProjectFragment = {
  __typename?: "Project";
  id: number;
  name: string;
};

export type ReportsViewShipmentsTableFilters__ProjectsQueryVariables = Exact<{
  [key: string]: never;
}>;

export type ReportsViewShipmentsTableFilters__ProjectsQuery = {
  __typename?: "Query";
  projectsFindMany: {
    __typename?: "ProjectsFindManyOutput";
    items: Array<{ __typename?: "Project"; id: number; name: string }>;
  };
};

export type ReportsViewShipmentsTableFilters__SupplierFragment = {
  __typename?: "Supplier";
  id: number;
  name: string;
};

export type ReportsViewShipmentsTableFilters__SuppliersQueryVariables = Exact<{
  [key: string]: never;
}>;

export type ReportsViewShipmentsTableFilters__SuppliersQuery = {
  __typename?: "Query";
  suppliersFindMany: {
    __typename?: "SuppliersFindManyOutput";
    items: Array<{ __typename?: "Supplier"; id: number; name: string }>;
  };
};

export type ReportsViewShipmentsTableFilters__CompanyFragment = {
  __typename?: "Company";
  id: number;
  name: string;
};

export type ReportsViewShipmentsTableFilters__CompaniesQueryVariables = Exact<{
  [key: string]: never;
}>;

export type ReportsViewShipmentsTableFilters__CompaniesQuery = {
  __typename?: "Query";
  companiesFindMany: {
    __typename?: "CompaniesFindManyOutput";
    items: Array<{ __typename?: "Company"; id: number; name: string }>;
  };
};

export type ReportsViewShipmentsTableFilters__ProductFragment = {
  __typename?: "Product";
  id: number;
  name: string;
};

export type ReportsViewShipmentsTableFilters__ProductsQueryVariables = Exact<{
  [key: string]: never;
}>;

export type ReportsViewShipmentsTableFilters__ProductsQuery = {
  __typename?: "Query";
  productsFindMany: {
    __typename?: "ProductsFindManyOutput";
    items: Array<{ __typename?: "Product"; id: number; name: string }>;
  };
};

export type ReportsViewShipmentsTable__ShipmentFragment = {
  __typename?: "Shipment";
  id: number;
  deliveryDate: string;
  lineItems: Array<{
    __typename?: "ShipmentLineItem";
    shippedQuantity: number;
    id: string;
    lineItem: {
      __typename?: "LineItem";
      priceWithDiscount: number | null;
      displayImage: string | null;
      displayName: string;
      displayVariantName: string | null;
      displaySize: string;
      price: number | null;
      discount: number;
      order: {
        __typename?: "Order";
        supplier: { __typename?: "Supplier"; name: string } | null;
      } | null;
    };
  }>;
  order: {
    __typename?: "Order";
    id: number;
    discount: number;
    project: {
      __typename?: "Project";
      name: string;
      company: { __typename?: "Company"; id: number; name: string };
    };
    subCompany: { __typename?: "SubCompany"; id: number; name: string } | null;
  };
  approvedBy: { __typename?: "Person"; name: string | null };
  certificates: Array<{ __typename?: "ShipmentCertificate"; url: string }>;
};

export type ReportsViewShipmentsTable__ShipmentLineItemFragment = {
  __typename?: "ShipmentLineItem";
  shippedQuantity: number;
  id: string;
  lineItem: {
    __typename?: "LineItem";
    priceWithDiscount: number | null;
    displayImage: string | null;
    displayName: string;
    displayVariantName: string | null;
    displaySize: string;
    price: number | null;
    discount: number;
    order: {
      __typename?: "Order";
      supplier: { __typename?: "Supplier"; name: string } | null;
    } | null;
  };
};

export type ReportsViewShipmentsTable__LineItemFragment = {
  __typename?: "LineItem";
  priceWithDiscount: number | null;
  displayImage: string | null;
  displayName: string;
  displayVariantName: string | null;
  displaySize: string;
  price: number | null;
  discount: number;
  order: {
    __typename?: "Order";
    supplier: { __typename?: "Supplier"; name: string } | null;
  } | null;
};

export type ReportsViewShipmentsTable__OrderFragment = {
  __typename?: "Order";
  supplier: { __typename?: "Supplier"; name: string } | null;
};

export type ReportsViewShipmentsTable__SupplierFragment = {
  __typename?: "Supplier";
  name: string;
};

export type ReportsViewShipmentsTableRow__ShipmentFragment = {
  __typename?: "Shipment";
  id: number;
  deliveryDate: string;
  order: {
    __typename?: "Order";
    id: number;
    discount: number;
    project: {
      __typename?: "Project";
      name: string;
      company: { __typename?: "Company"; id: number; name: string };
    };
    subCompany: { __typename?: "SubCompany"; id: number; name: string } | null;
  };
  approvedBy: { __typename?: "Person"; name: string | null };
  lineItems: Array<{
    __typename?: "ShipmentLineItem";
    shippedQuantity: number;
    id: string;
    lineItem: {
      __typename?: "LineItem";
      priceWithDiscount: number | null;
      displayImage: string | null;
      displayName: string;
      displayVariantName: string | null;
      displaySize: string;
      price: number | null;
      discount: number;
    };
  }>;
  certificates: Array<{ __typename?: "ShipmentCertificate"; url: string }>;
};

export type ReportsViewShipmentsTableRow__ShipmentLineItemFragment = {
  __typename?: "ShipmentLineItem";
  shippedQuantity: number;
  id: string;
  lineItem: {
    __typename?: "LineItem";
    priceWithDiscount: number | null;
    displayImage: string | null;
    displayName: string;
    displayVariantName: string | null;
    displaySize: string;
    price: number | null;
    discount: number;
  };
};

export type ReportsViewShipmentsTableRow__LineItemFragment = {
  __typename?: "LineItem";
  priceWithDiscount: number | null;
  displayImage: string | null;
  displayName: string;
  displayVariantName: string | null;
  displaySize: string;
  price: number | null;
  discount: number;
};

export type ReportsViewShipmentsTableRowLineItemsTable__ShipmentLineItemFragment =
  {
    __typename?: "ShipmentLineItem";
    id: string;
    shippedQuantity: number;
    lineItem: {
      __typename?: "LineItem";
      displayImage: string | null;
      displayName: string;
      displayVariantName: string | null;
      displaySize: string;
      price: number | null;
      discount: number;
    };
  };

export type ReportsViewShipmentsTableRowLineItemsTable__LineItemFragment = {
  __typename?: "LineItem";
  displayImage: string | null;
  displayName: string;
  displayVariantName: string | null;
  displaySize: string;
  price: number | null;
  discount: number;
};

export type ReportsView__FindManyShipmentReportsQueryVariables = Exact<{
  page: Scalars["Int"];
  perPage: Scalars["Int"];
  filters: ShipmentsFindManyFiltersInput;
}>;

export type ReportsView__FindManyShipmentReportsQuery = {
  __typename?: "Query";
  shipmentsFindMany: {
    __typename?: "ShipmentsFindManyOutput";
    totalPrice: number;
    isAnyLineItemHasNoPrice: boolean;
    items: Array<{
      __typename?: "Shipment";
      id: number;
      deliveryDate: string;
      lineItems: Array<{
        __typename?: "ShipmentLineItem";
        shippedQuantity: number;
        id: string;
        lineItem: {
          __typename?: "LineItem";
          vat: number;
          priceWithDiscount: number | null;
          displayImage: string | null;
          displayName: string;
          displayVariantName: string | null;
          displaySize: string;
          price: number | null;
          discount: number;
          order: {
            __typename?: "Order";
            id: number;
            supplier: { __typename?: "Supplier"; name: string } | null;
          } | null;
        };
      }>;
      order: {
        __typename?: "Order";
        id: number;
        discount: number;
        project: {
          __typename?: "Project";
          name: string;
          company: { __typename?: "Company"; id: number; name: string };
        };
        subCompany: {
          __typename?: "SubCompany";
          id: number;
          name: string;
        } | null;
      };
      approvedBy: { __typename?: "Person"; name: string | null };
      certificates: Array<{ __typename?: "ShipmentCertificate"; url: string }>;
    }>;
    pageInfo: {
      __typename?: "PaginationInfo";
      perPage: number;
      totalItems: number;
    };
  };
};

export type RequisitionView__RequisitionFragment = {
  __typename?: "Requisition";
  id: number;
  status: RequisitionStatus;
  note: string | null;
  deliveryDate: string;
  createdDate: string;
  discount: number;
  project: {
    __typename?: "Project";
    name: string;
    geoLocation: string;
    id: number;
    companyId: number;
    active: boolean;
  };
  lineItems: Array<{
    __typename: "RequisitionLineItem";
    vat: number;
    requisitionId: number;
    description: string | null;
    id: number;
    note: string | null;
    quantity: number;
    supplierId: number | null;
    variantId: number | null;
    displayVariantName: string | null;
    productId: number | null;
    price: number | null;
    discount: number;
    displayName: string;
    displaySize: string;
    displayImage: string | null;
    priceFormatted: string | null;
    priceTotal: number | null;
    priceTotalFormatted: string | null;
    orderItemChanged: boolean;
    priceTotalWithDiscountFormatted: string | null;
    discountTotalAmountFormatted: string | null;
    priceTotalWithDiscount: number | null;
    supplier: { __typename?: "Supplier"; id: number; name: string } | null;
    orderLineItem: { __typename?: "LineItem"; orderId: number } | null;
    product: {
      __typename?: "Product";
      id: number;
      variants: Array<{ __typename?: "ProductVariant"; id: number }>;
      companyCatalogProducts: Array<{
        __typename?: "CatalogProduct";
        id: number;
        variantIds: Array<number>;
        companyId: number;
      }>;
    } | null;
  }>;
  attachments: Array<{
    __typename?: "Attachment";
    id: number;
    url: string;
    name: string;
    mimeType: string;
  }>;
  creator: {
    __typename?: "Person";
    name: string | null;
    phoneNumber: string | null;
  };
  metadata: {
    __typename?: "RequisitionMetadata";
    editableProperties: Array<string>;
  };
};

export type RequisitionView__ProjectFragment = {
  __typename?: "Project";
  name: string;
  geoLocation: string;
  id: number;
  companyId: number;
};

export type RequisitionView__LineItemFragment = {
  __typename: "RequisitionLineItem";
  vat: number;
  requisitionId: number;
  description: string | null;
  id: number;
  note: string | null;
  quantity: number;
  supplierId: number | null;
  variantId: number | null;
  displayVariantName: string | null;
  productId: number | null;
  price: number | null;
  discount: number;
  displayName: string;
  displaySize: string;
  displayImage: string | null;
  priceFormatted: string | null;
  priceTotal: number | null;
  priceTotalFormatted: string | null;
  orderItemChanged: boolean;
  priceTotalWithDiscountFormatted: string | null;
  discountTotalAmountFormatted: string | null;
  priceTotalWithDiscount: number | null;
  supplier: { __typename?: "Supplier"; id: number; name: string } | null;
  orderLineItem: { __typename?: "LineItem"; orderId: number } | null;
  product: {
    __typename?: "Product";
    id: number;
    variants: Array<{ __typename?: "ProductVariant"; id: number }>;
    companyCatalogProducts: Array<{
      __typename?: "CatalogProduct";
      id: number;
      variantIds: Array<number>;
      companyId: number;
    }>;
  } | null;
};

export type RequisitionView__SupplierFragment = {
  __typename?: "Supplier";
  id: number;
  name: string;
};

export type RequisitionView__RequisitionMetadataFragment = {
  __typename?: "RequisitionMetadata";
  id: number;
  canDelete: boolean;
  canEditItems: boolean;
  editableProperties: Array<string>;
  nextPossibleStatuses: Array<RequisitionStatus>;
  canAttachFiles: boolean;
  editableItemIds: Array<number>;
};

export type RequisitionView__OrderLineItemFragment = {
  __typename?: "LineItem";
  orderId: number;
};

export type RequisitionView__AttachmentFragment = {
  __typename?: "Attachment";
  id: number;
  url: string;
  name: string;
  mimeType: string;
};

export type RequisitionViewLineItemsTable__RequisitionFragment = {
  __typename?: "Requisition";
  id: number;
  discount: number;
  lineItems: Array<{
    __typename?: "RequisitionLineItem";
    description: string | null;
    id: number;
    displayImage: string | null;
    displayName: string;
    displayVariantName: string | null;
    supplierId: number | null;
    quantity: number;
    displaySize: string;
    price: number | null;
    priceFormatted: string | null;
    priceTotal: number | null;
    priceTotalFormatted: string | null;
    note: string | null;
    productId: number | null;
    variantId: number | null;
    orderItemChanged: boolean;
    discount: number;
    priceTotalWithDiscountFormatted: string | null;
    discountTotalAmountFormatted: string | null;
    priceTotalWithDiscount: number | null;
    vat: number;
    supplier: { __typename?: "Supplier"; id: number; name: string } | null;
    orderLineItem: { __typename?: "LineItem"; orderId: number } | null;
    product: {
      __typename?: "Product";
      id: number;
      variants: Array<{ __typename?: "ProductVariant"; id: number }>;
      companyCatalogProducts: Array<{
        __typename?: "CatalogProduct";
        id: number;
        variantIds: Array<number>;
        companyId: number;
      }>;
    } | null;
  }>;
  metadata: {
    __typename?: "RequisitionMetadata";
    editableProperties: Array<string>;
  };
};

export type RequisitionViewLineItemsTable__LineItemFragment = {
  __typename?: "RequisitionLineItem";
  description: string | null;
  id: number;
  displayImage: string | null;
  displayName: string;
  displayVariantName: string | null;
  supplierId: number | null;
  quantity: number;
  displaySize: string;
  price: number | null;
  priceFormatted: string | null;
  priceTotal: number | null;
  priceTotalFormatted: string | null;
  note: string | null;
  productId: number | null;
  variantId: number | null;
  orderItemChanged: boolean;
  discount: number;
  priceTotalWithDiscountFormatted: string | null;
  discountTotalAmountFormatted: string | null;
  priceTotalWithDiscount: number | null;
  vat: number;
  supplier: { __typename?: "Supplier"; id: number; name: string } | null;
  orderLineItem: { __typename?: "LineItem"; orderId: number } | null;
  product: {
    __typename?: "Product";
    id: number;
    variants: Array<{ __typename?: "ProductVariant"; id: number }>;
    companyCatalogProducts: Array<{
      __typename?: "CatalogProduct";
      id: number;
      variantIds: Array<number>;
      companyId: number;
    }>;
  } | null;
};

export type RequisitionViewLineItemsTable__SupplierFragment = {
  __typename?: "Supplier";
  id: number;
  name: string;
};

export type RequisitionViewLineItemsTable__RequisitionMetadataFragment = {
  __typename?: "RequisitionMetadata";
  id: number;
  canEditItems: boolean;
  editableItemIds: Array<number>;
  editableProperties: Array<string>;
};

export type RequisitionViewLineItemsTable__OrderLineItemFragment = {
  __typename?: "LineItem";
  orderId: number;
};

export type RequisitionViewLineItemsTableRow__LineItemFragment = {
  __typename?: "RequisitionLineItem";
  id: number;
  displayImage: string | null;
  displayName: string;
  displayVariantName: string | null;
  supplierId: number | null;
  quantity: number;
  displaySize: string;
  price: number | null;
  priceFormatted: string | null;
  priceTotal: number | null;
  priceTotalFormatted: string | null;
  note: string | null;
  productId: number | null;
  variantId: number | null;
  orderItemChanged: boolean;
  discount: number;
  priceTotalWithDiscountFormatted: string | null;
  discountTotalAmountFormatted: string | null;
  product: {
    __typename?: "Product";
    id: number;
    variants: Array<{ __typename?: "ProductVariant"; id: number }>;
    companyCatalogProducts: Array<{
      __typename?: "CatalogProduct";
      id: number;
      variantIds: Array<number>;
      companyId: number;
    }>;
  } | null;
  supplier: { __typename?: "Supplier"; id: number; name: string } | null;
  orderLineItem: { __typename?: "LineItem"; orderId: number } | null;
};

export type RequisitionViewLineItemsTableRow__SupplierFragment = {
  __typename?: "Supplier";
  id: number;
  name: string;
};

export type RequisitionViewLineItemsTableRow__OrderLineItemFragment = {
  __typename?: "LineItem";
  orderId: number;
};

export type RequisitionViewLineItemsTableSummary__LineItemFragment = {
  __typename?: "RequisitionLineItem";
  quantity: number;
  priceTotalWithDiscount: number | null;
  vat: number;
  supplierId: number | null;
};

export type RequisitionViewLineItemsTableSummary__RequisitionFragment = {
  __typename?: "Requisition";
  id: number;
  discount: number;
  metadata: {
    __typename?: "RequisitionMetadata";
    editableProperties: Array<string>;
  };
};

export type RequisitionViewLineItemsTableSummary__RequisitionMetadataFragment =
  { __typename?: "RequisitionMetadata"; editableProperties: Array<string> };

export type RequisitionViewActions__DeleteRequisitionMutationVariables = Exact<{
  id: Scalars["Int"];
}>;

export type RequisitionViewActions__DeleteRequisitionMutation = {
  __typename?: "Mutation";
  requisitions: {
    __typename?: "RequisitionMutations";
    delete: { __typename?: "StatusOutput"; status: MutationStatus };
  };
};

export type RequisitionViewActions__RequisitionFragment = {
  __typename?: "Requisition";
  project: { __typename?: "Project"; id: number; companyId: number };
};

export type RequisitionViewActions__ProjectFragment = {
  __typename?: "Project";
  id: number;
  companyId: number;
};

export type RequisitionViewActions__RequisitionMetadataFragment = {
  __typename?: "RequisitionMetadata";
  id: number;
  canDelete: boolean;
  canEditItems: boolean;
  editableProperties: Array<string>;
  nextPossibleStatuses: Array<RequisitionStatus>;
};

export type RequisitionViewHeader__RequisitionFragment = {
  __typename?: "Requisition";
  id: number;
  status: RequisitionStatus;
  project: { __typename?: "Project"; name: string; geoLocation: string };
};

export type RequisitionViewHeader__ProjectFragment = {
  __typename?: "Project";
  name: string;
  geoLocation: string;
};

export type RequisitionViewInfo__RequisitionFragment = {
  __typename?: "Requisition";
  id: number;
  note: string | null;
  deliveryDate: string;
  status: RequisitionStatus;
  createdDate: string;
  attachments: Array<{
    __typename?: "Attachment";
    id: number;
    url: string;
    name: string;
    mimeType: string;
  }>;
  creator: {
    __typename?: "Person";
    name: string | null;
    phoneNumber: string | null;
  };
};

export type RequisitionViewInfo__RequisitionMetadataFragment = {
  __typename?: "RequisitionMetadata";
  id: number;
  editableProperties: Array<string>;
  canAttachFiles: boolean;
};

export type RequisitionViewInfo__AttachmentFragment = {
  __typename?: "Attachment";
  id: number;
  url: string;
  name: string;
  mimeType: string;
};

export type RequisitionViewInfoSummary__RequisitionFragment = {
  __typename?: "Requisition";
  createdDate: string;
  creator: {
    __typename?: "Person";
    name: string | null;
    phoneNumber: string | null;
  };
};

export type Requisition__AttachFilesMutationVariables = Exact<{
  data: Array<AttachmentCreateInput> | AttachmentCreateInput;
}>;

export type Requisition__AttachFilesMutation = {
  __typename?: "Mutation";
  attachments: {
    __typename?: "AttachmentsMutations";
    createBulk: {
      __typename?: "AttachmentsCreateOutput";
      attachments: Array<{
        __typename?: "Attachment";
        id: number;
        url: string;
        name: string;
        mimeType: string;
      }>;
    };
  };
};

export type RequisitionView__CreateOrdersMutationVariables = Exact<{
  requisitionId: Scalars["Int"];
}>;

export type RequisitionView__CreateOrdersMutation = {
  __typename?: "Mutation";
  requisitions: {
    __typename?: "RequisitionMutations";
    createOrders: {
      __typename?: "RequisitionCreateOrdersOutput";
      orderIds: Array<number>;
    };
  };
};

export type RequisitionDeleteAttachmentMutationVariables = Exact<{
  id: Scalars["Int"];
}>;

export type RequisitionDeleteAttachmentMutation = {
  __typename?: "Mutation";
  attachments: {
    __typename?: "AttachmentsMutations";
    delete: { __typename?: "StatusOutput"; status: MutationStatus };
  };
};

export type RequisitionView__UpdateCustomLineItemMutationVariables = Exact<{
  lineItemId: Scalars["Int"];
  lineItemCustom: RequisitionLineItemCustomUpdateInput;
}>;

export type RequisitionView__UpdateCustomLineItemMutation = {
  __typename?: "Mutation";
  lineItems: {
    __typename?: "LineItemsMutations";
    updateCustomRequisitionLineItem: {
      __typename?: "RequisitionLineItemUpdateOutput";
      lineItem: {
        __typename: "RequisitionLineItem";
        id: number;
        note: string | null;
        quantity: number;
        vat: number;
        supplierId: number | null;
        variantId: number | null;
        displayVariantName: string | null;
        productId: number | null;
        price: number | null;
        discount: number;
        requisitionId: number;
        displayName: string;
        displaySize: string;
        priceFormatted: string | null;
        priceTotal: number | null;
        priceTotalFormatted: string | null;
        priceWithDiscount: number | null;
        priceWithDiscountFormatted: string | null;
        priceTotalWithDiscount: number | null;
        priceTotalWithDiscountFormatted: string | null;
        priceWithVat: number | null;
        priceWithVatFormatted: string | null;
        priceTotalWithVat: number | null;
        priceTotalWithVatFormatted: string | null;
        vatAmount: number | null;
        vatAmountFormatted: string | null;
        discountAmount: number | null;
        discountAmountFormatted: string | null;
        discountTotalAmount: number | null;
        discountTotalAmountFormatted: string | null;
        supplier: { __typename?: "Supplier"; id: number; name: string } | null;
      };
    };
  };
};

export type RequisitionView__AddProductsMutationVariables = Exact<{
  requisitionId: Scalars["Int"];
  companyId: Scalars["Int"];
  lineItemProducts:
    | Array<RequisitionLineItemProductCreateInput>
    | RequisitionLineItemProductCreateInput;
}>;

export type RequisitionView__AddProductsMutation = {
  __typename?: "Mutation";
  lineItems: {
    __typename?: "LineItemsMutations";
    addProductsToRequisition: {
      __typename?: "RequisitionLineItemAddProductsOutput";
      status: MutationStatus;
      lineItems: Array<{
        __typename: "RequisitionLineItem";
        vat: number;
        requisitionId: number;
        description: string | null;
        id: number;
        note: string | null;
        quantity: number;
        supplierId: number | null;
        variantId: number | null;
        displayVariantName: string | null;
        productId: number | null;
        price: number | null;
        discount: number;
        displayName: string;
        displaySize: string;
        displayImage: string | null;
        priceFormatted: string | null;
        priceTotal: number | null;
        priceTotalFormatted: string | null;
        orderItemChanged: boolean;
        priceTotalWithDiscountFormatted: string | null;
        discountTotalAmountFormatted: string | null;
        priceTotalWithDiscount: number | null;
        supplier: { __typename?: "Supplier"; id: number; name: string } | null;
        orderLineItem: { __typename?: "LineItem"; orderId: number } | null;
        product: {
          __typename?: "Product";
          id: number;
          variants: Array<{ __typename?: "ProductVariant"; id: number }>;
          companyCatalogProducts: Array<{
            __typename?: "CatalogProduct";
            id: number;
            variantIds: Array<number>;
            companyId: number;
          }>;
        } | null;
      }>;
    };
  };
};

export type RequisitionView__CreateCustomProductsMutationVariables = Exact<{
  requisitionId: Scalars["Int"];
  lineItemProducts:
    | Array<RequisitionLineItemCustomCreateInput>
    | RequisitionLineItemCustomCreateInput;
}>;

export type RequisitionView__CreateCustomProductsMutation = {
  __typename?: "Mutation";
  lineItems: {
    __typename?: "LineItemsMutations";
    addCustomProductsToRequisition: {
      __typename?: "RequisitionLineItemAddProductsOutput";
      status: MutationStatus;
      lineItems: Array<{
        __typename: "RequisitionLineItem";
        vat: number;
        requisitionId: number;
        description: string | null;
        id: number;
        note: string | null;
        quantity: number;
        supplierId: number | null;
        variantId: number | null;
        displayVariantName: string | null;
        productId: number | null;
        price: number | null;
        discount: number;
        displayName: string;
        displaySize: string;
        displayImage: string | null;
        priceFormatted: string | null;
        priceTotal: number | null;
        priceTotalFormatted: string | null;
        orderItemChanged: boolean;
        priceTotalWithDiscountFormatted: string | null;
        discountTotalAmountFormatted: string | null;
        priceTotalWithDiscount: number | null;
        supplier: { __typename?: "Supplier"; id: number; name: string } | null;
        orderLineItem: { __typename?: "LineItem"; orderId: number } | null;
        product: {
          __typename?: "Product";
          id: number;
          variants: Array<{ __typename?: "ProductVariant"; id: number }>;
          companyCatalogProducts: Array<{
            __typename?: "CatalogProduct";
            id: number;
            variantIds: Array<number>;
            companyId: number;
          }>;
        } | null;
      }>;
    };
  };
};

export type RequisitionView__RemoveLineItemsMutationVariables = Exact<{
  lineItemIds: Array<Scalars["Int"]> | Scalars["Int"];
}>;

export type RequisitionView__RemoveLineItemsMutation = {
  __typename?: "Mutation";
  lineItems: {
    __typename?: "LineItemsMutations";
    removeRequisitionLineItem: {
      __typename?: "LineItemRemoveOutput";
      status: MutationStatus;
    };
  };
};

export type RequisitionView__GetRequisitionQueryVariables = Exact<{
  id: Scalars["Int"];
}>;

export type RequisitionView__GetRequisitionQuery = {
  __typename?: "Query";
  requisitionById: {
    __typename?: "Requisition";
    id: number;
    status: RequisitionStatus;
    note: string | null;
    deliveryDate: string;
    createdDate: string;
    discount: number;
    project: {
      __typename?: "Project";
      name: string;
      geoLocation: string;
      id: number;
      companyId: number;
      active: boolean;
    };
    lineItems: Array<{
      __typename: "RequisitionLineItem";
      vat: number;
      requisitionId: number;
      description: string | null;
      id: number;
      note: string | null;
      quantity: number;
      supplierId: number | null;
      variantId: number | null;
      displayVariantName: string | null;
      productId: number | null;
      price: number | null;
      discount: number;
      displayName: string;
      displaySize: string;
      displayImage: string | null;
      priceFormatted: string | null;
      priceTotal: number | null;
      priceTotalFormatted: string | null;
      orderItemChanged: boolean;
      priceTotalWithDiscountFormatted: string | null;
      discountTotalAmountFormatted: string | null;
      priceTotalWithDiscount: number | null;
      supplier: { __typename?: "Supplier"; id: number; name: string } | null;
      orderLineItem: { __typename?: "LineItem"; orderId: number } | null;
      product: {
        __typename?: "Product";
        id: number;
        variants: Array<{ __typename?: "ProductVariant"; id: number }>;
        companyCatalogProducts: Array<{
          __typename?: "CatalogProduct";
          id: number;
          variantIds: Array<number>;
          companyId: number;
        }>;
      } | null;
    }>;
    attachments: Array<{
      __typename?: "Attachment";
      id: number;
      url: string;
      name: string;
      mimeType: string;
    }>;
    creator: {
      __typename?: "Person";
      name: string | null;
      phoneNumber: string | null;
    };
    metadata: {
      __typename?: "RequisitionMetadata";
      editableProperties: Array<string>;
    };
  };
};

export type RequisitionView__GetRequisitionMetadataQueryVariables = Exact<{
  id: Scalars["Int"];
}>;

export type RequisitionView__GetRequisitionMetadataQuery = {
  __typename?: "Query";
  requisitionMetadataGetByRequisitionIds: Array<{
    __typename?: "RequisitionMetadata";
    id: number;
    canDelete: boolean;
    canEditItems: boolean;
    editableProperties: Array<string>;
    nextPossibleStatuses: Array<RequisitionStatus>;
    canAttachFiles: boolean;
    editableItemIds: Array<number>;
  }>;
};

export type RequisitionView__UpdateProductLineItemMutationVariables = Exact<{
  companyId: Scalars["Int"];
  lineItemId: Scalars["Int"];
  supplierId: InputMaybe<Scalars["Int"]>;
  data: RequisitionLineItemProductUpdateInput;
}>;

export type RequisitionView__UpdateProductLineItemMutation = {
  __typename?: "Mutation";
  lineItems: {
    __typename?: "LineItemsMutations";
    updateRequisitionLineItemWithProduct: {
      __typename?: "RequisitionLineItemUpdateOutput";
      lineItem: {
        __typename: "RequisitionLineItem";
        id: number;
        note: string | null;
        quantity: number;
        vat: number;
        supplierId: number | null;
        variantId: number | null;
        displayVariantName: string | null;
        productId: number | null;
        price: number | null;
        discount: number;
        requisitionId: number;
        priceFormatted: string | null;
        priceTotal: number | null;
        priceTotalFormatted: string | null;
        priceWithDiscount: number | null;
        priceWithDiscountFormatted: string | null;
        priceTotalWithDiscount: number | null;
        priceTotalWithDiscountFormatted: string | null;
        priceWithVat: number | null;
        priceWithVatFormatted: string | null;
        priceTotalWithVat: number | null;
        priceTotalWithVatFormatted: string | null;
        vatAmount: number | null;
        vatAmountFormatted: string | null;
        discountAmount: number | null;
        discountAmountFormatted: string | null;
        discountTotalAmount: number | null;
        discountTotalAmountFormatted: string | null;
        supplier: { __typename?: "Supplier"; id: number; name: string } | null;
      };
    };
  };
};

export type RequisitionView__UpdateRequisitionMutationVariables = Exact<{
  id: Scalars["Int"];
  requisition: RequisitionUpdateInput;
}>;

export type RequisitionView__UpdateRequisitionMutation = {
  __typename?: "Mutation";
  requisitions: {
    __typename?: "RequisitionMutations";
    update: {
      __typename?: "RequisitionUpdateOutput";
      requisition: {
        __typename?: "Requisition";
        id: number;
        status: RequisitionStatus;
        deliveryDate: string;
        note: string | null;
        discount: number;
      };
    };
  };
};

export type RequisitionViewUpdateRead__RequisitionFragment = {
  __typename?: "Requisition";
  id: number;
  status: RequisitionStatus;
  deliveryDate: string;
  note: string | null;
  discount: number;
};

export type RequisitionView__UpdateSupplierIdMutationVariables = Exact<{
  lineItemIds: Array<Scalars["Int"]> | Scalars["Int"];
  supplierId: Scalars["Int"];
}>;

export type RequisitionView__UpdateSupplierIdMutation = {
  __typename?: "Mutation";
  lineItems: {
    __typename?: "LineItemsMutations";
    updateRequisitionLineItemsSupplierId: {
      __typename?: "RequisitionLineItemsUpdateOutput";
      status: MutationStatus;
      lineItems: Array<{
        __typename: "RequisitionLineItem";
        id: number;
        note: string | null;
        quantity: number;
        vat: number;
        supplierId: number | null;
        variantId: number | null;
        displayVariantName: string | null;
        productId: number | null;
        price: number | null;
        discount: number;
        requisitionId: number;
        priceFormatted: string | null;
        priceTotal: number | null;
        priceTotalFormatted: string | null;
        priceWithDiscount: number | null;
        priceWithDiscountFormatted: string | null;
        priceTotalWithDiscount: number | null;
        priceTotalWithDiscountFormatted: string | null;
        priceWithVat: number | null;
        priceWithVatFormatted: string | null;
        priceTotalWithVat: number | null;
        priceTotalWithVatFormatted: string | null;
        vatAmount: number | null;
        vatAmountFormatted: string | null;
        discountAmount: number | null;
        discountAmountFormatted: string | null;
        discountTotalAmount: number | null;
        discountTotalAmountFormatted: string | null;
        supplier: { __typename?: "Supplier"; id: number; name: string } | null;
      }>;
    };
  };
};

export type RequisitionsView__FindManyOutputFragment = {
  __typename?: "RequisitionsFindManyOutput";
  pageInfo: {
    __typename?: "PaginationInfo";
    perPage: number;
    totalItems: number;
  };
  items: Array<{
    __typename?: "Requisition";
    id: number;
    createdDate: string;
    deliveryDate: string;
    status: RequisitionStatus;
    discount: number;
    lineItems: Array<{
      __typename?: "RequisitionLineItem";
      id: number;
      priceTotalWithDiscount: number | null;
      supplierId: number | null;
    }>;
    project: {
      __typename?: "Project";
      id: number;
      name: string;
      active: boolean;
    };
  }>;
};

export type RequisitionsView__RequisitionFragment = {
  __typename?: "Requisition";
  id: number;
  createdDate: string;
  deliveryDate: string;
  status: RequisitionStatus;
  discount: number;
  lineItems: Array<{
    __typename?: "RequisitionLineItem";
    id: number;
    priceTotalWithDiscount: number | null;
    supplierId: number | null;
  }>;
  project: {
    __typename?: "Project";
    id: number;
    name: string;
    active: boolean;
  };
};

export type RequisitionsView__ProjectFragment = {
  __typename?: "Project";
  id: number;
  name: string;
  active: boolean;
};

export type RequisitionsView__LineItemFragment = {
  __typename?: "RequisitionLineItem";
  id: number;
  priceTotalWithDiscount: number | null;
  supplierId: number | null;
};

export type RequisitionsView__FindProjectsQueryVariables = Exact<{
  [key: string]: never;
}>;

export type RequisitionsView__FindProjectsQuery = {
  __typename?: "Query";
  projectsFindMany: {
    __typename?: "ProjectsFindManyOutput";
    items: Array<{
      __typename?: "Project";
      id: number;
      name: string;
      active: boolean;
    }>;
  };
};

export type RequisitionsViewCreateRequisitionForm__ProjectFragment = {
  __typename?: "Project";
  id: number;
  name: string;
  active: boolean;
};

export type RequisitionsViewCreateRequisitionPopup__ProjectFragment = {
  __typename?: "Project";
  id: number;
  name: string;
  active: boolean;
};

export type RequisitionsViewTable__RequisitionFragment = {
  __typename?: "Requisition";
  id: number;
  createdDate: string;
  deliveryDate: string;
  status: RequisitionStatus;
  discount: number;
  lineItems: Array<{
    __typename?: "RequisitionLineItem";
    id: number;
    priceTotalWithDiscount: number | null;
    supplierId: number | null;
  }>;
  project: { __typename?: "Project"; id: number; name: string };
};

export type RequisitionsViewTable__LineItemFragment = {
  __typename?: "RequisitionLineItem";
  id: number;
  priceTotalWithDiscount: number | null;
  supplierId: number | null;
};

export type RequisitionsViewTable__ProjectFragment = {
  __typename?: "Project";
  id: number;
  name: string;
};

export type RequisitionsViewTableRow__RequisitionFragment = {
  __typename?: "Requisition";
  id: number;
  createdDate: string;
  deliveryDate: string;
  status: RequisitionStatus;
  discount: number;
  project: { __typename?: "Project"; id: number; name: string };
  lineItems: Array<{
    __typename?: "RequisitionLineItem";
    id: number;
    priceTotalWithDiscount: number | null;
    supplierId: number | null;
  }>;
};

export type RequisitionsViewTableRow__ProjectFragment = {
  __typename?: "Project";
  id: number;
  name: string;
};

export type RequisitionsViewTableRow__LineItemFragment = {
  __typename?: "RequisitionLineItem";
  id: number;
  priceTotalWithDiscount: number | null;
  supplierId: number | null;
};

export type RequisitionsView__FindManyRequisitionsQueryVariables = Exact<{
  page: Scalars["Int"];
  perPage: Scalars["Int"];
  filters: RequisitionsFindManyFiltersInput;
}>;

export type RequisitionsView__FindManyRequisitionsQuery = {
  __typename?: "Query";
  requisitionsFindMany: {
    __typename?: "RequisitionsFindManyOutput";
    pageInfo: {
      __typename?: "PaginationInfo";
      perPage: number;
      totalItems: number;
    };
    items: Array<{
      __typename?: "Requisition";
      id: number;
      createdDate: string;
      deliveryDate: string;
      status: RequisitionStatus;
      discount: number;
      lineItems: Array<{
        __typename?: "RequisitionLineItem";
        id: number;
        priceTotalWithDiscount: number | null;
        supplierId: number | null;
      }>;
      project: {
        __typename?: "Project";
        id: number;
        name: string;
        active: boolean;
      };
    }>;
  };
};

export type RequisitionsView__CreateRequisitionMutationVariables = Exact<{
  requisition: RequisitionCreateInput;
}>;

export type RequisitionsView__CreateRequisitionMutation = {
  __typename?: "Mutation";
  requisitions: {
    __typename?: "RequisitionMutations";
    create: {
      __typename?: "RequisitionCreateOutput";
      requisition: {
        __typename?: "Requisition";
        id: number;
        createdDate: string;
        deliveryDate: string;
        status: RequisitionStatus;
        discount: number;
        lineItems: Array<{
          __typename?: "RequisitionLineItem";
          id: number;
          priceTotalWithDiscount: number | null;
          supplierId: number | null;
        }>;
        project: {
          __typename?: "Project";
          id: number;
          name: string;
          active: boolean;
        };
      };
    };
  };
};

export type GetSubCompaniesQueryVariables = Exact<{
  filters: InputMaybe<SubCompanyFindManyFiltersInput>;
  pagination: InputMaybe<PaginationArgs>;
}>;

export type GetSubCompaniesQuery = {
  __typename?: "Query";
  subCompaniesFindMany: {
    __typename?: "SubCompanyFindManyOutput";
    items: Array<{
      __typename?: "SubCompany";
      id: number;
      name: string;
      companyId: number;
      localCompanyNumber: number | null;
    }>;
    pageInfo: {
      __typename?: "PaginationInfo";
      perPage: number;
      totalItems: number;
    };
  };
};

export type GetSubCompanies__SubCompanyFragment = {
  __typename?: "SubCompany";
  id: number;
  name: string;
  companyId: number;
  localCompanyNumber: number | null;
};

export const CategoriesSelectItemCategoryFragmentDoc = gql`
  fragment CategoriesSelectItemCategory on Category {
    id
    name
    parentId
    children {
      id
      name
      parentId
      children {
        id
        name
        parentId
        children {
          id
          name
          parentId
          children {
            id
            name
            parentId
            children {
              id
              name
              parentId
              children {
                id
                name
                parentId
                children {
                  id
                  name
                  parentId
                  children {
                    id
                    name
                    parentId
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`;
export const CategoriesSelectListCategoryFragmentDoc = gql`
  fragment CategoriesSelectListCategory on Category {
    id
    ...CategoriesSelectItemCategory
  }
  ${CategoriesSelectItemCategoryFragmentDoc}
`;
export const CategoryInputCategoryFragmentDoc = gql`
  fragment CategoryInputCategory on Category {
    ...CategoriesSelectListCategory
  }
  ${CategoriesSelectListCategoryFragmentDoc}
`;
export const ProductsAddPopupCategoryFragmentDoc = gql`
  fragment ProductsAddPopupCategory on Category {
    ...CategoryInputCategory
  }
  ${CategoryInputCategoryFragmentDoc}
`;
export const ProductsAddPopupProductsTableProductForm__ProductVariantFragmentDoc = gql`
  fragment ProductsAddPopupProductsTableProductForm__ProductVariant on ProductVariant {
    id
    name
  }
`;
export const ProductsAddPopupProductsTableProductForm__CatalogProductFragmentDoc = gql`
  fragment ProductsAddPopupProductsTableProductForm__CatalogProduct on CatalogProduct {
    id
    variantIds
    companyId
  }
`;
export const ProductsAddPopupProductsTableProductFormAttributes__ProductAttributeFragmentDoc = gql`
  fragment ProductsAddPopupProductsTableProductFormAttributes__ProductAttribute on ProductAttribute {
    attribute
    description
  }
`;
export const ProductsAddPopupProductsTableProductForm__ProductFragmentDoc = gql`
  fragment ProductsAddPopupProductsTableProductForm__Product on Product {
    id
    variants {
      ...ProductsAddPopupProductsTableProductForm__ProductVariant
    }
    companyCatalogProducts {
      ...ProductsAddPopupProductsTableProductForm__CatalogProduct
    }
    attributes {
      ...ProductsAddPopupProductsTableProductFormAttributes__ProductAttribute
    }
  }
  ${ProductsAddPopupProductsTableProductForm__ProductVariantFragmentDoc}
  ${ProductsAddPopupProductsTableProductForm__CatalogProductFragmentDoc}
  ${ProductsAddPopupProductsTableProductFormAttributes__ProductAttributeFragmentDoc}
`;
export const ProductsAddPopupProductsTableRow__ProductVariantFragmentDoc = gql`
  fragment ProductsAddPopupProductsTableRow__ProductVariant on ProductVariant {
    ...ProductsAddPopupProductsTableProductForm__ProductVariant
  }
  ${ProductsAddPopupProductsTableProductForm__ProductVariantFragmentDoc}
`;
export const ProductsAddPopupProductsTableRow__CatalogProductFragmentDoc = gql`
  fragment ProductsAddPopupProductsTableRow__CatalogProduct on CatalogProduct {
    ...ProductsAddPopupProductsTableProductForm__CatalogProduct
  }
  ${ProductsAddPopupProductsTableProductForm__CatalogProductFragmentDoc}
`;
export const ProductsAddPopupProductsTableRow__ProductFragmentDoc = gql`
  fragment ProductsAddPopupProductsTableRow__Product on Product {
    id
    primaryImage
    name
    ...ProductsAddPopupProductsTableProductForm__Product
    variants {
      ...ProductsAddPopupProductsTableRow__ProductVariant
    }
    companyCatalogProducts {
      ...ProductsAddPopupProductsTableRow__CatalogProduct
    }
  }
  ${ProductsAddPopupProductsTableProductForm__ProductFragmentDoc}
  ${ProductsAddPopupProductsTableRow__ProductVariantFragmentDoc}
  ${ProductsAddPopupProductsTableRow__CatalogProductFragmentDoc}
`;
export const ProductsAddPopupProductsTable__ProductFragmentDoc = gql`
  fragment ProductsAddPopupProductsTable__Product on Product {
    ...ProductsAddPopupProductsTableRow__Product
  }
  ${ProductsAddPopupProductsTableRow__ProductFragmentDoc}
`;
export const ProductsAddPopupProductFragmentDoc = gql`
  fragment ProductsAddPopupProduct on Product {
    ...ProductsAddPopupProductsTable__Product
  }
  ${ProductsAddPopupProductsTable__ProductFragmentDoc}
`;
export const PaginationFragmentDoc = gql`
  fragment Pagination on PaginationInfo {
    perPage
    totalItems
  }
`;
export const ProductsAddPopupPageInfoFragmentDoc = gql`
  fragment ProductsAddPopupPageInfo on PaginationInfo {
    ...Pagination
  }
  ${PaginationFragmentDoc}
`;
export const ProductsAddPopupProductsTableProductFormSupplierField__ProductSupplierPriceFragmentDoc = gql`
  fragment ProductsAddPopupProductsTableProductFormSupplierField__ProductSupplierPrice on ProductSupplierPrice {
    supplierId
    price
  }
`;
export const GetCompany__CompanyFragmentDoc = gql`
  fragment GetCompany__Company on Company {
    id
    name
    taxId
    active
    supplierIds
    useCompanyCatalog
  }
`;
export const CompanySuppliers__SupplierFragmentDoc = gql`
  fragment CompanySuppliers__Supplier on Supplier {
    id
    name
    enable
  }
`;
export const ProductWithCompanyCatalogFragmentDoc = gql`
  fragment ProductWithCompanyCatalog on Product {
    id
    variants {
      id
    }
    companyCatalogProducts {
      id
      companyId
      variantIds
    }
  }
`;
export const SupplierProductPrices__ProductSupplierPriceFragmentDoc = gql`
  fragment SupplierProductPrices__ProductSupplierPrice on ProductSupplierPrice {
    id
    supplierId
    variantId
    productId
    supplierName
    price
    priceFormatted
  }
`;
export const SupplierProductPrices__ProductFragmentDoc = gql`
  fragment SupplierProductPrices__Product on Product {
    id
    variants {
      id
    }
  }
`;
export const ProductsViewTable__ProductFragmentDoc = gql`
  fragment ProductsViewTable__Product on Product {
    id
    primaryImage
    name
    isActive
  }
`;
export const ProductsView__ProductFragmentDoc = gql`
  fragment ProductsView__Product on Product {
    ...ProductsViewTable__Product
  }
  ${ProductsViewTable__ProductFragmentDoc}
`;
export const GetCategories__CategoryFragmentDoc = gql`
  fragment GetCategories__Category on Category {
    id
    name
    parentId
    hasProducts
    children {
      id
      name
      parentId
      hasProducts
      children {
        id
        name
        parentId
        hasProducts
        children {
          id
          name
          parentId
          hasProducts
          children {
            id
            name
            parentId
            hasProducts
            children {
              id
              name
              parentId
              hasProducts
              children {
                id
                name
                parentId
                hasProducts
                children {
                  id
                  name
                  parentId
                  hasProducts
                  children {
                    id
                    name
                    parentId
                    hasProducts
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`;
export const CompaniesViewTable__CompanyFragmentDoc = gql`
  fragment CompaniesViewTable__Company on Company {
    id
    name
    taxId
    active
  }
`;
export const CompaniesView__CompanyFragmentDoc = gql`
  fragment CompaniesView__Company on Company {
    id
    ...CompaniesViewTable__Company
  }
  ${CompaniesViewTable__CompanyFragmentDoc}
`;
export const CompanyViewHeaderCompanyFragmentDoc = gql`
  fragment CompanyViewHeaderCompany on Company {
    name
    taxId
  }
`;
export const ManageCompanyModalCompanyFragmentDoc = gql`
  fragment ManageCompanyModalCompany on Company {
    id
    name
    taxId
    active
  }
`;
export const CompanyViewCompanyFragmentDoc = gql`
  fragment CompanyViewCompany on Company {
    supplierIds
    ...CompanyViewHeaderCompany
    ...ManageCompanyModalCompany
  }
  ${CompanyViewHeaderCompanyFragmentDoc}
  ${ManageCompanyModalCompanyFragmentDoc}
`;
export const CompanyViewProjectsTableProjectFragmentDoc = gql`
  fragment CompanyViewProjectsTableProject on Project {
    id
    name
    geoLocation
    active
  }
`;
export const CompanyViewProjectsProjectFormProjectFragmentDoc = gql`
  fragment CompanyViewProjectsProjectFormProject on Project {
    name
    geoLocation
    active
    personIds
    subCompanyId
  }
`;
export const CompanyViewProjects__ProjectFragmentDoc = gql`
  fragment CompanyViewProjects__Project on Project {
    ...CompanyViewProjectsTableProject
    ...CompanyViewProjectsProjectFormProject
  }
  ${CompanyViewProjectsTableProjectFragmentDoc}
  ${CompanyViewProjectsProjectFormProjectFragmentDoc}
`;
export const CompanyViewSubCompaniesTableRow__SubCompanyFragmentDoc = gql`
  fragment CompanyViewSubCompaniesTableRow__SubCompany on SubCompany {
    id
    name
    localCompanyNumber
    projects {
      id
    }
    orders {
      id
    }
  }
`;
export const CompanyViewSubCompaniesTable__SubCompanyFragmentDoc = gql`
  fragment CompanyViewSubCompaniesTable__SubCompany on SubCompany {
    ...CompanyViewSubCompaniesTableRow__SubCompany
  }
  ${CompanyViewSubCompaniesTableRow__SubCompanyFragmentDoc}
`;
export const CompanyViewSubCompanyForm__SubCompanyFragmentDoc = gql`
  fragment CompanyViewSubCompanyForm__SubCompany on SubCompany {
    name
    localCompanyNumber
  }
`;
export const CompanyViewSubCompanies__SubCompanyFragmentDoc = gql`
  fragment CompanyViewSubCompanies__SubCompany on SubCompany {
    id
    ...CompanyViewSubCompaniesTable__SubCompany
    ...CompanyViewSubCompanyForm__SubCompany
  }
  ${CompanyViewSubCompaniesTable__SubCompanyFragmentDoc}
  ${CompanyViewSubCompanyForm__SubCompanyFragmentDoc}
`;
export const CompanyViewUsersFilters__ProjectFragmentDoc = gql`
  fragment CompanyViewUsersFilters__Project on Project {
    id
    name
  }
`;
export const CompanyView__SubCompanyUpdateReadFragmentDoc = gql`
  fragment CompanyView__SubCompanyUpdateRead on SubCompany {
    id
    name
    localCompanyNumber
  }
`;
export const CompanyViewSuppliersTableSupplierFragmentDoc = gql`
  fragment CompanyViewSuppliersTableSupplier on Supplier {
    id
    name
  }
`;
export const CompanyViewUsersTableRow__ProjectFragmentDoc = gql`
  fragment CompanyViewUsersTableRow__Project on Project {
    id
    name
  }
`;
export const CompanyViewUsersTableRow__PersonFragmentDoc = gql`
  fragment CompanyViewUsersTableRow__Person on Person {
    id
    name
    email
    projectIds
    phoneNumber
    role
    projects {
      ...CompanyViewUsersTableRow__Project
    }
  }
  ${CompanyViewUsersTableRow__ProjectFragmentDoc}
`;
export const CompanyViewUsersTable__ProjectFragmentDoc = gql`
  fragment CompanyViewUsersTable__Project on Project {
    ...CompanyViewUsersTableRow__Project
  }
  ${CompanyViewUsersTableRow__ProjectFragmentDoc}
`;
export const CompanyViewUsersTable__PersonFragmentDoc = gql`
  fragment CompanyViewUsersTable__Person on Person {
    id
    ...CompanyViewUsersTableRow__Person
    projects {
      ...CompanyViewUsersTable__Project
    }
  }
  ${CompanyViewUsersTableRow__PersonFragmentDoc}
  ${CompanyViewUsersTable__ProjectFragmentDoc}
`;
export const CompanyViewUsers__ProjectFragmentDoc = gql`
  fragment CompanyViewUsers__Project on Project {
    ...CompanyViewUsersTable__Project
  }
  ${CompanyViewUsersTable__ProjectFragmentDoc}
`;
export const CompanyViewUsers__PersonFragmentDoc = gql`
  fragment CompanyViewUsers__Person on Person {
    id
    ...CompanyViewUsersTable__Person
    projects {
      ...CompanyViewUsers__Project
    }
  }
  ${CompanyViewUsersTable__PersonFragmentDoc}
  ${CompanyViewUsers__ProjectFragmentDoc}
`;
export const CompanyViewUsers__ReadPersonFragmentDoc = gql`
  fragment CompanyViewUsers__ReadPerson on Person {
    id
    projectIds
  }
`;
export const CompanyCatalogTableItem__ProductFragmentDoc = gql`
  fragment CompanyCatalogTableItem__Product on Product {
    id
    primaryImage
    name
    displaySize
  }
`;
export const CompanyCatalogTable__ProductFragmentDoc = gql`
  fragment CompanyCatalogTable__Product on Product {
    ...CompanyCatalogTableItem__Product
  }
  ${CompanyCatalogTableItem__ProductFragmentDoc}
`;
export const CompanyCatalogTableItemVariants__ProductVariantFragmentDoc = gql`
  fragment CompanyCatalogTableItemVariants__ProductVariant on ProductVariant {
    id
    image
    name
  }
`;
export const CompanyCatalogTableItem__ProductVariantFragmentDoc = gql`
  fragment CompanyCatalogTableItem__ProductVariant on ProductVariant {
    ...CompanyCatalogTableItemVariants__ProductVariant
  }
  ${CompanyCatalogTableItemVariants__ProductVariantFragmentDoc}
`;
export const CompanyCatalogTable__ProductVariantFragmentDoc = gql`
  fragment CompanyCatalogTable__ProductVariant on ProductVariant {
    ...CompanyCatalogTableItem__ProductVariant
  }
  ${CompanyCatalogTableItem__ProductVariantFragmentDoc}
`;
export const CompanyCatalogTable__CatalogProductFragmentDoc = gql`
  fragment CompanyCatalogTable__CatalogProduct on CatalogProduct {
    id
    product {
      ...CompanyCatalogTable__Product
    }
    variants {
      ...CompanyCatalogTable__ProductVariant
    }
  }
  ${CompanyCatalogTable__ProductFragmentDoc}
  ${CompanyCatalogTable__ProductVariantFragmentDoc}
`;
export const CompanyCatalog__ProductFragmentDoc = gql`
  fragment CompanyCatalog__Product on Product {
    ...CompanyCatalogTable__Product
  }
  ${CompanyCatalogTable__ProductFragmentDoc}
`;
export const CompanyCatalog__ProductVariantFragmentDoc = gql`
  fragment CompanyCatalog__ProductVariant on ProductVariant {
    ...CompanyCatalogTable__ProductVariant
  }
  ${CompanyCatalogTable__ProductVariantFragmentDoc}
`;
export const CompanyCatalog__CatalogProductFragmentDoc = gql`
  fragment CompanyCatalog__CatalogProduct on CatalogProduct {
    id
    ...CompanyCatalogTable__CatalogProduct
    product {
      ...CompanyCatalog__Product
    }
    variants {
      ...CompanyCatalog__ProductVariant
    }
  }
  ${CompanyCatalogTable__CatalogProductFragmentDoc}
  ${CompanyCatalog__ProductFragmentDoc}
  ${CompanyCatalog__ProductVariantFragmentDoc}
`;
export const CompanyCatalogAddProductsPopupTableItem__CatalogProductFragmentDoc = gql`
  fragment CompanyCatalogAddProductsPopupTableItem__CatalogProduct on CatalogProduct {
    id
    variantIds
    companyId
  }
`;
export const CompanyCatalogAddProductsPopupTableItemVariants__ProductVariantFragmentDoc = gql`
  fragment CompanyCatalogAddProductsPopupTableItemVariants__ProductVariant on ProductVariant {
    id
    image
    name
  }
`;
export const CompanyCatalogAddProductsPopupTableItem__ProductVariantFragmentDoc = gql`
  fragment CompanyCatalogAddProductsPopupTableItem__ProductVariant on ProductVariant {
    ...CompanyCatalogAddProductsPopupTableItemVariants__ProductVariant
  }
  ${CompanyCatalogAddProductsPopupTableItemVariants__ProductVariantFragmentDoc}
`;
export const CompanyCatalogAddProductsPopupTableItem__ProductFragmentDoc = gql`
  fragment CompanyCatalogAddProductsPopupTableItem__Product on Product {
    id
    primaryImage
    name
    companyCatalogProducts {
      ...CompanyCatalogAddProductsPopupTableItem__CatalogProduct
    }
    variants {
      ...CompanyCatalogAddProductsPopupTableItem__ProductVariant
    }
  }
  ${CompanyCatalogAddProductsPopupTableItem__CatalogProductFragmentDoc}
  ${CompanyCatalogAddProductsPopupTableItem__ProductVariantFragmentDoc}
`;
export const CompanyCatalogAddProductsPopupTable__ProductVariantFragmentDoc = gql`
  fragment CompanyCatalogAddProductsPopupTable__ProductVariant on ProductVariant {
    ...CompanyCatalogAddProductsPopupTableItem__ProductVariant
  }
  ${CompanyCatalogAddProductsPopupTableItem__ProductVariantFragmentDoc}
`;
export const CompanyCatalogAddProductsPopupTable__ProductFragmentDoc = gql`
  fragment CompanyCatalogAddProductsPopupTable__Product on Product {
    id
    ...CompanyCatalogAddProductsPopupTableItem__Product
    variants {
      ...CompanyCatalogAddProductsPopupTable__ProductVariant
    }
  }
  ${CompanyCatalogAddProductsPopupTableItem__ProductFragmentDoc}
  ${CompanyCatalogAddProductsPopupTable__ProductVariantFragmentDoc}
`;
export const CompanyCatalogAddProducts__ProductVariantFragmentDoc = gql`
  fragment CompanyCatalogAddProducts__ProductVariant on ProductVariant {
    ...CompanyCatalogAddProductsPopupTable__ProductVariant
  }
  ${CompanyCatalogAddProductsPopupTable__ProductVariantFragmentDoc}
`;
export const CompanyCatalogAddProducts__ProductFragmentDoc = gql`
  fragment CompanyCatalogAddProducts__Product on Product {
    id
    ...CompanyCatalogAddProductsPopupTable__Product
    variants {
      ...CompanyCatalogAddProducts__ProductVariant
    }
  }
  ${CompanyCatalogAddProductsPopupTable__ProductFragmentDoc}
  ${CompanyCatalogAddProducts__ProductVariantFragmentDoc}
`;
export const CompanyCatalog__AddProduct__ProductFragmentDoc = gql`
  fragment CompanyCatalog__AddProduct__Product on Product {
    id
    variants {
      id
    }
  }
`;
export const CompanyCatalog__AddProduct__ProductUpdateFragmentDoc = gql`
  fragment CompanyCatalog__AddProduct__ProductUpdate on Product {
    id
    companyCatalogProducts {
      id
      variantIds
      companyId
    }
  }
`;
export const CompanyCatalogTableActions__CatalogProductFragmentDoc = gql`
  fragment CompanyCatalogTableActions__CatalogProduct on CatalogProduct {
    id
  }
`;
export const UpdateCustomLineItemOptimisticResponse__LineItemReadFragmentDoc = gql`
  fragment UpdateCustomLineItemOptimisticResponse__LineItemRead on LineItem {
    id
    __typename
    note
    quantity
    vat
    variantId
    displayVariantName
    productId
    price
    discount
    orderId
    displayName
    displaySize
  }
`;
export const LineItemPricesFragmentDoc = gql`
  fragment LineItemPrices on LineItem {
    price
    priceFormatted
    priceTotal
    priceTotalFormatted
    priceWithDiscount
    priceWithDiscountFormatted
    priceTotalWithDiscount
    priceTotalWithDiscountFormatted
    priceWithVat
    priceWithVatFormatted
    priceTotalWithVat
    priceTotalWithVatFormatted
    vatAmount
    vatAmountFormatted
    discountAmount
    discountAmountFormatted
    discountTotalAmount
    discountTotalAmountFormatted
  }
`;
export const UpdateCustomLineItemOptimisticResponse__LineItemFragmentDoc = gql`
  fragment UpdateCustomLineItemOptimisticResponse__LineItem on LineItem {
    ...UpdateCustomLineItemOptimisticResponse__LineItemRead
    ...LineItemPrices
  }
  ${UpdateCustomLineItemOptimisticResponse__LineItemReadFragmentDoc}
  ${LineItemPricesFragmentDoc}
`;
export const UpdateCustomLineItemOptimisticResponse__RequisitionLineItemReadFragmentDoc = gql`
  fragment UpdateCustomLineItemOptimisticResponse__RequisitionLineItemRead on RequisitionLineItem {
    id
    __typename
    note
    quantity
    vat
    supplierId
    variantId
    displayVariantName
    productId
    price
    discount
    requisitionId
    displayName
    displaySize
    supplier {
      id
      name
    }
  }
`;
export const RequisitionLineItemPricesFragmentDoc = gql`
  fragment RequisitionLineItemPrices on RequisitionLineItem {
    price
    priceFormatted
    priceTotal
    priceTotalFormatted
    priceWithDiscount
    priceWithDiscountFormatted
    priceTotalWithDiscount
    priceTotalWithDiscountFormatted
    priceWithVat
    priceWithVatFormatted
    priceTotalWithVat
    priceTotalWithVatFormatted
    vatAmount
    vatAmountFormatted
    discountAmount
    discountAmountFormatted
    discountTotalAmount
    discountTotalAmountFormatted
  }
`;
export const UpdateCustomLineItemOptimisticResponse__RequisitionLineItemFragmentDoc = gql`
  fragment UpdateCustomLineItemOptimisticResponse__RequisitionLineItem on RequisitionLineItem {
    ...UpdateCustomLineItemOptimisticResponse__RequisitionLineItemRead
    ...RequisitionLineItemPrices
  }
  ${UpdateCustomLineItemOptimisticResponse__RequisitionLineItemReadFragmentDoc}
  ${RequisitionLineItemPricesFragmentDoc}
`;
export const UpdateCustomLineItemOptimisticResponse__SupplierFragmentDoc = gql`
  fragment UpdateCustomLineItemOptimisticResponse__Supplier on Supplier {
    id
    name
  }
`;
export const UpdateProductLineItemOptimisticResponse__ProductSupplierPriceFragmentDoc = gql`
  fragment UpdateProductLineItemOptimisticResponse__ProductSupplierPrice on ProductSupplierPrice {
    id
    price
  }
`;
export const UpdateProductLineItemOptimisticResponse__LineItemReadFragmentDoc = gql`
  fragment UpdateProductLineItemOptimisticResponse__LineItemRead on LineItem {
    id
    __typename
    note
    quantity
    vat
    variantId
    displayVariantName
    productId
    price
    discount
    orderId
  }
`;
export const UpdateProductLineItemOptimisticResponse__LineItemFragmentDoc = gql`
  fragment UpdateProductLineItemOptimisticResponse__LineItem on LineItem {
    ...UpdateProductLineItemOptimisticResponse__LineItemRead
    ...LineItemPrices
  }
  ${UpdateProductLineItemOptimisticResponse__LineItemReadFragmentDoc}
  ${LineItemPricesFragmentDoc}
`;
export const UpdateProductLineItemOptimisticResponse__RequisitionLineItemReadFragmentDoc = gql`
  fragment UpdateProductLineItemOptimisticResponse__RequisitionLineItemRead on RequisitionLineItem {
    id
    __typename
    note
    quantity
    vat
    supplierId
    variantId
    displayVariantName
    productId
    price
    discount
    requisitionId
    supplier {
      id
      name
    }
  }
`;
export const UpdateProductLineItemOptimisticResponse__RequisitionLineItemFragmentDoc = gql`
  fragment UpdateProductLineItemOptimisticResponse__RequisitionLineItem on RequisitionLineItem {
    ...UpdateProductLineItemOptimisticResponse__RequisitionLineItemRead
    ...RequisitionLineItemPrices
  }
  ${UpdateProductLineItemOptimisticResponse__RequisitionLineItemReadFragmentDoc}
  ${RequisitionLineItemPricesFragmentDoc}
`;
export const UpdateProductLineItemOptimisticResponse__SupplierFragmentDoc = gql`
  fragment UpdateProductLineItemOptimisticResponse__Supplier on Supplier {
    id
    name
  }
`;
export const UpdateProductLineItemOptimisticResponse__ProductVariantReadFragmentDoc = gql`
  fragment UpdateProductLineItemOptimisticResponse__ProductVariantRead on ProductVariant {
    id
    name
  }
`;
export const OrderViewHeader__OrderFragmentDoc = gql`
  fragment OrderViewHeader__Order on Order {
    id
    status
    project {
      name
      geoLocation
    }
  }
`;
export const OrderViewSummary__OrderFragmentDoc = gql`
  fragment OrderViewSummary__Order on Order {
    createdDate
    creator {
      name
      phoneNumber
    }
    dropOffContact {
      id
      email
      name
      phoneNumber
    }
  }
`;
export const OrderViewInfo__CompanyFragmentDoc = gql`
  fragment OrderViewInfo__Company on Company {
    id
    name
  }
`;
export const OrderViewInfo__ProjectFragmentDoc = gql`
  fragment OrderViewInfo__Project on Project {
    companyId
    name
    company {
      ...OrderViewInfo__Company
    }
  }
  ${OrderViewInfo__CompanyFragmentDoc}
`;
export const SupplierSingleInputSupplierFragmentDoc = gql`
  fragment SupplierSingleInputSupplier on Supplier {
    id
    name
  }
`;
export const OrderViewSupplierInputSupplierFragmentDoc = gql`
  fragment OrderViewSupplierInputSupplier on Supplier {
    ...SupplierSingleInputSupplier
  }
  ${SupplierSingleInputSupplierFragmentDoc}
`;
export const OrderViewInfo__SupplierFragmentDoc = gql`
  fragment OrderViewInfo__Supplier on Supplier {
    id
    name
    ...OrderViewSupplierInputSupplier
  }
  ${OrderViewSupplierInputSupplierFragmentDoc}
`;
export const OrderViewInfo__MetadataFieldFragmentDoc = gql`
  fragment OrderViewInfo__MetadataField on GraphQLMetadataField {
    canEdit
    reason
  }
`;
export const OrderViewInfo__OrderMetadataFragmentDoc = gql`
  fragment OrderViewInfo__OrderMetadata on OrderMetadata {
    id
    canDelete
    canAttachFiles
    fields {
      note {
        ...OrderViewInfo__MetadataField
      }
      supplierId {
        ...OrderViewInfo__MetadataField
      }
      deliveryDate {
        ...OrderViewInfo__MetadataField
      }
      subCompanyId {
        ...OrderViewInfo__MetadataField
      }
    }
  }
  ${OrderViewInfo__MetadataFieldFragmentDoc}
`;
export const CommentsSidebar__AttachmentFragmentDoc = gql`
  fragment CommentsSidebar__Attachment on Attachment {
    id
    url
    name
    mimeType
  }
`;
export const OrderViewInfo__AttachmentFragmentDoc = gql`
  fragment OrderViewInfo__Attachment on Attachment {
    ...CommentsSidebar__Attachment
  }
  ${CommentsSidebar__AttachmentFragmentDoc}
`;
export const OrderViewInfo__OrderFragmentDoc = gql`
  fragment OrderViewInfo__Order on Order {
    id
    status
    deliveryDate
    supplierId
    subCompanyId
    note
    ...OrderViewSummary__Order
    project {
      ...OrderViewInfo__Project
    }
    lineItems {
      productId
      variantId
    }
    supplier {
      ...OrderViewInfo__Supplier
    }
    metadata {
      ...OrderViewInfo__OrderMetadata
    }
    attachments {
      ...OrderViewInfo__Attachment
    }
  }
  ${OrderViewSummary__OrderFragmentDoc}
  ${OrderViewInfo__ProjectFragmentDoc}
  ${OrderViewInfo__SupplierFragmentDoc}
  ${OrderViewInfo__OrderMetadataFragmentDoc}
  ${OrderViewInfo__AttachmentFragmentDoc}
`;
export const OrderViewControls__OrderMetadataFragmentDoc = gql`
  fragment OrderViewControls__OrderMetadata on OrderMetadata {
    id
    canDelete
    fields {
      items {
        canEdit
        reason
      }
      status {
        canEdit
        reason
        possibleValues
      }
      shipments {
        canEdit
      }
    }
  }
`;
export const OrderViewControls__OrderFragmentDoc = gql`
  fragment OrderViewControls__Order on Order {
    status
    metadata {
      ...OrderViewControls__OrderMetadata
    }
  }
  ${OrderViewControls__OrderMetadataFragmentDoc}
`;
export const OrderViewShipmentsTableRow__OrderFragmentDoc = gql`
  fragment OrderViewShipmentsTableRow__Order on Order {
    id
    discount
  }
`;
export const OrderViewShipmentsTable__OrderFragmentDoc = gql`
  fragment OrderViewShipmentsTable__Order on Order {
    ...OrderViewShipmentsTableRow__Order
  }
  ${OrderViewShipmentsTableRow__OrderFragmentDoc}
`;
export const OrderViewShipments__OrderMetadataFragmentDoc = gql`
  fragment OrderViewShipments__OrderMetadata on OrderMetadata {
    fields {
      discount {
        canEdit
      }
    }
  }
`;
export const OrderViewShipments__OrderFragmentDoc = gql`
  fragment OrderViewShipments__Order on Order {
    supplierId
    discount
    ...OrderViewShipmentsTable__Order
    metadata {
      ...OrderViewShipments__OrderMetadata
    }
  }
  ${OrderViewShipmentsTable__OrderFragmentDoc}
  ${OrderViewShipments__OrderMetadataFragmentDoc}
`;
export const OrderViewTotalPrice__OrderFragmentDoc = gql`
  fragment OrderViewTotalPrice__Order on Order {
    discount
  }
`;
export const OrderViewLineItems__OrderMetadataFragmentDoc = gql`
  fragment OrderViewLineItems__OrderMetadata on OrderMetadata {
    id
    fields {
      items {
        canEdit
      }
      discount {
        canEdit
      }
    }
  }
`;
export const OrderViewLineItems__OrderFragmentDoc = gql`
  fragment OrderViewLineItems__Order on Order {
    id
    supplierId
    ...OrderViewTotalPrice__Order
    metadata {
      ...OrderViewLineItems__OrderMetadata
    }
  }
  ${OrderViewTotalPrice__OrderFragmentDoc}
  ${OrderViewLineItems__OrderMetadataFragmentDoc}
`;
export const OrderView__CompanyFragmentDoc = gql`
  fragment OrderView__Company on Company {
    ...OrderViewInfo__Company
  }
  ${OrderViewInfo__CompanyFragmentDoc}
`;
export const OrderView__ProjectFragmentDoc = gql`
  fragment OrderView__Project on Project {
    ...OrderViewInfo__Project
    company {
      ...OrderView__Company
    }
  }
  ${OrderViewInfo__ProjectFragmentDoc}
  ${OrderView__CompanyFragmentDoc}
`;
export const OrderViewLineItemTableRow__ProductVariantFragmentDoc = gql`
  fragment OrderViewLineItemTableRow__ProductVariant on ProductVariant {
    id
  }
`;
export const OrderViewLineItemTableRow__CatalogProductFragmentDoc = gql`
  fragment OrderViewLineItemTableRow__CatalogProduct on CatalogProduct {
    id
    variantIds
    companyId
  }
`;
export const OrderViewLineItemTableRow__ProductFragmentDoc = gql`
  fragment OrderViewLineItemTableRow__Product on Product {
    id
    variants {
      ...OrderViewLineItemTableRow__ProductVariant
    }
    companyCatalogProducts {
      ...OrderViewLineItemTableRow__CatalogProduct
    }
  }
  ${OrderViewLineItemTableRow__ProductVariantFragmentDoc}
  ${OrderViewLineItemTableRow__CatalogProductFragmentDoc}
`;
export const OrderViewLineItemTableRowLineItemFragmentDoc = gql`
  fragment OrderViewLineItemTableRowLineItem on LineItem {
    id
    displayImage
    displayName
    displayVariantName
    quantity
    displaySize
    price
    discount
    priceFormatted
    priceTotal
    priceTotalFormatted
    priceTotalWithDiscountFormatted
    discountTotalAmountFormatted
    note
    variantId
    requisitionLineItem {
      requisitionId
    }
    product {
      ...OrderViewLineItemTableRow__Product
    }
  }
  ${OrderViewLineItemTableRow__ProductFragmentDoc}
`;
export const OrderViewTotalPrice__LineItemFragmentDoc = gql`
  fragment OrderViewTotalPrice__LineItem on LineItem {
    quantity
    priceTotalWithDiscount
    vat
  }
`;
export const OrderViewLineItemTable__ProductVariantFragmentDoc = gql`
  fragment OrderViewLineItemTable__ProductVariant on ProductVariant {
    ...OrderViewLineItemTableRow__ProductVariant
  }
  ${OrderViewLineItemTableRow__ProductVariantFragmentDoc}
`;
export const OrderViewLineItemTable__CatalogProductFragmentDoc = gql`
  fragment OrderViewLineItemTable__CatalogProduct on CatalogProduct {
    ...OrderViewLineItemTableRow__CatalogProduct
  }
  ${OrderViewLineItemTableRow__CatalogProductFragmentDoc}
`;
export const OrderViewLineItemTable__ProductFragmentDoc = gql`
  fragment OrderViewLineItemTable__Product on Product {
    ...OrderViewLineItemTableRow__Product
    variants {
      ...OrderViewLineItemTable__ProductVariant
    }
    companyCatalogProducts {
      ...OrderViewLineItemTable__CatalogProduct
    }
  }
  ${OrderViewLineItemTableRow__ProductFragmentDoc}
  ${OrderViewLineItemTable__ProductVariantFragmentDoc}
  ${OrderViewLineItemTable__CatalogProductFragmentDoc}
`;
export const OrderViewLineItemsTableLineItemFragmentDoc = gql`
  fragment OrderViewLineItemsTableLineItem on LineItem {
    id
    ...OrderViewLineItemTableRowLineItem
    ...OrderViewTotalPrice__LineItem
    product {
      ...OrderViewLineItemTable__Product
    }
  }
  ${OrderViewLineItemTableRowLineItemFragmentDoc}
  ${OrderViewTotalPrice__LineItemFragmentDoc}
  ${OrderViewLineItemTable__ProductFragmentDoc}
`;
export const OrderViewLineItems__ProductVariantFragmentDoc = gql`
  fragment OrderViewLineItems__ProductVariant on ProductVariant {
    ...OrderViewLineItemTable__ProductVariant
  }
  ${OrderViewLineItemTable__ProductVariantFragmentDoc}
`;
export const OrderViewLineItems__CatalogProductFragmentDoc = gql`
  fragment OrderViewLineItems__CatalogProduct on CatalogProduct {
    ...OrderViewLineItemTable__CatalogProduct
  }
  ${OrderViewLineItemTable__CatalogProductFragmentDoc}
`;
export const OrderViewLineItems__ProductFragmentDoc = gql`
  fragment OrderViewLineItems__Product on Product {
    ...OrderViewLineItemTable__Product
    variants {
      ...OrderViewLineItems__ProductVariant
    }
    companyCatalogProducts {
      ...OrderViewLineItems__CatalogProduct
    }
  }
  ${OrderViewLineItemTable__ProductFragmentDoc}
  ${OrderViewLineItems__ProductVariantFragmentDoc}
  ${OrderViewLineItems__CatalogProductFragmentDoc}
`;
export const OrderViewLineItemsLineItemFragmentDoc = gql`
  fragment OrderViewLineItemsLineItem on LineItem {
    ...OrderViewLineItemsTableLineItem
    product {
      ...OrderViewLineItems__Product
    }
  }
  ${OrderViewLineItemsTableLineItemFragmentDoc}
  ${OrderViewLineItems__ProductFragmentDoc}
`;
export const OrderProductRegularLineItemFormLineItemFragmentDoc = gql`
  fragment OrderProductRegularLineItemFormLineItem on LineItem {
    description
    variantId
  }
`;
export const OrderProductRegularLineItemRow__LineItemFragmentDoc = gql`
  fragment OrderProductRegularLineItemRow__LineItem on LineItem {
    displayName
    ...OrderProductRegularLineItemFormLineItem
  }
  ${OrderProductRegularLineItemFormLineItemFragmentDoc}
`;
export const OrderProductRegularLineItemTable__LineItemFragmentDoc = gql`
  fragment OrderProductRegularLineItemTable__LineItem on LineItem {
    ...OrderProductRegularLineItemRow__LineItem
  }
  ${OrderProductRegularLineItemRow__LineItemFragmentDoc}
`;
export const OrderProductPopupForm__LineItemFragmentDoc = gql`
  fragment OrderProductPopupForm__LineItem on LineItem {
    ...OrderProductRegularLineItemTable__LineItem
  }
  ${OrderProductRegularLineItemTable__LineItemFragmentDoc}
`;
export const OrderViewLineItemUpdatePopup__LineItemFragmentDoc = gql`
  fragment OrderViewLineItemUpdatePopup__LineItem on LineItem {
    productId
    variantId
    quantity
    note
    price
    displayName
    description
    displaySize
    discount
    requisitionLineItem {
      requisitionId
    }
    ...OrderProductPopupForm__LineItem
  }
  ${OrderProductPopupForm__LineItemFragmentDoc}
`;
export const UseOrderLineItemEditLineItemFragmentDoc = gql`
  fragment UseOrderLineItemEditLineItem on LineItem {
    id
    ...OrderViewLineItemUpdatePopup__LineItem
  }
  ${OrderViewLineItemUpdatePopup__LineItemFragmentDoc}
`;
export const UseLineItemManagementMenuLineItemFragmentDoc = gql`
  fragment UseLineItemManagementMenuLineItem on LineItem {
    id
  }
`;
export const OrderViewAcceptShipment__ProductVariantFragmentDoc = gql`
  fragment OrderViewAcceptShipment__ProductVariant on ProductVariant {
    id
    color
  }
`;
export const OrderViewAcceptShipment__ProductFragmentDoc = gql`
  fragment OrderViewAcceptShipment__Product on Product {
    id
    variants {
      ...OrderViewAcceptShipment__ProductVariant
    }
  }
  ${OrderViewAcceptShipment__ProductVariantFragmentDoc}
`;
export const OrderViewAcceptShipment__LineItemFragmentDoc = gql`
  fragment OrderViewAcceptShipment__LineItem on LineItem {
    id
    displayImage
    quantity
    variantId
    note
    displayName
    displayVariantName
    displaySize
    description
    productId
    priceTotalWithVatFormatted
    product {
      ...OrderViewAcceptShipment__Product
    }
  }
  ${OrderViewAcceptShipment__ProductFragmentDoc}
`;
export const OrderProductCategoriesProductFragmentDoc = gql`
  fragment OrderProductCategoriesProduct on Product {
    categoryIds
  }
`;
export const OrderProductRegularLineItemFormProductFragmentDoc = gql`
  fragment OrderProductRegularLineItemFormProduct on Product {
    id
    attributes {
      ...ProductsAddPopupProductsTableProductFormAttributes__ProductAttribute
    }
    variants {
      id
      name
    }
    companyCatalogProducts {
      id
      companyId
      variantIds
    }
  }
  ${ProductsAddPopupProductsTableProductFormAttributes__ProductAttributeFragmentDoc}
`;
export const OrderProductRegularLineItemRowProductFragmentDoc = gql`
  fragment OrderProductRegularLineItemRowProduct on Product {
    primaryImage
    name
    variants {
      id
    }
    ...OrderProductRegularLineItemFormProduct
  }
  ${OrderProductRegularLineItemFormProductFragmentDoc}
`;
export const OrderProductRegularLineItemTableProductFragmentDoc = gql`
  fragment OrderProductRegularLineItemTableProduct on Product {
    ...OrderProductRegularLineItemRowProduct
  }
  ${OrderProductRegularLineItemRowProductFragmentDoc}
`;
export const OrderProductPopupForm__ProductFragmentDoc = gql`
  fragment OrderProductPopupForm__Product on Product {
    ...OrderProductRegularLineItemTableProduct
  }
  ${OrderProductRegularLineItemTableProductFragmentDoc}
`;
export const OrderViewLineItemUpdateProductFragmentDoc = gql`
  fragment OrderViewLineItemUpdateProduct on Product {
    ...OrderProductCategoriesProduct
    ...OrderProductPopupForm__Product
    companyCatalogProducts {
      id
      companyId
      variantIds
    }
  }
  ${OrderProductCategoriesProductFragmentDoc}
  ${OrderProductPopupForm__ProductFragmentDoc}
`;
export const OrderView__ProductVariantFragmentDoc = gql`
  fragment OrderView__ProductVariant on ProductVariant {
    id
    ...OrderViewAcceptShipment__ProductVariant
    ...OrderViewLineItems__ProductVariant
  }
  ${OrderViewAcceptShipment__ProductVariantFragmentDoc}
  ${OrderViewLineItems__ProductVariantFragmentDoc}
`;
export const OrderView__CatalogProductFragmentDoc = gql`
  fragment OrderView__CatalogProduct on CatalogProduct {
    id
    ...OrderViewLineItems__CatalogProduct
  }
  ${OrderViewLineItems__CatalogProductFragmentDoc}
`;
export const OrderView__ProductFragmentDoc = gql`
  fragment OrderView__Product on Product {
    ...OrderViewLineItemUpdateProduct
    ...OrderViewAcceptShipment__Product
    ...OrderViewLineItems__Product
    variants {
      ...OrderView__ProductVariant
    }
    companyCatalogProducts {
      ...OrderView__CatalogProduct
    }
  }
  ${OrderViewLineItemUpdateProductFragmentDoc}
  ${OrderViewAcceptShipment__ProductFragmentDoc}
  ${OrderViewLineItems__ProductFragmentDoc}
  ${OrderView__ProductVariantFragmentDoc}
  ${OrderView__CatalogProductFragmentDoc}
`;
export const OrderView__LineItemFragmentDoc = gql`
  fragment OrderView__LineItem on LineItem {
    ...OrderViewLineItemsLineItem
    ...UseOrderLineItemEditLineItem
    ...UseLineItemManagementMenuLineItem
    ...UpdateCustomLineItemOptimisticResponse__LineItemRead
    ...UpdateProductLineItemOptimisticResponse__LineItemRead
    ...OrderViewAcceptShipment__LineItem
    product {
      ...OrderView__Product
    }
  }
  ${OrderViewLineItemsLineItemFragmentDoc}
  ${UseOrderLineItemEditLineItemFragmentDoc}
  ${UseLineItemManagementMenuLineItemFragmentDoc}
  ${UpdateCustomLineItemOptimisticResponse__LineItemReadFragmentDoc}
  ${UpdateProductLineItemOptimisticResponse__LineItemReadFragmentDoc}
  ${OrderViewAcceptShipment__LineItemFragmentDoc}
  ${OrderView__ProductFragmentDoc}
`;
export const SupplierSelectPopup__SupplierFragmentDoc = gql`
  fragment SupplierSelectPopup__Supplier on Supplier {
    ...SupplierSingleInputSupplier
  }
  ${SupplierSingleInputSupplierFragmentDoc}
`;
export const ProductsAddPopup__SupplierFragmentDoc = gql`
  fragment ProductsAddPopup__Supplier on Supplier {
    id
    name
  }
`;
export const OrderView__SupplierFragmentDoc = gql`
  fragment OrderView__Supplier on Supplier {
    ...OrderViewInfo__Supplier
    ...SupplierSelectPopup__Supplier
    ...ProductsAddPopup__Supplier
  }
  ${OrderViewInfo__SupplierFragmentDoc}
  ${SupplierSelectPopup__SupplierFragmentDoc}
  ${ProductsAddPopup__SupplierFragmentDoc}
`;
export const OrderView__OrderMetadataFragmentDoc = gql`
  fragment OrderView__OrderMetadata on OrderMetadata {
    ...OrderViewControls__OrderMetadata
    ...OrderViewInfo__OrderMetadata
    ...OrderViewLineItems__OrderMetadata
  }
  ${OrderViewControls__OrderMetadataFragmentDoc}
  ${OrderViewInfo__OrderMetadataFragmentDoc}
  ${OrderViewLineItems__OrderMetadataFragmentDoc}
`;
export const OrderView__AttachmentFragmentDoc = gql`
  fragment OrderView__Attachment on Attachment {
    ...OrderViewInfo__Attachment
  }
  ${OrderViewInfo__AttachmentFragmentDoc}
`;
export const OrderViewTabsShipmentFragmentDoc = gql`
  fragment OrderViewTabsShipment on Shipment {
    id
  }
`;
export const OrderViewShipmentsTableRowItemsTable__LineItemFragmentDoc = gql`
  fragment OrderViewShipmentsTableRowItemsTable__LineItem on LineItem {
    id
    displayImage
    displayName
    displayVariantName
    displaySize
    price
    discount
  }
`;
export const OrderViewShipmentsTableRowItemsTable__ShipmentLineItemFragmentDoc = gql`
  fragment OrderViewShipmentsTableRowItemsTable__ShipmentLineItem on ShipmentLineItem {
    id
    shippedQuantity
    lineItem {
      ...OrderViewShipmentsTableRowItemsTable__LineItem
    }
  }
  ${OrderViewShipmentsTableRowItemsTable__LineItemFragmentDoc}
`;
export const OrderViewShipmentsTableRow__ShipmentLineItemLineItemFragmentDoc = gql`
  fragment OrderViewShipmentsTableRow__ShipmentLineItemLineItem on LineItem {
    priceWithDiscount
    ...OrderViewShipmentsTableRowItemsTable__LineItem
  }
  ${OrderViewShipmentsTableRowItemsTable__LineItemFragmentDoc}
`;
export const OrderViewShipmentsTableRow__ShipmentLineItemFragmentDoc = gql`
  fragment OrderViewShipmentsTableRow__ShipmentLineItem on ShipmentLineItem {
    shippedQuantity
    ...OrderViewShipmentsTableRowItemsTable__ShipmentLineItem
    lineItem {
      ...OrderViewShipmentsTableRow__ShipmentLineItemLineItem
    }
  }
  ${OrderViewShipmentsTableRowItemsTable__ShipmentLineItemFragmentDoc}
  ${OrderViewShipmentsTableRow__ShipmentLineItemLineItemFragmentDoc}
`;
export const OrderViewShipmentsTableRow__ShipmentCertificateFragmentDoc = gql`
  fragment OrderViewShipmentsTableRow__ShipmentCertificate on ShipmentCertificate {
    id
    url
  }
`;
export const OrderViewShipmentsTableRowShipmentFragmentDoc = gql`
  fragment OrderViewShipmentsTableRowShipment on Shipment {
    id
    orderId
    approvedBy {
      name
    }
    deliveryDate
    lineItems {
      ...OrderViewShipmentsTableRow__ShipmentLineItem
    }
    certificates {
      ...OrderViewShipmentsTableRow__ShipmentCertificate
    }
  }
  ${OrderViewShipmentsTableRow__ShipmentLineItemFragmentDoc}
  ${OrderViewShipmentsTableRow__ShipmentCertificateFragmentDoc}
`;
export const OrderViewShipmentsTable__ShipmentLineItemLineItemFragmentDoc = gql`
  fragment OrderViewShipmentsTable__ShipmentLineItemLineItem on LineItem {
    ...OrderViewShipmentsTableRow__ShipmentLineItemLineItem
  }
  ${OrderViewShipmentsTableRow__ShipmentLineItemLineItemFragmentDoc}
`;
export const OrderViewShipmentsTable__ShipmentLineItemFragmentDoc = gql`
  fragment OrderViewShipmentsTable__ShipmentLineItem on ShipmentLineItem {
    ...OrderViewShipmentsTableRow__ShipmentLineItem
    lineItem {
      ...OrderViewShipmentsTable__ShipmentLineItemLineItem
    }
  }
  ${OrderViewShipmentsTableRow__ShipmentLineItemFragmentDoc}
  ${OrderViewShipmentsTable__ShipmentLineItemLineItemFragmentDoc}
`;
export const OrderViewShipmentsTable__ShipmentCertificateFragmentDoc = gql`
  fragment OrderViewShipmentsTable__ShipmentCertificate on ShipmentCertificate {
    ...OrderViewShipmentsTableRow__ShipmentCertificate
  }
  ${OrderViewShipmentsTableRow__ShipmentCertificateFragmentDoc}
`;
export const OrderViewShipmentsTableShipmentFragmentDoc = gql`
  fragment OrderViewShipmentsTableShipment on Shipment {
    ...OrderViewShipmentsTableRowShipment
    lineItems {
      ...OrderViewShipmentsTable__ShipmentLineItem
    }
    certificates {
      ...OrderViewShipmentsTable__ShipmentCertificate
    }
  }
  ${OrderViewShipmentsTableRowShipmentFragmentDoc}
  ${OrderViewShipmentsTable__ShipmentLineItemFragmentDoc}
  ${OrderViewShipmentsTable__ShipmentCertificateFragmentDoc}
`;
export const OrderViewShipments__ShipmentLineItemLineItemFragmentDoc = gql`
  fragment OrderViewShipments__ShipmentLineItemLineItem on LineItem {
    vat
    priceWithDiscount
    ...OrderViewShipmentsTable__ShipmentLineItemLineItem
  }
  ${OrderViewShipmentsTable__ShipmentLineItemLineItemFragmentDoc}
`;
export const OrderViewShipments__ShipmentLineItemFragmentDoc = gql`
  fragment OrderViewShipments__ShipmentLineItem on ShipmentLineItem {
    ...OrderViewShipmentsTable__ShipmentLineItem
    lineItem {
      ...OrderViewShipments__ShipmentLineItemLineItem
    }
  }
  ${OrderViewShipmentsTable__ShipmentLineItemFragmentDoc}
  ${OrderViewShipments__ShipmentLineItemLineItemFragmentDoc}
`;
export const OrderViewShipments__ShipmentCertificateFragmentDoc = gql`
  fragment OrderViewShipments__ShipmentCertificate on ShipmentCertificate {
    ...OrderViewShipmentsTable__ShipmentCertificate
  }
  ${OrderViewShipmentsTable__ShipmentCertificateFragmentDoc}
`;
export const OrderViewShipmentsShipmentFragmentDoc = gql`
  fragment OrderViewShipmentsShipment on Shipment {
    ...OrderViewShipmentsTableShipment
    lineItems {
      ...OrderViewShipments__ShipmentLineItem
    }
    certificates {
      ...OrderViewShipments__ShipmentCertificate
    }
  }
  ${OrderViewShipmentsTableShipmentFragmentDoc}
  ${OrderViewShipments__ShipmentLineItemFragmentDoc}
  ${OrderViewShipments__ShipmentCertificateFragmentDoc}
`;
export const OrderViewAcceptShipment__ShipmentLineItemLineItemFragmentDoc = gql`
  fragment OrderViewAcceptShipment__ShipmentLineItemLineItem on LineItem {
    id
  }
`;
export const OrderViewAcceptShipment__ShipmentLineItemFragmentDoc = gql`
  fragment OrderViewAcceptShipment__ShipmentLineItem on ShipmentLineItem {
    id
    shippedQuantity
    lineItem {
      ...OrderViewAcceptShipment__ShipmentLineItemLineItem
    }
  }
  ${OrderViewAcceptShipment__ShipmentLineItemLineItemFragmentDoc}
`;
export const OrderViewAcceptShipment__ShipmentCertificateFragmentDoc = gql`
  fragment OrderViewAcceptShipment__ShipmentCertificate on ShipmentCertificate {
    id
    url
  }
`;
export const OrderViewAcceptShipment__ShipmentFragmentDoc = gql`
  fragment OrderViewAcceptShipment__Shipment on Shipment {
    id
    lineItems {
      ...OrderViewAcceptShipment__ShipmentLineItem
    }
    certificates {
      ...OrderViewAcceptShipment__ShipmentCertificate
    }
  }
  ${OrderViewAcceptShipment__ShipmentLineItemFragmentDoc}
  ${OrderViewAcceptShipment__ShipmentCertificateFragmentDoc}
`;
export const OrderViewLineItemsTable__ShipmentLineItemFragmentDoc = gql`
  fragment OrderViewLineItemsTable__ShipmentLineItem on ShipmentLineItem {
    id
    shippedQuantity
    lineItem {
      id
    }
  }
`;
export const OrderViewLineItems__ShipmentLineItemFragmentDoc = gql`
  fragment OrderViewLineItems__ShipmentLineItem on ShipmentLineItem {
    ...OrderViewLineItemsTable__ShipmentLineItem
  }
  ${OrderViewLineItemsTable__ShipmentLineItemFragmentDoc}
`;
export const OrderView__ShipmentLineItemLineItemFragmentDoc = gql`
  fragment OrderView__ShipmentLineItemLineItem on LineItem {
    ...OrderViewAcceptShipment__ShipmentLineItemLineItem
    ...OrderViewShipments__ShipmentLineItemLineItem
  }
  ${OrderViewAcceptShipment__ShipmentLineItemLineItemFragmentDoc}
  ${OrderViewShipments__ShipmentLineItemLineItemFragmentDoc}
`;
export const OrderView__ShipmentLineItemFragmentDoc = gql`
  fragment OrderView__ShipmentLineItem on ShipmentLineItem {
    ...OrderViewShipments__ShipmentLineItem
    ...OrderViewLineItems__ShipmentLineItem
    ...OrderViewAcceptShipment__ShipmentLineItem
    lineItem {
      ...OrderView__ShipmentLineItemLineItem
    }
  }
  ${OrderViewShipments__ShipmentLineItemFragmentDoc}
  ${OrderViewLineItems__ShipmentLineItemFragmentDoc}
  ${OrderViewAcceptShipment__ShipmentLineItemFragmentDoc}
  ${OrderView__ShipmentLineItemLineItemFragmentDoc}
`;
export const OrderViewShipmentFragmentDoc = gql`
  fragment OrderViewShipment on Shipment {
    ...OrderViewTabsShipment
    ...OrderViewShipmentsShipment
    ...OrderViewAcceptShipment__Shipment
    lineItems {
      ...OrderView__ShipmentLineItem
    }
  }
  ${OrderViewTabsShipmentFragmentDoc}
  ${OrderViewShipmentsShipmentFragmentDoc}
  ${OrderViewAcceptShipment__ShipmentFragmentDoc}
  ${OrderView__ShipmentLineItemFragmentDoc}
`;
export const OrderView__OrderFragmentDoc = gql`
  fragment OrderView__Order on Order {
    id
    status
    ...OrderViewHeader__Order
    ...OrderViewInfo__Order
    ...OrderViewControls__Order
    ...OrderViewShipments__Order
    ...OrderViewLineItems__Order
    ...OrderViewTotalPrice__Order
    project {
      ...OrderView__Project
    }
    lineItems {
      ...OrderView__LineItem
    }
    supplier {
      ...OrderView__Supplier
    }
    metadata {
      ...OrderView__OrderMetadata
    }
    attachments {
      ...OrderView__Attachment
    }
    shipments {
      ...OrderViewShipment
    }
  }
  ${OrderViewHeader__OrderFragmentDoc}
  ${OrderViewInfo__OrderFragmentDoc}
  ${OrderViewControls__OrderFragmentDoc}
  ${OrderViewShipments__OrderFragmentDoc}
  ${OrderViewLineItems__OrderFragmentDoc}
  ${OrderViewTotalPrice__OrderFragmentDoc}
  ${OrderView__ProjectFragmentDoc}
  ${OrderView__LineItemFragmentDoc}
  ${OrderView__SupplierFragmentDoc}
  ${OrderView__OrderMetadataFragmentDoc}
  ${OrderView__AttachmentFragmentDoc}
  ${OrderViewShipmentFragmentDoc}
`;
export const OrderView__ShipmentCertificateFragmentDoc = gql`
  fragment OrderView__ShipmentCertificate on ShipmentCertificate {
    ...OrderViewAcceptShipment__ShipmentCertificate
    ...OrderViewShipments__ShipmentCertificate
  }
  ${OrderViewAcceptShipment__ShipmentCertificateFragmentDoc}
  ${OrderViewShipments__ShipmentCertificateFragmentDoc}
`;
export const OrderProductRegularLineItemForm__RequisitionLineItemFragmentDoc = gql`
  fragment OrderProductRegularLineItemForm__RequisitionLineItem on RequisitionLineItem {
    description
    variantId
  }
`;
export const OrderProductRegularLineItemRow__RequisitionLineItemFragmentDoc = gql`
  fragment OrderProductRegularLineItemRow__RequisitionLineItem on RequisitionLineItem {
    displayName
    ...OrderProductRegularLineItemForm__RequisitionLineItem
  }
  ${OrderProductRegularLineItemForm__RequisitionLineItemFragmentDoc}
`;
export const OrderProductRegularLineItemTable__RequisitionLineItemFragmentDoc = gql`
  fragment OrderProductRegularLineItemTable__RequisitionLineItem on RequisitionLineItem {
    ...OrderProductRegularLineItemRow__RequisitionLineItem
  }
  ${OrderProductRegularLineItemRow__RequisitionLineItemFragmentDoc}
`;
export const OrderProductPopupForm__RequisitionLineItemFragmentDoc = gql`
  fragment OrderProductPopupForm__RequisitionLineItem on RequisitionLineItem {
    ...OrderProductRegularLineItemTable__RequisitionLineItem
  }
  ${OrderProductRegularLineItemTable__RequisitionLineItemFragmentDoc}
`;
export const OrderViewLineItemUpdatePopup__RequisitionLineItemFragmentDoc = gql`
  fragment OrderViewLineItemUpdatePopup__RequisitionLineItem on RequisitionLineItem {
    productId
    variantId
    quantity
    note
    price
    supplierId
    displayName
    description
    displaySize
    discount
    orderLineItem {
      orderId
    }
    orderItemChanged
    ...OrderProductPopupForm__RequisitionLineItem
  }
  ${OrderProductPopupForm__RequisitionLineItemFragmentDoc}
`;
export const OrderProductCategoriesCategoryFragmentDoc = gql`
  fragment OrderProductCategoriesCategory on Category {
    id
    name
    children {
      id
      name
      children {
        id
        name
        children {
          id
          name
          children {
            id
            name
            children {
              id
              name
              children {
                id
                name
                children {
                  id
                  name
                }
              }
            }
          }
        }
      }
    }
  }
`;
export const OrderViewLineItemUpdateCategoryFragmentDoc = gql`
  fragment OrderViewLineItemUpdateCategory on Category {
    ...OrderProductCategoriesCategory
  }
  ${OrderProductCategoriesCategoryFragmentDoc}
`;
export const SupplierInputProductSupplierPriceFragmentDoc = gql`
  fragment SupplierInputProductSupplierPrice on ProductSupplierPrice {
    id
    supplierId
    supplierName
    price
    priceFormatted
  }
`;
export const OrderProductRegularLineItemFormSupplierInputProductSupplierPriceFragmentDoc = gql`
  fragment OrderProductRegularLineItemFormSupplierInputProductSupplierPrice on ProductSupplierPrice {
    price
    ...SupplierInputProductSupplierPrice
  }
  ${SupplierInputProductSupplierPriceFragmentDoc}
`;
export const OrderProductRegularLineItemFormSupplierFieldProductSupplierPriceFragmentDoc = gql`
  fragment OrderProductRegularLineItemFormSupplierFieldProductSupplierPrice on ProductSupplierPrice {
    ...OrderProductRegularLineItemFormSupplierInputProductSupplierPrice
  }
  ${OrderProductRegularLineItemFormSupplierInputProductSupplierPriceFragmentDoc}
`;
export const OrderProductRegularLineItemFormProductSupplierPriceFragmentDoc = gql`
  fragment OrderProductRegularLineItemFormProductSupplierPrice on ProductSupplierPrice {
    ...OrderProductRegularLineItemFormSupplierFieldProductSupplierPrice
  }
  ${OrderProductRegularLineItemFormSupplierFieldProductSupplierPriceFragmentDoc}
`;
export const OrderProductRegularLineItemRowProductSupplierPriceFragmentDoc = gql`
  fragment OrderProductRegularLineItemRowProductSupplierPrice on ProductSupplierPrice {
    ...OrderProductRegularLineItemFormProductSupplierPrice
  }
  ${OrderProductRegularLineItemFormProductSupplierPriceFragmentDoc}
`;
export const OrderProductRegularLineItemTableProductSupplierPriceFragmentDoc = gql`
  fragment OrderProductRegularLineItemTableProductSupplierPrice on ProductSupplierPrice {
    ...OrderProductRegularLineItemRowProductSupplierPrice
  }
  ${OrderProductRegularLineItemRowProductSupplierPriceFragmentDoc}
`;
export const OrderProductPopupForm__ProductSupplierPriceFragmentDoc = gql`
  fragment OrderProductPopupForm__ProductSupplierPrice on ProductSupplierPrice {
    ...OrderProductRegularLineItemTableProductSupplierPrice
  }
  ${OrderProductRegularLineItemTableProductSupplierPriceFragmentDoc}
`;
export const OrderViewLineItemUpdatePopupProductSupplierPriceFragmentDoc = gql`
  fragment OrderViewLineItemUpdatePopupProductSupplierPrice on ProductSupplierPrice {
    ...OrderProductPopupForm__ProductSupplierPrice
  }
  ${OrderProductPopupForm__ProductSupplierPriceFragmentDoc}
`;
export const OrderViewInfo__LineItemFragmentDoc = gql`
  fragment OrderViewInfo__LineItem on LineItem {
    productId
    variantId
  }
`;
export const OrderLineItemProductUpdate__OrderLineItemFragmentDoc = gql`
  fragment OrderLineItemProductUpdate__OrderLineItem on LineItem {
    id
    note
    quantity
    variantId
    displayVariantName
    orderId
    ...LineItemPrices
  }
  ${LineItemPricesFragmentDoc}
`;
export const OrderLineItemQuantityUpdateOptimisticResponseRead__LineItemFragmentDoc = gql`
  fragment OrderLineItemQuantityUpdateOptimisticResponseRead__LineItem on LineItem {
    id
    orderId
    vat
    price
    discount
    __typename
  }
`;
export const OrderLineItemUpdateDiscountOptimisticResponseRead__LineItemFragmentDoc = gql`
  fragment OrderLineItemUpdateDiscountOptimisticResponseRead__LineItem on LineItem {
    id
    vat
    quantity
    price
    __typename
  }
`;
export const OrderLineItemUpdatePriceOptimisticResponseRead__LineItemFragmentDoc = gql`
  fragment OrderLineItemUpdatePriceOptimisticResponseRead__LineItem on LineItem {
    id
    vat
    quantity
    discount
    __typename
  }
`;
export const UseOrderLineItemShowDeliveryStatusFragmentDoc = gql`
  fragment UseOrderLineItemShowDeliveryStatus on Order {
    status
  }
`;
export const OrderView__UpdateShipmentReadFragmentDoc = gql`
  fragment OrderView__UpdateShipmentRead on Shipment {
    id
    lineItems {
      id
    }
  }
`;
export const OrderUpdate__OrderFragmentDoc = gql`
  fragment OrderUpdate__Order on Order {
    id
    supplierId
    subCompanyId
    note
    status
    deliveryDate
    discount
    supplier {
      ...OrderView__Supplier
    }
    metadata {
      ...OrderView__OrderMetadata
    }
  }
  ${OrderView__SupplierFragmentDoc}
  ${OrderView__OrderMetadataFragmentDoc}
`;
export const LineItemsTotalPriceCell__LineItemFragmentDoc = gql`
  fragment LineItemsTotalPriceCell__LineItem on LineItem {
    id
    priceTotalWithDiscount
  }
`;
export const OrdersViewOrdersTable__LineItemFragmentDoc = gql`
  fragment OrdersViewOrdersTable__LineItem on LineItem {
    ...LineItemsTotalPriceCell__LineItem
  }
  ${LineItemsTotalPriceCell__LineItemFragmentDoc}
`;
export const GetOrderSupplierLabelOrderFragmentDoc = gql`
  fragment GetOrderSupplierLabelOrder on Order {
    supplier {
      id
      name
    }
  }
`;
export const OrdersViewOrdersTableOrderFragmentDoc = gql`
  fragment OrdersViewOrdersTableOrder on Order {
    id
    createdDate
    deliveryDate
    status
    supplierId
    discount
    subCompany {
      id
      name
    }
    lineItems {
      ...OrdersViewOrdersTable__LineItem
    }
    project {
      name
      company {
        name
      }
    }
    ...GetOrderSupplierLabelOrder
  }
  ${OrdersViewOrdersTable__LineItemFragmentDoc}
  ${GetOrderSupplierLabelOrderFragmentDoc}
`;
export const OrdersViewOrdersOrderFragmentDoc = gql`
  fragment OrdersViewOrdersOrder on Order {
    ...OrdersViewOrdersTableOrder
  }
  ${OrdersViewOrdersTableOrderFragmentDoc}
`;
export const OrdersViewOrdersPageInfoFragmentDoc = gql`
  fragment OrdersViewOrdersPageInfo on PaginationInfo {
    ...Pagination
  }
  ${PaginationFragmentDoc}
`;
export const OrderViewOrdersFragmentDoc = gql`
  fragment OrderViewOrders on OrdersFindManyOutput {
    items {
      ...OrdersViewOrdersOrder
    }
    pageInfo {
      totalItems
      ...OrdersViewOrdersPageInfo
    }
  }
  ${OrdersViewOrdersOrderFragmentDoc}
  ${OrdersViewOrdersPageInfoFragmentDoc}
`;
export const OrdersFiltersProjectFragmentDoc = gql`
  fragment OrdersFiltersProject on Project {
    id
    name
  }
`;
export const GetPersons__PersonFragmentDoc = gql`
  fragment GetPersons__Person on Person {
    id
    name
    email
    phoneNumber
    companyId
  }
`;
export const OrdersCreateFormProjectFragmentDoc = gql`
  fragment OrdersCreateFormProject on Project {
    id
    name
    active
    companyId
    subCompanyId
    persons {
      ...GetPersons__Person
    }
  }
  ${GetPersons__PersonFragmentDoc}
`;
export const OrdersCreatePopupProjectFragmentDoc = gql`
  fragment OrdersCreatePopupProject on Project {
    ...OrdersCreateFormProject
  }
  ${OrdersCreateFormProjectFragmentDoc}
`;
export const OrdersViewProjectFragmentDoc = gql`
  fragment OrdersViewProject on Project {
    ...OrdersFiltersProject
    ...OrdersCreatePopupProject
  }
  ${OrdersFiltersProjectFragmentDoc}
  ${OrdersCreatePopupProjectFragmentDoc}
`;
export const OrdersViewOrders__LineItemFragmentDoc = gql`
  fragment OrdersViewOrders__LineItem on LineItem {
    ...OrdersViewOrdersTable__LineItem
  }
  ${OrdersViewOrdersTable__LineItemFragmentDoc}
`;
export const OrdersView__LineItemFragmentDoc = gql`
  fragment OrdersView__LineItem on LineItem {
    ...OrdersViewOrders__LineItem
  }
  ${OrdersViewOrders__LineItemFragmentDoc}
`;
export const ProductForm__ProductSizeFragmentDoc = gql`
  fragment ProductForm__ProductSize on ProductSize {
    type
    unit
    amount
    width
    length
  }
`;
export const ProductForm__ProductSupplierFragmentDoc = gql`
  fragment ProductForm__ProductSupplier on ProductSupplier {
    id
    name
    manufacturer
    productId
    price
    status
    supplier
  }
`;
export const ProductForm__ProductVariantFragmentDoc = gql`
  fragment ProductForm__ProductVariant on ProductVariant {
    id
    name
    image
    color
    status
    size {
      ...ProductForm__ProductSize
    }
    supplierItems {
      ...ProductForm__ProductSupplier
    }
  }
  ${ProductForm__ProductSizeFragmentDoc}
  ${ProductForm__ProductSupplierFragmentDoc}
`;
export const ProductForm__ProductFragmentDoc = gql`
  fragment ProductForm__Product on Product {
    name
    isActive
    primaryImage
    attributes {
      attribute
      description
    }
    categoryIds
    images
    aka
    size {
      ...ProductForm__ProductSize
    }
    supplierItems {
      ...ProductForm__ProductSupplier
    }
    variants {
      ...ProductForm__ProductVariant
    }
  }
  ${ProductForm__ProductSizeFragmentDoc}
  ${ProductForm__ProductSupplierFragmentDoc}
  ${ProductForm__ProductVariantFragmentDoc}
`;
export const ProductView__ProductSizeFragmentDoc = gql`
  fragment ProductView__ProductSize on ProductSize {
    ...ProductForm__ProductSize
  }
  ${ProductForm__ProductSizeFragmentDoc}
`;
export const ProductView__ProductSupplierFragmentDoc = gql`
  fragment ProductView__ProductSupplier on ProductSupplier {
    ...ProductForm__ProductSupplier
  }
  ${ProductForm__ProductSupplierFragmentDoc}
`;
export const ProductView__ProductVariantFragmentDoc = gql`
  fragment ProductView__ProductVariant on ProductVariant {
    ...ProductForm__ProductVariant
    size {
      ...ProductView__ProductSize
    }
    supplierItems {
      ...ProductView__ProductSupplier
    }
  }
  ${ProductForm__ProductVariantFragmentDoc}
  ${ProductView__ProductSizeFragmentDoc}
  ${ProductView__ProductSupplierFragmentDoc}
`;
export const ProductView__ProductFragmentDoc = gql`
  fragment ProductView__Product on Product {
    id
    ...ProductForm__Product
    size {
      ...ProductView__ProductSize
    }
    supplierItems {
      ...ProductView__ProductSupplier
    }
    variants {
      ...ProductView__ProductVariant
    }
  }
  ${ProductForm__ProductFragmentDoc}
  ${ProductView__ProductSizeFragmentDoc}
  ${ProductView__ProductSupplierFragmentDoc}
  ${ProductView__ProductVariantFragmentDoc}
`;
export const ReportsViewShipmentsTableRowLineItemsTable__LineItemFragmentDoc = gql`
  fragment ReportsViewShipmentsTableRowLineItemsTable__LineItem on LineItem {
    displayImage
    displayName
    displayVariantName
    displaySize
    price
    discount
  }
`;
export const ReportsViewShipmentsTableRow__LineItemFragmentDoc = gql`
  fragment ReportsViewShipmentsTableRow__LineItem on LineItem {
    priceWithDiscount
    ...ReportsViewShipmentsTableRowLineItemsTable__LineItem
  }
  ${ReportsViewShipmentsTableRowLineItemsTable__LineItemFragmentDoc}
`;
export const ReportsViewShipmentsTable__SupplierFragmentDoc = gql`
  fragment ReportsViewShipmentsTable__Supplier on Supplier {
    name
  }
`;
export const ReportsViewShipmentsTable__OrderFragmentDoc = gql`
  fragment ReportsViewShipmentsTable__Order on Order {
    supplier {
      ...ReportsViewShipmentsTable__Supplier
    }
  }
  ${ReportsViewShipmentsTable__SupplierFragmentDoc}
`;
export const ReportsViewShipmentsTable__LineItemFragmentDoc = gql`
  fragment ReportsViewShipmentsTable__LineItem on LineItem {
    ...ReportsViewShipmentsTableRow__LineItem
    order {
      ...ReportsViewShipmentsTable__Order
    }
  }
  ${ReportsViewShipmentsTableRow__LineItemFragmentDoc}
  ${ReportsViewShipmentsTable__OrderFragmentDoc}
`;
export const ReportsViewShipments__SupplierFragmentDoc = gql`
  fragment ReportsViewShipments__Supplier on Supplier {
    ...ReportsViewShipmentsTable__Supplier
  }
  ${ReportsViewShipmentsTable__SupplierFragmentDoc}
`;
export const ReportsViewShipments__OrderFragmentDoc = gql`
  fragment ReportsViewShipments__Order on Order {
    id
    ...ReportsViewShipmentsTable__Order
    supplier {
      ...ReportsViewShipments__Supplier
    }
  }
  ${ReportsViewShipmentsTable__OrderFragmentDoc}
  ${ReportsViewShipments__SupplierFragmentDoc}
`;
export const ReportsViewShipments__LineItemFragmentDoc = gql`
  fragment ReportsViewShipments__LineItem on LineItem {
    vat
    ...ReportsViewShipmentsTable__LineItem
    order {
      ...ReportsViewShipments__Order
    }
  }
  ${ReportsViewShipmentsTable__LineItemFragmentDoc}
  ${ReportsViewShipments__OrderFragmentDoc}
`;
export const ReportsViewShipmentsTableRowLineItemsTable__ShipmentLineItemFragmentDoc = gql`
  fragment ReportsViewShipmentsTableRowLineItemsTable__ShipmentLineItem on ShipmentLineItem {
    id
    lineItem {
      ...ReportsViewShipmentsTableRowLineItemsTable__LineItem
    }
    shippedQuantity
  }
  ${ReportsViewShipmentsTableRowLineItemsTable__LineItemFragmentDoc}
`;
export const ReportsViewShipmentsTableRow__ShipmentLineItemFragmentDoc = gql`
  fragment ReportsViewShipmentsTableRow__ShipmentLineItem on ShipmentLineItem {
    shippedQuantity
    lineItem {
      ...ReportsViewShipmentsTableRow__LineItem
    }
    ...ReportsViewShipmentsTableRowLineItemsTable__ShipmentLineItem
  }
  ${ReportsViewShipmentsTableRow__LineItemFragmentDoc}
  ${ReportsViewShipmentsTableRowLineItemsTable__ShipmentLineItemFragmentDoc}
`;
export const ReportsViewShipmentsTable__ShipmentLineItemFragmentDoc = gql`
  fragment ReportsViewShipmentsTable__ShipmentLineItem on ShipmentLineItem {
    ...ReportsViewShipmentsTableRow__ShipmentLineItem
    lineItem {
      ...ReportsViewShipmentsTable__LineItem
    }
  }
  ${ReportsViewShipmentsTableRow__ShipmentLineItemFragmentDoc}
  ${ReportsViewShipmentsTable__LineItemFragmentDoc}
`;
export const ReportsViewShipments__ShipmentLineItemFragmentDoc = gql`
  fragment ReportsViewShipments__ShipmentLineItem on ShipmentLineItem {
    lineItem {
      ...ReportsViewShipments__LineItem
    }
    ...ReportsViewShipmentsTable__ShipmentLineItem
  }
  ${ReportsViewShipments__LineItemFragmentDoc}
  ${ReportsViewShipmentsTable__ShipmentLineItemFragmentDoc}
`;
export const ReportsViewShipmentsTableRow__ShipmentFragmentDoc = gql`
  fragment ReportsViewShipmentsTableRow__Shipment on Shipment {
    id
    order {
      id
      discount
      project {
        name
        company {
          id
          name
        }
      }
      subCompany {
        id
        name
      }
    }
    approvedBy {
      name
    }
    deliveryDate
    lineItems {
      ...ReportsViewShipmentsTableRow__ShipmentLineItem
    }
    certificates {
      url
    }
  }
  ${ReportsViewShipmentsTableRow__ShipmentLineItemFragmentDoc}
`;
export const ReportsViewShipmentsTable__ShipmentFragmentDoc = gql`
  fragment ReportsViewShipmentsTable__Shipment on Shipment {
    ...ReportsViewShipmentsTableRow__Shipment
    lineItems {
      ...ReportsViewShipmentsTable__ShipmentLineItem
    }
  }
  ${ReportsViewShipmentsTableRow__ShipmentFragmentDoc}
  ${ReportsViewShipmentsTable__ShipmentLineItemFragmentDoc}
`;
export const ReportsViewShipments__ShipmentFragmentDoc = gql`
  fragment ReportsViewShipments__Shipment on Shipment {
    lineItems {
      ...ReportsViewShipments__ShipmentLineItem
    }
    ...ReportsViewShipmentsTable__Shipment
  }
  ${ReportsViewShipments__ShipmentLineItemFragmentDoc}
  ${ReportsViewShipmentsTable__ShipmentFragmentDoc}
`;
export const ReportsViewShipments__FindManyOutputFragmentDoc = gql`
  fragment ReportsViewShipments__FindManyOutput on ShipmentsFindManyOutput {
    totalPrice
    isAnyLineItemHasNoPrice
    items {
      ...ReportsViewShipments__Shipment
    }
    pageInfo {
      ...Pagination
    }
  }
  ${ReportsViewShipments__ShipmentFragmentDoc}
  ${PaginationFragmentDoc}
`;
export const ReportsViewShipmentsTableFilters__ProjectFragmentDoc = gql`
  fragment ReportsViewShipmentsTableFilters__Project on Project {
    id
    name
  }
`;
export const ReportsViewShipmentsTableFilters__SupplierFragmentDoc = gql`
  fragment ReportsViewShipmentsTableFilters__Supplier on Supplier {
    id
    name
  }
`;
export const ReportsViewShipmentsTableFilters__CompanyFragmentDoc = gql`
  fragment ReportsViewShipmentsTableFilters__Company on Company {
    id
    name
  }
`;
export const ReportsViewShipmentsTableFilters__ProductFragmentDoc = gql`
  fragment ReportsViewShipmentsTableFilters__Product on Product {
    id
    name
  }
`;
export const RequisitionViewHeader__ProjectFragmentDoc = gql`
  fragment RequisitionViewHeader__Project on Project {
    name
    geoLocation
  }
`;
export const RequisitionViewHeader__RequisitionFragmentDoc = gql`
  fragment RequisitionViewHeader__Requisition on Requisition {
    id
    status
    project {
      ...RequisitionViewHeader__Project
    }
  }
  ${RequisitionViewHeader__ProjectFragmentDoc}
`;
export const RequisitionViewInfoSummary__RequisitionFragmentDoc = gql`
  fragment RequisitionViewInfoSummary__Requisition on Requisition {
    createdDate
    creator {
      name
      phoneNumber
    }
  }
`;
export const RequisitionViewInfo__AttachmentFragmentDoc = gql`
  fragment RequisitionViewInfo__Attachment on Attachment {
    ...CommentsSidebar__Attachment
  }
  ${CommentsSidebar__AttachmentFragmentDoc}
`;
export const RequisitionViewInfo__RequisitionFragmentDoc = gql`
  fragment RequisitionViewInfo__Requisition on Requisition {
    id
    note
    deliveryDate
    status
    ...RequisitionViewInfoSummary__Requisition
    attachments {
      ...RequisitionViewInfo__Attachment
    }
  }
  ${RequisitionViewInfoSummary__RequisitionFragmentDoc}
  ${RequisitionViewInfo__AttachmentFragmentDoc}
`;
export const RequisitionViewActions__ProjectFragmentDoc = gql`
  fragment RequisitionViewActions__Project on Project {
    id
    companyId
  }
`;
export const RequisitionViewActions__RequisitionFragmentDoc = gql`
  fragment RequisitionViewActions__Requisition on Requisition {
    project {
      ...RequisitionViewActions__Project
    }
  }
  ${RequisitionViewActions__ProjectFragmentDoc}
`;
export const RequisitionViewLineItemsTableSummary__RequisitionMetadataFragmentDoc = gql`
  fragment RequisitionViewLineItemsTableSummary__RequisitionMetadata on RequisitionMetadata {
    editableProperties
  }
`;
export const RequisitionViewLineItemsTableSummary__RequisitionFragmentDoc = gql`
  fragment RequisitionViewLineItemsTableSummary__Requisition on Requisition {
    id
    discount
    metadata {
      ...RequisitionViewLineItemsTableSummary__RequisitionMetadata
    }
  }
  ${RequisitionViewLineItemsTableSummary__RequisitionMetadataFragmentDoc}
`;
export const RequisitionViewLineItemsTableRow__SupplierFragmentDoc = gql`
  fragment RequisitionViewLineItemsTableRow__Supplier on Supplier {
    id
    name
  }
`;
export const RequisitionViewLineItemsTableRow__OrderLineItemFragmentDoc = gql`
  fragment RequisitionViewLineItemsTableRow__OrderLineItem on LineItem {
    orderId
  }
`;
export const RequisitionViewLineItemsTableRow__LineItemFragmentDoc = gql`
  fragment RequisitionViewLineItemsTableRow__LineItem on RequisitionLineItem {
    id
    displayImage
    displayName
    displayVariantName
    supplierId
    quantity
    displaySize
    price
    priceFormatted
    priceTotal
    priceTotalFormatted
    note
    productId
    variantId
    orderItemChanged
    discount
    priceTotalWithDiscountFormatted
    discountTotalAmountFormatted
    product {
      id
      variants {
        id
      }
      companyCatalogProducts {
        id
        variantIds
        companyId
      }
    }
    supplier {
      ...RequisitionViewLineItemsTableRow__Supplier
    }
    orderLineItem {
      ...RequisitionViewLineItemsTableRow__OrderLineItem
    }
  }
  ${RequisitionViewLineItemsTableRow__SupplierFragmentDoc}
  ${RequisitionViewLineItemsTableRow__OrderLineItemFragmentDoc}
`;
export const RequisitionViewLineItemsTableSummary__LineItemFragmentDoc = gql`
  fragment RequisitionViewLineItemsTableSummary__LineItem on RequisitionLineItem {
    quantity
    priceTotalWithDiscount
    vat
    supplierId
  }
`;
export const RequisitionViewLineItemsTable__SupplierFragmentDoc = gql`
  fragment RequisitionViewLineItemsTable__Supplier on Supplier {
    ...RequisitionViewLineItemsTableRow__Supplier
    ...SupplierSelectPopup__Supplier
  }
  ${RequisitionViewLineItemsTableRow__SupplierFragmentDoc}
  ${SupplierSelectPopup__SupplierFragmentDoc}
`;
export const RequisitionViewLineItemsTable__OrderLineItemFragmentDoc = gql`
  fragment RequisitionViewLineItemsTable__OrderLineItem on LineItem {
    orderId
    ...RequisitionViewLineItemsTableRow__OrderLineItem
  }
  ${RequisitionViewLineItemsTableRow__OrderLineItemFragmentDoc}
`;
export const RequisitionViewLineItemsTable__LineItemFragmentDoc = gql`
  fragment RequisitionViewLineItemsTable__LineItem on RequisitionLineItem {
    ...RequisitionViewLineItemsTableRow__LineItem
    ...RequisitionViewLineItemsTableSummary__LineItem
    description
    supplier {
      ...RequisitionViewLineItemsTable__Supplier
    }
    orderLineItem {
      ...RequisitionViewLineItemsTable__OrderLineItem
    }
  }
  ${RequisitionViewLineItemsTableRow__LineItemFragmentDoc}
  ${RequisitionViewLineItemsTableSummary__LineItemFragmentDoc}
  ${RequisitionViewLineItemsTable__SupplierFragmentDoc}
  ${RequisitionViewLineItemsTable__OrderLineItemFragmentDoc}
`;
export const RequisitionViewLineItemsTable__RequisitionFragmentDoc = gql`
  fragment RequisitionViewLineItemsTable__Requisition on Requisition {
    ...RequisitionViewLineItemsTableSummary__Requisition
    lineItems {
      ...RequisitionViewLineItemsTable__LineItem
    }
  }
  ${RequisitionViewLineItemsTableSummary__RequisitionFragmentDoc}
  ${RequisitionViewLineItemsTable__LineItemFragmentDoc}
`;
export const RequisitionsViewTableRow__ProjectFragmentDoc = gql`
  fragment RequisitionsViewTableRow__Project on Project {
    id
    name
  }
`;
export const RequisitionsViewTable__ProjectFragmentDoc = gql`
  fragment RequisitionsViewTable__Project on Project {
    ...RequisitionsViewTableRow__Project
  }
  ${RequisitionsViewTableRow__ProjectFragmentDoc}
`;
export const RequisitionsViewCreateRequisitionForm__ProjectFragmentDoc = gql`
  fragment RequisitionsViewCreateRequisitionForm__Project on Project {
    id
    name
    active
  }
`;
export const RequisitionsViewCreateRequisitionPopup__ProjectFragmentDoc = gql`
  fragment RequisitionsViewCreateRequisitionPopup__Project on Project {
    ...RequisitionsViewCreateRequisitionForm__Project
  }
  ${RequisitionsViewCreateRequisitionForm__ProjectFragmentDoc}
`;
export const RequisitionsView__ProjectFragmentDoc = gql`
  fragment RequisitionsView__Project on Project {
    ...RequisitionsViewTable__Project
    ...RequisitionsViewCreateRequisitionPopup__Project
  }
  ${RequisitionsViewTable__ProjectFragmentDoc}
  ${RequisitionsViewCreateRequisitionPopup__ProjectFragmentDoc}
`;
export const RequisitionView__SupplierFragmentDoc = gql`
  fragment RequisitionView__Supplier on Supplier {
    ...RequisitionViewLineItemsTable__Supplier
  }
  ${RequisitionViewLineItemsTable__SupplierFragmentDoc}
`;
export const RequisitionView__OrderLineItemFragmentDoc = gql`
  fragment RequisitionView__OrderLineItem on LineItem {
    ...RequisitionViewLineItemsTable__OrderLineItem
  }
  ${RequisitionViewLineItemsTable__OrderLineItemFragmentDoc}
`;
export const RequisitionView__LineItemFragmentDoc = gql`
  fragment RequisitionView__LineItem on RequisitionLineItem {
    vat
    requisitionId
    ...RequisitionViewLineItemsTable__LineItem
    ...UpdateCustomLineItemOptimisticResponse__RequisitionLineItemRead
    ...UpdateProductLineItemOptimisticResponse__RequisitionLineItemRead
    supplier {
      ...RequisitionView__Supplier
    }
    orderLineItem {
      ...RequisitionView__OrderLineItem
    }
  }
  ${RequisitionViewLineItemsTable__LineItemFragmentDoc}
  ${UpdateCustomLineItemOptimisticResponse__RequisitionLineItemReadFragmentDoc}
  ${UpdateProductLineItemOptimisticResponse__RequisitionLineItemReadFragmentDoc}
  ${RequisitionView__SupplierFragmentDoc}
  ${RequisitionView__OrderLineItemFragmentDoc}
`;
export const RequisitionView__AttachmentFragmentDoc = gql`
  fragment RequisitionView__Attachment on Attachment {
    ...RequisitionViewInfo__Attachment
  }
  ${RequisitionViewInfo__AttachmentFragmentDoc}
`;
export const RequisitionView__RequisitionFragmentDoc = gql`
  fragment RequisitionView__Requisition on Requisition {
    ...RequisitionViewHeader__Requisition
    ...RequisitionViewInfo__Requisition
    ...RequisitionViewActions__Requisition
    ...RequisitionViewLineItemsTable__Requisition
    project {
      ...RequisitionsView__Project
    }
    lineItems {
      ...RequisitionView__LineItem
    }
    attachments {
      ...RequisitionView__Attachment
    }
  }
  ${RequisitionViewHeader__RequisitionFragmentDoc}
  ${RequisitionViewInfo__RequisitionFragmentDoc}
  ${RequisitionViewActions__RequisitionFragmentDoc}
  ${RequisitionViewLineItemsTable__RequisitionFragmentDoc}
  ${RequisitionsView__ProjectFragmentDoc}
  ${RequisitionView__LineItemFragmentDoc}
  ${RequisitionView__AttachmentFragmentDoc}
`;
export const RequisitionView__ProjectFragmentDoc = gql`
  fragment RequisitionView__Project on Project {
    ...RequisitionViewHeader__Project
    ...RequisitionViewActions__Project
  }
  ${RequisitionViewHeader__ProjectFragmentDoc}
  ${RequisitionViewActions__ProjectFragmentDoc}
`;
export const RequisitionViewActions__RequisitionMetadataFragmentDoc = gql`
  fragment RequisitionViewActions__RequisitionMetadata on RequisitionMetadata {
    id
    canDelete
    canEditItems
    editableProperties
    nextPossibleStatuses
  }
`;
export const RequisitionViewInfo__RequisitionMetadataFragmentDoc = gql`
  fragment RequisitionViewInfo__RequisitionMetadata on RequisitionMetadata {
    id
    editableProperties
    canAttachFiles
  }
`;
export const RequisitionViewLineItemsTable__RequisitionMetadataFragmentDoc = gql`
  fragment RequisitionViewLineItemsTable__RequisitionMetadata on RequisitionMetadata {
    id
    canEditItems
    editableItemIds
    ...RequisitionViewLineItemsTableSummary__RequisitionMetadata
  }
  ${RequisitionViewLineItemsTableSummary__RequisitionMetadataFragmentDoc}
`;
export const RequisitionView__RequisitionMetadataFragmentDoc = gql`
  fragment RequisitionView__RequisitionMetadata on RequisitionMetadata {
    ...RequisitionViewActions__RequisitionMetadata
    ...RequisitionViewInfo__RequisitionMetadata
    ...RequisitionViewLineItemsTable__RequisitionMetadata
  }
  ${RequisitionViewActions__RequisitionMetadataFragmentDoc}
  ${RequisitionViewInfo__RequisitionMetadataFragmentDoc}
  ${RequisitionViewLineItemsTable__RequisitionMetadataFragmentDoc}
`;
export const RequisitionViewUpdateRead__RequisitionFragmentDoc = gql`
  fragment RequisitionViewUpdateRead__Requisition on Requisition {
    id
    status
    deliveryDate
    note
    discount
  }
`;
export const RequisitionsViewTableRow__LineItemFragmentDoc = gql`
  fragment RequisitionsViewTableRow__LineItem on RequisitionLineItem {
    id
    priceTotalWithDiscount
    supplierId
  }
`;
export const RequisitionsViewTableRow__RequisitionFragmentDoc = gql`
  fragment RequisitionsViewTableRow__Requisition on Requisition {
    id
    createdDate
    deliveryDate
    status
    discount
    project {
      ...RequisitionsViewTableRow__Project
    }
    lineItems {
      ...RequisitionsViewTableRow__LineItem
    }
  }
  ${RequisitionsViewTableRow__ProjectFragmentDoc}
  ${RequisitionsViewTableRow__LineItemFragmentDoc}
`;
export const RequisitionsViewTable__LineItemFragmentDoc = gql`
  fragment RequisitionsViewTable__LineItem on RequisitionLineItem {
    ...RequisitionsViewTableRow__LineItem
  }
  ${RequisitionsViewTableRow__LineItemFragmentDoc}
`;
export const RequisitionsViewTable__RequisitionFragmentDoc = gql`
  fragment RequisitionsViewTable__Requisition on Requisition {
    ...RequisitionsViewTableRow__Requisition
    lineItems {
      ...RequisitionsViewTable__LineItem
    }
    project {
      ...RequisitionsViewTable__Project
    }
  }
  ${RequisitionsViewTableRow__RequisitionFragmentDoc}
  ${RequisitionsViewTable__LineItemFragmentDoc}
  ${RequisitionsViewTable__ProjectFragmentDoc}
`;
export const RequisitionsView__LineItemFragmentDoc = gql`
  fragment RequisitionsView__LineItem on RequisitionLineItem {
    ...RequisitionsViewTable__LineItem
  }
  ${RequisitionsViewTable__LineItemFragmentDoc}
`;
export const RequisitionsView__RequisitionFragmentDoc = gql`
  fragment RequisitionsView__Requisition on Requisition {
    ...RequisitionsViewTable__Requisition
    lineItems {
      ...RequisitionsView__LineItem
    }
    project {
      ...RequisitionsView__Project
    }
  }
  ${RequisitionsViewTable__RequisitionFragmentDoc}
  ${RequisitionsView__LineItemFragmentDoc}
  ${RequisitionsView__ProjectFragmentDoc}
`;
export const RequisitionsView__FindManyOutputFragmentDoc = gql`
  fragment RequisitionsView__FindManyOutput on RequisitionsFindManyOutput {
    pageInfo {
      ...Pagination
    }
    items {
      ...RequisitionsView__Requisition
    }
  }
  ${PaginationFragmentDoc}
  ${RequisitionsView__RequisitionFragmentDoc}
`;
export const GetSubCompanies__SubCompanyFragmentDoc = gql`
  fragment GetSubCompanies__SubCompany on SubCompany {
    id
    name
    companyId
    localCompanyNumber
  }
`;
export const ProductsAddPopup__GetCategoriesDocument = gql`
  query ProductsAddPopup__GetCategories {
    categoriesFindMany {
      items {
        ...ProductsAddPopupCategory
      }
    }
  }
  ${ProductsAddPopupCategoryFragmentDoc}
`;

/**
 * __useProductsAddPopup__GetCategoriesQuery__
 *
 * To run a query within a React component, call `useProductsAddPopup__GetCategoriesQuery` and pass it any options that fit your needs.
 * When your component renders, `useProductsAddPopup__GetCategoriesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useProductsAddPopup__GetCategoriesQuery({
 *   variables: {
 *   },
 * });
 */
export function useProductsAddPopup__GetCategoriesQuery(
  baseOptions?: Apollo.QueryHookOptions<
    ProductsAddPopup__GetCategoriesQuery,
    ProductsAddPopup__GetCategoriesQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    ProductsAddPopup__GetCategoriesQuery,
    ProductsAddPopup__GetCategoriesQueryVariables
  >(ProductsAddPopup__GetCategoriesDocument, options);
}
export function useProductsAddPopup__GetCategoriesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    ProductsAddPopup__GetCategoriesQuery,
    ProductsAddPopup__GetCategoriesQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    ProductsAddPopup__GetCategoriesQuery,
    ProductsAddPopup__GetCategoriesQueryVariables
  >(ProductsAddPopup__GetCategoriesDocument, options);
}
export type ProductsAddPopup__GetCategoriesQueryHookResult = ReturnType<
  typeof useProductsAddPopup__GetCategoriesQuery
>;
export type ProductsAddPopup__GetCategoriesLazyQueryHookResult = ReturnType<
  typeof useProductsAddPopup__GetCategoriesLazyQuery
>;
export type ProductsAddPopup__GetCategoriesQueryResult = Apollo.QueryResult<
  ProductsAddPopup__GetCategoriesQuery,
  ProductsAddPopup__GetCategoriesQueryVariables
>;
export const ProductsAddPopup__GetProductsDocument = gql`
  query ProductsAddPopup__GetProducts(
    $page: Int!
    $perPage: Int!
    $filters: ProductsFindManyFiltersInput!
  ) {
    productsFindMany(
      pagination: { page: $page, perPage: $perPage }
      filters: $filters
    ) {
      items {
        ...ProductsAddPopupProduct
      }
      pageInfo {
        ...ProductsAddPopupPageInfo
      }
    }
  }
  ${ProductsAddPopupProductFragmentDoc}
  ${ProductsAddPopupPageInfoFragmentDoc}
`;

/**
 * __useProductsAddPopup__GetProductsQuery__
 *
 * To run a query within a React component, call `useProductsAddPopup__GetProductsQuery` and pass it any options that fit your needs.
 * When your component renders, `useProductsAddPopup__GetProductsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useProductsAddPopup__GetProductsQuery({
 *   variables: {
 *      page: // value for 'page'
 *      perPage: // value for 'perPage'
 *      filters: // value for 'filters'
 *   },
 * });
 */
export function useProductsAddPopup__GetProductsQuery(
  baseOptions: Apollo.QueryHookOptions<
    ProductsAddPopup__GetProductsQuery,
    ProductsAddPopup__GetProductsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    ProductsAddPopup__GetProductsQuery,
    ProductsAddPopup__GetProductsQueryVariables
  >(ProductsAddPopup__GetProductsDocument, options);
}
export function useProductsAddPopup__GetProductsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    ProductsAddPopup__GetProductsQuery,
    ProductsAddPopup__GetProductsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    ProductsAddPopup__GetProductsQuery,
    ProductsAddPopup__GetProductsQueryVariables
  >(ProductsAddPopup__GetProductsDocument, options);
}
export type ProductsAddPopup__GetProductsQueryHookResult = ReturnType<
  typeof useProductsAddPopup__GetProductsQuery
>;
export type ProductsAddPopup__GetProductsLazyQueryHookResult = ReturnType<
  typeof useProductsAddPopup__GetProductsLazyQuery
>;
export type ProductsAddPopup__GetProductsQueryResult = Apollo.QueryResult<
  ProductsAddPopup__GetProductsQuery,
  ProductsAddPopup__GetProductsQueryVariables
>;
export const SupplierFieldSupplierIdsProductLastOrderedFromDocument = gql`
  query SupplierFieldSupplierIdsProductLastOrderedFrom(
    $companyId: Int!
    $productId: Int!
  ) {
    supplierIdsGetProductLastOrderedFrom(
      companyId: $companyId
      productId: $productId
    )
  }
`;

/**
 * __useSupplierFieldSupplierIdsProductLastOrderedFromQuery__
 *
 * To run a query within a React component, call `useSupplierFieldSupplierIdsProductLastOrderedFromQuery` and pass it any options that fit your needs.
 * When your component renders, `useSupplierFieldSupplierIdsProductLastOrderedFromQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSupplierFieldSupplierIdsProductLastOrderedFromQuery({
 *   variables: {
 *      companyId: // value for 'companyId'
 *      productId: // value for 'productId'
 *   },
 * });
 */
export function useSupplierFieldSupplierIdsProductLastOrderedFromQuery(
  baseOptions: Apollo.QueryHookOptions<
    SupplierFieldSupplierIdsProductLastOrderedFromQuery,
    SupplierFieldSupplierIdsProductLastOrderedFromQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    SupplierFieldSupplierIdsProductLastOrderedFromQuery,
    SupplierFieldSupplierIdsProductLastOrderedFromQueryVariables
  >(SupplierFieldSupplierIdsProductLastOrderedFromDocument, options);
}
export function useSupplierFieldSupplierIdsProductLastOrderedFromLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    SupplierFieldSupplierIdsProductLastOrderedFromQuery,
    SupplierFieldSupplierIdsProductLastOrderedFromQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    SupplierFieldSupplierIdsProductLastOrderedFromQuery,
    SupplierFieldSupplierIdsProductLastOrderedFromQueryVariables
  >(SupplierFieldSupplierIdsProductLastOrderedFromDocument, options);
}
export type SupplierFieldSupplierIdsProductLastOrderedFromQueryHookResult =
  ReturnType<typeof useSupplierFieldSupplierIdsProductLastOrderedFromQuery>;
export type SupplierFieldSupplierIdsProductLastOrderedFromLazyQueryHookResult =
  ReturnType<typeof useSupplierFieldSupplierIdsProductLastOrderedFromLazyQuery>;
export type SupplierFieldSupplierIdsProductLastOrderedFromQueryResult =
  Apollo.QueryResult<
    SupplierFieldSupplierIdsProductLastOrderedFromQuery,
    SupplierFieldSupplierIdsProductLastOrderedFromQueryVariables
  >;
export const GetCompanyDocument = gql`
  query GetCompany($companyId: Int!) {
    companyById(companyId: $companyId) {
      ...GetCompany__Company
    }
  }
  ${GetCompany__CompanyFragmentDoc}
`;

/**
 * __useGetCompanyQuery__
 *
 * To run a query within a React component, call `useGetCompanyQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCompanyQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCompanyQuery({
 *   variables: {
 *      companyId: // value for 'companyId'
 *   },
 * });
 */
export function useGetCompanyQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetCompanyQuery,
    GetCompanyQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetCompanyQuery, GetCompanyQueryVariables>(
    GetCompanyDocument,
    options,
  );
}
export function useGetCompanyLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetCompanyQuery,
    GetCompanyQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetCompanyQuery, GetCompanyQueryVariables>(
    GetCompanyDocument,
    options,
  );
}
export type GetCompanyQueryHookResult = ReturnType<typeof useGetCompanyQuery>;
export type GetCompanyLazyQueryHookResult = ReturnType<
  typeof useGetCompanyLazyQuery
>;
export type GetCompanyQueryResult = Apollo.QueryResult<
  GetCompanyQuery,
  GetCompanyQueryVariables
>;
export const CompanySuppliersDocument = gql`
  query CompanySuppliers($companyId: Int!) {
    companyById(companyId: $companyId) {
      id
      suppliers {
        ...CompanySuppliers__Supplier
      }
    }
  }
  ${CompanySuppliers__SupplierFragmentDoc}
`;

/**
 * __useCompanySuppliersQuery__
 *
 * To run a query within a React component, call `useCompanySuppliersQuery` and pass it any options that fit your needs.
 * When your component renders, `useCompanySuppliersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCompanySuppliersQuery({
 *   variables: {
 *      companyId: // value for 'companyId'
 *   },
 * });
 */
export function useCompanySuppliersQuery(
  baseOptions: Apollo.QueryHookOptions<
    CompanySuppliersQuery,
    CompanySuppliersQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<CompanySuppliersQuery, CompanySuppliersQueryVariables>(
    CompanySuppliersDocument,
    options,
  );
}
export function useCompanySuppliersLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    CompanySuppliersQuery,
    CompanySuppliersQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    CompanySuppliersQuery,
    CompanySuppliersQueryVariables
  >(CompanySuppliersDocument, options);
}
export type CompanySuppliersQueryHookResult = ReturnType<
  typeof useCompanySuppliersQuery
>;
export type CompanySuppliersLazyQueryHookResult = ReturnType<
  typeof useCompanySuppliersLazyQuery
>;
export type CompanySuppliersQueryResult = Apollo.QueryResult<
  CompanySuppliersQuery,
  CompanySuppliersQueryVariables
>;
export const CompanyUpdateDocument = gql`
  mutation CompanyUpdate($companyId: Int!, $data: CompanyUpdateInput!) {
    companies {
      update(companyId: $companyId, company: $data) {
        company {
          ...GetCompany__Company
        }
      }
    }
  }
  ${GetCompany__CompanyFragmentDoc}
`;
export type CompanyUpdateMutationFn = Apollo.MutationFunction<
  CompanyUpdateMutation,
  CompanyUpdateMutationVariables
>;

/**
 * __useCompanyUpdateMutation__
 *
 * To run a mutation, you first call `useCompanyUpdateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCompanyUpdateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [companyUpdateMutation, { data, loading, error }] = useCompanyUpdateMutation({
 *   variables: {
 *      companyId: // value for 'companyId'
 *      data: // value for 'data'
 *   },
 * });
 */
export function useCompanyUpdateMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CompanyUpdateMutation,
    CompanyUpdateMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    CompanyUpdateMutation,
    CompanyUpdateMutationVariables
  >(CompanyUpdateDocument, options);
}
export type CompanyUpdateMutationHookResult = ReturnType<
  typeof useCompanyUpdateMutation
>;
export type CompanyUpdateMutationResult =
  Apollo.MutationResult<CompanyUpdateMutation>;
export type CompanyUpdateMutationOptions = Apollo.BaseMutationOptions<
  CompanyUpdateMutation,
  CompanyUpdateMutationVariables
>;
export const GetPersonsByProjectDocument = gql`
  query GetPersonsByProject($projectId: Int!) {
    personsFindMany(filters: { projectsIds: [$projectId] }) {
      items {
        ...GetPersons__Person
      }
    }
  }
  ${GetPersons__PersonFragmentDoc}
`;

/**
 * __useGetPersonsByProjectQuery__
 *
 * To run a query within a React component, call `useGetPersonsByProjectQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPersonsByProjectQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPersonsByProjectQuery({
 *   variables: {
 *      projectId: // value for 'projectId'
 *   },
 * });
 */
export function useGetPersonsByProjectQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetPersonsByProjectQuery,
    GetPersonsByProjectQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetPersonsByProjectQuery,
    GetPersonsByProjectQueryVariables
  >(GetPersonsByProjectDocument, options);
}
export function useGetPersonsByProjectLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetPersonsByProjectQuery,
    GetPersonsByProjectQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetPersonsByProjectQuery,
    GetPersonsByProjectQueryVariables
  >(GetPersonsByProjectDocument, options);
}
export type GetPersonsByProjectQueryHookResult = ReturnType<
  typeof useGetPersonsByProjectQuery
>;
export type GetPersonsByProjectLazyQueryHookResult = ReturnType<
  typeof useGetPersonsByProjectLazyQuery
>;
export type GetPersonsByProjectQueryResult = Apollo.QueryResult<
  GetPersonsByProjectQuery,
  GetPersonsByProjectQueryVariables
>;
export const GetPersonsDocument = gql`
  query GetPersons {
    personsFindMany {
      items {
        ...GetPersons__Person
      }
    }
  }
  ${GetPersons__PersonFragmentDoc}
`;

/**
 * __useGetPersonsQuery__
 *
 * To run a query within a React component, call `useGetPersonsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPersonsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPersonsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetPersonsQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetPersonsQuery,
    GetPersonsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetPersonsQuery, GetPersonsQueryVariables>(
    GetPersonsDocument,
    options,
  );
}
export function useGetPersonsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetPersonsQuery,
    GetPersonsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetPersonsQuery, GetPersonsQueryVariables>(
    GetPersonsDocument,
    options,
  );
}
export type GetPersonsQueryHookResult = ReturnType<typeof useGetPersonsQuery>;
export type GetPersonsLazyQueryHookResult = ReturnType<
  typeof useGetPersonsLazyQuery
>;
export type GetPersonsQueryResult = Apollo.QueryResult<
  GetPersonsQuery,
  GetPersonsQueryVariables
>;
export const SupplierProductPrices__GetPricesDocument = gql`
  query SupplierProductPrices__GetPrices(
    $companyId: Int!
    $productIds: [SupplierPriceFindManyProductIds!]!
  ) {
    productSupplierPricesFindMany(
      params: { companyId: $companyId, productIds: $productIds }
    ) {
      items {
        ...SupplierProductPrices__ProductSupplierPrice
      }
    }
  }
  ${SupplierProductPrices__ProductSupplierPriceFragmentDoc}
`;

/**
 * __useSupplierProductPrices__GetPricesQuery__
 *
 * To run a query within a React component, call `useSupplierProductPrices__GetPricesQuery` and pass it any options that fit your needs.
 * When your component renders, `useSupplierProductPrices__GetPricesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSupplierProductPrices__GetPricesQuery({
 *   variables: {
 *      companyId: // value for 'companyId'
 *      productIds: // value for 'productIds'
 *   },
 * });
 */
export function useSupplierProductPrices__GetPricesQuery(
  baseOptions: Apollo.QueryHookOptions<
    SupplierProductPrices__GetPricesQuery,
    SupplierProductPrices__GetPricesQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    SupplierProductPrices__GetPricesQuery,
    SupplierProductPrices__GetPricesQueryVariables
  >(SupplierProductPrices__GetPricesDocument, options);
}
export function useSupplierProductPrices__GetPricesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    SupplierProductPrices__GetPricesQuery,
    SupplierProductPrices__GetPricesQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    SupplierProductPrices__GetPricesQuery,
    SupplierProductPrices__GetPricesQueryVariables
  >(SupplierProductPrices__GetPricesDocument, options);
}
export type SupplierProductPrices__GetPricesQueryHookResult = ReturnType<
  typeof useSupplierProductPrices__GetPricesQuery
>;
export type SupplierProductPrices__GetPricesLazyQueryHookResult = ReturnType<
  typeof useSupplierProductPrices__GetPricesLazyQuery
>;
export type SupplierProductPrices__GetPricesQueryResult = Apollo.QueryResult<
  SupplierProductPrices__GetPricesQuery,
  SupplierProductPrices__GetPricesQueryVariables
>;
export const GetAttributesDocument = gql`
  query GetAttributes {
    attributesGet {
      attribute
    }
  }
`;

/**
 * __useGetAttributesQuery__
 *
 * To run a query within a React component, call `useGetAttributesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAttributesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAttributesQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetAttributesQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetAttributesQuery,
    GetAttributesQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetAttributesQuery, GetAttributesQueryVariables>(
    GetAttributesDocument,
    options,
  );
}
export function useGetAttributesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetAttributesQuery,
    GetAttributesQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetAttributesQuery, GetAttributesQueryVariables>(
    GetAttributesDocument,
    options,
  );
}
export type GetAttributesQueryHookResult = ReturnType<
  typeof useGetAttributesQuery
>;
export type GetAttributesLazyQueryHookResult = ReturnType<
  typeof useGetAttributesLazyQuery
>;
export type GetAttributesQueryResult = Apollo.QueryResult<
  GetAttributesQuery,
  GetAttributesQueryVariables
>;
export const ProductsView__GetProductsDocument = gql`
  query ProductsView__GetProducts(
    $filters: ProductsFindManyFiltersInput!
    $pagination: PaginationArgs!
  ) {
    productsFindMany(filters: $filters, pagination: $pagination) {
      items {
        ...ProductsView__Product
      }
      pageInfo {
        totalItems
      }
    }
  }
  ${ProductsView__ProductFragmentDoc}
`;

/**
 * __useProductsView__GetProductsQuery__
 *
 * To run a query within a React component, call `useProductsView__GetProductsQuery` and pass it any options that fit your needs.
 * When your component renders, `useProductsView__GetProductsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useProductsView__GetProductsQuery({
 *   variables: {
 *      filters: // value for 'filters'
 *      pagination: // value for 'pagination'
 *   },
 * });
 */
export function useProductsView__GetProductsQuery(
  baseOptions: Apollo.QueryHookOptions<
    ProductsView__GetProductsQuery,
    ProductsView__GetProductsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    ProductsView__GetProductsQuery,
    ProductsView__GetProductsQueryVariables
  >(ProductsView__GetProductsDocument, options);
}
export function useProductsView__GetProductsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    ProductsView__GetProductsQuery,
    ProductsView__GetProductsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    ProductsView__GetProductsQuery,
    ProductsView__GetProductsQueryVariables
  >(ProductsView__GetProductsDocument, options);
}
export type ProductsView__GetProductsQueryHookResult = ReturnType<
  typeof useProductsView__GetProductsQuery
>;
export type ProductsView__GetProductsLazyQueryHookResult = ReturnType<
  typeof useProductsView__GetProductsLazyQuery
>;
export type ProductsView__GetProductsQueryResult = Apollo.QueryResult<
  ProductsView__GetProductsQuery,
  ProductsView__GetProductsQueryVariables
>;
export const GetCategoriesDocument = gql`
  query GetCategories {
    categoriesFindMany {
      items {
        ...GetCategories__Category
      }
    }
  }
  ${GetCategories__CategoryFragmentDoc}
`;

/**
 * __useGetCategoriesQuery__
 *
 * To run a query within a React component, call `useGetCategoriesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCategoriesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCategoriesQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetCategoriesQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetCategoriesQuery,
    GetCategoriesQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetCategoriesQuery, GetCategoriesQueryVariables>(
    GetCategoriesDocument,
    options,
  );
}
export function useGetCategoriesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetCategoriesQuery,
    GetCategoriesQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetCategoriesQuery, GetCategoriesQueryVariables>(
    GetCategoriesDocument,
    options,
  );
}
export type GetCategoriesQueryHookResult = ReturnType<
  typeof useGetCategoriesQuery
>;
export type GetCategoriesLazyQueryHookResult = ReturnType<
  typeof useGetCategoriesLazyQuery
>;
export type GetCategoriesQueryResult = Apollo.QueryResult<
  GetCategoriesQuery,
  GetCategoriesQueryVariables
>;
export const CategoryCreateDocument = gql`
  mutation CategoryCreate($data: CategoryCreateInput!) {
    categories {
      create(category: $data) {
        category {
          ...GetCategories__Category
        }
      }
    }
  }
  ${GetCategories__CategoryFragmentDoc}
`;
export type CategoryCreateMutationFn = Apollo.MutationFunction<
  CategoryCreateMutation,
  CategoryCreateMutationVariables
>;

/**
 * __useCategoryCreateMutation__
 *
 * To run a mutation, you first call `useCategoryCreateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCategoryCreateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [categoryCreateMutation, { data, loading, error }] = useCategoryCreateMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useCategoryCreateMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CategoryCreateMutation,
    CategoryCreateMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    CategoryCreateMutation,
    CategoryCreateMutationVariables
  >(CategoryCreateDocument, options);
}
export type CategoryCreateMutationHookResult = ReturnType<
  typeof useCategoryCreateMutation
>;
export type CategoryCreateMutationResult =
  Apollo.MutationResult<CategoryCreateMutation>;
export type CategoryCreateMutationOptions = Apollo.BaseMutationOptions<
  CategoryCreateMutation,
  CategoryCreateMutationVariables
>;
export const CategoryDeleteDocument = gql`
  mutation CategoryDelete($id: Int!) {
    categories {
      delete(categoryId: $id) {
        status
      }
    }
  }
`;
export type CategoryDeleteMutationFn = Apollo.MutationFunction<
  CategoryDeleteMutation,
  CategoryDeleteMutationVariables
>;

/**
 * __useCategoryDeleteMutation__
 *
 * To run a mutation, you first call `useCategoryDeleteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCategoryDeleteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [categoryDeleteMutation, { data, loading, error }] = useCategoryDeleteMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useCategoryDeleteMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CategoryDeleteMutation,
    CategoryDeleteMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    CategoryDeleteMutation,
    CategoryDeleteMutationVariables
  >(CategoryDeleteDocument, options);
}
export type CategoryDeleteMutationHookResult = ReturnType<
  typeof useCategoryDeleteMutation
>;
export type CategoryDeleteMutationResult =
  Apollo.MutationResult<CategoryDeleteMutation>;
export type CategoryDeleteMutationOptions = Apollo.BaseMutationOptions<
  CategoryDeleteMutation,
  CategoryDeleteMutationVariables
>;
export const CategoryUpdateDocument = gql`
  mutation CategoryUpdate($data: CategoryCreateInput!, $id: Int!) {
    categories {
      update(category: $data, categoryId: $id) {
        category {
          ...GetCategories__Category
        }
      }
    }
  }
  ${GetCategories__CategoryFragmentDoc}
`;
export type CategoryUpdateMutationFn = Apollo.MutationFunction<
  CategoryUpdateMutation,
  CategoryUpdateMutationVariables
>;

/**
 * __useCategoryUpdateMutation__
 *
 * To run a mutation, you first call `useCategoryUpdateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCategoryUpdateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [categoryUpdateMutation, { data, loading, error }] = useCategoryUpdateMutation({
 *   variables: {
 *      data: // value for 'data'
 *      id: // value for 'id'
 *   },
 * });
 */
export function useCategoryUpdateMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CategoryUpdateMutation,
    CategoryUpdateMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    CategoryUpdateMutation,
    CategoryUpdateMutationVariables
  >(CategoryUpdateDocument, options);
}
export type CategoryUpdateMutationHookResult = ReturnType<
  typeof useCategoryUpdateMutation
>;
export type CategoryUpdateMutationResult =
  Apollo.MutationResult<CategoryUpdateMutation>;
export type CategoryUpdateMutationOptions = Apollo.BaseMutationOptions<
  CategoryUpdateMutation,
  CategoryUpdateMutationVariables
>;
export const CompanyViewProjects__CreateProjectDocument = gql`
  mutation CompanyViewProjects__CreateProject(
    $companyId: Int!
    $project: ProjectCreateInput!
  ) {
    projects {
      create(companyId: $companyId, project: $project) {
        project {
          ...CompanyViewProjects__Project
        }
      }
    }
  }
  ${CompanyViewProjects__ProjectFragmentDoc}
`;
export type CompanyViewProjects__CreateProjectMutationFn =
  Apollo.MutationFunction<
    CompanyViewProjects__CreateProjectMutation,
    CompanyViewProjects__CreateProjectMutationVariables
  >;

/**
 * __useCompanyViewProjects__CreateProjectMutation__
 *
 * To run a mutation, you first call `useCompanyViewProjects__CreateProjectMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCompanyViewProjects__CreateProjectMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [companyViewProjectsCreateProjectMutation, { data, loading, error }] = useCompanyViewProjects__CreateProjectMutation({
 *   variables: {
 *      companyId: // value for 'companyId'
 *      project: // value for 'project'
 *   },
 * });
 */
export function useCompanyViewProjects__CreateProjectMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CompanyViewProjects__CreateProjectMutation,
    CompanyViewProjects__CreateProjectMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    CompanyViewProjects__CreateProjectMutation,
    CompanyViewProjects__CreateProjectMutationVariables
  >(CompanyViewProjects__CreateProjectDocument, options);
}
export type CompanyViewProjects__CreateProjectMutationHookResult = ReturnType<
  typeof useCompanyViewProjects__CreateProjectMutation
>;
export type CompanyViewProjects__CreateProjectMutationResult =
  Apollo.MutationResult<CompanyViewProjects__CreateProjectMutation>;
export type CompanyViewProjects__CreateProjectMutationOptions =
  Apollo.BaseMutationOptions<
    CompanyViewProjects__CreateProjectMutation,
    CompanyViewProjects__CreateProjectMutationVariables
  >;
export const CompanyViewProjectsProjectUpdateDocument = gql`
  mutation CompanyViewProjectsProjectUpdate(
    $projectId: Int!
    $project: ProjectUpdateInput!
  ) {
    projects {
      update(projectId: $projectId, project: $project) {
        status
        project {
          ...CompanyViewProjects__Project
        }
      }
    }
  }
  ${CompanyViewProjects__ProjectFragmentDoc}
`;
export type CompanyViewProjectsProjectUpdateMutationFn =
  Apollo.MutationFunction<
    CompanyViewProjectsProjectUpdateMutation,
    CompanyViewProjectsProjectUpdateMutationVariables
  >;

/**
 * __useCompanyViewProjectsProjectUpdateMutation__
 *
 * To run a mutation, you first call `useCompanyViewProjectsProjectUpdateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCompanyViewProjectsProjectUpdateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [companyViewProjectsProjectUpdateMutation, { data, loading, error }] = useCompanyViewProjectsProjectUpdateMutation({
 *   variables: {
 *      projectId: // value for 'projectId'
 *      project: // value for 'project'
 *   },
 * });
 */
export function useCompanyViewProjectsProjectUpdateMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CompanyViewProjectsProjectUpdateMutation,
    CompanyViewProjectsProjectUpdateMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    CompanyViewProjectsProjectUpdateMutation,
    CompanyViewProjectsProjectUpdateMutationVariables
  >(CompanyViewProjectsProjectUpdateDocument, options);
}
export type CompanyViewProjectsProjectUpdateMutationHookResult = ReturnType<
  typeof useCompanyViewProjectsProjectUpdateMutation
>;
export type CompanyViewProjectsProjectUpdateMutationResult =
  Apollo.MutationResult<CompanyViewProjectsProjectUpdateMutation>;
export type CompanyViewProjectsProjectUpdateMutationOptions =
  Apollo.BaseMutationOptions<
    CompanyViewProjectsProjectUpdateMutation,
    CompanyViewProjectsProjectUpdateMutationVariables
  >;
export const CompanyViewProjects__GetProjectsDocument = gql`
  query CompanyViewProjects__GetProjects($companyId: Int!) {
    projectsGetByCompanyId(companyId: $companyId) {
      items {
        name
        ...CompanyViewProjects__Project
      }
    }
  }
  ${CompanyViewProjects__ProjectFragmentDoc}
`;

/**
 * __useCompanyViewProjects__GetProjectsQuery__
 *
 * To run a query within a React component, call `useCompanyViewProjects__GetProjectsQuery` and pass it any options that fit your needs.
 * When your component renders, `useCompanyViewProjects__GetProjectsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCompanyViewProjects__GetProjectsQuery({
 *   variables: {
 *      companyId: // value for 'companyId'
 *   },
 * });
 */
export function useCompanyViewProjects__GetProjectsQuery(
  baseOptions: Apollo.QueryHookOptions<
    CompanyViewProjects__GetProjectsQuery,
    CompanyViewProjects__GetProjectsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    CompanyViewProjects__GetProjectsQuery,
    CompanyViewProjects__GetProjectsQueryVariables
  >(CompanyViewProjects__GetProjectsDocument, options);
}
export function useCompanyViewProjects__GetProjectsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    CompanyViewProjects__GetProjectsQuery,
    CompanyViewProjects__GetProjectsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    CompanyViewProjects__GetProjectsQuery,
    CompanyViewProjects__GetProjectsQueryVariables
  >(CompanyViewProjects__GetProjectsDocument, options);
}
export type CompanyViewProjects__GetProjectsQueryHookResult = ReturnType<
  typeof useCompanyViewProjects__GetProjectsQuery
>;
export type CompanyViewProjects__GetProjectsLazyQueryHookResult = ReturnType<
  typeof useCompanyViewProjects__GetProjectsLazyQuery
>;
export type CompanyViewProjects__GetProjectsQueryResult = Apollo.QueryResult<
  CompanyViewProjects__GetProjectsQuery,
  CompanyViewProjects__GetProjectsQueryVariables
>;
export const CompanyViewUsersFilters__FindProjectsDocument = gql`
  query CompanyViewUsersFilters__FindProjects {
    projectsFindMany {
      items {
        ...CompanyViewUsersFilters__Project
      }
    }
  }
  ${CompanyViewUsersFilters__ProjectFragmentDoc}
`;

/**
 * __useCompanyViewUsersFilters__FindProjectsQuery__
 *
 * To run a query within a React component, call `useCompanyViewUsersFilters__FindProjectsQuery` and pass it any options that fit your needs.
 * When your component renders, `useCompanyViewUsersFilters__FindProjectsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCompanyViewUsersFilters__FindProjectsQuery({
 *   variables: {
 *   },
 * });
 */
export function useCompanyViewUsersFilters__FindProjectsQuery(
  baseOptions?: Apollo.QueryHookOptions<
    CompanyViewUsersFilters__FindProjectsQuery,
    CompanyViewUsersFilters__FindProjectsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    CompanyViewUsersFilters__FindProjectsQuery,
    CompanyViewUsersFilters__FindProjectsQueryVariables
  >(CompanyViewUsersFilters__FindProjectsDocument, options);
}
export function useCompanyViewUsersFilters__FindProjectsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    CompanyViewUsersFilters__FindProjectsQuery,
    CompanyViewUsersFilters__FindProjectsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    CompanyViewUsersFilters__FindProjectsQuery,
    CompanyViewUsersFilters__FindProjectsQueryVariables
  >(CompanyViewUsersFilters__FindProjectsDocument, options);
}
export type CompanyViewUsersFilters__FindProjectsQueryHookResult = ReturnType<
  typeof useCompanyViewUsersFilters__FindProjectsQuery
>;
export type CompanyViewUsersFilters__FindProjectsLazyQueryHookResult =
  ReturnType<typeof useCompanyViewUsersFilters__FindProjectsLazyQuery>;
export type CompanyViewUsersFilters__FindProjectsQueryResult =
  Apollo.QueryResult<
    CompanyViewUsersFilters__FindProjectsQuery,
    CompanyViewUsersFilters__FindProjectsQueryVariables
  >;
export const CompanyViewSubCompanies__GetSubCompaniesDocument = gql`
  query CompanyViewSubCompanies__GetSubCompanies(
    $filters: SubCompanyFindManyFiltersInput
    $pagination: PaginationArgs
  ) {
    subCompaniesFindMany(pagination: $pagination, filters: $filters) {
      items {
        ...CompanyViewSubCompanies__SubCompany
      }
      pageInfo {
        ...Pagination
      }
    }
  }
  ${CompanyViewSubCompanies__SubCompanyFragmentDoc}
  ${PaginationFragmentDoc}
`;

/**
 * __useCompanyViewSubCompanies__GetSubCompaniesQuery__
 *
 * To run a query within a React component, call `useCompanyViewSubCompanies__GetSubCompaniesQuery` and pass it any options that fit your needs.
 * When your component renders, `useCompanyViewSubCompanies__GetSubCompaniesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCompanyViewSubCompanies__GetSubCompaniesQuery({
 *   variables: {
 *      filters: // value for 'filters'
 *      pagination: // value for 'pagination'
 *   },
 * });
 */
export function useCompanyViewSubCompanies__GetSubCompaniesQuery(
  baseOptions?: Apollo.QueryHookOptions<
    CompanyViewSubCompanies__GetSubCompaniesQuery,
    CompanyViewSubCompanies__GetSubCompaniesQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    CompanyViewSubCompanies__GetSubCompaniesQuery,
    CompanyViewSubCompanies__GetSubCompaniesQueryVariables
  >(CompanyViewSubCompanies__GetSubCompaniesDocument, options);
}
export function useCompanyViewSubCompanies__GetSubCompaniesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    CompanyViewSubCompanies__GetSubCompaniesQuery,
    CompanyViewSubCompanies__GetSubCompaniesQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    CompanyViewSubCompanies__GetSubCompaniesQuery,
    CompanyViewSubCompanies__GetSubCompaniesQueryVariables
  >(CompanyViewSubCompanies__GetSubCompaniesDocument, options);
}
export type CompanyViewSubCompanies__GetSubCompaniesQueryHookResult =
  ReturnType<typeof useCompanyViewSubCompanies__GetSubCompaniesQuery>;
export type CompanyViewSubCompanies__GetSubCompaniesLazyQueryHookResult =
  ReturnType<typeof useCompanyViewSubCompanies__GetSubCompaniesLazyQuery>;
export type CompanyViewSubCompanies__GetSubCompaniesQueryResult =
  Apollo.QueryResult<
    CompanyViewSubCompanies__GetSubCompaniesQuery,
    CompanyViewSubCompanies__GetSubCompaniesQueryVariables
  >;
export const CompanyView__SubCompanyCreateDocument = gql`
  mutation CompanyView__SubCompanyCreate($data: SubCompanyCreateInput!) {
    subCompanies {
      create(data: $data) {
        subCompany {
          ...CompanyViewSubCompanies__SubCompany
        }
      }
    }
  }
  ${CompanyViewSubCompanies__SubCompanyFragmentDoc}
`;
export type CompanyView__SubCompanyCreateMutationFn = Apollo.MutationFunction<
  CompanyView__SubCompanyCreateMutation,
  CompanyView__SubCompanyCreateMutationVariables
>;

/**
 * __useCompanyView__SubCompanyCreateMutation__
 *
 * To run a mutation, you first call `useCompanyView__SubCompanyCreateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCompanyView__SubCompanyCreateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [companyViewSubCompanyCreateMutation, { data, loading, error }] = useCompanyView__SubCompanyCreateMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useCompanyView__SubCompanyCreateMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CompanyView__SubCompanyCreateMutation,
    CompanyView__SubCompanyCreateMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    CompanyView__SubCompanyCreateMutation,
    CompanyView__SubCompanyCreateMutationVariables
  >(CompanyView__SubCompanyCreateDocument, options);
}
export type CompanyView__SubCompanyCreateMutationHookResult = ReturnType<
  typeof useCompanyView__SubCompanyCreateMutation
>;
export type CompanyView__SubCompanyCreateMutationResult =
  Apollo.MutationResult<CompanyView__SubCompanyCreateMutation>;
export type CompanyView__SubCompanyCreateMutationOptions =
  Apollo.BaseMutationOptions<
    CompanyView__SubCompanyCreateMutation,
    CompanyView__SubCompanyCreateMutationVariables
  >;
export const CompanyView__SubCompanyDeleteDocument = gql`
  mutation CompanyView__SubCompanyDelete($id: Int!) {
    subCompanies {
      delete(id: $id) {
        status
      }
    }
  }
`;
export type CompanyView__SubCompanyDeleteMutationFn = Apollo.MutationFunction<
  CompanyView__SubCompanyDeleteMutation,
  CompanyView__SubCompanyDeleteMutationVariables
>;

/**
 * __useCompanyView__SubCompanyDeleteMutation__
 *
 * To run a mutation, you first call `useCompanyView__SubCompanyDeleteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCompanyView__SubCompanyDeleteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [companyViewSubCompanyDeleteMutation, { data, loading, error }] = useCompanyView__SubCompanyDeleteMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useCompanyView__SubCompanyDeleteMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CompanyView__SubCompanyDeleteMutation,
    CompanyView__SubCompanyDeleteMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    CompanyView__SubCompanyDeleteMutation,
    CompanyView__SubCompanyDeleteMutationVariables
  >(CompanyView__SubCompanyDeleteDocument, options);
}
export type CompanyView__SubCompanyDeleteMutationHookResult = ReturnType<
  typeof useCompanyView__SubCompanyDeleteMutation
>;
export type CompanyView__SubCompanyDeleteMutationResult =
  Apollo.MutationResult<CompanyView__SubCompanyDeleteMutation>;
export type CompanyView__SubCompanyDeleteMutationOptions =
  Apollo.BaseMutationOptions<
    CompanyView__SubCompanyDeleteMutation,
    CompanyView__SubCompanyDeleteMutationVariables
  >;
export const CompanyView__SubCompanyUpdateDocument = gql`
  mutation CompanyView__SubCompanyUpdate(
    $id: Int!
    $data: SubCompanyUpdateInput!
  ) {
    subCompanies {
      update(data: $data, id: $id) {
        subCompany {
          ...CompanyViewSubCompanies__SubCompany
        }
      }
    }
  }
  ${CompanyViewSubCompanies__SubCompanyFragmentDoc}
`;
export type CompanyView__SubCompanyUpdateMutationFn = Apollo.MutationFunction<
  CompanyView__SubCompanyUpdateMutation,
  CompanyView__SubCompanyUpdateMutationVariables
>;

/**
 * __useCompanyView__SubCompanyUpdateMutation__
 *
 * To run a mutation, you first call `useCompanyView__SubCompanyUpdateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCompanyView__SubCompanyUpdateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [companyViewSubCompanyUpdateMutation, { data, loading, error }] = useCompanyView__SubCompanyUpdateMutation({
 *   variables: {
 *      id: // value for 'id'
 *      data: // value for 'data'
 *   },
 * });
 */
export function useCompanyView__SubCompanyUpdateMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CompanyView__SubCompanyUpdateMutation,
    CompanyView__SubCompanyUpdateMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    CompanyView__SubCompanyUpdateMutation,
    CompanyView__SubCompanyUpdateMutationVariables
  >(CompanyView__SubCompanyUpdateDocument, options);
}
export type CompanyView__SubCompanyUpdateMutationHookResult = ReturnType<
  typeof useCompanyView__SubCompanyUpdateMutation
>;
export type CompanyView__SubCompanyUpdateMutationResult =
  Apollo.MutationResult<CompanyView__SubCompanyUpdateMutation>;
export type CompanyView__SubCompanyUpdateMutationOptions =
  Apollo.BaseMutationOptions<
    CompanyView__SubCompanyUpdateMutation,
    CompanyView__SubCompanyUpdateMutationVariables
  >;
export const CompanyViewSuppliersSuppliersDocument = gql`
  query CompanyViewSuppliersSuppliers {
    suppliersFindMany {
      items {
        id
        name
      }
    }
  }
`;

/**
 * __useCompanyViewSuppliersSuppliersQuery__
 *
 * To run a query within a React component, call `useCompanyViewSuppliersSuppliersQuery` and pass it any options that fit your needs.
 * When your component renders, `useCompanyViewSuppliersSuppliersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCompanyViewSuppliersSuppliersQuery({
 *   variables: {
 *   },
 * });
 */
export function useCompanyViewSuppliersSuppliersQuery(
  baseOptions?: Apollo.QueryHookOptions<
    CompanyViewSuppliersSuppliersQuery,
    CompanyViewSuppliersSuppliersQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    CompanyViewSuppliersSuppliersQuery,
    CompanyViewSuppliersSuppliersQueryVariables
  >(CompanyViewSuppliersSuppliersDocument, options);
}
export function useCompanyViewSuppliersSuppliersLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    CompanyViewSuppliersSuppliersQuery,
    CompanyViewSuppliersSuppliersQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    CompanyViewSuppliersSuppliersQuery,
    CompanyViewSuppliersSuppliersQueryVariables
  >(CompanyViewSuppliersSuppliersDocument, options);
}
export type CompanyViewSuppliersSuppliersQueryHookResult = ReturnType<
  typeof useCompanyViewSuppliersSuppliersQuery
>;
export type CompanyViewSuppliersSuppliersLazyQueryHookResult = ReturnType<
  typeof useCompanyViewSuppliersSuppliersLazyQuery
>;
export type CompanyViewSuppliersSuppliersQueryResult = Apollo.QueryResult<
  CompanyViewSuppliersSuppliersQuery,
  CompanyViewSuppliersSuppliersQueryVariables
>;
export const CompanyViewUsers__GetPersonsDocument = gql`
  query CompanyViewUsers__GetPersons(
    $filters: PersonsFindManyFiltersInput
    $page: Int!
    $perPage: Int!
  ) {
    personsFindMany(
      filters: $filters
      pagination: { page: $page, perPage: $perPage }
    ) {
      items {
        ...CompanyViewUsers__Person
      }
      pageInfo {
        ...Pagination
      }
    }
  }
  ${CompanyViewUsers__PersonFragmentDoc}
  ${PaginationFragmentDoc}
`;

/**
 * __useCompanyViewUsers__GetPersonsQuery__
 *
 * To run a query within a React component, call `useCompanyViewUsers__GetPersonsQuery` and pass it any options that fit your needs.
 * When your component renders, `useCompanyViewUsers__GetPersonsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCompanyViewUsers__GetPersonsQuery({
 *   variables: {
 *      filters: // value for 'filters'
 *      page: // value for 'page'
 *      perPage: // value for 'perPage'
 *   },
 * });
 */
export function useCompanyViewUsers__GetPersonsQuery(
  baseOptions: Apollo.QueryHookOptions<
    CompanyViewUsers__GetPersonsQuery,
    CompanyViewUsers__GetPersonsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    CompanyViewUsers__GetPersonsQuery,
    CompanyViewUsers__GetPersonsQueryVariables
  >(CompanyViewUsers__GetPersonsDocument, options);
}
export function useCompanyViewUsers__GetPersonsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    CompanyViewUsers__GetPersonsQuery,
    CompanyViewUsers__GetPersonsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    CompanyViewUsers__GetPersonsQuery,
    CompanyViewUsers__GetPersonsQueryVariables
  >(CompanyViewUsers__GetPersonsDocument, options);
}
export type CompanyViewUsers__GetPersonsQueryHookResult = ReturnType<
  typeof useCompanyViewUsers__GetPersonsQuery
>;
export type CompanyViewUsers__GetPersonsLazyQueryHookResult = ReturnType<
  typeof useCompanyViewUsers__GetPersonsLazyQuery
>;
export type CompanyViewUsers__GetPersonsQueryResult = Apollo.QueryResult<
  CompanyViewUsers__GetPersonsQuery,
  CompanyViewUsers__GetPersonsQueryVariables
>;
export const CompanyViewUsers__FindProjectsDocument = gql`
  query CompanyViewUsers__FindProjects {
    projectsFindMany {
      items {
        companyId
        ...CompanyViewUsersFilters__Project
      }
    }
  }
  ${CompanyViewUsersFilters__ProjectFragmentDoc}
`;

/**
 * __useCompanyViewUsers__FindProjectsQuery__
 *
 * To run a query within a React component, call `useCompanyViewUsers__FindProjectsQuery` and pass it any options that fit your needs.
 * When your component renders, `useCompanyViewUsers__FindProjectsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCompanyViewUsers__FindProjectsQuery({
 *   variables: {
 *   },
 * });
 */
export function useCompanyViewUsers__FindProjectsQuery(
  baseOptions?: Apollo.QueryHookOptions<
    CompanyViewUsers__FindProjectsQuery,
    CompanyViewUsers__FindProjectsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    CompanyViewUsers__FindProjectsQuery,
    CompanyViewUsers__FindProjectsQueryVariables
  >(CompanyViewUsers__FindProjectsDocument, options);
}
export function useCompanyViewUsers__FindProjectsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    CompanyViewUsers__FindProjectsQuery,
    CompanyViewUsers__FindProjectsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    CompanyViewUsers__FindProjectsQuery,
    CompanyViewUsers__FindProjectsQueryVariables
  >(CompanyViewUsers__FindProjectsDocument, options);
}
export type CompanyViewUsers__FindProjectsQueryHookResult = ReturnType<
  typeof useCompanyViewUsers__FindProjectsQuery
>;
export type CompanyViewUsers__FindProjectsLazyQueryHookResult = ReturnType<
  typeof useCompanyViewUsers__FindProjectsLazyQuery
>;
export type CompanyViewUsers__FindProjectsQueryResult = Apollo.QueryResult<
  CompanyViewUsers__FindProjectsQuery,
  CompanyViewUsers__FindProjectsQueryVariables
>;
export const CompanyViewUsers__UpdatePersonProjectsDocument = gql`
  mutation CompanyViewUsers__UpdatePersonProjects(
    $id: Int!
    $projectIds: [Int!]!
  ) {
    persons {
      updateProjects(projectIds: $projectIds, id: $id) {
        person {
          ...CompanyViewUsers__ReadPerson
        }
      }
    }
  }
  ${CompanyViewUsers__ReadPersonFragmentDoc}
`;
export type CompanyViewUsers__UpdatePersonProjectsMutationFn =
  Apollo.MutationFunction<
    CompanyViewUsers__UpdatePersonProjectsMutation,
    CompanyViewUsers__UpdatePersonProjectsMutationVariables
  >;

/**
 * __useCompanyViewUsers__UpdatePersonProjectsMutation__
 *
 * To run a mutation, you first call `useCompanyViewUsers__UpdatePersonProjectsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCompanyViewUsers__UpdatePersonProjectsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [companyViewUsersUpdatePersonProjectsMutation, { data, loading, error }] = useCompanyViewUsers__UpdatePersonProjectsMutation({
 *   variables: {
 *      id: // value for 'id'
 *      projectIds: // value for 'projectIds'
 *   },
 * });
 */
export function useCompanyViewUsers__UpdatePersonProjectsMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CompanyViewUsers__UpdatePersonProjectsMutation,
    CompanyViewUsers__UpdatePersonProjectsMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    CompanyViewUsers__UpdatePersonProjectsMutation,
    CompanyViewUsers__UpdatePersonProjectsMutationVariables
  >(CompanyViewUsers__UpdatePersonProjectsDocument, options);
}
export type CompanyViewUsers__UpdatePersonProjectsMutationHookResult =
  ReturnType<typeof useCompanyViewUsers__UpdatePersonProjectsMutation>;
export type CompanyViewUsers__UpdatePersonProjectsMutationResult =
  Apollo.MutationResult<CompanyViewUsers__UpdatePersonProjectsMutation>;
export type CompanyViewUsers__UpdatePersonProjectsMutationOptions =
  Apollo.BaseMutationOptions<
    CompanyViewUsers__UpdatePersonProjectsMutation,
    CompanyViewUsers__UpdatePersonProjectsMutationVariables
  >;
export const CompanyViewCompanyDocument = gql`
  query CompanyViewCompany($companyId: Int!) {
    companyById(companyId: $companyId) {
      ...CompanyViewCompany
    }
  }
  ${CompanyViewCompanyFragmentDoc}
`;

/**
 * __useCompanyViewCompanyQuery__
 *
 * To run a query within a React component, call `useCompanyViewCompanyQuery` and pass it any options that fit your needs.
 * When your component renders, `useCompanyViewCompanyQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCompanyViewCompanyQuery({
 *   variables: {
 *      companyId: // value for 'companyId'
 *   },
 * });
 */
export function useCompanyViewCompanyQuery(
  baseOptions: Apollo.QueryHookOptions<
    CompanyViewCompanyQuery,
    CompanyViewCompanyQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    CompanyViewCompanyQuery,
    CompanyViewCompanyQueryVariables
  >(CompanyViewCompanyDocument, options);
}
export function useCompanyViewCompanyLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    CompanyViewCompanyQuery,
    CompanyViewCompanyQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    CompanyViewCompanyQuery,
    CompanyViewCompanyQueryVariables
  >(CompanyViewCompanyDocument, options);
}
export type CompanyViewCompanyQueryHookResult = ReturnType<
  typeof useCompanyViewCompanyQuery
>;
export type CompanyViewCompanyLazyQueryHookResult = ReturnType<
  typeof useCompanyViewCompanyLazyQuery
>;
export type CompanyViewCompanyQueryResult = Apollo.QueryResult<
  CompanyViewCompanyQuery,
  CompanyViewCompanyQueryVariables
>;
export const CompanyView__UpdateSuppliersDocument = gql`
  mutation CompanyView__UpdateSuppliers(
    $companyId: Int!
    $supplierIds: [Int!]!
  ) {
    companies {
      update(companyId: $companyId, company: { supplierIds: $supplierIds }) {
        status
        company {
          id
          supplierIds
        }
      }
    }
  }
`;
export type CompanyView__UpdateSuppliersMutationFn = Apollo.MutationFunction<
  CompanyView__UpdateSuppliersMutation,
  CompanyView__UpdateSuppliersMutationVariables
>;

/**
 * __useCompanyView__UpdateSuppliersMutation__
 *
 * To run a mutation, you first call `useCompanyView__UpdateSuppliersMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCompanyView__UpdateSuppliersMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [companyViewUpdateSuppliersMutation, { data, loading, error }] = useCompanyView__UpdateSuppliersMutation({
 *   variables: {
 *      companyId: // value for 'companyId'
 *      supplierIds: // value for 'supplierIds'
 *   },
 * });
 */
export function useCompanyView__UpdateSuppliersMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CompanyView__UpdateSuppliersMutation,
    CompanyView__UpdateSuppliersMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    CompanyView__UpdateSuppliersMutation,
    CompanyView__UpdateSuppliersMutationVariables
  >(CompanyView__UpdateSuppliersDocument, options);
}
export type CompanyView__UpdateSuppliersMutationHookResult = ReturnType<
  typeof useCompanyView__UpdateSuppliersMutation
>;
export type CompanyView__UpdateSuppliersMutationResult =
  Apollo.MutationResult<CompanyView__UpdateSuppliersMutation>;
export type CompanyView__UpdateSuppliersMutationOptions =
  Apollo.BaseMutationOptions<
    CompanyView__UpdateSuppliersMutation,
    CompanyView__UpdateSuppliersMutationVariables
  >;
export const GetCompaniesDocument = gql`
  query GetCompanies {
    companiesFindMany {
      items {
        ...CompaniesView__Company
      }
    }
  }
  ${CompaniesView__CompanyFragmentDoc}
`;

/**
 * __useGetCompaniesQuery__
 *
 * To run a query within a React component, call `useGetCompaniesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCompaniesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCompaniesQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetCompaniesQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetCompaniesQuery,
    GetCompaniesQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetCompaniesQuery, GetCompaniesQueryVariables>(
    GetCompaniesDocument,
    options,
  );
}
export function useGetCompaniesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetCompaniesQuery,
    GetCompaniesQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetCompaniesQuery, GetCompaniesQueryVariables>(
    GetCompaniesDocument,
    options,
  );
}
export type GetCompaniesQueryHookResult = ReturnType<
  typeof useGetCompaniesQuery
>;
export type GetCompaniesLazyQueryHookResult = ReturnType<
  typeof useGetCompaniesLazyQuery
>;
export type GetCompaniesQueryResult = Apollo.QueryResult<
  GetCompaniesQuery,
  GetCompaniesQueryVariables
>;
export const CreateCompanyDocument = gql`
  mutation CreateCompany($company: CompanyCreateInput!) {
    companies {
      create(company: $company) {
        status
        company {
          ...GetCompany__Company
        }
      }
    }
  }
  ${GetCompany__CompanyFragmentDoc}
`;
export type CreateCompanyMutationFn = Apollo.MutationFunction<
  CreateCompanyMutation,
  CreateCompanyMutationVariables
>;

/**
 * __useCreateCompanyMutation__
 *
 * To run a mutation, you first call `useCreateCompanyMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateCompanyMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createCompanyMutation, { data, loading, error }] = useCreateCompanyMutation({
 *   variables: {
 *      company: // value for 'company'
 *   },
 * });
 */
export function useCreateCompanyMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateCompanyMutation,
    CreateCompanyMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    CreateCompanyMutation,
    CreateCompanyMutationVariables
  >(CreateCompanyDocument, options);
}
export type CreateCompanyMutationHookResult = ReturnType<
  typeof useCreateCompanyMutation
>;
export type CreateCompanyMutationResult =
  Apollo.MutationResult<CreateCompanyMutation>;
export type CreateCompanyMutationOptions = Apollo.BaseMutationOptions<
  CreateCompanyMutation,
  CreateCompanyMutationVariables
>;
export const CompanyCatalog__AddProductDocument = gql`
  mutation CompanyCatalog__AddProduct($data: CatalogProductCreateInput!) {
    catalogProducts {
      addProducts(data: [$data]) {
        status
        items {
          id
          companyId
          variantIds
          product {
            id
          }
        }
      }
    }
  }
`;
export type CompanyCatalog__AddProductMutationFn = Apollo.MutationFunction<
  CompanyCatalog__AddProductMutation,
  CompanyCatalog__AddProductMutationVariables
>;

/**
 * __useCompanyCatalog__AddProductMutation__
 *
 * To run a mutation, you first call `useCompanyCatalog__AddProductMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCompanyCatalog__AddProductMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [companyCatalogAddProductMutation, { data, loading, error }] = useCompanyCatalog__AddProductMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useCompanyCatalog__AddProductMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CompanyCatalog__AddProductMutation,
    CompanyCatalog__AddProductMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    CompanyCatalog__AddProductMutation,
    CompanyCatalog__AddProductMutationVariables
  >(CompanyCatalog__AddProductDocument, options);
}
export type CompanyCatalog__AddProductMutationHookResult = ReturnType<
  typeof useCompanyCatalog__AddProductMutation
>;
export type CompanyCatalog__AddProductMutationResult =
  Apollo.MutationResult<CompanyCatalog__AddProductMutation>;
export type CompanyCatalog__AddProductMutationOptions =
  Apollo.BaseMutationOptions<
    CompanyCatalog__AddProductMutation,
    CompanyCatalog__AddProductMutationVariables
  >;
export const CompanyCatalog__AddProductsByFiltersDocument = gql`
  mutation CompanyCatalog__AddProductsByFilters(
    $companyId: Int!
    $totalItems: Int!
    $filters: ProductsFindManyFiltersInput!
  ) {
    catalogProducts {
      addProductsByFilters(
        companyId: $companyId
        totalItems: $totalItems
        filters: $filters
      ) {
        status
      }
    }
  }
`;
export type CompanyCatalog__AddProductsByFiltersMutationFn =
  Apollo.MutationFunction<
    CompanyCatalog__AddProductsByFiltersMutation,
    CompanyCatalog__AddProductsByFiltersMutationVariables
  >;

/**
 * __useCompanyCatalog__AddProductsByFiltersMutation__
 *
 * To run a mutation, you first call `useCompanyCatalog__AddProductsByFiltersMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCompanyCatalog__AddProductsByFiltersMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [companyCatalogAddProductsByFiltersMutation, { data, loading, error }] = useCompanyCatalog__AddProductsByFiltersMutation({
 *   variables: {
 *      companyId: // value for 'companyId'
 *      totalItems: // value for 'totalItems'
 *      filters: // value for 'filters'
 *   },
 * });
 */
export function useCompanyCatalog__AddProductsByFiltersMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CompanyCatalog__AddProductsByFiltersMutation,
    CompanyCatalog__AddProductsByFiltersMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    CompanyCatalog__AddProductsByFiltersMutation,
    CompanyCatalog__AddProductsByFiltersMutationVariables
  >(CompanyCatalog__AddProductsByFiltersDocument, options);
}
export type CompanyCatalog__AddProductsByFiltersMutationHookResult = ReturnType<
  typeof useCompanyCatalog__AddProductsByFiltersMutation
>;
export type CompanyCatalog__AddProductsByFiltersMutationResult =
  Apollo.MutationResult<CompanyCatalog__AddProductsByFiltersMutation>;
export type CompanyCatalog__AddProductsByFiltersMutationOptions =
  Apollo.BaseMutationOptions<
    CompanyCatalog__AddProductsByFiltersMutation,
    CompanyCatalog__AddProductsByFiltersMutationVariables
  >;
export const CompanyCatalogAddProducts__GetProductsDocument = gql`
  query CompanyCatalogAddProducts__GetProducts(
    $page: Int!
    $perPage: Int!
    $filters: ProductsFindManyFiltersInput!
  ) {
    productsFindMany(
      pagination: { page: $page, perPage: $perPage }
      filters: $filters
    ) {
      items {
        ...CompanyCatalogAddProducts__Product
      }
      pageInfo {
        ...Pagination
      }
    }
  }
  ${CompanyCatalogAddProducts__ProductFragmentDoc}
  ${PaginationFragmentDoc}
`;

/**
 * __useCompanyCatalogAddProducts__GetProductsQuery__
 *
 * To run a query within a React component, call `useCompanyCatalogAddProducts__GetProductsQuery` and pass it any options that fit your needs.
 * When your component renders, `useCompanyCatalogAddProducts__GetProductsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCompanyCatalogAddProducts__GetProductsQuery({
 *   variables: {
 *      page: // value for 'page'
 *      perPage: // value for 'perPage'
 *      filters: // value for 'filters'
 *   },
 * });
 */
export function useCompanyCatalogAddProducts__GetProductsQuery(
  baseOptions: Apollo.QueryHookOptions<
    CompanyCatalogAddProducts__GetProductsQuery,
    CompanyCatalogAddProducts__GetProductsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    CompanyCatalogAddProducts__GetProductsQuery,
    CompanyCatalogAddProducts__GetProductsQueryVariables
  >(CompanyCatalogAddProducts__GetProductsDocument, options);
}
export function useCompanyCatalogAddProducts__GetProductsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    CompanyCatalogAddProducts__GetProductsQuery,
    CompanyCatalogAddProducts__GetProductsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    CompanyCatalogAddProducts__GetProductsQuery,
    CompanyCatalogAddProducts__GetProductsQueryVariables
  >(CompanyCatalogAddProducts__GetProductsDocument, options);
}
export type CompanyCatalogAddProducts__GetProductsQueryHookResult = ReturnType<
  typeof useCompanyCatalogAddProducts__GetProductsQuery
>;
export type CompanyCatalogAddProducts__GetProductsLazyQueryHookResult =
  ReturnType<typeof useCompanyCatalogAddProducts__GetProductsLazyQuery>;
export type CompanyCatalogAddProducts__GetProductsQueryResult =
  Apollo.QueryResult<
    CompanyCatalogAddProducts__GetProductsQuery,
    CompanyCatalogAddProducts__GetProductsQueryVariables
  >;
export const CompanyCatalog__DeleteProductsByFiltersDocument = gql`
  mutation CompanyCatalog__DeleteProductsByFilters(
    $companyId: Int!
    $totalItems: Int!
    $filters: CatalogProductsFindManyFiltersInput!
  ) {
    catalogProducts {
      deleteProductsByFilters(
        companyId: $companyId
        totalItems: $totalItems
        filters: $filters
      ) {
        status
      }
    }
  }
`;
export type CompanyCatalog__DeleteProductsByFiltersMutationFn =
  Apollo.MutationFunction<
    CompanyCatalog__DeleteProductsByFiltersMutation,
    CompanyCatalog__DeleteProductsByFiltersMutationVariables
  >;

/**
 * __useCompanyCatalog__DeleteProductsByFiltersMutation__
 *
 * To run a mutation, you first call `useCompanyCatalog__DeleteProductsByFiltersMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCompanyCatalog__DeleteProductsByFiltersMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [companyCatalogDeleteProductsByFiltersMutation, { data, loading, error }] = useCompanyCatalog__DeleteProductsByFiltersMutation({
 *   variables: {
 *      companyId: // value for 'companyId'
 *      totalItems: // value for 'totalItems'
 *      filters: // value for 'filters'
 *   },
 * });
 */
export function useCompanyCatalog__DeleteProductsByFiltersMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CompanyCatalog__DeleteProductsByFiltersMutation,
    CompanyCatalog__DeleteProductsByFiltersMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    CompanyCatalog__DeleteProductsByFiltersMutation,
    CompanyCatalog__DeleteProductsByFiltersMutationVariables
  >(CompanyCatalog__DeleteProductsByFiltersDocument, options);
}
export type CompanyCatalog__DeleteProductsByFiltersMutationHookResult =
  ReturnType<typeof useCompanyCatalog__DeleteProductsByFiltersMutation>;
export type CompanyCatalog__DeleteProductsByFiltersMutationResult =
  Apollo.MutationResult<CompanyCatalog__DeleteProductsByFiltersMutation>;
export type CompanyCatalog__DeleteProductsByFiltersMutationOptions =
  Apollo.BaseMutationOptions<
    CompanyCatalog__DeleteProductsByFiltersMutation,
    CompanyCatalog__DeleteProductsByFiltersMutationVariables
  >;
export const CompanyCatalog__GetProductsDocument = gql`
  query CompanyCatalog__GetProducts(
    $pagination: PaginationArgs!
    $filters: CatalogProductsFindManyFiltersInput!
  ) {
    catalogProductsFindMany(pagination: $pagination, filters: $filters) {
      items {
        ...CompanyCatalog__CatalogProduct
      }
      pageInfo {
        ...Pagination
      }
    }
  }
  ${CompanyCatalog__CatalogProductFragmentDoc}
  ${PaginationFragmentDoc}
`;

/**
 * __useCompanyCatalog__GetProductsQuery__
 *
 * To run a query within a React component, call `useCompanyCatalog__GetProductsQuery` and pass it any options that fit your needs.
 * When your component renders, `useCompanyCatalog__GetProductsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCompanyCatalog__GetProductsQuery({
 *   variables: {
 *      pagination: // value for 'pagination'
 *      filters: // value for 'filters'
 *   },
 * });
 */
export function useCompanyCatalog__GetProductsQuery(
  baseOptions: Apollo.QueryHookOptions<
    CompanyCatalog__GetProductsQuery,
    CompanyCatalog__GetProductsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    CompanyCatalog__GetProductsQuery,
    CompanyCatalog__GetProductsQueryVariables
  >(CompanyCatalog__GetProductsDocument, options);
}
export function useCompanyCatalog__GetProductsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    CompanyCatalog__GetProductsQuery,
    CompanyCatalog__GetProductsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    CompanyCatalog__GetProductsQuery,
    CompanyCatalog__GetProductsQueryVariables
  >(CompanyCatalog__GetProductsDocument, options);
}
export type CompanyCatalog__GetProductsQueryHookResult = ReturnType<
  typeof useCompanyCatalog__GetProductsQuery
>;
export type CompanyCatalog__GetProductsLazyQueryHookResult = ReturnType<
  typeof useCompanyCatalog__GetProductsLazyQuery
>;
export type CompanyCatalog__GetProductsQueryResult = Apollo.QueryResult<
  CompanyCatalog__GetProductsQuery,
  CompanyCatalog__GetProductsQueryVariables
>;
export const CompanyCatalog__DeleteProductsDocument = gql`
  mutation CompanyCatalog__DeleteProducts(
    $data: [CatalogProductDeleteInput!]!
  ) {
    catalogProducts {
      deleteProducts(data: $data) {
        status
      }
    }
  }
`;
export type CompanyCatalog__DeleteProductsMutationFn = Apollo.MutationFunction<
  CompanyCatalog__DeleteProductsMutation,
  CompanyCatalog__DeleteProductsMutationVariables
>;

/**
 * __useCompanyCatalog__DeleteProductsMutation__
 *
 * To run a mutation, you first call `useCompanyCatalog__DeleteProductsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCompanyCatalog__DeleteProductsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [companyCatalogDeleteProductsMutation, { data, loading, error }] = useCompanyCatalog__DeleteProductsMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useCompanyCatalog__DeleteProductsMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CompanyCatalog__DeleteProductsMutation,
    CompanyCatalog__DeleteProductsMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    CompanyCatalog__DeleteProductsMutation,
    CompanyCatalog__DeleteProductsMutationVariables
  >(CompanyCatalog__DeleteProductsDocument, options);
}
export type CompanyCatalog__DeleteProductsMutationHookResult = ReturnType<
  typeof useCompanyCatalog__DeleteProductsMutation
>;
export type CompanyCatalog__DeleteProductsMutationResult =
  Apollo.MutationResult<CompanyCatalog__DeleteProductsMutation>;
export type CompanyCatalog__DeleteProductsMutationOptions =
  Apollo.BaseMutationOptions<
    CompanyCatalog__DeleteProductsMutation,
    CompanyCatalog__DeleteProductsMutationVariables
  >;
export const OrderViewLineItemUpdatePopupCategoriesDocument = gql`
  query OrderViewLineItemUpdatePopupCategories {
    categoriesFindMany {
      items {
        ...OrderViewLineItemUpdateCategory
      }
    }
  }
  ${OrderViewLineItemUpdateCategoryFragmentDoc}
`;

/**
 * __useOrderViewLineItemUpdatePopupCategoriesQuery__
 *
 * To run a query within a React component, call `useOrderViewLineItemUpdatePopupCategoriesQuery` and pass it any options that fit your needs.
 * When your component renders, `useOrderViewLineItemUpdatePopupCategoriesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOrderViewLineItemUpdatePopupCategoriesQuery({
 *   variables: {
 *   },
 * });
 */
export function useOrderViewLineItemUpdatePopupCategoriesQuery(
  baseOptions?: Apollo.QueryHookOptions<
    OrderViewLineItemUpdatePopupCategoriesQuery,
    OrderViewLineItemUpdatePopupCategoriesQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    OrderViewLineItemUpdatePopupCategoriesQuery,
    OrderViewLineItemUpdatePopupCategoriesQueryVariables
  >(OrderViewLineItemUpdatePopupCategoriesDocument, options);
}
export function useOrderViewLineItemUpdatePopupCategoriesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    OrderViewLineItemUpdatePopupCategoriesQuery,
    OrderViewLineItemUpdatePopupCategoriesQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    OrderViewLineItemUpdatePopupCategoriesQuery,
    OrderViewLineItemUpdatePopupCategoriesQueryVariables
  >(OrderViewLineItemUpdatePopupCategoriesDocument, options);
}
export type OrderViewLineItemUpdatePopupCategoriesQueryHookResult = ReturnType<
  typeof useOrderViewLineItemUpdatePopupCategoriesQuery
>;
export type OrderViewLineItemUpdatePopupCategoriesLazyQueryHookResult =
  ReturnType<typeof useOrderViewLineItemUpdatePopupCategoriesLazyQuery>;
export type OrderViewLineItemUpdatePopupCategoriesQueryResult =
  Apollo.QueryResult<
    OrderViewLineItemUpdatePopupCategoriesQuery,
    OrderViewLineItemUpdatePopupCategoriesQueryVariables
  >;
export const OrderViewLineItemUpdatePopupProductDocument = gql`
  query OrderViewLineItemUpdatePopupProduct($productId: Int!) {
    productById(productId: $productId) {
      ...OrderViewLineItemUpdateProduct
    }
  }
  ${OrderViewLineItemUpdateProductFragmentDoc}
`;

/**
 * __useOrderViewLineItemUpdatePopupProductQuery__
 *
 * To run a query within a React component, call `useOrderViewLineItemUpdatePopupProductQuery` and pass it any options that fit your needs.
 * When your component renders, `useOrderViewLineItemUpdatePopupProductQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOrderViewLineItemUpdatePopupProductQuery({
 *   variables: {
 *      productId: // value for 'productId'
 *   },
 * });
 */
export function useOrderViewLineItemUpdatePopupProductQuery(
  baseOptions: Apollo.QueryHookOptions<
    OrderViewLineItemUpdatePopupProductQuery,
    OrderViewLineItemUpdatePopupProductQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    OrderViewLineItemUpdatePopupProductQuery,
    OrderViewLineItemUpdatePopupProductQueryVariables
  >(OrderViewLineItemUpdatePopupProductDocument, options);
}
export function useOrderViewLineItemUpdatePopupProductLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    OrderViewLineItemUpdatePopupProductQuery,
    OrderViewLineItemUpdatePopupProductQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    OrderViewLineItemUpdatePopupProductQuery,
    OrderViewLineItemUpdatePopupProductQueryVariables
  >(OrderViewLineItemUpdatePopupProductDocument, options);
}
export type OrderViewLineItemUpdatePopupProductQueryHookResult = ReturnType<
  typeof useOrderViewLineItemUpdatePopupProductQuery
>;
export type OrderViewLineItemUpdatePopupProductLazyQueryHookResult = ReturnType<
  typeof useOrderViewLineItemUpdatePopupProductLazyQuery
>;
export type OrderViewLineItemUpdatePopupProductQueryResult = Apollo.QueryResult<
  OrderViewLineItemUpdatePopupProductQuery,
  OrderViewLineItemUpdatePopupProductQueryVariables
>;
export const OrderAddProductsDocument = gql`
  mutation OrderAddProducts(
    $orderId: Int!
    $companyId: Int!
    $supplierId: Int!
    $lineItemProducts: [OrderLineItemProductCreateInput!]!
  ) {
    lineItems {
      addProductsToOrder(
        orderId: $orderId
        companyId: $companyId
        supplierId: $supplierId
        lineItemProducts: $lineItemProducts
      ) {
        status
        lineItems {
          ...OrderView__LineItem
        }
      }
    }
  }
  ${OrderView__LineItemFragmentDoc}
`;
export type OrderAddProductsMutationFn = Apollo.MutationFunction<
  OrderAddProductsMutation,
  OrderAddProductsMutationVariables
>;

/**
 * __useOrderAddProductsMutation__
 *
 * To run a mutation, you first call `useOrderAddProductsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useOrderAddProductsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [orderAddProductsMutation, { data, loading, error }] = useOrderAddProductsMutation({
 *   variables: {
 *      orderId: // value for 'orderId'
 *      companyId: // value for 'companyId'
 *      supplierId: // value for 'supplierId'
 *      lineItemProducts: // value for 'lineItemProducts'
 *   },
 * });
 */
export function useOrderAddProductsMutation(
  baseOptions?: Apollo.MutationHookOptions<
    OrderAddProductsMutation,
    OrderAddProductsMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    OrderAddProductsMutation,
    OrderAddProductsMutationVariables
  >(OrderAddProductsDocument, options);
}
export type OrderAddProductsMutationHookResult = ReturnType<
  typeof useOrderAddProductsMutation
>;
export type OrderAddProductsMutationResult =
  Apollo.MutationResult<OrderAddProductsMutation>;
export type OrderAddProductsMutationOptions = Apollo.BaseMutationOptions<
  OrderAddProductsMutation,
  OrderAddProductsMutationVariables
>;
export const Order__AttachFilesDocument = gql`
  mutation Order__AttachFiles($data: [AttachmentCreateInput!]!) {
    attachments {
      createBulk(data: $data) {
        attachments {
          ...OrderView__Attachment
        }
      }
    }
  }
  ${OrderView__AttachmentFragmentDoc}
`;
export type Order__AttachFilesMutationFn = Apollo.MutationFunction<
  Order__AttachFilesMutation,
  Order__AttachFilesMutationVariables
>;

/**
 * __useOrder__AttachFilesMutation__
 *
 * To run a mutation, you first call `useOrder__AttachFilesMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useOrder__AttachFilesMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [orderAttachFilesMutation, { data, loading, error }] = useOrder__AttachFilesMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useOrder__AttachFilesMutation(
  baseOptions?: Apollo.MutationHookOptions<
    Order__AttachFilesMutation,
    Order__AttachFilesMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    Order__AttachFilesMutation,
    Order__AttachFilesMutationVariables
  >(Order__AttachFilesDocument, options);
}
export type Order__AttachFilesMutationHookResult = ReturnType<
  typeof useOrder__AttachFilesMutation
>;
export type Order__AttachFilesMutationResult =
  Apollo.MutationResult<Order__AttachFilesMutation>;
export type Order__AttachFilesMutationOptions = Apollo.BaseMutationOptions<
  Order__AttachFilesMutation,
  Order__AttachFilesMutationVariables
>;
export const OrderDeleteAttachmentDocument = gql`
  mutation OrderDeleteAttachment($id: Int!) {
    attachments {
      delete(id: $id) {
        status
      }
    }
  }
`;
export type OrderDeleteAttachmentMutationFn = Apollo.MutationFunction<
  OrderDeleteAttachmentMutation,
  OrderDeleteAttachmentMutationVariables
>;

/**
 * __useOrderDeleteAttachmentMutation__
 *
 * To run a mutation, you first call `useOrderDeleteAttachmentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useOrderDeleteAttachmentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [orderDeleteAttachmentMutation, { data, loading, error }] = useOrderDeleteAttachmentMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useOrderDeleteAttachmentMutation(
  baseOptions?: Apollo.MutationHookOptions<
    OrderDeleteAttachmentMutation,
    OrderDeleteAttachmentMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    OrderDeleteAttachmentMutation,
    OrderDeleteAttachmentMutationVariables
  >(OrderDeleteAttachmentDocument, options);
}
export type OrderDeleteAttachmentMutationHookResult = ReturnType<
  typeof useOrderDeleteAttachmentMutation
>;
export type OrderDeleteAttachmentMutationResult =
  Apollo.MutationResult<OrderDeleteAttachmentMutation>;
export type OrderDeleteAttachmentMutationOptions = Apollo.BaseMutationOptions<
  OrderDeleteAttachmentMutation,
  OrderDeleteAttachmentMutationVariables
>;
export const OrderLineItemProductUpdateDocument = gql`
  mutation OrderLineItemProductUpdate(
    $companyId: Int!
    $supplierId: Int!
    $lineItemId: Int!
    $lineItemProduct: OrderLineItemProductUpdateInput!
  ) {
    lineItems {
      updateOrderLineItemWithProduct(
        companyId: $companyId
        supplierId: $supplierId
        lineItemId: $lineItemId
        lineItemProduct: $lineItemProduct
      ) {
        lineItem {
          ...OrderLineItemProductUpdate__OrderLineItem
        }
      }
    }
  }
  ${OrderLineItemProductUpdate__OrderLineItemFragmentDoc}
`;
export type OrderLineItemProductUpdateMutationFn = Apollo.MutationFunction<
  OrderLineItemProductUpdateMutation,
  OrderLineItemProductUpdateMutationVariables
>;

/**
 * __useOrderLineItemProductUpdateMutation__
 *
 * To run a mutation, you first call `useOrderLineItemProductUpdateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useOrderLineItemProductUpdateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [orderLineItemProductUpdateMutation, { data, loading, error }] = useOrderLineItemProductUpdateMutation({
 *   variables: {
 *      companyId: // value for 'companyId'
 *      supplierId: // value for 'supplierId'
 *      lineItemId: // value for 'lineItemId'
 *      lineItemProduct: // value for 'lineItemProduct'
 *   },
 * });
 */
export function useOrderLineItemProductUpdateMutation(
  baseOptions?: Apollo.MutationHookOptions<
    OrderLineItemProductUpdateMutation,
    OrderLineItemProductUpdateMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    OrderLineItemProductUpdateMutation,
    OrderLineItemProductUpdateMutationVariables
  >(OrderLineItemProductUpdateDocument, options);
}
export type OrderLineItemProductUpdateMutationHookResult = ReturnType<
  typeof useOrderLineItemProductUpdateMutation
>;
export type OrderLineItemProductUpdateMutationResult =
  Apollo.MutationResult<OrderLineItemProductUpdateMutation>;
export type OrderLineItemProductUpdateMutationOptions =
  Apollo.BaseMutationOptions<
    OrderLineItemProductUpdateMutation,
    OrderLineItemProductUpdateMutationVariables
  >;
export const OrderLineItemQuantityUpdateDocument = gql`
  mutation OrderLineItemQuantityUpdate(
    $companyId: Int!
    $supplierId: Int!
    $lineItemId: Int!
    $quantity: Float!
  ) {
    lineItems {
      updateOrderLineItemWithProduct(
        companyId: $companyId
        supplierId: $supplierId
        lineItemId: $lineItemId
        lineItemProduct: { quantity: $quantity }
      ) {
        lineItem {
          id
          orderId
          quantity
          ...LineItemPrices
        }
      }
    }
  }
  ${LineItemPricesFragmentDoc}
`;
export type OrderLineItemQuantityUpdateMutationFn = Apollo.MutationFunction<
  OrderLineItemQuantityUpdateMutation,
  OrderLineItemQuantityUpdateMutationVariables
>;

/**
 * __useOrderLineItemQuantityUpdateMutation__
 *
 * To run a mutation, you first call `useOrderLineItemQuantityUpdateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useOrderLineItemQuantityUpdateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [orderLineItemQuantityUpdateMutation, { data, loading, error }] = useOrderLineItemQuantityUpdateMutation({
 *   variables: {
 *      companyId: // value for 'companyId'
 *      supplierId: // value for 'supplierId'
 *      lineItemId: // value for 'lineItemId'
 *      quantity: // value for 'quantity'
 *   },
 * });
 */
export function useOrderLineItemQuantityUpdateMutation(
  baseOptions?: Apollo.MutationHookOptions<
    OrderLineItemQuantityUpdateMutation,
    OrderLineItemQuantityUpdateMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    OrderLineItemQuantityUpdateMutation,
    OrderLineItemQuantityUpdateMutationVariables
  >(OrderLineItemQuantityUpdateDocument, options);
}
export type OrderLineItemQuantityUpdateMutationHookResult = ReturnType<
  typeof useOrderLineItemQuantityUpdateMutation
>;
export type OrderLineItemQuantityUpdateMutationResult =
  Apollo.MutationResult<OrderLineItemQuantityUpdateMutation>;
export type OrderLineItemQuantityUpdateMutationOptions =
  Apollo.BaseMutationOptions<
    OrderLineItemQuantityUpdateMutation,
    OrderLineItemQuantityUpdateMutationVariables
  >;
export const OrderLineItemUpdateDiscountDocument = gql`
  mutation OrderLineItemUpdateDiscount(
    $companyId: Int!
    $supplierId: Int!
    $lineItemId: Int!
    $discount: Int!
  ) {
    lineItems {
      updateOrderLineItemWithProduct(
        companyId: $companyId
        supplierId: $supplierId
        lineItemId: $lineItemId
        lineItemProduct: { discount: $discount }
      ) {
        status
        lineItem {
          id
          orderId
          discount
          ...LineItemPrices
        }
      }
    }
  }
  ${LineItemPricesFragmentDoc}
`;
export type OrderLineItemUpdateDiscountMutationFn = Apollo.MutationFunction<
  OrderLineItemUpdateDiscountMutation,
  OrderLineItemUpdateDiscountMutationVariables
>;

/**
 * __useOrderLineItemUpdateDiscountMutation__
 *
 * To run a mutation, you first call `useOrderLineItemUpdateDiscountMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useOrderLineItemUpdateDiscountMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [orderLineItemUpdateDiscountMutation, { data, loading, error }] = useOrderLineItemUpdateDiscountMutation({
 *   variables: {
 *      companyId: // value for 'companyId'
 *      supplierId: // value for 'supplierId'
 *      lineItemId: // value for 'lineItemId'
 *      discount: // value for 'discount'
 *   },
 * });
 */
export function useOrderLineItemUpdateDiscountMutation(
  baseOptions?: Apollo.MutationHookOptions<
    OrderLineItemUpdateDiscountMutation,
    OrderLineItemUpdateDiscountMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    OrderLineItemUpdateDiscountMutation,
    OrderLineItemUpdateDiscountMutationVariables
  >(OrderLineItemUpdateDiscountDocument, options);
}
export type OrderLineItemUpdateDiscountMutationHookResult = ReturnType<
  typeof useOrderLineItemUpdateDiscountMutation
>;
export type OrderLineItemUpdateDiscountMutationResult =
  Apollo.MutationResult<OrderLineItemUpdateDiscountMutation>;
export type OrderLineItemUpdateDiscountMutationOptions =
  Apollo.BaseMutationOptions<
    OrderLineItemUpdateDiscountMutation,
    OrderLineItemUpdateDiscountMutationVariables
  >;
export const OrderLineItemUpdatePriceDocument = gql`
  mutation OrderLineItemUpdatePrice(
    $companyId: Int!
    $supplierId: Int!
    $lineItemId: Int!
    $price: Float
  ) {
    lineItems {
      updateOrderLineItemWithProduct(
        companyId: $companyId
        supplierId: $supplierId
        lineItemId: $lineItemId
        lineItemProduct: { price: $price }
      ) {
        status
        lineItem {
          id
          price
          ...LineItemPrices
        }
      }
    }
  }
  ${LineItemPricesFragmentDoc}
`;
export type OrderLineItemUpdatePriceMutationFn = Apollo.MutationFunction<
  OrderLineItemUpdatePriceMutation,
  OrderLineItemUpdatePriceMutationVariables
>;

/**
 * __useOrderLineItemUpdatePriceMutation__
 *
 * To run a mutation, you first call `useOrderLineItemUpdatePriceMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useOrderLineItemUpdatePriceMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [orderLineItemUpdatePriceMutation, { data, loading, error }] = useOrderLineItemUpdatePriceMutation({
 *   variables: {
 *      companyId: // value for 'companyId'
 *      supplierId: // value for 'supplierId'
 *      lineItemId: // value for 'lineItemId'
 *      price: // value for 'price'
 *   },
 * });
 */
export function useOrderLineItemUpdatePriceMutation(
  baseOptions?: Apollo.MutationHookOptions<
    OrderLineItemUpdatePriceMutation,
    OrderLineItemUpdatePriceMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    OrderLineItemUpdatePriceMutation,
    OrderLineItemUpdatePriceMutationVariables
  >(OrderLineItemUpdatePriceDocument, options);
}
export type OrderLineItemUpdatePriceMutationHookResult = ReturnType<
  typeof useOrderLineItemUpdatePriceMutation
>;
export type OrderLineItemUpdatePriceMutationResult =
  Apollo.MutationResult<OrderLineItemUpdatePriceMutation>;
export type OrderLineItemUpdatePriceMutationOptions =
  Apollo.BaseMutationOptions<
    OrderLineItemUpdatePriceMutation,
    OrderLineItemUpdatePriceMutationVariables
  >;
export const OrderRemoveDocument = gql`
  mutation OrderRemove($orderId: Int!) {
    orders {
      remove(orderId: $orderId) {
        status
      }
    }
  }
`;
export type OrderRemoveMutationFn = Apollo.MutationFunction<
  OrderRemoveMutation,
  OrderRemoveMutationVariables
>;

/**
 * __useOrderRemoveMutation__
 *
 * To run a mutation, you first call `useOrderRemoveMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useOrderRemoveMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [orderRemoveMutation, { data, loading, error }] = useOrderRemoveMutation({
 *   variables: {
 *      orderId: // value for 'orderId'
 *   },
 * });
 */
export function useOrderRemoveMutation(
  baseOptions?: Apollo.MutationHookOptions<
    OrderRemoveMutation,
    OrderRemoveMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<OrderRemoveMutation, OrderRemoveMutationVariables>(
    OrderRemoveDocument,
    options,
  );
}
export type OrderRemoveMutationHookResult = ReturnType<
  typeof useOrderRemoveMutation
>;
export type OrderRemoveMutationResult =
  Apollo.MutationResult<OrderRemoveMutation>;
export type OrderRemoveMutationOptions = Apollo.BaseMutationOptions<
  OrderRemoveMutation,
  OrderRemoveMutationVariables
>;
export const OrderLineItemsRemoveDocument = gql`
  mutation OrderLineItemsRemove($lineItemIds: [Int!]!) {
    lineItems {
      removeOrderLineItem(lineItemIds: $lineItemIds) {
        status
      }
    }
  }
`;
export type OrderLineItemsRemoveMutationFn = Apollo.MutationFunction<
  OrderLineItemsRemoveMutation,
  OrderLineItemsRemoveMutationVariables
>;

/**
 * __useOrderLineItemsRemoveMutation__
 *
 * To run a mutation, you first call `useOrderLineItemsRemoveMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useOrderLineItemsRemoveMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [orderLineItemsRemoveMutation, { data, loading, error }] = useOrderLineItemsRemoveMutation({
 *   variables: {
 *      lineItemIds: // value for 'lineItemIds'
 *   },
 * });
 */
export function useOrderLineItemsRemoveMutation(
  baseOptions?: Apollo.MutationHookOptions<
    OrderLineItemsRemoveMutation,
    OrderLineItemsRemoveMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    OrderLineItemsRemoveMutation,
    OrderLineItemsRemoveMutationVariables
  >(OrderLineItemsRemoveDocument, options);
}
export type OrderLineItemsRemoveMutationHookResult = ReturnType<
  typeof useOrderLineItemsRemoveMutation
>;
export type OrderLineItemsRemoveMutationResult =
  Apollo.MutationResult<OrderLineItemsRemoveMutation>;
export type OrderLineItemsRemoveMutationOptions = Apollo.BaseMutationOptions<
  OrderLineItemsRemoveMutation,
  OrderLineItemsRemoveMutationVariables
>;
export const CreateShipmentDocument = gql`
  mutation CreateShipment($orderId: Int!, $data: ShipmentCreateInput!) {
    shipments {
      create(orderId: $orderId, data: $data) {
        ...OrderViewShipment
      }
    }
  }
  ${OrderViewShipmentFragmentDoc}
`;
export type CreateShipmentMutationFn = Apollo.MutationFunction<
  CreateShipmentMutation,
  CreateShipmentMutationVariables
>;

/**
 * __useCreateShipmentMutation__
 *
 * To run a mutation, you first call `useCreateShipmentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateShipmentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createShipmentMutation, { data, loading, error }] = useCreateShipmentMutation({
 *   variables: {
 *      orderId: // value for 'orderId'
 *      data: // value for 'data'
 *   },
 * });
 */
export function useCreateShipmentMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateShipmentMutation,
    CreateShipmentMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    CreateShipmentMutation,
    CreateShipmentMutationVariables
  >(CreateShipmentDocument, options);
}
export type CreateShipmentMutationHookResult = ReturnType<
  typeof useCreateShipmentMutation
>;
export type CreateShipmentMutationResult =
  Apollo.MutationResult<CreateShipmentMutation>;
export type CreateShipmentMutationOptions = Apollo.BaseMutationOptions<
  CreateShipmentMutation,
  CreateShipmentMutationVariables
>;
export const OrderView__DeleteShipmentCertificateDocument = gql`
  mutation OrderView__DeleteShipmentCertificate(
    $orderId: Int!
    $shipmentId: Int!
    $certificateId: Int!
  ) {
    shipments {
      deleteCertificate(
        orderId: $orderId
        shipmentId: $shipmentId
        certificateId: $certificateId
      ) {
        status
      }
    }
  }
`;
export type OrderView__DeleteShipmentCertificateMutationFn =
  Apollo.MutationFunction<
    OrderView__DeleteShipmentCertificateMutation,
    OrderView__DeleteShipmentCertificateMutationVariables
  >;

/**
 * __useOrderView__DeleteShipmentCertificateMutation__
 *
 * To run a mutation, you first call `useOrderView__DeleteShipmentCertificateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useOrderView__DeleteShipmentCertificateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [orderViewDeleteShipmentCertificateMutation, { data, loading, error }] = useOrderView__DeleteShipmentCertificateMutation({
 *   variables: {
 *      orderId: // value for 'orderId'
 *      shipmentId: // value for 'shipmentId'
 *      certificateId: // value for 'certificateId'
 *   },
 * });
 */
export function useOrderView__DeleteShipmentCertificateMutation(
  baseOptions?: Apollo.MutationHookOptions<
    OrderView__DeleteShipmentCertificateMutation,
    OrderView__DeleteShipmentCertificateMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    OrderView__DeleteShipmentCertificateMutation,
    OrderView__DeleteShipmentCertificateMutationVariables
  >(OrderView__DeleteShipmentCertificateDocument, options);
}
export type OrderView__DeleteShipmentCertificateMutationHookResult = ReturnType<
  typeof useOrderView__DeleteShipmentCertificateMutation
>;
export type OrderView__DeleteShipmentCertificateMutationResult =
  Apollo.MutationResult<OrderView__DeleteShipmentCertificateMutation>;
export type OrderView__DeleteShipmentCertificateMutationOptions =
  Apollo.BaseMutationOptions<
    OrderView__DeleteShipmentCertificateMutation,
    OrderView__DeleteShipmentCertificateMutationVariables
  >;
export const OrderView__UpdateShipmentDocument = gql`
  mutation OrderView__UpdateShipment(
    $orderId: Int!
    $shipmentId: Int!
    $data: ShipmentCreateInput!
  ) {
    shipments {
      update(data: $data, orderId: $orderId, shipmentId: $shipmentId) {
        id
        lineItems {
          id
          shippedQuantity
        }
      }
    }
  }
`;
export type OrderView__UpdateShipmentMutationFn = Apollo.MutationFunction<
  OrderView__UpdateShipmentMutation,
  OrderView__UpdateShipmentMutationVariables
>;

/**
 * __useOrderView__UpdateShipmentMutation__
 *
 * To run a mutation, you first call `useOrderView__UpdateShipmentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useOrderView__UpdateShipmentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [orderViewUpdateShipmentMutation, { data, loading, error }] = useOrderView__UpdateShipmentMutation({
 *   variables: {
 *      orderId: // value for 'orderId'
 *      shipmentId: // value for 'shipmentId'
 *      data: // value for 'data'
 *   },
 * });
 */
export function useOrderView__UpdateShipmentMutation(
  baseOptions?: Apollo.MutationHookOptions<
    OrderView__UpdateShipmentMutation,
    OrderView__UpdateShipmentMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    OrderView__UpdateShipmentMutation,
    OrderView__UpdateShipmentMutationVariables
  >(OrderView__UpdateShipmentDocument, options);
}
export type OrderView__UpdateShipmentMutationHookResult = ReturnType<
  typeof useOrderView__UpdateShipmentMutation
>;
export type OrderView__UpdateShipmentMutationResult =
  Apollo.MutationResult<OrderView__UpdateShipmentMutation>;
export type OrderView__UpdateShipmentMutationOptions =
  Apollo.BaseMutationOptions<
    OrderView__UpdateShipmentMutation,
    OrderView__UpdateShipmentMutationVariables
  >;
export const OrderView__UpdateOrderDocument = gql`
  mutation OrderView__UpdateOrder($orderId: Int!, $order: OrderUpdateInput!) {
    orders {
      update(orderId: $orderId, order: $order) {
        order {
          ...OrderUpdate__Order
        }
      }
    }
  }
  ${OrderUpdate__OrderFragmentDoc}
`;
export type OrderView__UpdateOrderMutationFn = Apollo.MutationFunction<
  OrderView__UpdateOrderMutation,
  OrderView__UpdateOrderMutationVariables
>;

/**
 * __useOrderView__UpdateOrderMutation__
 *
 * To run a mutation, you first call `useOrderView__UpdateOrderMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useOrderView__UpdateOrderMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [orderViewUpdateOrderMutation, { data, loading, error }] = useOrderView__UpdateOrderMutation({
 *   variables: {
 *      orderId: // value for 'orderId'
 *      order: // value for 'order'
 *   },
 * });
 */
export function useOrderView__UpdateOrderMutation(
  baseOptions?: Apollo.MutationHookOptions<
    OrderView__UpdateOrderMutation,
    OrderView__UpdateOrderMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    OrderView__UpdateOrderMutation,
    OrderView__UpdateOrderMutationVariables
  >(OrderView__UpdateOrderDocument, options);
}
export type OrderView__UpdateOrderMutationHookResult = ReturnType<
  typeof useOrderView__UpdateOrderMutation
>;
export type OrderView__UpdateOrderMutationResult =
  Apollo.MutationResult<OrderView__UpdateOrderMutation>;
export type OrderView__UpdateOrderMutationOptions = Apollo.BaseMutationOptions<
  OrderView__UpdateOrderMutation,
  OrderView__UpdateOrderMutationVariables
>;
export const OrderView__UpdateCustomLineItemDocument = gql`
  mutation OrderView__UpdateCustomLineItem(
    $lineItemId: Int!
    $lineItemCustom: OrderLineItemCustomUpdateInput!
  ) {
    lineItems {
      updateCustomOrderLineItem(
        lineItemId: $lineItemId
        lineItemCustom: $lineItemCustom
      ) {
        lineItem {
          ...UpdateCustomLineItemOptimisticResponse__LineItem
        }
      }
    }
  }
  ${UpdateCustomLineItemOptimisticResponse__LineItemFragmentDoc}
`;
export type OrderView__UpdateCustomLineItemMutationFn = Apollo.MutationFunction<
  OrderView__UpdateCustomLineItemMutation,
  OrderView__UpdateCustomLineItemMutationVariables
>;

/**
 * __useOrderView__UpdateCustomLineItemMutation__
 *
 * To run a mutation, you first call `useOrderView__UpdateCustomLineItemMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useOrderView__UpdateCustomLineItemMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [orderViewUpdateCustomLineItemMutation, { data, loading, error }] = useOrderView__UpdateCustomLineItemMutation({
 *   variables: {
 *      lineItemId: // value for 'lineItemId'
 *      lineItemCustom: // value for 'lineItemCustom'
 *   },
 * });
 */
export function useOrderView__UpdateCustomLineItemMutation(
  baseOptions?: Apollo.MutationHookOptions<
    OrderView__UpdateCustomLineItemMutation,
    OrderView__UpdateCustomLineItemMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    OrderView__UpdateCustomLineItemMutation,
    OrderView__UpdateCustomLineItemMutationVariables
  >(OrderView__UpdateCustomLineItemDocument, options);
}
export type OrderView__UpdateCustomLineItemMutationHookResult = ReturnType<
  typeof useOrderView__UpdateCustomLineItemMutation
>;
export type OrderView__UpdateCustomLineItemMutationResult =
  Apollo.MutationResult<OrderView__UpdateCustomLineItemMutation>;
export type OrderView__UpdateCustomLineItemMutationOptions =
  Apollo.BaseMutationOptions<
    OrderView__UpdateCustomLineItemMutation,
    OrderView__UpdateCustomLineItemMutationVariables
  >;
export const OrderView__CreateCustomProductDocument = gql`
  mutation OrderView__CreateCustomProduct(
    $orderId: Int!
    $lineItemCustom: LineItemCustomCreateInput!
  ) {
    lineItems {
      addCustomLineItemToOrder(
        orderId: $orderId
        lineItemCustom: $lineItemCustom
      ) {
        lineItem {
          ...OrderView__LineItem
        }
      }
    }
  }
  ${OrderView__LineItemFragmentDoc}
`;
export type OrderView__CreateCustomProductMutationFn = Apollo.MutationFunction<
  OrderView__CreateCustomProductMutation,
  OrderView__CreateCustomProductMutationVariables
>;

/**
 * __useOrderView__CreateCustomProductMutation__
 *
 * To run a mutation, you first call `useOrderView__CreateCustomProductMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useOrderView__CreateCustomProductMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [orderViewCreateCustomProductMutation, { data, loading, error }] = useOrderView__CreateCustomProductMutation({
 *   variables: {
 *      orderId: // value for 'orderId'
 *      lineItemCustom: // value for 'lineItemCustom'
 *   },
 * });
 */
export function useOrderView__CreateCustomProductMutation(
  baseOptions?: Apollo.MutationHookOptions<
    OrderView__CreateCustomProductMutation,
    OrderView__CreateCustomProductMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    OrderView__CreateCustomProductMutation,
    OrderView__CreateCustomProductMutationVariables
  >(OrderView__CreateCustomProductDocument, options);
}
export type OrderView__CreateCustomProductMutationHookResult = ReturnType<
  typeof useOrderView__CreateCustomProductMutation
>;
export type OrderView__CreateCustomProductMutationResult =
  Apollo.MutationResult<OrderView__CreateCustomProductMutation>;
export type OrderView__CreateCustomProductMutationOptions =
  Apollo.BaseMutationOptions<
    OrderView__CreateCustomProductMutation,
    OrderView__CreateCustomProductMutationVariables
  >;
export const OrderView__GetOrderDocument = gql`
  query OrderView__GetOrder($id: Int!) {
    orderById(id: $id) {
      ...OrderView__Order
    }
  }
  ${OrderView__OrderFragmentDoc}
`;

/**
 * __useOrderView__GetOrderQuery__
 *
 * To run a query within a React component, call `useOrderView__GetOrderQuery` and pass it any options that fit your needs.
 * When your component renders, `useOrderView__GetOrderQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOrderView__GetOrderQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useOrderView__GetOrderQuery(
  baseOptions: Apollo.QueryHookOptions<
    OrderView__GetOrderQuery,
    OrderView__GetOrderQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    OrderView__GetOrderQuery,
    OrderView__GetOrderQueryVariables
  >(OrderView__GetOrderDocument, options);
}
export function useOrderView__GetOrderLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    OrderView__GetOrderQuery,
    OrderView__GetOrderQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    OrderView__GetOrderQuery,
    OrderView__GetOrderQueryVariables
  >(OrderView__GetOrderDocument, options);
}
export type OrderView__GetOrderQueryHookResult = ReturnType<
  typeof useOrderView__GetOrderQuery
>;
export type OrderView__GetOrderLazyQueryHookResult = ReturnType<
  typeof useOrderView__GetOrderLazyQuery
>;
export type OrderView__GetOrderQueryResult = Apollo.QueryResult<
  OrderView__GetOrderQuery,
  OrderView__GetOrderQueryVariables
>;
export const OrdersViewFilters__GetSuppliersDocument = gql`
  query OrdersViewFilters__GetSuppliers {
    suppliersFindMany {
      items {
        id
        name
      }
    }
  }
`;

/**
 * __useOrdersViewFilters__GetSuppliersQuery__
 *
 * To run a query within a React component, call `useOrdersViewFilters__GetSuppliersQuery` and pass it any options that fit your needs.
 * When your component renders, `useOrdersViewFilters__GetSuppliersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOrdersViewFilters__GetSuppliersQuery({
 *   variables: {
 *   },
 * });
 */
export function useOrdersViewFilters__GetSuppliersQuery(
  baseOptions?: Apollo.QueryHookOptions<
    OrdersViewFilters__GetSuppliersQuery,
    OrdersViewFilters__GetSuppliersQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    OrdersViewFilters__GetSuppliersQuery,
    OrdersViewFilters__GetSuppliersQueryVariables
  >(OrdersViewFilters__GetSuppliersDocument, options);
}
export function useOrdersViewFilters__GetSuppliersLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    OrdersViewFilters__GetSuppliersQuery,
    OrdersViewFilters__GetSuppliersQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    OrdersViewFilters__GetSuppliersQuery,
    OrdersViewFilters__GetSuppliersQueryVariables
  >(OrdersViewFilters__GetSuppliersDocument, options);
}
export type OrdersViewFilters__GetSuppliersQueryHookResult = ReturnType<
  typeof useOrdersViewFilters__GetSuppliersQuery
>;
export type OrdersViewFilters__GetSuppliersLazyQueryHookResult = ReturnType<
  typeof useOrdersViewFilters__GetSuppliersLazyQuery
>;
export type OrdersViewFilters__GetSuppliersQueryResult = Apollo.QueryResult<
  OrdersViewFilters__GetSuppliersQuery,
  OrdersViewFilters__GetSuppliersQueryVariables
>;
export const OrderCreateDocument = gql`
  mutation OrderCreate($order: OrderCreateInput!) {
    orders {
      create(order: $order) {
        orderId
      }
    }
  }
`;
export type OrderCreateMutationFn = Apollo.MutationFunction<
  OrderCreateMutation,
  OrderCreateMutationVariables
>;

/**
 * __useOrderCreateMutation__
 *
 * To run a mutation, you first call `useOrderCreateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useOrderCreateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [orderCreateMutation, { data, loading, error }] = useOrderCreateMutation({
 *   variables: {
 *      order: // value for 'order'
 *   },
 * });
 */
export function useOrderCreateMutation(
  baseOptions?: Apollo.MutationHookOptions<
    OrderCreateMutation,
    OrderCreateMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<OrderCreateMutation, OrderCreateMutationVariables>(
    OrderCreateDocument,
    options,
  );
}
export type OrderCreateMutationHookResult = ReturnType<
  typeof useOrderCreateMutation
>;
export type OrderCreateMutationResult =
  Apollo.MutationResult<OrderCreateMutation>;
export type OrderCreateMutationOptions = Apollo.BaseMutationOptions<
  OrderCreateMutation,
  OrderCreateMutationVariables
>;
export const OrdersView__GetOrdersDocument = gql`
  query OrdersView__GetOrders(
    $page: Int!
    $filters: OrdersFindManyFiltersInput!
    $sortBy: OrdersSortInput!
  ) {
    ordersFindMany(
      pagination: { page: $page, perPage: 20 }
      filters: $filters
      sortBy: $sortBy
    ) {
      ...OrderViewOrders
    }
  }
  ${OrderViewOrdersFragmentDoc}
`;

/**
 * __useOrdersView__GetOrdersQuery__
 *
 * To run a query within a React component, call `useOrdersView__GetOrdersQuery` and pass it any options that fit your needs.
 * When your component renders, `useOrdersView__GetOrdersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOrdersView__GetOrdersQuery({
 *   variables: {
 *      page: // value for 'page'
 *      filters: // value for 'filters'
 *      sortBy: // value for 'sortBy'
 *   },
 * });
 */
export function useOrdersView__GetOrdersQuery(
  baseOptions: Apollo.QueryHookOptions<
    OrdersView__GetOrdersQuery,
    OrdersView__GetOrdersQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    OrdersView__GetOrdersQuery,
    OrdersView__GetOrdersQueryVariables
  >(OrdersView__GetOrdersDocument, options);
}
export function useOrdersView__GetOrdersLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    OrdersView__GetOrdersQuery,
    OrdersView__GetOrdersQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    OrdersView__GetOrdersQuery,
    OrdersView__GetOrdersQueryVariables
  >(OrdersView__GetOrdersDocument, options);
}
export type OrdersView__GetOrdersQueryHookResult = ReturnType<
  typeof useOrdersView__GetOrdersQuery
>;
export type OrdersView__GetOrdersLazyQueryHookResult = ReturnType<
  typeof useOrdersView__GetOrdersLazyQuery
>;
export type OrdersView__GetOrdersQueryResult = Apollo.QueryResult<
  OrdersView__GetOrdersQuery,
  OrdersView__GetOrdersQueryVariables
>;
export const OrdersView__GetProjectsDocument = gql`
  query OrdersView__GetProjects {
    projectsFindMany {
      items {
        ...OrdersViewProject
      }
    }
  }
  ${OrdersViewProjectFragmentDoc}
`;

/**
 * __useOrdersView__GetProjectsQuery__
 *
 * To run a query within a React component, call `useOrdersView__GetProjectsQuery` and pass it any options that fit your needs.
 * When your component renders, `useOrdersView__GetProjectsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOrdersView__GetProjectsQuery({
 *   variables: {
 *   },
 * });
 */
export function useOrdersView__GetProjectsQuery(
  baseOptions?: Apollo.QueryHookOptions<
    OrdersView__GetProjectsQuery,
    OrdersView__GetProjectsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    OrdersView__GetProjectsQuery,
    OrdersView__GetProjectsQueryVariables
  >(OrdersView__GetProjectsDocument, options);
}
export function useOrdersView__GetProjectsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    OrdersView__GetProjectsQuery,
    OrdersView__GetProjectsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    OrdersView__GetProjectsQuery,
    OrdersView__GetProjectsQueryVariables
  >(OrdersView__GetProjectsDocument, options);
}
export type OrdersView__GetProjectsQueryHookResult = ReturnType<
  typeof useOrdersView__GetProjectsQuery
>;
export type OrdersView__GetProjectsLazyQueryHookResult = ReturnType<
  typeof useOrdersView__GetProjectsLazyQuery
>;
export type OrdersView__GetProjectsQueryResult = Apollo.QueryResult<
  OrdersView__GetProjectsQuery,
  OrdersView__GetProjectsQueryVariables
>;
export const ProductForm__GetSuppliersDocument = gql`
  query ProductForm__GetSuppliers {
    suppliersFindMany {
      items {
        id
        name
        enable
      }
    }
  }
`;

/**
 * __useProductForm__GetSuppliersQuery__
 *
 * To run a query within a React component, call `useProductForm__GetSuppliersQuery` and pass it any options that fit your needs.
 * When your component renders, `useProductForm__GetSuppliersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useProductForm__GetSuppliersQuery({
 *   variables: {
 *   },
 * });
 */
export function useProductForm__GetSuppliersQuery(
  baseOptions?: Apollo.QueryHookOptions<
    ProductForm__GetSuppliersQuery,
    ProductForm__GetSuppliersQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    ProductForm__GetSuppliersQuery,
    ProductForm__GetSuppliersQueryVariables
  >(ProductForm__GetSuppliersDocument, options);
}
export function useProductForm__GetSuppliersLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    ProductForm__GetSuppliersQuery,
    ProductForm__GetSuppliersQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    ProductForm__GetSuppliersQuery,
    ProductForm__GetSuppliersQueryVariables
  >(ProductForm__GetSuppliersDocument, options);
}
export type ProductForm__GetSuppliersQueryHookResult = ReturnType<
  typeof useProductForm__GetSuppliersQuery
>;
export type ProductForm__GetSuppliersLazyQueryHookResult = ReturnType<
  typeof useProductForm__GetSuppliersLazyQuery
>;
export type ProductForm__GetSuppliersQueryResult = Apollo.QueryResult<
  ProductForm__GetSuppliersQuery,
  ProductForm__GetSuppliersQueryVariables
>;
export const ProductView__GetProductDocument = gql`
  query ProductView__GetProduct($productId: Int!) {
    productById(productId: $productId) {
      ...ProductView__Product
    }
  }
  ${ProductView__ProductFragmentDoc}
`;

/**
 * __useProductView__GetProductQuery__
 *
 * To run a query within a React component, call `useProductView__GetProductQuery` and pass it any options that fit your needs.
 * When your component renders, `useProductView__GetProductQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useProductView__GetProductQuery({
 *   variables: {
 *      productId: // value for 'productId'
 *   },
 * });
 */
export function useProductView__GetProductQuery(
  baseOptions: Apollo.QueryHookOptions<
    ProductView__GetProductQuery,
    ProductView__GetProductQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    ProductView__GetProductQuery,
    ProductView__GetProductQueryVariables
  >(ProductView__GetProductDocument, options);
}
export function useProductView__GetProductLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    ProductView__GetProductQuery,
    ProductView__GetProductQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    ProductView__GetProductQuery,
    ProductView__GetProductQueryVariables
  >(ProductView__GetProductDocument, options);
}
export type ProductView__GetProductQueryHookResult = ReturnType<
  typeof useProductView__GetProductQuery
>;
export type ProductView__GetProductLazyQueryHookResult = ReturnType<
  typeof useProductView__GetProductLazyQuery
>;
export type ProductView__GetProductQueryResult = Apollo.QueryResult<
  ProductView__GetProductQuery,
  ProductView__GetProductQueryVariables
>;
export const ProductView__CreateProductDocument = gql`
  mutation ProductView__CreateProduct($product: ProductCreateInput!) {
    products {
      create(product: $product) {
        product {
          ...ProductView__Product
        }
      }
    }
  }
  ${ProductView__ProductFragmentDoc}
`;
export type ProductView__CreateProductMutationFn = Apollo.MutationFunction<
  ProductView__CreateProductMutation,
  ProductView__CreateProductMutationVariables
>;

/**
 * __useProductView__CreateProductMutation__
 *
 * To run a mutation, you first call `useProductView__CreateProductMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useProductView__CreateProductMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [productViewCreateProductMutation, { data, loading, error }] = useProductView__CreateProductMutation({
 *   variables: {
 *      product: // value for 'product'
 *   },
 * });
 */
export function useProductView__CreateProductMutation(
  baseOptions?: Apollo.MutationHookOptions<
    ProductView__CreateProductMutation,
    ProductView__CreateProductMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    ProductView__CreateProductMutation,
    ProductView__CreateProductMutationVariables
  >(ProductView__CreateProductDocument, options);
}
export type ProductView__CreateProductMutationHookResult = ReturnType<
  typeof useProductView__CreateProductMutation
>;
export type ProductView__CreateProductMutationResult =
  Apollo.MutationResult<ProductView__CreateProductMutation>;
export type ProductView__CreateProductMutationOptions =
  Apollo.BaseMutationOptions<
    ProductView__CreateProductMutation,
    ProductView__CreateProductMutationVariables
  >;
export const ProductView__UpdateProductDocument = gql`
  mutation ProductView__UpdateProduct(
    $product: ProductUpdateInput!
    $productId: Int!
  ) {
    products {
      update(product: $product, productId: $productId) {
        product {
          ...ProductView__Product
        }
      }
    }
  }
  ${ProductView__ProductFragmentDoc}
`;
export type ProductView__UpdateProductMutationFn = Apollo.MutationFunction<
  ProductView__UpdateProductMutation,
  ProductView__UpdateProductMutationVariables
>;

/**
 * __useProductView__UpdateProductMutation__
 *
 * To run a mutation, you first call `useProductView__UpdateProductMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useProductView__UpdateProductMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [productViewUpdateProductMutation, { data, loading, error }] = useProductView__UpdateProductMutation({
 *   variables: {
 *      product: // value for 'product'
 *      productId: // value for 'productId'
 *   },
 * });
 */
export function useProductView__UpdateProductMutation(
  baseOptions?: Apollo.MutationHookOptions<
    ProductView__UpdateProductMutation,
    ProductView__UpdateProductMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    ProductView__UpdateProductMutation,
    ProductView__UpdateProductMutationVariables
  >(ProductView__UpdateProductDocument, options);
}
export type ProductView__UpdateProductMutationHookResult = ReturnType<
  typeof useProductView__UpdateProductMutation
>;
export type ProductView__UpdateProductMutationResult =
  Apollo.MutationResult<ProductView__UpdateProductMutation>;
export type ProductView__UpdateProductMutationOptions =
  Apollo.BaseMutationOptions<
    ProductView__UpdateProductMutation,
    ProductView__UpdateProductMutationVariables
  >;
export const ReportsViewShipmentsTableFilters__ProjectsDocument = gql`
  query ReportsViewShipmentsTableFilters__Projects {
    projectsFindMany {
      items {
        ...ReportsViewShipmentsTableFilters__Project
      }
    }
  }
  ${ReportsViewShipmentsTableFilters__ProjectFragmentDoc}
`;

/**
 * __useReportsViewShipmentsTableFilters__ProjectsQuery__
 *
 * To run a query within a React component, call `useReportsViewShipmentsTableFilters__ProjectsQuery` and pass it any options that fit your needs.
 * When your component renders, `useReportsViewShipmentsTableFilters__ProjectsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useReportsViewShipmentsTableFilters__ProjectsQuery({
 *   variables: {
 *   },
 * });
 */
export function useReportsViewShipmentsTableFilters__ProjectsQuery(
  baseOptions?: Apollo.QueryHookOptions<
    ReportsViewShipmentsTableFilters__ProjectsQuery,
    ReportsViewShipmentsTableFilters__ProjectsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    ReportsViewShipmentsTableFilters__ProjectsQuery,
    ReportsViewShipmentsTableFilters__ProjectsQueryVariables
  >(ReportsViewShipmentsTableFilters__ProjectsDocument, options);
}
export function useReportsViewShipmentsTableFilters__ProjectsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    ReportsViewShipmentsTableFilters__ProjectsQuery,
    ReportsViewShipmentsTableFilters__ProjectsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    ReportsViewShipmentsTableFilters__ProjectsQuery,
    ReportsViewShipmentsTableFilters__ProjectsQueryVariables
  >(ReportsViewShipmentsTableFilters__ProjectsDocument, options);
}
export type ReportsViewShipmentsTableFilters__ProjectsQueryHookResult =
  ReturnType<typeof useReportsViewShipmentsTableFilters__ProjectsQuery>;
export type ReportsViewShipmentsTableFilters__ProjectsLazyQueryHookResult =
  ReturnType<typeof useReportsViewShipmentsTableFilters__ProjectsLazyQuery>;
export type ReportsViewShipmentsTableFilters__ProjectsQueryResult =
  Apollo.QueryResult<
    ReportsViewShipmentsTableFilters__ProjectsQuery,
    ReportsViewShipmentsTableFilters__ProjectsQueryVariables
  >;
export const ReportsViewShipmentsTableFilters__SuppliersDocument = gql`
  query ReportsViewShipmentsTableFilters__Suppliers {
    suppliersFindMany {
      items {
        ...ReportsViewShipmentsTableFilters__Supplier
      }
    }
  }
  ${ReportsViewShipmentsTableFilters__SupplierFragmentDoc}
`;

/**
 * __useReportsViewShipmentsTableFilters__SuppliersQuery__
 *
 * To run a query within a React component, call `useReportsViewShipmentsTableFilters__SuppliersQuery` and pass it any options that fit your needs.
 * When your component renders, `useReportsViewShipmentsTableFilters__SuppliersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useReportsViewShipmentsTableFilters__SuppliersQuery({
 *   variables: {
 *   },
 * });
 */
export function useReportsViewShipmentsTableFilters__SuppliersQuery(
  baseOptions?: Apollo.QueryHookOptions<
    ReportsViewShipmentsTableFilters__SuppliersQuery,
    ReportsViewShipmentsTableFilters__SuppliersQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    ReportsViewShipmentsTableFilters__SuppliersQuery,
    ReportsViewShipmentsTableFilters__SuppliersQueryVariables
  >(ReportsViewShipmentsTableFilters__SuppliersDocument, options);
}
export function useReportsViewShipmentsTableFilters__SuppliersLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    ReportsViewShipmentsTableFilters__SuppliersQuery,
    ReportsViewShipmentsTableFilters__SuppliersQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    ReportsViewShipmentsTableFilters__SuppliersQuery,
    ReportsViewShipmentsTableFilters__SuppliersQueryVariables
  >(ReportsViewShipmentsTableFilters__SuppliersDocument, options);
}
export type ReportsViewShipmentsTableFilters__SuppliersQueryHookResult =
  ReturnType<typeof useReportsViewShipmentsTableFilters__SuppliersQuery>;
export type ReportsViewShipmentsTableFilters__SuppliersLazyQueryHookResult =
  ReturnType<typeof useReportsViewShipmentsTableFilters__SuppliersLazyQuery>;
export type ReportsViewShipmentsTableFilters__SuppliersQueryResult =
  Apollo.QueryResult<
    ReportsViewShipmentsTableFilters__SuppliersQuery,
    ReportsViewShipmentsTableFilters__SuppliersQueryVariables
  >;
export const ReportsViewShipmentsTableFilters__CompaniesDocument = gql`
  query ReportsViewShipmentsTableFilters__Companies {
    companiesFindMany {
      items {
        ...ReportsViewShipmentsTableFilters__Company
      }
    }
  }
  ${ReportsViewShipmentsTableFilters__CompanyFragmentDoc}
`;

/**
 * __useReportsViewShipmentsTableFilters__CompaniesQuery__
 *
 * To run a query within a React component, call `useReportsViewShipmentsTableFilters__CompaniesQuery` and pass it any options that fit your needs.
 * When your component renders, `useReportsViewShipmentsTableFilters__CompaniesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useReportsViewShipmentsTableFilters__CompaniesQuery({
 *   variables: {
 *   },
 * });
 */
export function useReportsViewShipmentsTableFilters__CompaniesQuery(
  baseOptions?: Apollo.QueryHookOptions<
    ReportsViewShipmentsTableFilters__CompaniesQuery,
    ReportsViewShipmentsTableFilters__CompaniesQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    ReportsViewShipmentsTableFilters__CompaniesQuery,
    ReportsViewShipmentsTableFilters__CompaniesQueryVariables
  >(ReportsViewShipmentsTableFilters__CompaniesDocument, options);
}
export function useReportsViewShipmentsTableFilters__CompaniesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    ReportsViewShipmentsTableFilters__CompaniesQuery,
    ReportsViewShipmentsTableFilters__CompaniesQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    ReportsViewShipmentsTableFilters__CompaniesQuery,
    ReportsViewShipmentsTableFilters__CompaniesQueryVariables
  >(ReportsViewShipmentsTableFilters__CompaniesDocument, options);
}
export type ReportsViewShipmentsTableFilters__CompaniesQueryHookResult =
  ReturnType<typeof useReportsViewShipmentsTableFilters__CompaniesQuery>;
export type ReportsViewShipmentsTableFilters__CompaniesLazyQueryHookResult =
  ReturnType<typeof useReportsViewShipmentsTableFilters__CompaniesLazyQuery>;
export type ReportsViewShipmentsTableFilters__CompaniesQueryResult =
  Apollo.QueryResult<
    ReportsViewShipmentsTableFilters__CompaniesQuery,
    ReportsViewShipmentsTableFilters__CompaniesQueryVariables
  >;
export const ReportsViewShipmentsTableFilters__ProductsDocument = gql`
  query ReportsViewShipmentsTableFilters__Products {
    productsFindMany {
      items {
        ...ReportsViewShipmentsTableFilters__Product
      }
    }
  }
  ${ReportsViewShipmentsTableFilters__ProductFragmentDoc}
`;

/**
 * __useReportsViewShipmentsTableFilters__ProductsQuery__
 *
 * To run a query within a React component, call `useReportsViewShipmentsTableFilters__ProductsQuery` and pass it any options that fit your needs.
 * When your component renders, `useReportsViewShipmentsTableFilters__ProductsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useReportsViewShipmentsTableFilters__ProductsQuery({
 *   variables: {
 *   },
 * });
 */
export function useReportsViewShipmentsTableFilters__ProductsQuery(
  baseOptions?: Apollo.QueryHookOptions<
    ReportsViewShipmentsTableFilters__ProductsQuery,
    ReportsViewShipmentsTableFilters__ProductsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    ReportsViewShipmentsTableFilters__ProductsQuery,
    ReportsViewShipmentsTableFilters__ProductsQueryVariables
  >(ReportsViewShipmentsTableFilters__ProductsDocument, options);
}
export function useReportsViewShipmentsTableFilters__ProductsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    ReportsViewShipmentsTableFilters__ProductsQuery,
    ReportsViewShipmentsTableFilters__ProductsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    ReportsViewShipmentsTableFilters__ProductsQuery,
    ReportsViewShipmentsTableFilters__ProductsQueryVariables
  >(ReportsViewShipmentsTableFilters__ProductsDocument, options);
}
export type ReportsViewShipmentsTableFilters__ProductsQueryHookResult =
  ReturnType<typeof useReportsViewShipmentsTableFilters__ProductsQuery>;
export type ReportsViewShipmentsTableFilters__ProductsLazyQueryHookResult =
  ReturnType<typeof useReportsViewShipmentsTableFilters__ProductsLazyQuery>;
export type ReportsViewShipmentsTableFilters__ProductsQueryResult =
  Apollo.QueryResult<
    ReportsViewShipmentsTableFilters__ProductsQuery,
    ReportsViewShipmentsTableFilters__ProductsQueryVariables
  >;
export const ReportsView__FindManyShipmentReportsDocument = gql`
  query ReportsView__FindManyShipmentReports(
    $page: Int!
    $perPage: Int!
    $filters: ShipmentsFindManyFiltersInput!
  ) {
    shipmentsFindMany(
      pagination: { page: $page, perPage: $perPage }
      filters: $filters
    ) {
      ...ReportsViewShipments__FindManyOutput
    }
  }
  ${ReportsViewShipments__FindManyOutputFragmentDoc}
`;

/**
 * __useReportsView__FindManyShipmentReportsQuery__
 *
 * To run a query within a React component, call `useReportsView__FindManyShipmentReportsQuery` and pass it any options that fit your needs.
 * When your component renders, `useReportsView__FindManyShipmentReportsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useReportsView__FindManyShipmentReportsQuery({
 *   variables: {
 *      page: // value for 'page'
 *      perPage: // value for 'perPage'
 *      filters: // value for 'filters'
 *   },
 * });
 */
export function useReportsView__FindManyShipmentReportsQuery(
  baseOptions: Apollo.QueryHookOptions<
    ReportsView__FindManyShipmentReportsQuery,
    ReportsView__FindManyShipmentReportsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    ReportsView__FindManyShipmentReportsQuery,
    ReportsView__FindManyShipmentReportsQueryVariables
  >(ReportsView__FindManyShipmentReportsDocument, options);
}
export function useReportsView__FindManyShipmentReportsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    ReportsView__FindManyShipmentReportsQuery,
    ReportsView__FindManyShipmentReportsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    ReportsView__FindManyShipmentReportsQuery,
    ReportsView__FindManyShipmentReportsQueryVariables
  >(ReportsView__FindManyShipmentReportsDocument, options);
}
export type ReportsView__FindManyShipmentReportsQueryHookResult = ReturnType<
  typeof useReportsView__FindManyShipmentReportsQuery
>;
export type ReportsView__FindManyShipmentReportsLazyQueryHookResult =
  ReturnType<typeof useReportsView__FindManyShipmentReportsLazyQuery>;
export type ReportsView__FindManyShipmentReportsQueryResult =
  Apollo.QueryResult<
    ReportsView__FindManyShipmentReportsQuery,
    ReportsView__FindManyShipmentReportsQueryVariables
  >;
export const RequisitionViewActions__DeleteRequisitionDocument = gql`
  mutation RequisitionViewActions__DeleteRequisition($id: Int!) {
    requisitions {
      delete(id: $id) {
        status
      }
    }
  }
`;
export type RequisitionViewActions__DeleteRequisitionMutationFn =
  Apollo.MutationFunction<
    RequisitionViewActions__DeleteRequisitionMutation,
    RequisitionViewActions__DeleteRequisitionMutationVariables
  >;

/**
 * __useRequisitionViewActions__DeleteRequisitionMutation__
 *
 * To run a mutation, you first call `useRequisitionViewActions__DeleteRequisitionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRequisitionViewActions__DeleteRequisitionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [requisitionViewActionsDeleteRequisitionMutation, { data, loading, error }] = useRequisitionViewActions__DeleteRequisitionMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useRequisitionViewActions__DeleteRequisitionMutation(
  baseOptions?: Apollo.MutationHookOptions<
    RequisitionViewActions__DeleteRequisitionMutation,
    RequisitionViewActions__DeleteRequisitionMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    RequisitionViewActions__DeleteRequisitionMutation,
    RequisitionViewActions__DeleteRequisitionMutationVariables
  >(RequisitionViewActions__DeleteRequisitionDocument, options);
}
export type RequisitionViewActions__DeleteRequisitionMutationHookResult =
  ReturnType<typeof useRequisitionViewActions__DeleteRequisitionMutation>;
export type RequisitionViewActions__DeleteRequisitionMutationResult =
  Apollo.MutationResult<RequisitionViewActions__DeleteRequisitionMutation>;
export type RequisitionViewActions__DeleteRequisitionMutationOptions =
  Apollo.BaseMutationOptions<
    RequisitionViewActions__DeleteRequisitionMutation,
    RequisitionViewActions__DeleteRequisitionMutationVariables
  >;
export const Requisition__AttachFilesDocument = gql`
  mutation Requisition__AttachFiles($data: [AttachmentCreateInput!]!) {
    attachments {
      createBulk(data: $data) {
        attachments {
          ...RequisitionView__Attachment
        }
      }
    }
  }
  ${RequisitionView__AttachmentFragmentDoc}
`;
export type Requisition__AttachFilesMutationFn = Apollo.MutationFunction<
  Requisition__AttachFilesMutation,
  Requisition__AttachFilesMutationVariables
>;

/**
 * __useRequisition__AttachFilesMutation__
 *
 * To run a mutation, you first call `useRequisition__AttachFilesMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRequisition__AttachFilesMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [requisitionAttachFilesMutation, { data, loading, error }] = useRequisition__AttachFilesMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useRequisition__AttachFilesMutation(
  baseOptions?: Apollo.MutationHookOptions<
    Requisition__AttachFilesMutation,
    Requisition__AttachFilesMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    Requisition__AttachFilesMutation,
    Requisition__AttachFilesMutationVariables
  >(Requisition__AttachFilesDocument, options);
}
export type Requisition__AttachFilesMutationHookResult = ReturnType<
  typeof useRequisition__AttachFilesMutation
>;
export type Requisition__AttachFilesMutationResult =
  Apollo.MutationResult<Requisition__AttachFilesMutation>;
export type Requisition__AttachFilesMutationOptions =
  Apollo.BaseMutationOptions<
    Requisition__AttachFilesMutation,
    Requisition__AttachFilesMutationVariables
  >;
export const RequisitionView__CreateOrdersDocument = gql`
  mutation RequisitionView__CreateOrders($requisitionId: Int!) {
    requisitions {
      createOrders(requisitionId: $requisitionId) {
        orderIds
      }
    }
  }
`;
export type RequisitionView__CreateOrdersMutationFn = Apollo.MutationFunction<
  RequisitionView__CreateOrdersMutation,
  RequisitionView__CreateOrdersMutationVariables
>;

/**
 * __useRequisitionView__CreateOrdersMutation__
 *
 * To run a mutation, you first call `useRequisitionView__CreateOrdersMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRequisitionView__CreateOrdersMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [requisitionViewCreateOrdersMutation, { data, loading, error }] = useRequisitionView__CreateOrdersMutation({
 *   variables: {
 *      requisitionId: // value for 'requisitionId'
 *   },
 * });
 */
export function useRequisitionView__CreateOrdersMutation(
  baseOptions?: Apollo.MutationHookOptions<
    RequisitionView__CreateOrdersMutation,
    RequisitionView__CreateOrdersMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    RequisitionView__CreateOrdersMutation,
    RequisitionView__CreateOrdersMutationVariables
  >(RequisitionView__CreateOrdersDocument, options);
}
export type RequisitionView__CreateOrdersMutationHookResult = ReturnType<
  typeof useRequisitionView__CreateOrdersMutation
>;
export type RequisitionView__CreateOrdersMutationResult =
  Apollo.MutationResult<RequisitionView__CreateOrdersMutation>;
export type RequisitionView__CreateOrdersMutationOptions =
  Apollo.BaseMutationOptions<
    RequisitionView__CreateOrdersMutation,
    RequisitionView__CreateOrdersMutationVariables
  >;
export const RequisitionDeleteAttachmentDocument = gql`
  mutation RequisitionDeleteAttachment($id: Int!) {
    attachments {
      delete(id: $id) {
        status
      }
    }
  }
`;
export type RequisitionDeleteAttachmentMutationFn = Apollo.MutationFunction<
  RequisitionDeleteAttachmentMutation,
  RequisitionDeleteAttachmentMutationVariables
>;

/**
 * __useRequisitionDeleteAttachmentMutation__
 *
 * To run a mutation, you first call `useRequisitionDeleteAttachmentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRequisitionDeleteAttachmentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [requisitionDeleteAttachmentMutation, { data, loading, error }] = useRequisitionDeleteAttachmentMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useRequisitionDeleteAttachmentMutation(
  baseOptions?: Apollo.MutationHookOptions<
    RequisitionDeleteAttachmentMutation,
    RequisitionDeleteAttachmentMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    RequisitionDeleteAttachmentMutation,
    RequisitionDeleteAttachmentMutationVariables
  >(RequisitionDeleteAttachmentDocument, options);
}
export type RequisitionDeleteAttachmentMutationHookResult = ReturnType<
  typeof useRequisitionDeleteAttachmentMutation
>;
export type RequisitionDeleteAttachmentMutationResult =
  Apollo.MutationResult<RequisitionDeleteAttachmentMutation>;
export type RequisitionDeleteAttachmentMutationOptions =
  Apollo.BaseMutationOptions<
    RequisitionDeleteAttachmentMutation,
    RequisitionDeleteAttachmentMutationVariables
  >;
export const RequisitionView__UpdateCustomLineItemDocument = gql`
  mutation RequisitionView__UpdateCustomLineItem(
    $lineItemId: Int!
    $lineItemCustom: RequisitionLineItemCustomUpdateInput!
  ) {
    lineItems {
      updateCustomRequisitionLineItem(
        lineItemId: $lineItemId
        lineItemCustom: $lineItemCustom
      ) {
        lineItem {
          ...UpdateCustomLineItemOptimisticResponse__RequisitionLineItem
        }
      }
    }
  }
  ${UpdateCustomLineItemOptimisticResponse__RequisitionLineItemFragmentDoc}
`;
export type RequisitionView__UpdateCustomLineItemMutationFn =
  Apollo.MutationFunction<
    RequisitionView__UpdateCustomLineItemMutation,
    RequisitionView__UpdateCustomLineItemMutationVariables
  >;

/**
 * __useRequisitionView__UpdateCustomLineItemMutation__
 *
 * To run a mutation, you first call `useRequisitionView__UpdateCustomLineItemMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRequisitionView__UpdateCustomLineItemMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [requisitionViewUpdateCustomLineItemMutation, { data, loading, error }] = useRequisitionView__UpdateCustomLineItemMutation({
 *   variables: {
 *      lineItemId: // value for 'lineItemId'
 *      lineItemCustom: // value for 'lineItemCustom'
 *   },
 * });
 */
export function useRequisitionView__UpdateCustomLineItemMutation(
  baseOptions?: Apollo.MutationHookOptions<
    RequisitionView__UpdateCustomLineItemMutation,
    RequisitionView__UpdateCustomLineItemMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    RequisitionView__UpdateCustomLineItemMutation,
    RequisitionView__UpdateCustomLineItemMutationVariables
  >(RequisitionView__UpdateCustomLineItemDocument, options);
}
export type RequisitionView__UpdateCustomLineItemMutationHookResult =
  ReturnType<typeof useRequisitionView__UpdateCustomLineItemMutation>;
export type RequisitionView__UpdateCustomLineItemMutationResult =
  Apollo.MutationResult<RequisitionView__UpdateCustomLineItemMutation>;
export type RequisitionView__UpdateCustomLineItemMutationOptions =
  Apollo.BaseMutationOptions<
    RequisitionView__UpdateCustomLineItemMutation,
    RequisitionView__UpdateCustomLineItemMutationVariables
  >;
export const RequisitionView__AddProductsDocument = gql`
  mutation RequisitionView__AddProducts(
    $requisitionId: Int!
    $companyId: Int!
    $lineItemProducts: [RequisitionLineItemProductCreateInput!]!
  ) {
    lineItems {
      addProductsToRequisition(
        requisitionId: $requisitionId
        companyId: $companyId
        lineItemProducts: $lineItemProducts
      ) {
        status
        lineItems {
          ...RequisitionView__LineItem
        }
      }
    }
  }
  ${RequisitionView__LineItemFragmentDoc}
`;
export type RequisitionView__AddProductsMutationFn = Apollo.MutationFunction<
  RequisitionView__AddProductsMutation,
  RequisitionView__AddProductsMutationVariables
>;

/**
 * __useRequisitionView__AddProductsMutation__
 *
 * To run a mutation, you first call `useRequisitionView__AddProductsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRequisitionView__AddProductsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [requisitionViewAddProductsMutation, { data, loading, error }] = useRequisitionView__AddProductsMutation({
 *   variables: {
 *      requisitionId: // value for 'requisitionId'
 *      companyId: // value for 'companyId'
 *      lineItemProducts: // value for 'lineItemProducts'
 *   },
 * });
 */
export function useRequisitionView__AddProductsMutation(
  baseOptions?: Apollo.MutationHookOptions<
    RequisitionView__AddProductsMutation,
    RequisitionView__AddProductsMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    RequisitionView__AddProductsMutation,
    RequisitionView__AddProductsMutationVariables
  >(RequisitionView__AddProductsDocument, options);
}
export type RequisitionView__AddProductsMutationHookResult = ReturnType<
  typeof useRequisitionView__AddProductsMutation
>;
export type RequisitionView__AddProductsMutationResult =
  Apollo.MutationResult<RequisitionView__AddProductsMutation>;
export type RequisitionView__AddProductsMutationOptions =
  Apollo.BaseMutationOptions<
    RequisitionView__AddProductsMutation,
    RequisitionView__AddProductsMutationVariables
  >;
export const RequisitionView__CreateCustomProductsDocument = gql`
  mutation RequisitionView__CreateCustomProducts(
    $requisitionId: Int!
    $lineItemProducts: [RequisitionLineItemCustomCreateInput!]!
  ) {
    lineItems {
      addCustomProductsToRequisition(
        requisitionId: $requisitionId
        lineItemProducts: $lineItemProducts
      ) {
        status
        lineItems {
          ...RequisitionView__LineItem
        }
      }
    }
  }
  ${RequisitionView__LineItemFragmentDoc}
`;
export type RequisitionView__CreateCustomProductsMutationFn =
  Apollo.MutationFunction<
    RequisitionView__CreateCustomProductsMutation,
    RequisitionView__CreateCustomProductsMutationVariables
  >;

/**
 * __useRequisitionView__CreateCustomProductsMutation__
 *
 * To run a mutation, you first call `useRequisitionView__CreateCustomProductsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRequisitionView__CreateCustomProductsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [requisitionViewCreateCustomProductsMutation, { data, loading, error }] = useRequisitionView__CreateCustomProductsMutation({
 *   variables: {
 *      requisitionId: // value for 'requisitionId'
 *      lineItemProducts: // value for 'lineItemProducts'
 *   },
 * });
 */
export function useRequisitionView__CreateCustomProductsMutation(
  baseOptions?: Apollo.MutationHookOptions<
    RequisitionView__CreateCustomProductsMutation,
    RequisitionView__CreateCustomProductsMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    RequisitionView__CreateCustomProductsMutation,
    RequisitionView__CreateCustomProductsMutationVariables
  >(RequisitionView__CreateCustomProductsDocument, options);
}
export type RequisitionView__CreateCustomProductsMutationHookResult =
  ReturnType<typeof useRequisitionView__CreateCustomProductsMutation>;
export type RequisitionView__CreateCustomProductsMutationResult =
  Apollo.MutationResult<RequisitionView__CreateCustomProductsMutation>;
export type RequisitionView__CreateCustomProductsMutationOptions =
  Apollo.BaseMutationOptions<
    RequisitionView__CreateCustomProductsMutation,
    RequisitionView__CreateCustomProductsMutationVariables
  >;
export const RequisitionView__RemoveLineItemsDocument = gql`
  mutation RequisitionView__RemoveLineItems($lineItemIds: [Int!]!) {
    lineItems {
      removeRequisitionLineItem(lineItemIds: $lineItemIds) {
        status
      }
    }
  }
`;
export type RequisitionView__RemoveLineItemsMutationFn =
  Apollo.MutationFunction<
    RequisitionView__RemoveLineItemsMutation,
    RequisitionView__RemoveLineItemsMutationVariables
  >;

/**
 * __useRequisitionView__RemoveLineItemsMutation__
 *
 * To run a mutation, you first call `useRequisitionView__RemoveLineItemsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRequisitionView__RemoveLineItemsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [requisitionViewRemoveLineItemsMutation, { data, loading, error }] = useRequisitionView__RemoveLineItemsMutation({
 *   variables: {
 *      lineItemIds: // value for 'lineItemIds'
 *   },
 * });
 */
export function useRequisitionView__RemoveLineItemsMutation(
  baseOptions?: Apollo.MutationHookOptions<
    RequisitionView__RemoveLineItemsMutation,
    RequisitionView__RemoveLineItemsMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    RequisitionView__RemoveLineItemsMutation,
    RequisitionView__RemoveLineItemsMutationVariables
  >(RequisitionView__RemoveLineItemsDocument, options);
}
export type RequisitionView__RemoveLineItemsMutationHookResult = ReturnType<
  typeof useRequisitionView__RemoveLineItemsMutation
>;
export type RequisitionView__RemoveLineItemsMutationResult =
  Apollo.MutationResult<RequisitionView__RemoveLineItemsMutation>;
export type RequisitionView__RemoveLineItemsMutationOptions =
  Apollo.BaseMutationOptions<
    RequisitionView__RemoveLineItemsMutation,
    RequisitionView__RemoveLineItemsMutationVariables
  >;
export const RequisitionView__GetRequisitionDocument = gql`
  query RequisitionView__GetRequisition($id: Int!) {
    requisitionById(id: $id) {
      ...RequisitionView__Requisition
    }
  }
  ${RequisitionView__RequisitionFragmentDoc}
`;

/**
 * __useRequisitionView__GetRequisitionQuery__
 *
 * To run a query within a React component, call `useRequisitionView__GetRequisitionQuery` and pass it any options that fit your needs.
 * When your component renders, `useRequisitionView__GetRequisitionQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useRequisitionView__GetRequisitionQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useRequisitionView__GetRequisitionQuery(
  baseOptions: Apollo.QueryHookOptions<
    RequisitionView__GetRequisitionQuery,
    RequisitionView__GetRequisitionQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    RequisitionView__GetRequisitionQuery,
    RequisitionView__GetRequisitionQueryVariables
  >(RequisitionView__GetRequisitionDocument, options);
}
export function useRequisitionView__GetRequisitionLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    RequisitionView__GetRequisitionQuery,
    RequisitionView__GetRequisitionQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    RequisitionView__GetRequisitionQuery,
    RequisitionView__GetRequisitionQueryVariables
  >(RequisitionView__GetRequisitionDocument, options);
}
export type RequisitionView__GetRequisitionQueryHookResult = ReturnType<
  typeof useRequisitionView__GetRequisitionQuery
>;
export type RequisitionView__GetRequisitionLazyQueryHookResult = ReturnType<
  typeof useRequisitionView__GetRequisitionLazyQuery
>;
export type RequisitionView__GetRequisitionQueryResult = Apollo.QueryResult<
  RequisitionView__GetRequisitionQuery,
  RequisitionView__GetRequisitionQueryVariables
>;
export const RequisitionView__GetRequisitionMetadataDocument = gql`
  query RequisitionView__GetRequisitionMetadata($id: Int!) {
    requisitionMetadataGetByRequisitionIds(ids: [$id]) {
      ...RequisitionView__RequisitionMetadata
    }
  }
  ${RequisitionView__RequisitionMetadataFragmentDoc}
`;

/**
 * __useRequisitionView__GetRequisitionMetadataQuery__
 *
 * To run a query within a React component, call `useRequisitionView__GetRequisitionMetadataQuery` and pass it any options that fit your needs.
 * When your component renders, `useRequisitionView__GetRequisitionMetadataQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useRequisitionView__GetRequisitionMetadataQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useRequisitionView__GetRequisitionMetadataQuery(
  baseOptions: Apollo.QueryHookOptions<
    RequisitionView__GetRequisitionMetadataQuery,
    RequisitionView__GetRequisitionMetadataQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    RequisitionView__GetRequisitionMetadataQuery,
    RequisitionView__GetRequisitionMetadataQueryVariables
  >(RequisitionView__GetRequisitionMetadataDocument, options);
}
export function useRequisitionView__GetRequisitionMetadataLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    RequisitionView__GetRequisitionMetadataQuery,
    RequisitionView__GetRequisitionMetadataQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    RequisitionView__GetRequisitionMetadataQuery,
    RequisitionView__GetRequisitionMetadataQueryVariables
  >(RequisitionView__GetRequisitionMetadataDocument, options);
}
export type RequisitionView__GetRequisitionMetadataQueryHookResult = ReturnType<
  typeof useRequisitionView__GetRequisitionMetadataQuery
>;
export type RequisitionView__GetRequisitionMetadataLazyQueryHookResult =
  ReturnType<typeof useRequisitionView__GetRequisitionMetadataLazyQuery>;
export type RequisitionView__GetRequisitionMetadataQueryResult =
  Apollo.QueryResult<
    RequisitionView__GetRequisitionMetadataQuery,
    RequisitionView__GetRequisitionMetadataQueryVariables
  >;
export const RequisitionView__UpdateProductLineItemDocument = gql`
  mutation RequisitionView__UpdateProductLineItem(
    $companyId: Int!
    $lineItemId: Int!
    $supplierId: Int
    $data: RequisitionLineItemProductUpdateInput!
  ) {
    lineItems {
      updateRequisitionLineItemWithProduct(
        companyId: $companyId
        lineItemId: $lineItemId
        supplierId: $supplierId
        lineItemProduct: $data
      ) {
        lineItem {
          ...UpdateProductLineItemOptimisticResponse__RequisitionLineItem
        }
      }
    }
  }
  ${UpdateProductLineItemOptimisticResponse__RequisitionLineItemFragmentDoc}
`;
export type RequisitionView__UpdateProductLineItemMutationFn =
  Apollo.MutationFunction<
    RequisitionView__UpdateProductLineItemMutation,
    RequisitionView__UpdateProductLineItemMutationVariables
  >;

/**
 * __useRequisitionView__UpdateProductLineItemMutation__
 *
 * To run a mutation, you first call `useRequisitionView__UpdateProductLineItemMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRequisitionView__UpdateProductLineItemMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [requisitionViewUpdateProductLineItemMutation, { data, loading, error }] = useRequisitionView__UpdateProductLineItemMutation({
 *   variables: {
 *      companyId: // value for 'companyId'
 *      lineItemId: // value for 'lineItemId'
 *      supplierId: // value for 'supplierId'
 *      data: // value for 'data'
 *   },
 * });
 */
export function useRequisitionView__UpdateProductLineItemMutation(
  baseOptions?: Apollo.MutationHookOptions<
    RequisitionView__UpdateProductLineItemMutation,
    RequisitionView__UpdateProductLineItemMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    RequisitionView__UpdateProductLineItemMutation,
    RequisitionView__UpdateProductLineItemMutationVariables
  >(RequisitionView__UpdateProductLineItemDocument, options);
}
export type RequisitionView__UpdateProductLineItemMutationHookResult =
  ReturnType<typeof useRequisitionView__UpdateProductLineItemMutation>;
export type RequisitionView__UpdateProductLineItemMutationResult =
  Apollo.MutationResult<RequisitionView__UpdateProductLineItemMutation>;
export type RequisitionView__UpdateProductLineItemMutationOptions =
  Apollo.BaseMutationOptions<
    RequisitionView__UpdateProductLineItemMutation,
    RequisitionView__UpdateProductLineItemMutationVariables
  >;
export const RequisitionView__UpdateRequisitionDocument = gql`
  mutation RequisitionView__UpdateRequisition(
    $id: Int!
    $requisition: RequisitionUpdateInput!
  ) {
    requisitions {
      update(id: $id, requisition: $requisition) {
        requisition {
          id
          status
          deliveryDate
          note
          discount
        }
      }
    }
  }
`;
export type RequisitionView__UpdateRequisitionMutationFn =
  Apollo.MutationFunction<
    RequisitionView__UpdateRequisitionMutation,
    RequisitionView__UpdateRequisitionMutationVariables
  >;

/**
 * __useRequisitionView__UpdateRequisitionMutation__
 *
 * To run a mutation, you first call `useRequisitionView__UpdateRequisitionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRequisitionView__UpdateRequisitionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [requisitionViewUpdateRequisitionMutation, { data, loading, error }] = useRequisitionView__UpdateRequisitionMutation({
 *   variables: {
 *      id: // value for 'id'
 *      requisition: // value for 'requisition'
 *   },
 * });
 */
export function useRequisitionView__UpdateRequisitionMutation(
  baseOptions?: Apollo.MutationHookOptions<
    RequisitionView__UpdateRequisitionMutation,
    RequisitionView__UpdateRequisitionMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    RequisitionView__UpdateRequisitionMutation,
    RequisitionView__UpdateRequisitionMutationVariables
  >(RequisitionView__UpdateRequisitionDocument, options);
}
export type RequisitionView__UpdateRequisitionMutationHookResult = ReturnType<
  typeof useRequisitionView__UpdateRequisitionMutation
>;
export type RequisitionView__UpdateRequisitionMutationResult =
  Apollo.MutationResult<RequisitionView__UpdateRequisitionMutation>;
export type RequisitionView__UpdateRequisitionMutationOptions =
  Apollo.BaseMutationOptions<
    RequisitionView__UpdateRequisitionMutation,
    RequisitionView__UpdateRequisitionMutationVariables
  >;
export const RequisitionView__UpdateSupplierIdDocument = gql`
  mutation RequisitionView__UpdateSupplierId(
    $lineItemIds: [Int!]!
    $supplierId: Int!
  ) {
    lineItems {
      updateRequisitionLineItemsSupplierId(
        lineItemIds: $lineItemIds
        supplierId: $supplierId
      ) {
        status
        lineItems {
          ...UpdateProductLineItemOptimisticResponse__RequisitionLineItem
        }
      }
    }
  }
  ${UpdateProductLineItemOptimisticResponse__RequisitionLineItemFragmentDoc}
`;
export type RequisitionView__UpdateSupplierIdMutationFn =
  Apollo.MutationFunction<
    RequisitionView__UpdateSupplierIdMutation,
    RequisitionView__UpdateSupplierIdMutationVariables
  >;

/**
 * __useRequisitionView__UpdateSupplierIdMutation__
 *
 * To run a mutation, you first call `useRequisitionView__UpdateSupplierIdMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRequisitionView__UpdateSupplierIdMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [requisitionViewUpdateSupplierIdMutation, { data, loading, error }] = useRequisitionView__UpdateSupplierIdMutation({
 *   variables: {
 *      lineItemIds: // value for 'lineItemIds'
 *      supplierId: // value for 'supplierId'
 *   },
 * });
 */
export function useRequisitionView__UpdateSupplierIdMutation(
  baseOptions?: Apollo.MutationHookOptions<
    RequisitionView__UpdateSupplierIdMutation,
    RequisitionView__UpdateSupplierIdMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    RequisitionView__UpdateSupplierIdMutation,
    RequisitionView__UpdateSupplierIdMutationVariables
  >(RequisitionView__UpdateSupplierIdDocument, options);
}
export type RequisitionView__UpdateSupplierIdMutationHookResult = ReturnType<
  typeof useRequisitionView__UpdateSupplierIdMutation
>;
export type RequisitionView__UpdateSupplierIdMutationResult =
  Apollo.MutationResult<RequisitionView__UpdateSupplierIdMutation>;
export type RequisitionView__UpdateSupplierIdMutationOptions =
  Apollo.BaseMutationOptions<
    RequisitionView__UpdateSupplierIdMutation,
    RequisitionView__UpdateSupplierIdMutationVariables
  >;
export const RequisitionsView__FindProjectsDocument = gql`
  query RequisitionsView__FindProjects {
    projectsFindMany {
      items {
        ...RequisitionsView__Project
      }
    }
  }
  ${RequisitionsView__ProjectFragmentDoc}
`;

/**
 * __useRequisitionsView__FindProjectsQuery__
 *
 * To run a query within a React component, call `useRequisitionsView__FindProjectsQuery` and pass it any options that fit your needs.
 * When your component renders, `useRequisitionsView__FindProjectsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useRequisitionsView__FindProjectsQuery({
 *   variables: {
 *   },
 * });
 */
export function useRequisitionsView__FindProjectsQuery(
  baseOptions?: Apollo.QueryHookOptions<
    RequisitionsView__FindProjectsQuery,
    RequisitionsView__FindProjectsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    RequisitionsView__FindProjectsQuery,
    RequisitionsView__FindProjectsQueryVariables
  >(RequisitionsView__FindProjectsDocument, options);
}
export function useRequisitionsView__FindProjectsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    RequisitionsView__FindProjectsQuery,
    RequisitionsView__FindProjectsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    RequisitionsView__FindProjectsQuery,
    RequisitionsView__FindProjectsQueryVariables
  >(RequisitionsView__FindProjectsDocument, options);
}
export type RequisitionsView__FindProjectsQueryHookResult = ReturnType<
  typeof useRequisitionsView__FindProjectsQuery
>;
export type RequisitionsView__FindProjectsLazyQueryHookResult = ReturnType<
  typeof useRequisitionsView__FindProjectsLazyQuery
>;
export type RequisitionsView__FindProjectsQueryResult = Apollo.QueryResult<
  RequisitionsView__FindProjectsQuery,
  RequisitionsView__FindProjectsQueryVariables
>;
export const RequisitionsView__FindManyRequisitionsDocument = gql`
  query RequisitionsView__FindManyRequisitions(
    $page: Int!
    $perPage: Int!
    $filters: RequisitionsFindManyFiltersInput!
  ) {
    requisitionsFindMany(
      pagination: { page: $page, perPage: $perPage }
      filters: $filters
    ) {
      ...RequisitionsView__FindManyOutput
    }
  }
  ${RequisitionsView__FindManyOutputFragmentDoc}
`;

/**
 * __useRequisitionsView__FindManyRequisitionsQuery__
 *
 * To run a query within a React component, call `useRequisitionsView__FindManyRequisitionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useRequisitionsView__FindManyRequisitionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useRequisitionsView__FindManyRequisitionsQuery({
 *   variables: {
 *      page: // value for 'page'
 *      perPage: // value for 'perPage'
 *      filters: // value for 'filters'
 *   },
 * });
 */
export function useRequisitionsView__FindManyRequisitionsQuery(
  baseOptions: Apollo.QueryHookOptions<
    RequisitionsView__FindManyRequisitionsQuery,
    RequisitionsView__FindManyRequisitionsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    RequisitionsView__FindManyRequisitionsQuery,
    RequisitionsView__FindManyRequisitionsQueryVariables
  >(RequisitionsView__FindManyRequisitionsDocument, options);
}
export function useRequisitionsView__FindManyRequisitionsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    RequisitionsView__FindManyRequisitionsQuery,
    RequisitionsView__FindManyRequisitionsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    RequisitionsView__FindManyRequisitionsQuery,
    RequisitionsView__FindManyRequisitionsQueryVariables
  >(RequisitionsView__FindManyRequisitionsDocument, options);
}
export type RequisitionsView__FindManyRequisitionsQueryHookResult = ReturnType<
  typeof useRequisitionsView__FindManyRequisitionsQuery
>;
export type RequisitionsView__FindManyRequisitionsLazyQueryHookResult =
  ReturnType<typeof useRequisitionsView__FindManyRequisitionsLazyQuery>;
export type RequisitionsView__FindManyRequisitionsQueryResult =
  Apollo.QueryResult<
    RequisitionsView__FindManyRequisitionsQuery,
    RequisitionsView__FindManyRequisitionsQueryVariables
  >;
export const RequisitionsView__CreateRequisitionDocument = gql`
  mutation RequisitionsView__CreateRequisition(
    $requisition: RequisitionCreateInput!
  ) {
    requisitions {
      create(requisition: $requisition) {
        requisition {
          ...RequisitionsView__Requisition
        }
      }
    }
  }
  ${RequisitionsView__RequisitionFragmentDoc}
`;
export type RequisitionsView__CreateRequisitionMutationFn =
  Apollo.MutationFunction<
    RequisitionsView__CreateRequisitionMutation,
    RequisitionsView__CreateRequisitionMutationVariables
  >;

/**
 * __useRequisitionsView__CreateRequisitionMutation__
 *
 * To run a mutation, you first call `useRequisitionsView__CreateRequisitionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRequisitionsView__CreateRequisitionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [requisitionsViewCreateRequisitionMutation, { data, loading, error }] = useRequisitionsView__CreateRequisitionMutation({
 *   variables: {
 *      requisition: // value for 'requisition'
 *   },
 * });
 */
export function useRequisitionsView__CreateRequisitionMutation(
  baseOptions?: Apollo.MutationHookOptions<
    RequisitionsView__CreateRequisitionMutation,
    RequisitionsView__CreateRequisitionMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    RequisitionsView__CreateRequisitionMutation,
    RequisitionsView__CreateRequisitionMutationVariables
  >(RequisitionsView__CreateRequisitionDocument, options);
}
export type RequisitionsView__CreateRequisitionMutationHookResult = ReturnType<
  typeof useRequisitionsView__CreateRequisitionMutation
>;
export type RequisitionsView__CreateRequisitionMutationResult =
  Apollo.MutationResult<RequisitionsView__CreateRequisitionMutation>;
export type RequisitionsView__CreateRequisitionMutationOptions =
  Apollo.BaseMutationOptions<
    RequisitionsView__CreateRequisitionMutation,
    RequisitionsView__CreateRequisitionMutationVariables
  >;
export const GetSubCompaniesDocument = gql`
  query GetSubCompanies(
    $filters: SubCompanyFindManyFiltersInput
    $pagination: PaginationArgs
  ) {
    subCompaniesFindMany(pagination: $pagination, filters: $filters) {
      items {
        ...GetSubCompanies__SubCompany
      }
      pageInfo {
        ...Pagination
      }
    }
  }
  ${GetSubCompanies__SubCompanyFragmentDoc}
  ${PaginationFragmentDoc}
`;

/**
 * __useGetSubCompaniesQuery__
 *
 * To run a query within a React component, call `useGetSubCompaniesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetSubCompaniesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetSubCompaniesQuery({
 *   variables: {
 *      filters: // value for 'filters'
 *      pagination: // value for 'pagination'
 *   },
 * });
 */
export function useGetSubCompaniesQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetSubCompaniesQuery,
    GetSubCompaniesQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetSubCompaniesQuery, GetSubCompaniesQueryVariables>(
    GetSubCompaniesDocument,
    options,
  );
}
export function useGetSubCompaniesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetSubCompaniesQuery,
    GetSubCompaniesQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetSubCompaniesQuery,
    GetSubCompaniesQueryVariables
  >(GetSubCompaniesDocument, options);
}
export type GetSubCompaniesQueryHookResult = ReturnType<
  typeof useGetSubCompaniesQuery
>;
export type GetSubCompaniesLazyQueryHookResult = ReturnType<
  typeof useGetSubCompaniesLazyQuery
>;
export type GetSubCompaniesQueryResult = Apollo.QueryResult<
  GetSubCompaniesQuery,
  GetSubCompaniesQueryVariables
>;
