import { UserRole } from "../../../generated/graphql";
import { authenticationClient } from "../clients";
import { SuccessfulAuth } from "../../auth/authSlice";
import { decodeIdToken } from "../clients/decodeIdToken";

interface Props {
  accessToken: string;
  idToken: string;
}

const getUserInfo = ({ accessToken, idToken }: Props) =>
  new Promise<SuccessfulAuth>((resolve, reject) =>
    authenticationClient.userInfo(accessToken, (error, result) => {
      if (error) {
        reject(error);
      } else {
        const {
          email,
          sub: userId,
          "https://punct.co.il/roles": roles,
        } = result;

        const { companyName, name, userImage, idTokenError, companyId } =
          decodeIdToken(idToken);

        if (idTokenError) {
          reject(idTokenError);
        }

        resolve({
          email,
          accessToken,
          userId,
          companyName,
          name,
          userImage,
          roles: roles as UserRole[],
          companyId,
        });
      }
    }),
  );

export { getUserInfo };
