import {
  CircularProgress,
  InputAdornment,
  StandardTextFieldProps as MaterialTextFieldProps,
  TextField as MaterialTextField,
} from "@material-ui/core";
import cn from "classnames";
import React, {
  ForwardedRef,
  forwardRef,
  PropsWithChildren,
  ReactNode,
} from "react";
import styled, { ThemeProps } from "styled-components";
import { PunctTheme, styledColor } from "../../styles/theme";

type StyledTextFieldProps = ThemeProps<PunctTheme> & {
  loading?: boolean;
};

const StyledTextField = styled(MaterialTextField)`
  &.TextInput__textField_fullWidth {
    width: 100%;
  }

  .TextInput__input {
    text-align: right;
    color: ${(props: StyledTextFieldProps) =>
      props.loading ? "transparent" : styledColor("dark")(props)};
  }

  .TextInput__label {
    text-align: right;
    color: #292929;
  }
`;

const StyledCircularProgress = styled(CircularProgress)`
  position: absolute;
  inset-inline-start: 0;
`;

export type TextInputProps = Omit<
  MaterialTextFieldProps,
  "error" | "defaultValue"
> & {
  className?: string;
  inputClassName?: string;
  error?: string;
  inputSuffix?: ReactNode;
  inputPrefix?: ReactNode;
  fullWidth?: boolean;
  shrinkLabel?: boolean;
  "aria-label"?: string;
  autoSelect?: boolean;
  loading?: boolean;
};

const TextInput = forwardRef(function TextInput(
  {
    id,
    className,
    inputClassName,
    error,
    inputSuffix,
    inputPrefix,
    fullWidth = false,
    shrinkLabel,
    "aria-label": ariaLabel,
    onFocus,
    autoSelect,
    loading,
    placeholder,
    ...textInputProps
  }: PropsWithChildren<TextInputProps>,
  ref: ForwardedRef<HTMLDivElement>,
) {
  return (
    <div ref={ref} className={className}>
      <StyledTextField
        loading={loading}
        placeholder={loading ? undefined : placeholder}
        className={cn({ TextInput__textField_fullWidth: fullWidth })}
        error={!!error}
        helperText={error}
        inputProps={{
          "data-testid": "text-input",
          "aria-label": ariaLabel,
          autocomplete: "off",
        }}
        InputProps={{
          classes: {
            input: inputClassName,
          },
          id,
          className: cn("TextInput__input"),
          endAdornment: inputSuffix ? (
            <InputAdornment position="end">{inputSuffix}</InputAdornment>
          ) : undefined,
          readOnly: loading,
          startAdornment: loading ? (
            <StyledCircularProgress size={16} />
          ) : inputPrefix ? (
            <InputAdornment position="start">{inputPrefix}</InputAdornment>
          ) : undefined,
        }}
        InputLabelProps={{
          className: "TextInput__label",
          shrink: shrinkLabel ? true : undefined,
          htmlFor: id,
        }}
        onFocus={(e) => {
          if (autoSelect) {
            e.target.select();
          }
          onFocus?.(e);
        }}
        {...textInputProps}
      />
    </div>
  );
});

export { TextInput };
