import React, { FC } from "react";
import { Icon, IconProps } from "./components/Icon";

const ArrowDown: FC<IconProps> = ({ ...props }) => {
  return (
    <Icon {...props} d="M7.41 7.84L12 12.42l4.59-4.58L18 9.25l-6 6-6-6z" />
  );
};

export { ArrowDown };
