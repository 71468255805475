// This file configures the initialization of Sentry on the browser.
// The config you add here will be used whenever a page is visited.
// https://docs.sentry.io/platforms/javascript/guides/nextjs/

import * as Sentry from "@sentry/nextjs";
import { baseSentryConfig } from "./baseSentryConfig";

// don't use sentry locally
if (process.env.NEXT_PUBLIC_APP_ENV !== "local") {
  Sentry.init(baseSentryConfig);
}
