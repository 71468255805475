import { createSelector, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "./index";

type RouterHistoryReducer = {
  history: string[];
};

const initialState: RouterHistoryReducer = {
  history: [],
};

const {
  actions: { push: pushToRouterHistory },
  reducer: houterHistoryReducer,
} = createSlice({
  name: "routerHistory",
  initialState,
  reducers: {
    push: (state, action: PayloadAction<string>) => ({
      ...state,
      history: [action.payload, ...state.history],
    }),
  },
});

const routerHistorySelector = createSelector(
  (state: RootState) => state.routerHistory,
  (historyState: RouterHistoryReducer) => historyState.history,
);

export { houterHistoryReducer, pushToRouterHistory, routerHistorySelector };
