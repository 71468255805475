import { createTheme } from "@material-ui/core/styles";
import CircularProgress from "@material-ui/core/CircularProgress";
import { ThemeProvider } from "@material-ui/styles";
import React, { ComponentProps, FC } from "react";
import styled from "styled-components";
import { theme as punctTheme } from "../styles/theme";

const StyledSpinner = styled.div``;

const theme = createTheme({
  palette: {
    primary: {
      main: punctTheme.colors.yellow,
    },
  },
});

type Props = {
  className?: string;
  size?: number;
} & Pick<ComponentProps<typeof CircularProgress>, "color">;

const Spinner: FC<Props> = ({ className, color = "primary", size = 40 }) => {
  return (
    <StyledSpinner className={className}>
      <ThemeProvider theme={theme}>
        <CircularProgress size={size} color={color} />
      </ThemeProvider>
    </StyledSpinner>
  );
};

export { Spinner };
