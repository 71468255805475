import React, { FC } from "react";
import styled from "styled-components";

const StyledHeaderAvatar = styled.button`
  width: 40px;
  height: 40px;

  border: none;
  padding: 0;
  background: none;

  cursor: pointer;

  .HeaderAvatar__image {
    width: 100%;
    height: 100%;

    object-fit: contain;
    border-radius: 50%;
  }

  .HeaderAvatar__name {
    display: flex;
    justify-content: center;
    align-items: center;

    font-weight: bold;
    font-size: 16px;
    line-height: 21px;
    text-transform: uppercase;

    background: ${({ theme }) => theme.colors.white};
    border-radius: 50%;
  }
`;

interface Props {
  className?: string;
  name?: string;
  image?: string;
  onClick: (event: React.MouseEvent<HTMLButtonElement>) => void;
  "data-cy"?: string;
}

const HeaderAvatar: FC<Props> = ({
  className,
  name,
  image,
  onClick,
  "data-cy": dataCy,
}) => {
  return (
    <StyledHeaderAvatar
      className={className}
      onClick={onClick}
      data-cy={dataCy}
    >
      {image ? (
        <img className="HeaderAvatar__image" src={image} alt="User avatar" />
      ) : (
        <div className="HeaderAvatar__name">{name?.slice(0, 1)}</div>
      )}
    </StyledHeaderAvatar>
  );
};

export { HeaderAvatar };
