import cn from "classnames";
import Link from "next/link";
import { useRouter } from "next/router";
import React, { FC } from "react";
import styled from "styled-components";

const StyledHeaderLink = styled.div`
  position: relative;
  height: 100%;

  display: flex;
  align-items: center;

  &.HeaderLink_active:after {
    content: "";
    position: absolute;
    bottom: 0;
    left: 0;

    width: 100%;
    height: 3px;

    background: ${({ theme }) => theme.colors.black};
  }

  .HeaderLink__anchor {
    font-weight: normal;
    font-size: 16px;
    line-height: 21px;

    color: ${({ theme }) => theme.colors.black};
    text-decoration: none;
  }

  &.HeaderLink_active .HeaderLink__anchor {
    font-weight: bold;
  }
`;

interface Props {
  href: string;
}

const HeaderLink: FC<Props> = ({ href, children }) => {
  const { asPath } = useRouter();
  const isActive = asPath.startsWith(href);

  return (
    <StyledHeaderLink className={cn({ HeaderLink_active: isActive })}>
      <Link href={href}>
        <a className="HeaderLink__anchor">{children}</a>
      </Link>
    </StyledHeaderLink>
  );
};

export { HeaderLink };
