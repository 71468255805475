import React, { useEffect, useState } from "react";
import styled from "styled-components";
import Divider from "@material-ui/core/Divider";
import { useLogin } from "./";
import { LoadingSpinner } from "../../../components/LoadingSpinner";
import { useSocialLogin } from ".";
import { LoginForm } from ".";
import { AuthFormsContainer, SocialLoginForm } from "../components";

const StyledLoginView = styled.div`
  .LoginView__headerText {
    font-size: 18px;
    font-weight: bold;
    color: #292929;
    margin-top: 60px;
    margin-bottom: 111px;
  }

  @media only screen and (max-height: 1080px) {
    .LoginView__headerText {
      font-size: 18px;
      font-weight: bold;
      color: #292929;
      margin-top: 60px;
      margin-bottom: 60px;
    }
  }

  .LoginView__divider {
    height: 0.2px;
    width: 100%;
    color: #707070;
    margin-bottom: 26.5px;
  }
`;

const LoginView = () => {
  const { handleLogin, error, isLoading, setError } = useLogin();
  const { handleSocialLogin, isLoading: isSocialLoginLoading } =
    useSocialLogin();
  const [isLoginLoading, setIsLoginLoading] = useState(false);

  useEffect(() => {
    if ((isLoading || isSocialLoginLoading) && !error) {
      setIsLoginLoading(true);
    }
    if (error) {
      setIsLoginLoading(false);
    }
  }, [isLoading, isSocialLoginLoading, error]);

  return (
    <StyledLoginView>
      <AuthFormsContainer>
        <LoadingSpinner isVisible={isLoginLoading} />

        <div className="LoginView__headerText">ברוכים הבאים</div>
        <LoginForm
          handleLogin={handleLogin}
          error={error}
          setError={setError}
        />

        <Divider className="LoginView__divider" />
        <SocialLoginForm handleSocialLogin={handleSocialLogin} />
      </AuthFormsContainer>
    </StyledLoginView>
  );
};

export { LoginView };
