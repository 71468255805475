import React, { FC } from "react";
import styled from "styled-components";

const StyledModalBody = styled.div`
  padding: 8px 24px;
  max-height: 50vh;
  overflow: auto;
`;

interface Props {
  className?: string;
}

const ModalBody: FC<Props> = ({ className, children }) => {
  return <StyledModalBody className={className}>{children}</StyledModalBody>;
};

export { ModalBody };
