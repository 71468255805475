import { useCallback, useState } from "react";
import { useFlag } from "../../../hooks/useFlag";
import { useDispatch } from "react-redux";
import { resetAuth, updateAuth } from "../authSlice";
import { login } from "../../auth0";
import { useRouter } from "next/router";
import { UserRole } from "../../../generated/graphql";

const DEFAULT_ERROR_MESSAGE = "שם המשתמש ו/או הסיסמא אינם נכונים";
const NO_PERMISSION_ERROR_MESSAGE =
  "אין לך את ההרשאה המתאימה להיכנס אל אתר פונקט";

const useLogin = () => {
  const { push } = useRouter();
  const dispatch = useDispatch();

  const [isLoading, onLoadingStart, onLoadingEnd] = useFlag();

  const [error, setError] = useState("");

  const handleLogin = useCallback(
    (email: string, password: string) => {
      onLoadingStart();

      login({ email, password })
        .then((auth) => {
          if (!auth.roles || auth.roles.includes(UserRole.OrderCreator)) {
            setError(NO_PERMISSION_ERROR_MESSAGE);
            return dispatch(resetAuth());
          }

          setError("");

          dispatch(updateAuth(auth));

          if (auth.roles?.includes(UserRole.CatalogManager)) {
            push("/catalog");
          } else {
            push("/requisitions");
          }
        })
        .catch(() => {
          setError(DEFAULT_ERROR_MESSAGE);

          dispatch(resetAuth());
        })
        .finally(onLoadingEnd);
    },
    [dispatch, onLoadingEnd, onLoadingStart, push],
  );

  return { isLoading, error, handleLogin, setError };
};

export { useLogin };
