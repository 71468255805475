import cn from "classnames";
import React, { FC } from "react";
import styled from "styled-components";

const StyledContainer = styled.div`
  padding-left: 64px;
  padding-right: 64px;

  &.Container_fullScreen {
    flex: 1;
    display: flex;
    flex-direction: column;
    overflow: hidden;
  }
`;

interface Props {
  className?: string;
  fullScreen?: boolean;
}

const Container: FC<Props> = ({ className, fullScreen = false, children }) => {
  return (
    <StyledContainer
      className={cn(className, { Container_fullScreen: fullScreen })}
    >
      {children}
    </StyledContainer>
  );
};

export { Container };
