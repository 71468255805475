import { useField } from "formik";
import React, { CSSProperties, FC } from "react";
import { Omit, TextField as MaterialTextField } from "@material-ui/core";
import { TextInput, TextInputProps } from "./TextInput";
import { InfoView } from "../InfoView/InfoView";

type StyledTextFieldProps = Omit<
  TextInputProps,
  "value" | "onChange" | "onBlur" | "name"
> &
  Required<Pick<TextInputProps, "name">> & {
    readOnly?: boolean;
  };

const TextField: FC<StyledTextFieldProps> = ({
  name,
  readOnly,
  ...textInputProps
}) => {
  const [field, { error, touched }] = useField(name);

  return readOnly ? (
    <InfoView
      label={textInputProps.label}
      value={field.value}
      className={textInputProps.className}
    />
  ) : (
    <TextInput
      error={touched && error ? error : undefined}
      {...field}
      {...textInputProps}
    />
  );
};

const ReactCssTextStyle: CSSProperties = {
  fontSize: "20px",
  textAlign: "right",
  color: "#292929",
};

type ControlledTextFieldProps = {
  value: string | number;
  onChange: React.ChangeEventHandler<HTMLTextAreaElement | HTMLInputElement>;
  autoFocus?: boolean;
  fontSize?: string;
};

const ControlledTextField: FC<ControlledTextFieldProps> = ({
  value,
  onChange,
  autoFocus = false,
  fontSize = "16px",
}) => (
  <MaterialTextField
    autoFocus={autoFocus}
    value={value}
    onChange={onChange}
    InputProps={{
      style: {
        ...ReactCssTextStyle,
        fontSize,
      },
    }}
    InputLabelProps={{
      style: ReactCssTextStyle,
    }}
  />
);

export { TextField, ControlledTextField };
