import React, { FC } from "react";
import { useField } from "formik";
import { ChipInput } from "./ChipInput";

type Props = {
  name: string;
};

const ChipField: FC<Props> = ({ name }) => {
  const [
    { value: chips },
    { error, touched },
    { setValue: setChips, setTouched },
  ] = useField<string[]>(name);

  return (
    <ChipInput
      value={chips}
      error={touched && error ? error : undefined}
      onChange={setChips}
      onBlur={() => setTouched(true)}
    />
  );
};

export { ChipField };
