import { Auth0UserProfile } from "auth0-js";
import { webAuthClient } from "../clients";
import authParams from "../params";

const { domain, response_type } = authParams;

export type SocialLoginConnection = "apple" | "google-oauth2";

interface Props {
  connection: SocialLoginConnection;
}

const socialLogin = ({ connection }: Props) =>
  new Promise<Auth0UserProfile>(() =>
    webAuthClient.authorize({
      domain,
      connection,
      responseType: response_type,
      redirectUri: `${process.env.NEXT_PUBLIC_HOST}auth/success`,
    }),
  );

export { socialLogin };
