import { ChangeEventHandler, FC, useCallback } from "react";
import styled from "styled-components";
import { Close, SearchIcon } from "../icons";

type ContainerProps = { disabled?: boolean };
const Container = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;

  padding-inline: 8px;

  min-width: 189px;
  height: 36px;

  background: ${({ theme }) => theme.colors.grey}19;
  border-radius: 55px;

  fill: ${({ theme }) => theme.colors.grey}80;
  opacity: ${(props: ContainerProps) => (props.disabled ? 0.6 : 1)};

  & > * + * {
    margin-inline-start: 4px;
  }

  .SearchInput__input {
    margin-bottom: 1px;
    flex: 1;

    font-weight: 600;
    font-size: 16px;
    line-height: 21px;
    color: ${({ theme }) => theme.colors.black};
    background: none;

    border-width: 0;
  }

  .SearchInput__input::placeholder {
    // opatity: 0.5;
    color: ${({ theme }) => theme.colors.grey}80;
  }

  .SearchInput__input:focus {
    outline-width: 0;
  }
`;

interface Props {
  className?: string;
  value: string | undefined | null;
  onChange: (value: string) => void;
  placeholder?: string;
  disabled?: boolean;
}

const SearchInput: FC<Props> = ({
  className,
  value,
  disabled,
  onChange,
  placeholder = "חיפוש",
}) => {
  const handleOnChange: ChangeEventHandler<HTMLInputElement> = useCallback(
    (event) => {
      const {
        target: { value },
      } = event;

      onChange(value);
    },
    [onChange],
  );

  const handleClear = useCallback(() => onChange(""), [onChange]);

  return (
    <Container className={className} disabled={disabled}>
      {!value ? (
        <SearchIcon />
      ) : (
        <Close onClick={disabled ? undefined : handleClear} />
      )}

      <input
        aria-label={placeholder}
        disabled={disabled}
        value={value ?? ""}
        className="SearchInput__input"
        placeholder={placeholder}
        onChange={handleOnChange}
      />
    </Container>
  );
};

export { SearchInput };
