import { CircularProgress } from "@material-ui/core";
import styled from "styled-components";
import React, { FC } from "react";
import { TrapFocus } from "./TrapFocus";

const StyledSpinner = styled.div`
  position: fixed;
  display: flex;
  flex-direction: row;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 200;
  justify-content: center;
  align-items: center;

  .Spinner__overlay {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;

    background: #000000;
    opacity: 0.6;
  }

  .Spinner__main {
    color: #ffc107;
  }
`;

type SpinnerProps = {
  isVisible: boolean;
  size?: number;
};

const SPINNER_SIZE = 64;

const LoadingSpinner: FC<SpinnerProps> = ({ size = SPINNER_SIZE, isVisible }) =>
  isVisible ? (
    <TrapFocus focusFirst={true}>
      <StyledSpinner>
        <div className="Spinner__overlay" />
        <CircularProgress className="Spinner__main" size={size} />
      </StyledSpinner>
    </TrapFocus>
  ) : (
    <div />
  );

export { LoadingSpinner };
