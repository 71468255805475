import { Auth0Result } from "auth0-js";
import { authenticationClient } from "../clients";
import authParams from "../params";
import { getUserInfo } from "./getUserInfo";
import { SuccessfulAuth } from "../../auth/authSlice";

const { realm } = authParams;

interface Props {
  email: string;
  password: string;
}

const login = ({ email, password }: Props) =>
  new Promise<SuccessfulAuth>((resolve, reject) =>
    authenticationClient.login(
      { username: email, password, realm },
      (error, result: Auth0Result) => {
        if (error || !result.accessToken || !result.idToken) {
          reject(error);
        } else {
          const { accessToken, idToken } = result;

          getUserInfo({ accessToken, idToken }).then(resolve).catch(reject);
        }
      },
    ),
  );

export { login };
