import { ChangeEvent, FC, useState } from "react";
import styled from "styled-components";
import { TextInput } from "../../../components/TextInput";
import { Button } from "../../../components/Button";
import Link from "next/link";

const StyledLoginForm = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  .LoginForm__userEmail {
    height: 56px;
    width: 100%;
    background: #f9f9f9 0% 0% no-repeat padding-box;
    border: 1px solid #292929;
    border-radius: 100px;
    margin-bottom: 11px;
    padding-right: 21px;
  }

  .LoginForm__password {
    height: 56px;
    width: 100%;
    background: #f9f9f9 0% 0% no-repeat padding-box;
    border: 1px solid #292929;
    border-radius: 100px;
    margin-bottom: 12px;
    padding-right: 21px;
  }

  .LoginForm__forgotPassword {
    font-size: 16px;
    opacity: 0.68;
    color: #292929;
    margin-top: 6px;
    margin-bottom: 10.5px;
  }

  .LoginForm__forgotPasswordDesc {
    width: 232px;
    color: #ff0742;
    font-size: 18px;
    text-align: center;
    height: 50px;
  }

  .LoginForm__loginButton {
    width: 95%;
    height: 48px;
    border-radius: 100px;
  }

  .LoginForm__buttonText {
    font-size: 18px;
    color: #170a02;
  }
`;

interface Props {
  handleLogin: (email: string, password: string) => void;
  error: string;
  setError: React.Dispatch<React.SetStateAction<string>>;
}
const LoginForm: FC<Props> = ({
  handleLogin: handleLoginProp,
  error,
  setError,
}) => {
  const [userEmail, setUserEmail] = useState("");
  const [userPassword, setUserPassword] = useState("");

  const handleUserEmail = (event: ChangeEvent<HTMLInputElement>) => {
    setUserEmail(event.target.value);
    setError("");
  };
  const handleUserPassword = (event: ChangeEvent<HTMLInputElement>) => {
    setUserPassword(event.target.value);
    setError("");
  };

  const handleLogin = () => {
    setError("");
    handleLoginProp(userEmail, userPassword);
  };
  return (
    <StyledLoginForm>
      <div className="LoginForm__userEmail">
        <TextInput
          id="LoginForm__userEmail"
          label="כתובת מייל"
          onChange={(event) => handleUserEmail(event)}
          dir="ltr"
          disableunderline={true}
        />
      </div>

      <div className="LoginForm__password">
        <TextInput
          id="LoginForm__password"
          label="סיסמה"
          onChange={(event) => handleUserPassword(event)}
          type="password"
          dir="ltr"
          disableunderline={true}
        />
      </div>

      <Button
        type="button"
        variant="contained"
        className="LoginForm__loginButton"
        onClick={handleLogin}
      >
        <div className="LoginForm__buttonText">כניסה</div>
      </Button>
      {<div className="LoginForm__forgotPasswordDesc">{error}</div>}
      <Link href="/auth/forgetPassword">
        <Button>
          <div className="LoginForm__forgotPassword">שכחתי סיסמה</div>
        </Button>
      </Link>
    </StyledLoginForm>
  );
};

export { LoginForm };
