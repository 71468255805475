import React, { FC } from "react";
import { Icon, IconProps } from "./components/Icon";
import { useTheme } from "../styles/theme";

export const TriangleDown: FC<IconProps> = ({
  fill,
  width,
  height,
  ...props
}) => {
  const theme = useTheme();
  return (
    <Icon
      {...props}
      viewBox="0 0 9 6"
      width={width ?? "9px"}
      height={height ?? "6px"}
      d="M4.70725 5.42141C4.50809 5.64835 4.15477 5.64835 3.95562 5.42141L0.640786 1.64397C0.35722 1.32083 0.586686 0.814179 1.0166 0.814179L7.64626 0.814179C8.07618 0.814179 8.30564 1.32083 8.02208 1.64397L4.70725 5.42141Z"
      fill={fill ?? theme.colors.dark}
    />
  );
};
