import { createTheme } from "@material-ui/core/styles";

const materialTheme = createTheme({
  direction: "rtl",
  typography: {
    fontFamily: "'Assistant', sans-serif",
  },
  palette: {
    secondary: {
      main: "#ffc107",
    },
  },
});

export { materialTheme };
