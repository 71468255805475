import { gql } from "@apollo/client";
import React, { FC, MouseEvent, useCallback } from "react";
import { TablePagination } from "@material-ui/core";
import { PaginationFragment } from "../../generated/graphql";

export interface PaginationProps {
  className?: string;
  page: number;
  pageInfo?: PaginationFragment;
  onChangePage: (page: number) => void;
}

const Pagination: FC<PaginationProps> = ({
  page,
  className,
  pageInfo,
  onChangePage,
}) => {
  const handleChangePage = useCallback(
    (_event: MouseEvent<HTMLButtonElement> | null, newPage: number) => {
      onChangePage(newPage);
    },
    [onChangePage],
  );

  return (
    <TablePagination
      aria-label="TablePagination"
      component="div"
      className={className}
      labelDisplayedRows={({ from, to, count }) =>
        "".concat(
          "מציג", // Showing
          " ",
          from.toString(),
          " - ",
          to.toString(),
          " ",
          "מתוך ", // Of
          count.toString(),
        )
      }
      labelRowsPerPage={"תוצאות בעמוד"} // Results on page
      rowsPerPageOptions={[20]}
      onPageChange={handleChangePage}
      page={page}
      rowsPerPage={pageInfo?.perPage || 20}
      count={pageInfo?.totalItems || 0}
    />
  );
};

const paginationPageInfoFragment = gql`
  fragment Pagination on PaginationInfo {
    perPage
    totalItems
  }
`;

export { Pagination, paginationPageInfoFragment };
