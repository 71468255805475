import cn from "classnames";
import React, { FC, useEffect } from "react";
import styled from "styled-components";
import { SnackbarsContainer } from "../snackbars";
import { useRouter } from "next/router";
import { Header } from "./Header";
import { useDispatch } from "react-redux";
import { pushToRouterHistory } from "../../redux/routerHistoryReducer";
import { extractRouteParamsFromLocation } from "./utils/extractRouteParamsFromLocation";

const StyledBase = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 100vh;

  &.Base_fullScreen {
    height: 100vh;
  }

  .Base__main {
    flex: 1;
    display: flex;
    flex-direction: column;
    overflow: hidden;
  }
`;

interface Props {
  fullScreen?: boolean;
}

const Base: FC<Props> = ({ children, fullScreen }) => {
  const router = useRouter();
  const dispatch = useDispatch();

  useEffect(() => {
    const handleRouteChange = (url: string) => {
      dispatch(pushToRouterHistory(extractRouteParamsFromLocation(url)));
    };

    handleRouteChange(router.asPath);
    router.events.on("routeChangeStart", handleRouteChange);

    return () => {
      router.events.off("routeChangeStart", handleRouteChange);
    };
    // Dependencies are empty by design. https://nextjs.org/docs/api-reference/next/router#usage-6
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const isHeader =
    !router.pathname.startsWith("/auth") && router.pathname !== "/";

  return (
    <StyledBase className={cn(fullScreen && "Base_fullScreen")}>
      {isHeader && <Header />}

      <main className="Base__main">{children}</main>

      {/*<Footer />*/}
      <SnackbarsContainer />
    </StyledBase>
  );
};

export { Base };
