import React, { FC } from "react";
import { Button, Modal } from "../../../components";
import { ModalActions, ModalHeader } from "../../../components/Modal";

interface Props {
  onConfirm: () => void;
  onCancel: () => void;
}

const HeaderConfirmLogoutModal: FC<Props> = ({ onConfirm, onCancel }) => {
  return (
    <Modal isOpen onClose={onCancel}>
      <ModalHeader>האם ברצונך להתנתק?</ModalHeader>

      <ModalActions>
        {/* Logout */}
        <Button onClick={onConfirm} data-cy="confirm-logout">
          התנתקות
        </Button>

        {/* Return */}
        <Button variant="contained" onClick={onCancel}>
          חזרה
        </Button>
      </ModalActions>
    </Modal>
  );
};

export { HeaderConfirmLogoutModal };
