import React, { FC, MouseEventHandler, SVGAttributes } from "react";

export interface IconProps extends SVGAttributes<SVGSVGElement> {
  height?: string | number;
  width?: string | number;
  onClick?: MouseEventHandler;
  color?: string;
}

interface Props extends IconProps {
  d?: string;
}

const Icon: FC<Props> = ({
  d,
  color,
  height = "24px",
  width = "24px",
  ...props
}) => {
  return (
    <svg
      style={{ cursor: "pointer", ...props.style }}
      viewBox={props.viewBox ?? "0 0 24 24"}
      height={height}
      width={width}
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      {props.children ? props.children : <path d={d} fill={color} />}
    </svg>
  );
};

export { Icon };
