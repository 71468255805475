import React, { FC } from "react";
import { Icon, IconProps } from "./components/Icon";

const Check: FC<IconProps> = ({ ...props }) => {
  return (
    <Icon {...props} d="M9 16.2L4.8 12l-1.4 1.4L9 19 21 7l-1.4-1.4L9 16.2z" />
  );
};

export { Check };
