import React, { FC } from "react";
import { ThemeProvider } from "@material-ui/core/styles";
import { MaterialRTL } from "./MaterialRTL";
import { materialTheme } from "./materialTheme";

interface Props {}

const MaterialProvider: FC<Props> = ({ children }) => {
  return (
    <MaterialRTL>
      <ThemeProvider theme={materialTheme}>{children}</ThemeProvider>
    </MaterialRTL>
  );
};

export { MaterialProvider };
