import { useEffect } from "react";

const useLockBodyScroll = (isOpen: boolean): void => {
  useEffect(() => {
    if (!isOpen) {
      return;
    }

    document.body.style.overflow = "hidden";

    return () => {
      document.body.style.overflow = "auto";
    };
  }, [isOpen]);
};

export { useLockBodyScroll };
