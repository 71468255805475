import React, { FC } from "react";
import styled from "styled-components";

const StyledModalHeader = styled.div`
  padding: 16px 24px;
  font-size: 20px;
  line-height: 32px;
`;

interface Props {}

const ModalHeader: FC<Props> = ({ children }) => {
  return <StyledModalHeader>{children}</StyledModalHeader>;
};

export { ModalHeader };
