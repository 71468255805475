import { TextFieldProps } from "@material-ui/core";
import React, { ChangeEvent, CSSProperties, FC } from "react";
import { TextField as MaterialTextField } from "@material-ui/core";

interface Props {
  placeholder?: string;
  value?: string;
  onChange?: (value: ChangeEvent<HTMLInputElement>) => void;
  error?: string;
  label?: string;
  defaultValue?: string;
  id?: string;
  type?: string;
  dir?: string;
  disableunderline?: boolean;
}

const ReactCssTextStyle: CSSProperties = {
  fontSize: "20px",
  textAlign: "right",
  color: "#292929",
};

type StyledTextInputProps = TextFieldProps & { disableunderline?: boolean };

const StyledTextInput = (props: StyledTextInputProps) => (
  <MaterialTextField
    InputProps={{
      style: ReactCssTextStyle,
      disableUnderline: props.disableunderline && true,
    }}
    InputLabelProps={{
      style: ReactCssTextStyle,
    }}
    {...props}
  />
);

const TextInput: FC<Props> = ({
  placeholder,
  value,
  onChange,
  error,
  label,
  defaultValue,
  id,
  type,
  dir,
  disableunderline,
}) => {
  if (!onChange) {
    return <h1>You are probably doing something wrong!!!</h1>;
  }

  return (
    <StyledTextInput
      placeholder={placeholder}
      value={value}
      onChange={onChange}
      error={!!error}
      helperText={error}
      label={label}
      defaultValue={defaultValue}
      id={id}
      type={type}
      dir={dir}
      disableunderline={disableunderline}
    />
  );
};

export { TextInput };
