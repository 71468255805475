import React, {
  ButtonHTMLAttributes,
  FC,
  ReactNode,
  useMemo,
  useRef,
  useState,
} from "react";
import cn from "classnames";
import styled, { ThemeProps } from "styled-components";
import { Spinner } from "../Spinner";
import { PunctTheme, styledColor, styledSpace } from "../../styles/theme";
import { TriangleDown } from "../../icons/TriangleDown";
import { ClickAwayListener, Popper } from "@material-ui/core";

type ContainerButtonProps = {
  hasActions?: boolean;
  onlyActions?: boolean;
} & ThemeProps<PunctTheme>;

const StyledButton = styled.button`
  transition: all 0.2s ease;
  position: relative;
  padding-inline-start: ${(props: ContainerButtonProps) =>
    props.onlyActions ? 0 : styledSpace(7)(props)};
  padding-inline-end: ${(props: ContainerButtonProps) =>
    props.onlyActions ? 0 : styledSpace(7)(props)};
  min-height: ${styledSpace(12)};

  display: inline-flex;
  align-items: center;

  background: none;
  border: none;
  border-radius: 20px;

  font-size: 16px;
  line-height: ${styledSpace(7)};
  font-weight: 600;

  cursor: pointer;

  border-end-end-radius: ${(props: ContainerButtonProps) =>
    props.hasActions ? 0 : "20px"};
  border-start-end-radius: ${(props: ContainerButtonProps) =>
    props.hasActions ? 0 : "20px"};

  outline: none !important;

  &.Button_variant_contained {
    background: ${styledColor("yellow")};
  }

  &:disabled {
    cursor: default;
    color: ${styledColor("grey")};
    background: ${styledColor("lightgrey")};
    border: 1px solid ${styledColor("lightgrey")};
  }

  &.Button_variant_outlined {
    border: 1px solid ${styledColor("yellow")};
  }

  &.Button_variant_contained:focus {
    box-shadow: ${(props) =>
      props.onlyActions
        ? "none"
        : `0 0 0 1px #ffffff, 0 0 0 3px ${styledColor("yellow")(props)}`};
  }

  &.Button_size_small {
    padding: ${styledSpace(3)} ${styledSpace(8)};

    font-size: 14px;
    line-height: ${styledSpace(7)};
  }

  &.Button_color_error {
    color: ${styledColor("white")};
    background-color: ${styledColor("red")};
  }

  &.Button_color_grey {
    border: 1px solid ${styledColor("grey")};
  }

  &.Button_fullWidth {
    width: 100%;
  }

  &.Button_iconStart {
    padding-inline-start: ${styledSpace(3)};
  }

  &.Button_iconEnd {
    padding-inline-end: ${styledSpace(3)};
  }

  .Button__text {
    flex: 1;
    text-align: center;
    text-transform: uppercase;
  }

  .Button__iconStart {
    margin-inline-end: ${styledSpace(3)};
    fill: ${styledColor("yellow")};
    line-height: 0;

    svg {
      width: ${styledSpace(8)};
      height: ${styledSpace(8)};
    }
  }

  .Button__iconEnd {
    margin-inline-start: ${styledSpace(3)};
    fill: ${styledColor("yellow")};
    line-height: 0;

    svg {
      width: ${styledSpace(8)};
      height: ${styledSpace(8)};
    }
  }

  .Button__spinner {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: ${styledSpace(7)} !important;
    height: ${styledSpace(7)} !important;
  }
`;

type SpinnerProps = {
  buttonColor: ButtonProps["color"];
} & ThemeProps<PunctTheme>;

const StyledSpinner = styled(Spinner)`
  && {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: ${styledSpace(7)};
    height: ${styledSpace(7)};
    color: ${(props: SpinnerProps) =>
      props.buttonColor === "error"
        ? styledColor("white")(props)
        : props.buttonColor === "grey"
        ? styledColor("lightgrey")(props)
        : styledColor("yellow")(props)};
  }
`;

type DropdownSectionProps = {
  actionsOnly?: boolean;
} & ThemeProps<PunctTheme>;

const DropdownSection = styled.div`
  height: 100%;
  padding-inline-start: ${(props: DropdownSectionProps) =>
    props.actionsOnly ? styledSpace(6)(props) : styledSpace(5)(props)};
  padding-inline-end: ${styledSpace(6)};
  border-inline-start: ${(props: DropdownSectionProps) =>
    props.actionsOnly ? "none" : `1px solid ${styledColor("grey", 15)(props)}`};
  display: flex;
  align-items: center;
`;

const StyledPopper = styled(Popper)`
  z-index: 4;
  padding: 0;
  border-radius: 11px;
  box-shadow: 0 3px 13px rgba(119, 119, 119, 0.23);
  background-color: ${styledColor("white")};
  overflow: hidden;
`;

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  position: relative;
  background-color: ${styledColor("yellow")};
  border-radius: 1000px;
`;

type ActionButtonProps = {
  separated?: boolean;
} & ThemeProps<PunctTheme>;

export const ActionButton = styled.button`
  cursor: pointer;
  display: flex;
  align-items: center;
  font-size: 14px;
  line-height: 16px;
  min-height: ${styledSpace(10)};
  color: ${styledColor("dark")};
  gap: ${styledSpace(4)};
  border: none;
  background: none;
  padding: ${styledSpace(4)} ${styledSpace(6)};
  width: 100%;
  border-block-start: ${(props: ActionButtonProps) =>
    props.separated ? `1px solid ${styledColor("grey", 20)(props)}` : "none"};
  transition: all 0.3s ease;

  &:hover {
    background-color: ${styledColor("lightgrey", 40)};
  }

  svg {
    width: ${styledSpace(10)};
    height: ${styledSpace(10)};
  }
`;

export type ButtonAction = {
  label: string;
  icon?: ReactNode;
  disabled?: boolean;
  withSeparator?: boolean;
  onClick: () => void;
};

export interface ButtonProps extends ButtonHTMLAttributes<HTMLButtonElement> {
  className?: string;
  disabled?: boolean;
  variant?: "default" | "outlined" | "contained";
  size?: "default" | "small";
  color?: "primary" | "error" | "grey";
  fullWidth?: boolean;
  isLoading?: boolean;
  iconStart?: ReactNode;
  iconEnd?: ReactNode;
  type?: "button" | "submit" | "reset" | undefined;
  actions?: ButtonAction[];
}

const Button: FC<ButtonProps> = ({
  className,
  variant = "default",
  size = "default",
  color = "primary",
  fullWidth = false,
  isLoading,
  iconStart,
  iconEnd,
  disabled,
  type = "button",
  children,
  actions,
  ...buttonProps
}) => {
  const actionsAnchorRef = useRef<HTMLDivElement>(null);
  const [isActionMenuOpen, setIsActionMenuOpen] = useState(false);
  const buttonElement = useMemo(() => {
    return (
      <StyledButton
        hasActions={!!actions?.length}
        onlyActions={!children}
        type={type}
        disabled={isLoading || disabled}
        className={cn(
          className,
          `Button_variant_${variant}`,
          `Button_size_${size}`,
          `Button_color_${color}`,
          {
            Button_fullWidth: fullWidth,
            Button_iconStart: !!iconStart,
            Button_iconEnd: !!iconEnd,
          },
        )}
        {...buttonProps}
      >
        {!isLoading && iconStart && (
          <div className="Button__iconStart" data-testid="icon-start">
            {iconStart}
          </div>
        )}

        {!isLoading && <span className="Button__text">{children}</span>}

        {!isLoading && iconEnd && (
          <div className="Button__iconEnd" data-testid="icon-end">
            {iconEnd}
          </div>
        )}

        {isLoading && (
          <StyledSpinner buttonColor={color} size={20} color="inherit" />
        )}
      </StyledButton>
    );
  }, [
    actions?.length,
    buttonProps,
    children,
    className,
    color,
    disabled,
    fullWidth,
    iconEnd,
    iconStart,
    isLoading,
    size,
    type,
    variant,
  ]);
  return !actions?.length && !children ? null : (
    <>
      {!!actions?.length ? (
        <Wrapper>
          {buttonElement}
          <DropdownSection
            actionsOnly={!children}
            ref={actionsAnchorRef}
            onClick={(e) => {
              e.stopPropagation();
              setIsActionMenuOpen((c) => !c);
            }}
          >
            <TriangleDown />
          </DropdownSection>
        </Wrapper>
      ) : (
        buttonElement
      )}
      {!!actions?.length && (
        <ClickAwayListener onClickAway={() => setIsActionMenuOpen(false)}>
          <StyledPopper
            placement="bottom-end"
            anchorEl={actionsAnchorRef?.current}
            open={isActionMenuOpen}
            transition
          >
            {actions.map(
              ({ icon, label, onClick, disabled, withSeparator }) => (
                <ActionButton
                  key={label}
                  separated={withSeparator}
                  disabled={disabled}
                  onClick={() => {
                    setIsActionMenuOpen(false);
                    onClick();
                  }}
                >
                  {icon}
                  {label}
                </ActionButton>
              ),
            )}
          </StyledPopper>
        </ClickAwayListener>
      )}
    </>
  );
};

export { Button };
