import { webAuthClient } from "../clients";
import authParams from "../params";

const { realm } = authParams;

interface Props {
  email: string;
}

const sendResetPasswordEmail = ({ email }: Props) =>
  new Promise<void>((resolve, reject) =>
    webAuthClient.changePassword(
      {
        connection: realm,
        email,
      },
      (error) => {
        if (error) {
          reject(error);
        } else {
          resolve();
        }
      },
    ),
  );

export { sendResetPasswordEmail };
