import React, { FC } from "react";
import styled from "styled-components";

const StyledAuthFormsContainer = styled.div`
  display: flex;
  position: absolute;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  background-image: url("/userFormsBackground.png");
  background-size: cover;

  .AuthFormsContainer__container {
    display: flex;
    width: 100%;

    max-height: 993px;
    max-width: 1324px;
    margin-left: 64px;
    margin-right: 64px;
    margin-top: 64px;
    margin-bottom: 64px;
  }

  @media only screen and (max-width: 1388px) {
    .AuthFormsContainer__container {
      display: flex;
      width: 100%;

      max-height: 993px;
      max-width: 692px;
      margin-left: 64px;
      margin-right: 64px;
      margin-top: 64px;
      margin-bottom: 64px;
    }
  }

  @media only screen and (max-height: 1080px) {
    .AuthFormsContainer__container {
      display: flex;
      width: 100%;

      max-height: 700px;
      max-width: 1324px;
      margin-left: 64px;
      margin-right: 64px;
      margin-top: 64px;
      margin-bottom: 64px;
    }
  }

  .AuthFormsContainer__image {
    object-fit: cover;
  }

  @media only screen and (max-width: 1388px) {
    .AuthFormsContainer__image {
      display: none;
    }
  }

  .AuthFormsContainer__outerFormContainer {
    display: flex;
    flex: 1;
    background-color: white;
    justify-content: center;
  }

  .AuthFormsContainer__formContainer {
    display: flex;
    flex-direction: column;
    width: 232px;
    padding-top: 31px;
    padding-bottom: 80px;
    overflow: hidden;
    align-items: center;
    margin-left: 200px;
    margin-right: 200px;
  }

  .AuthFormsContainer__logoContainer {
    display: flex;
    flex-direction: column;
    font-weight: bold;
    align-items: center;
  }

  .AuthFormsContainer__underLogoText {
    display: flex;
    color: rgb(46, 66, 61);
    line-height: 18px;
    font-size: 14px;
    justify-content: center;
    margin-top: 2%;
  }

  .AuthFormsContainer__underLogoText {
    color: rgb(46, 66, 61);
    line-height: 18px;
    font-size: 14px;
    text-align: center;
  }
`;

interface Props {}

const AuthFormsContainer: FC<Props> = ({ children }) => {
  return (
    <StyledAuthFormsContainer>
      <div className="AuthFormsContainer__container">
        <img className="AuthFormsContainer__image" src={"/builders.png"} />
        <div className="AuthFormsContainer__outerFormContainer">
          <div className="AuthFormsContainer__formContainer">
            <div className="AuthFormsContainer__logoContainer">
              <img src={"/punct-logo.png"} />
              <div className="AuthFormsContainer__underLogoText">
                CONTROLING YOUR BUILDING SITE
              </div>
            </div>
            {children}
          </div>
        </div>
      </div>
    </StyledAuthFormsContainer>
  );
};

export { AuthFormsContainer };
