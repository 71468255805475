import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import {
  addAuthorizationBearerToken,
  removeAuthorizationBearerToken,
} from "../../api/punctApi";
import { RootState, Thunk } from "../../redux";
import { UserRole } from "../../generated/graphql";
import { authSerializer } from "./utils/authSerializer";

export interface AuthState {
  email?: string;
  userId?: string;
  accessToken?: string;
  companyName?: string;
  name?: string;
  userImage?: string;
  roles?: UserRole[];
  companyId?: number;
}

export interface SuccessfulAuth extends AuthState {
  userId: string;
  accessToken: string;
}

const initialState: AuthState = authSerializer.load() || {};

const {
  actions: { resetAuthSuccess, updateAuthSuccess },
  reducer: authReducer,
} = createSlice({
  name: "auth",
  initialState,
  reducers: {
    resetAuthSuccess: () => ({}),
    updateAuthSuccess: (state, action: PayloadAction<AuthState>) =>
      action.payload,
  },
});

const updateAuth =
  (state: SuccessfulAuth): Thunk<void> =>
  async (dispatch) => {
    try {
      const { accessToken } = state;

      authSerializer.save(state);

      addAuthorizationBearerToken(accessToken);
      dispatch(updateAuthSuccess(state));
    } catch (err) {
      dispatch(resetAuthSuccess());
    }
  };

const resetAuth = (): Thunk<void> => (dispatch) => {
  authSerializer.reset();
  removeAuthorizationBearerToken();
  dispatch(resetAuthSuccess());
};

const getAuthState =
  () =>
  ({ auth }: RootState): AuthState => ({
    userId: auth.userId,
    email: auth.email,
    accessToken: auth.accessToken,
    name: auth.name,
    companyName: auth.companyName,
    userImage: auth.userImage,
    roles: auth.roles,
    companyId: auth.companyId,
  });

const getAccessToken = ({ auth }: RootState): string | undefined =>
  auth.accessToken;

const getUserRoles = ({ auth }: RootState): UserRole[] => auth.roles || [];
const getCompanyId = ({ auth }: RootState) => auth.companyId;

export {
  authReducer,
  getAccessToken,
  getAuthState,
  getUserRoles,
  getCompanyId,
  updateAuth,
  resetAuth,
};
