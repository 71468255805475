import React, { FC } from "react";
import styled from "styled-components";
import { HeaderLink } from "./HeaderLink";
import { useAuthGuard } from "../../auth";
import { UserRole } from "../../../generated/graphql";

const StyledHeaderNavigation = styled.div`
  height: 100%;
  display: flex;

  & > * + * {
    margin-inline-start: 64px;
  }
`;

interface Props {}

const HeaderNavigation: FC<Props> = () => {
  const { isRole, isAllowed } = useAuthGuard([
    UserRole.CatalogManager,
    UserRole.SystemAdmin,
  ]);

  const isAdminRole = isRole(UserRole.SystemAdmin);
  const isOrderModeratorRole = isRole(UserRole.OrderModerator);

  return (
    <StyledHeaderNavigation>
      {/* Requisition Management */}
      <HeaderLink href="/requisitions">דרישות</HeaderLink>
      {/* Order Management */}
      <HeaderLink href="/orders">הזמנות</HeaderLink>
      {/* Reports */}
      <HeaderLink href="/reports">דוחות</HeaderLink>
      {/* Companies Table */}
      {isAdminRole && <HeaderLink href="/companies">חברות</HeaderLink>}
      {isOrderModeratorRole && (
        <>
          {/* Company settings */}
          <HeaderLink href="/my-company">הגדרות חברה</HeaderLink>
          {/* Company catalog */}
          <HeaderLink href="/company-catalog">קטלוג חברה</HeaderLink>
        </>
      )}
      {/* Catalog */}
      {isAllowed && <HeaderLink href="/catalog">קטלוג</HeaderLink>}
    </StyledHeaderNavigation>
  );
};

export { HeaderNavigation };
