import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { v4 as uuidv4 } from "uuid";
import { RootState } from "../../redux";
import { Color } from "@material-ui/lab/Alert";

export interface SnackbarState {
  type: Color;
  content: string;
}

interface SnackbarInternalState extends SnackbarState {
  id: string;
}

export interface SnackbarsState {
  snackbars: SnackbarInternalState[];
}

const initialState: SnackbarsState = {
  snackbars: [],
};

const {
  actions: { openSnackbar, closeSnackbar },
  reducer: snackbarsReducer,
} = createSlice({
  name: "snackbars",
  initialState,
  reducers: {
    openSnackbar: (state, action: PayloadAction<SnackbarState>) => ({
      ...state,
      snackbars: [...state.snackbars, { id: uuidv4(), ...action.payload }],
    }),
    closeSnackbar: (state, action: PayloadAction<{ snackbarId: string }>) => {
      return {
        ...state,
        snackbars: state.snackbars.filter(
          (snackbarState) => snackbarState.id !== action.payload.snackbarId,
        ),
      };
    },
  },
});

const getSnackbars = (state: RootState): SnackbarInternalState[] =>
  state.snackbars.snackbars;

export { snackbarsReducer, openSnackbar, closeSnackbar, getSnackbars };
