import React, { FC, useCallback } from "react";
import { useSelector } from "react-redux";
import styled from "styled-components";
import { Container } from "../../../components";
import { useFlag } from "../../../hooks/useFlag";
import { getAuthState, useLogout } from "../../auth";
import { HeaderConfirmLogoutModal } from "./HeaderConfirmLogoutModal";
import { HeaderNavigation } from "./HeaderNavigation";
import { HeaderUser } from "./HeaderUser";

const StyledHeader = styled.header`
  .Header__container {
    width: 100%;
    height: 64px;

    display: flex;
    justify-content: space-between;
    align-items: center;

    background: ${({ theme }) => theme.colors.yellow};
  }
`;

interface Props {}

const Header: FC<Props> = () => {
  const { companyName, name, userImage } = useSelector(getAuthState());
  const { handleLogout } = useLogout();

  const [isLogoutRequested, onRequestLogout, onCancelLogout] = useFlag(false);

  const handleConfirmLogout = useCallback(() => {
    onCancelLogout();
    handleLogout();
  }, [handleLogout, onCancelLogout]);

  return (
    <StyledHeader>
      <Container className="Header__container">
        <HeaderNavigation />

        <HeaderUser
          userName={name}
          userImage={userImage}
          companyName={companyName}
          count={4}
          onRequestLogout={onRequestLogout}
        />

        {isLogoutRequested && (
          <HeaderConfirmLogoutModal
            onConfirm={handleConfirmLogout}
            onCancel={onCancelLogout}
          />
        )}
      </Container>
    </StyledHeader>
  );
};

export { Header };
