import { getCurrentHub, startTransaction } from "@sentry/nextjs";
import { TransactionContext } from "@sentry/types";

export enum SpanStatus {
  Ok = "ok",
  UnknownError = "unknown_error",
}

export const SentryHelpers = {
  startTransaction: (context: TransactionContext) => {
    const transaction = startTransaction(context);
    getCurrentHub().configureScope((scope) => scope.setSpan(transaction));

    return transaction;
  },
};
