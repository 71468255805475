import {
  ApolloClient,
  ApolloLink,
  ApolloProvider as InternalApolloProvider,
  createHttpLink,
  InMemoryCache,
} from "@apollo/client";
import { setContext } from "@apollo/client/link/context";
import { onError } from "@apollo/client/link/error";
import React, { FC, useMemo } from "react";
import { useAccessToken } from "../modules/auth";
import { captureException } from "@sentry/nextjs";
import { SentryHelpers, SpanStatus } from "../sentry/sentry.helpers";
import { Span, Transaction } from "@sentry/types";

interface Props {}

type SentryOperationContext = {
  transaction: Transaction;
  span: Span;
};

function createOmitTypenameLink() {
  return new ApolloLink((operation, forward) => {
    if (operation.variables) {
      operation.variables = JSON.parse(
        JSON.stringify(operation.variables),
        omitTypename,
      );
    }

    return forward(operation);
  });
}

function omitTypename<T>(key: string, value: T) {
  return key === "__typename" ? undefined : value;
}

const ApolloProvider: FC<Props> = ({ children }) => {
  const accessToken = useAccessToken();

  const apolloClient = useMemo(() => {
    const httpLink = createHttpLink({
      uri: process.env.NEXT_PUBLIC_API_GRAPHQL_URL,
    });

    const omitTypenameInInputsLink = createOmitTypenameLink();

    const authLink = setContext((_, { headers }) => {
      return {
        headers: {
          ...headers,
          Authorization: accessToken ? `Bearer ${accessToken}` : "",
        },
      };
    });

    const sentryLink = new ApolloLink((operation, forward) => {
      const transaction = SentryHelpers.startTransaction({
        name: operation.operationName,
      });
      const span = transaction.startChild({
        data: {
          query: operation.query,
        },
      });
      const sentryContext: SentryOperationContext = { transaction, span };
      operation.setContext(sentryContext);
      return forward(operation).map((data) => {
        if (!transaction.status) {
          transaction.setStatus(SpanStatus.Ok);
          span.setStatus(SpanStatus.Ok);
        }
        span.finish();
        transaction.finish();
        return data;
      });
    });

    const errorLink = onError(({ operation, graphQLErrors, networkError }) => {
      if (graphQLErrors) {
        const mappedGQLErrors = graphQLErrors.map(
          ({ message, locations, path }) => ({ message, locations, path }),
        );
        captureException({ graphQLErrors: JSON.stringify(mappedGQLErrors) });
      }

      if (networkError) {
        captureException(networkError);
      }

      const sentryContext = operation.getContext() as SentryOperationContext;
      sentryContext.span.setStatus(SpanStatus.UnknownError);
      sentryContext.transaction.setStatus(SpanStatus.UnknownError);
    });

    return new ApolloClient({
      link: ApolloLink.from([
        omitTypenameInInputsLink,
        sentryLink,
        authLink,
        errorLink,
        httpLink,
      ]),
      cache: new InMemoryCache(),
      defaultOptions: {
        watchQuery: {
          fetchPolicy: "cache-and-network",
        },
      },
    });
  }, [accessToken]);

  return (
    <InternalApolloProvider client={apolloClient}>
      {children}
    </InternalApolloProvider>
  );
};

export { ApolloProvider };
