import React, { FC } from "react";
import styled from "styled-components";

import { HeaderUserMenu } from "./HeaderUserMenu";

const StyledHeaderUser = styled.div`
  display: flex;
  align-items: center;

  .HeaderUser__menu {
    margin-inline-start: 16px;
  }

  .HeaderUser__company {
    margin-inline-start: 24px;

    font-weight: bold;
    font-size: 16px;
    line-height: 21px;
  }
`;

interface Props {
  userName?: string;
  userImage?: string;
  companyName?: string;
  count: number;
  onRequestLogout: () => void;
}

const HeaderUser: FC<Props> = ({
  userName,
  userImage,
  companyName,
  count,
  onRequestLogout,
}) => {
  return (
    <StyledHeaderUser>
      <HeaderUserMenu
        className="HeaderUser__menu"
        userName={userName}
        userImage={userImage}
        onRequestLogout={onRequestLogout}
      />

      <span className="HeaderUser__company">{companyName}</span>
    </StyledHeaderUser>
  );
};

export { HeaderUser };
