import { FC } from "react";
import styled from "styled-components";
import { SocialLoginConnection } from "../../auth0/functions/socialLogin";

const StyledSocialLoginForm = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;

  .SocialLoginForm__googleLogin {
    display: flex;
    flex-direction: row-reverse;
    align-items: center;
    appearance: none;
    -webkit-appearance: none;
    width: 232px;
    height: 56px;
    border: none;
    cursor: pointer;
    border-radius: 100px;
    font-size: 17px;
    line-height: 1.47059;
    font-weight: 400;
    font-style: normal;
    margin-bottom: 14px;
    color: white;
    background-color: #4285f4;
  }

  .SocialLoginForm__googleLogin:hover {
    box-shadow: 0 0 6px #4285f4;
  }

  .SocialLoginForm__googleLogin:active {
    background: #1669f2;
  }

  .SocialLoginForm__appleLogin {
    display: flex;
    flex-direction: row-reverse;
    align-items: center;
    appearance: none;
    -webkit-appearance: none;
    width: 232px;
    height: 56px;
    border: none;
    background: #1d1d1f;
    cursor: pointer;
    border-radius: 100px;
    font-size: 17px;
    line-height: 1.47059;
    font-weight: 400;
    font-style: normal;
    color: white;
    margin-bottom: 80px;
  }

  .SocialLoginForm__appleLogin:hover {
    box-shadow: 0 0 6px #1d1d1f;
  }

  .SocialLoginForm__appleLogin:active {
    background: #000000;
  }

  .SocialLoginForm__socialLoginText {
    margin-left: 55px;
  }

  .SocialLoginForm__socialLoginIconWrapper {
    position: absolute;
    margin-left: 9px;
    width: 35px;
    height: 35px;
    border-radius: 3px;
    background-color: white;
  }
`;

interface Props {
  handleSocialLogin: (connection: SocialLoginConnection) => void;
}
const SocialLoginForm: FC<Props> = ({ handleSocialLogin }) => {
  return (
    <StyledSocialLoginForm>
      <button
        className="SocialLoginForm__googleLogin"
        onClick={() => handleSocialLogin("google-oauth2")}
      >
        <div className="SocialLoginForm__socialLoginIconWrapper">
          <img src={"/google-login.svg"} />
        </div>
        <div className="SocialLoginForm__socialLoginText">
          Login with Google
        </div>
      </button>
      <button
        className="SocialLoginForm__appleLogin"
        onClick={() => handleSocialLogin("apple")}
      >
        <div className="SocialLoginForm__socialLoginIconWrapper">
          {" "}
          <img src={"/apple-login.png"} />
        </div>
        <div className="SocialLoginForm__socialLoginText">Login with Apple</div>
      </button>
    </StyledSocialLoginForm>
  );
};

export { SocialLoginForm };
