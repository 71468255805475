import { Dispatch, FC, SetStateAction, useState, ChangeEvent } from "react";
import styled from "styled-components";
import { TextInput } from "../../../components/TextInput";
import { Button } from "../../../components/Button";

const StyledForgetPasswordForm = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  .ForgetPasswordForm__buttonText {
    font-size: 18px;
    color: #170a02;
  }

  .ForgetPasswordForm__userEmail {
    height: 56px;
    width: 100%;
    background: #f9f9f9 0% 0% no-repeat padding-box;
    border: 1px solid #292929;
    border-radius: 100px;
    margin-bottom: 11px;
    padding-right: 21px;
    padding-left: 21px;
  }

  .ForgetPasswordForm__description {
    width: 100%;
    color: #292929;
    margin-top: 6px;
    margin-bottom: 10.5px;
    font-size: 18px;
    text-align: center;
    height: 80px;
  }

  .ForgetPasswordForm__resetButton {
    width: 95%;
    height: 48px;
    border-radius: 100px;
  }
`;

interface Props {
  handleReset: () => void;
  setUserEmail: Dispatch<SetStateAction<string>>;
}

const ForgetPasswordForm: FC<Props> = ({
  handleReset: handleResetProp,
  setUserEmail,
}) => {
  const [sent, setSent] = useState(false);
  const handleReset = () => {
    handleResetProp();
    setSent(true);
  };

  const handleUserEmail = (event: ChangeEvent<HTMLInputElement>) => {
    setUserEmail(event.target.value);
    setSent(false);
  };
  return (
    <StyledForgetPasswordForm>
      <div className="ForgetPasswordForm__userEmail">
        <TextInput
          id="ForgetPasswordForm__userEmail"
          label="כתובת מייל"
          onChange={handleUserEmail}
          dir="ltr"
          disableunderline={true}
        />
      </div>

      <Button
        onClick={handleReset}
        type="button"
        variant="contained"
        className="ForgetPasswordForm__resetButton"
      >
        <div className="ForgetPasswordForm__buttonText">איפוס</div>
      </Button>
      <div className="ForgetPasswordForm__description">
        {sent && "במידה וקיים משתמש עם כתובת מייל זאת תשלח אליו סיסמה לשחזור"}
      </div>
    </StyledForgetPasswordForm>
  );
};

export { ForgetPasswordForm };
