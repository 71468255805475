import { useCallback } from "react";
import { useSelector } from "react-redux";
import { getUserRoles } from ".";
import { UserRole } from "../../generated/graphql";

const useAuthGuard = (roles: UserRole[]) => {
  const userRoles = useSelector(getUserRoles);

  const overlappedRoles = userRoles
    ? roles.filter((role) => userRoles.includes(role))
    : [];

  const isRole = useCallback(
    (role: UserRole) => userRoles.includes(role),
    [userRoles],
  );

  return {
    isAllowed: overlappedRoles.length > 0,
    isRole,
  };
};

export { useAuthGuard };
