import { jssPreset, StylesProvider } from "@material-ui/core";
import { create } from "jss";
import jssRTL from "jss-rtl";
import React, { FC } from "react";

interface Props {}

const jss = create({ plugins: [...jssPreset().plugins, jssRTL()] });

const MaterialRTL: FC<Props> = ({ children }) => {
  return <StylesProvider jss={jss}>{children}</StylesProvider>;
};

export { MaterialRTL };
