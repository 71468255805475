import { useCallback, useState } from "react";

type UseFlag = (
  defaultValue?: boolean | (() => boolean),
) => [boolean, () => void, () => void, () => void];

const useFlag: UseFlag = (defaultValue = false) => {
  const [boolean, setBoolean] = useState(defaultValue);

  const setToTrue = useCallback(() => {
    setBoolean(true);
  }, []);

  const setToFalse = useCallback(() => {
    setBoolean(false);
  }, []);

  const toggleBoolean = useCallback(() => {
    setBoolean((state) => !state);
  }, []);

  return [boolean, setToTrue, setToFalse, toggleBoolean];
};

export { useFlag };
