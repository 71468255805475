import { ThemeContext, ThemeProps } from "styled-components";
import { useContext } from "react";

const SPACER = 4;

export const theme = {
  SPACER,
  colors: {
    white: "#ffffff",
    dark: "#1d2429",
    black: "#2D2C2C",
    grey: "#616267",
    darkgrey: "#4f4f4f",
    lightgrey: "#e2e2e2",
    yellow: "#FDD835",
    red: "#FA1515",
    green: "#43A047",
    lightgreen: "#42D46B",
    blue: "#1E88E5",
    orange: "#FB8C00",
  },
};

export type PunctTheme = typeof theme;
export type PunctThemeProps<T> = ThemeProps<PunctTheme> & T;
export const styledSpace = (k: number) => (props: ThemeProps<PunctTheme>) =>
  `${k < 3 ? k : props.theme.SPACER * (k - 2)}px`;

const convertDecimalOpacityToHex = (opacity: number) => {
  const hexValue = Math.round((opacity / 100) * 256 - 1).toString(16);
  if (hexValue.toString().length === 1) return `0${hexValue}`;
  return hexValue;
};
export const styledColor =
  (color: keyof PunctTheme["colors"], opacity = 100) =>
  (props: ThemeProps<PunctTheme>) =>
    `${props.theme.colors[color]}${convertDecimalOpacityToHex(opacity)}`;

export const useTheme = () => useContext<PunctTheme>(ThemeContext);

export const mediumLaptopMediaWidth = "1400px";
