import React, { ButtonHTMLAttributes, FC } from "react";
import MaterialIconButton from "@material-ui/core/IconButton";

interface Props extends Omit<ButtonHTMLAttributes<HTMLButtonElement>, "color"> {
  className?: string;
  size?: "medium" | "small";
}

const IconButton: FC<Props> = ({
  className,
  size = "medium",
  children,
  ...buttonProps
}) => {
  return (
    <MaterialIconButton className={className} size={size} {...buttonProps}>
      {children}
    </MaterialIconButton>
  );
};

export { IconButton };
