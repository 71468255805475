import React, { FC, ReactNode } from "react";
import styled from "styled-components";
import { styledColor, styledSpace } from "../../styles/theme";

const Container = styled.div``;

const Label = styled.div`
  font-size: 12px;
  line-height: ${styledSpace(6)};
  color: ${styledColor("black", 50)};
  font-weight: 600;
  margin-bottom: ${styledSpace(4)};
`;

const Value = styled.div`
  font-size: 16px;
  line-height: ${styledSpace(7)};
  color: ${styledColor("black")};
  font-weight: 500;
  display: flex;
  gap: ${styledSpace(3)};
`;

type Props = {
  label: ReactNode;
  value: ReactNode;
  className?: string;
};

export const InfoView: FC<Props> = ({ label, value, className }) => {
  return (
    <Container className={className}>
      <Label>{label}</Label>
      {value && <Value>{value}</Value>}
    </Container>
  );
};
