import React, { FC } from "react";
import styled from "styled-components";
import { Button } from "../../../components/Button";
import { Modal, ModalActions, ModalHeader } from "../../../components/Modal";

const StyledApproveLogOutModal = styled.div`
  .ApproveLogOutModal__modal {
    display: flex;
    flex-direction: column;
    align-items: center;
    max-width: 337px;
  }

  .ApproveLogOutModal__header {
    color: black;
    font-size: 20px;
    margin-top: 69px;
    margin-bottom: 60px;
  }

  .ApproveLogOutModal__cancelButton {
    width: 228px;
    height: 48px;
    border-radius: 100px;
  }

  .ApproveLogOutModal__backButton {
    margin: 18px 54.5px 48px;
    width: 228px;
    height: 48px;
    border-radius: 100px;
  }
`;

export interface Props {
  isOpen: boolean;
  onCancel: () => void;
  onLogout: () => void;
}

const ApproveLogOutModal: FC<Props> = ({ isOpen, onCancel, onLogout }) => {
  return (
    <StyledApproveLogOutModal>
      <Modal isOpen={isOpen} className="ApproveLogOutModal__modal">
        <ModalHeader>
          <div className="ApproveLogOutModal__header"> האם ברצונך להתנתק?</div>
        </ModalHeader>

        <ModalActions>
          <Button
            type="button"
            onClick={onLogout}
            variant="outlined"
            className="ApproveLogOutModal__cancelButton"
            data-cy="confirmLogout_Button"
          >
            התנתקות
          </Button>

          <Button
            type="button"
            onClick={onCancel}
            variant="contained"
            className="ApproveLogOutModal__backButton"
          >
            חזרה
          </Button>
        </ModalActions>
      </Modal>
    </StyledApproveLogOutModal>
  );
};

export { ApproveLogOutModal };
