import React, { FC } from "react";
import styled from "styled-components";
import { useLockBodyScroll } from "../../hooks/useLockBodyScroll";
import { usePressKeys } from "../../hooks/usePressKeys";
import { TrapFocus } from "../TrapFocus";
import { Modal as MaterialModal } from "@material-ui/core";
import cn from "classnames";

const StyledModal = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  height: 100vh;
  width: 100vw;

  z-index: 100;

  .Modal__overlay {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;

    background: #000000;
    opacity: 0.6;
  }

  .Modal__body {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);

    &.size-sm {
      width: 504px;
      padding: 16px;
    }

    &.size-md {
      width: 50%;
      padding: 0;
    }

    &.size-lg {
      width: 80%;
      height: 90%;
      padding: 32px;
    }

    background: #ffffff;
    border-radius: 8px;
    overflow: hidden;
  }
`;

interface Props {
  className?: string;
  isOpen: boolean;
  focusFirst?: boolean;
  onClose?: () => void;
  size?: "sm" | "md" | "lg" | "logout";
}

const Modal: FC<Props> = ({
  className,
  isOpen,
  focusFirst = true,
  onClose,
  size = "sm",
  children,
}) => {
  usePressKeys(() => onClose && onClose());
  useLockBodyScroll(isOpen);

  if (!isOpen) {
    return null;
  }

  return (
    <MaterialModal open={true}>
      <TrapFocus focusFirst={focusFirst}>
        <StyledModal>
          <div className="Modal__overlay" onClick={onClose} />
          <div className={cn(className, `Modal__body size-${size}`)}>
            {children}
          </div>
        </StyledModal>
      </TrapFocus>
    </MaterialModal>
  );
};

export { Modal };
