import { useRouter } from "next/router";
import { useCallback, useEffect, useMemo } from "react";
import { useFlag } from "../../../hooks/useFlag";
import { resetAuth, updateAuth } from "../authSlice";
import { useDispatch } from "react-redux";
import { parseHash } from "../../auth0/functions/parseHash";

const DEFAULT_ERROR_MESSAGE = "ההתחברות נכשלה";

const useSuccessfulSocialLogin = () => {
  const dispatch = useDispatch();

  const { push, asPath } = useRouter();

  const [isSuccessful, setSuccessfulTrue, setSuccessfulFalse] = useFlag();
  const [isLoading, setLoadingTrue, setLoadingFalse] = useFlag();

  const message = useMemo(
    () =>
      isLoading
        ? "טוען.."
        : isSuccessful
        ? "התחברות הסתיימה בהצלחה,\nהינך מועבר לאפליקציה.."
        : DEFAULT_ERROR_MESSAGE,
    [isLoading, isSuccessful],
  );

  const handleRedirectToApp = useCallback(() => {
    push("/catalog");
  }, [push]);

  useEffect(() => {
    setLoadingTrue();

    const hash = asPath.split("#")[1];

    if (hash) {
      parseHash({ hash })
        .then((auth) => {
          dispatch(updateAuth(auth));

          setSuccessfulTrue();
        })
        .catch(() => {
          dispatch(resetAuth());

          setSuccessfulFalse();
        })
        .finally(() => {
          setLoadingFalse();
        });
    }
  }, [asPath]);

  useEffect(() => {
    if (!isLoading && isSuccessful) {
      setTimeout(() => {
        handleRedirectToApp();
      }, 1000);
    }
  }, [isLoading, isSuccessful]);

  return {
    isLoading,
    isSuccessful,
    message,
    handleRedirectToApp,
  };
};

export { useSuccessfulSocialLogin };
