import React, { FC } from "react";
import styled from "styled-components";
import { Button } from "./Button";
import { Modal } from "./Modal";

const StyledUnsavedFormModal = styled.div`
  .UnsavedFormModal__buttons {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
    margin-top: 16px;
  }
`;

export interface Props {
  isOpen: boolean;
  onCancel: () => void;
  onSubmit: () => void;
}

const UnsavedFormModal: FC<Props> = ({ isOpen, onCancel, onSubmit }) => {
  return (
    <StyledUnsavedFormModal>
      <Modal isOpen={isOpen}>
        <span>יש שינויים שלא נשמרו, האם ברצונך לעזוב את העמוד?</span>

        <div className="UnsavedFormModal__buttons">
          <Button type="button" onClick={onCancel}>
            ביטול
          </Button>

          <Button type="button" onClick={onSubmit}>
            המשך ללא שמירה
          </Button>
        </div>
      </Modal>
    </StyledUnsavedFormModal>
  );
};

export { UnsavedFormModal };
